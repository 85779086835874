.custom-grid {
  display: grid;
  grid-template: 1fr/1fr 1fr 1fr;
  gap: 10px;
  height: auto;
}

@media (max-width: 992px) {
  .custom-grid {
    height: 100%;
    grid-template: 1fr/1fr 1fr;
  }
}
@media (max-width: 772px) {
  .custom-grid {
    height: 100%;
    grid-template: 1fr/1fr;
  }
}

.error-style {
  color: red;
  font-size: 10px;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
