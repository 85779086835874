/* -------------------------------------------------------

    Theme Name: Crafto - The Multipurpose HTML5 Template
    Theme URL: https://craftohtml.themezaa.com/
    Description: Elevate your online presence with Crafto - a modern, versatile, multipurpose Bootstrap 5 responsive HTML5, SCSS template using highly creative 48+ ready demos.
    Author: ThemeZaa - https://www.themezaa.com/
    Author ThemeForest URL: https://themeforest.net/user/themezaa
    Copyright(c) 2024 themezaa.com
    Version: 1.0

------------------------------------------------------- */
/*

Table of contents

    01. Google font
    02. CSS color variable
    03. Reset
    04. Typography
    05. General
    06. Background and border
    07. Width
    08. Height
    09. Loop
    10. Header
    11. Page title
    12. Layout
    13. Elements
    14. Blog
    15. Portfolio
    16. Shop
    17. Footer

*/
/* ===================================
    01. Google font
====================================== */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");
:root {
  --alt-font: "Plus Jakarta Sans", sans-serif;
  --primary-font: "Inter", sans-serif;
}

/* ===================================
    02. CSS color variable
====================================== */
:root {
  --base-color: #2946f3;
  --white: #ffffff;
  --black: #000000;
  --red: #dc3131;
  --light-red: #feedec;
  --green: #2ebb79;
  --crusoe-green: #d39121;
  --yellow: #ffea23;
  --dark-gray: #232323;
  --medium-gray: #717580;
  --extra-medium-gray: #e4e4e4;
  --light-gray: #a8a8a8;
  --very-light-gray: #f7f7f7;
  --light-medium-gray: #eaeaeb;
  --charcoal-blue: #202329;
  --slate-blue: #262b35;
  --medium-slate-blue: #374162;
  --extra-medium-slate-blue: #23262d;
  --dark-slate-blue: #1f232c;
  --extra-dark-slate-blue: #121418;
  --extra-very-slate-blue: #161620;
  --tussock-yellow: #BC8947;
  --aluminium-grey:#80858F;
  --solitude-blue:#f0f4fd;
  --golden-yellow:#fd961e;
  --selago:#eaedff;
  --white-ice:#d8f5ef;
  --cornflower-blue:#445fed;
  --jade:#00AF6B;
  --orange: #ef991f;
  --majorelle-blue: #724ade;
  --light-majorelle-blue: #f2edfe;
  --spring-wood: #f9f6f3;
  --tropical-blue: #1ea3b1;
  --camarone: #20642b;
  --seal-brown: #0e0708;
  --Wasabi: #8ea63a;
}

/* ===================================
    03. Reset
====================================== */
.alt-font {
  font-family: var(--alt-font);
}
.primary-font {
  font-family: var(--primary-font) !important;
}
html {
  font-size: 16px;
}
body {
  font-family: var(--primary-font);
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: var(--medium-gray);
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
}
section {
  padding-top: 110px;
  padding-bottom: 110px;
}
section.half-section {
  padding-top: 70px;
  padding-bottom: 70px;
}
section.big-section {
  padding-top: 130px;
  padding-bottom: 130px;
}
section.extra-big-section {
  padding-top: 160px;
  padding-bottom: 160px;
}
img {
  max-width: 100%;
  height: auto;
}
ul li {
  list-style: none;
}
a {
  color: var(--medium-gray);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
}
a:hover {
  color: var(--base-color);
  text-decoration: none;
}
p {
  margin-bottom: 25px;
}
.last-paragraph-no-margin p:last-of-type {
  margin-bottom: 0;
}
video {
  background-size: cover;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
:focus-visible {
  outline: none;
}
[class*=line-icon], [class*=" line-icon"], [class*=bi-], [class*=feather], [class*=ti-] {
  display: inline-block;
}
.form-control, .form-control.is-invalid, .was-validated .form-control:invalid {
  background-position: right 20px center !important;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}
.g-recaptcha.is-invalid iframe {
  border: 1px solid #dc3545 !important;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  padding-right: 15px;
  padding-left: 15px;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.row > * {
  padding-right: 15px;
  padding-left: 15px;
}
.row.g-0 {
  margin-right: 0;
  margin-left: 0;
}
.row.g-0 > * {
  padding-right: 0;
  padding-left: 0;
}
.row.gutter-very-small {
  margin-left: -5px;
  margin-right: -5px;
}
.row.gutter-very-small > * {
  padding-right: 5px;
  padding-left: 5px;
}
.row.gutter-small {
  margin-left: -10px;
  margin-right: -10px;
}
.row.gutter-small > * {
  padding-right: 10px;
  padding-left: 10px;
}
/* ===================================
    04. Typography
====================================== */
/* Heading */
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 30px;
  letter-spacing: -0.5px;
  font-weight: inherit;
}
h1, .h1 {
  font-size: 4.375rem;
  line-height: 4.375rem;
}
h2, .h2 {
  font-size: 3.438rem;
  line-height: 3.438rem;
}
h3, .h3 {
  font-size: 2.813rem;
  line-height: 3.125rem;
}
h4 {
  font-size: 2.375rem;
  line-height: 2.7rem;
}
h5 {
  font-size: 2rem;
  line-height: 2.2rem;
}
h6 {
  font-size: 1.75rem;
  line-height: 1.95rem;
}
/* Font custom */
.font-style-italic {
  font-style: italic;
}
.hover-text {
  display: none;
}
b, strong {
  font-weight: 700;
}
.text-white-space-nowrap {
  white-space: nowrap;
}
.text-white-space-normal {
  white-space: normal;
}
.text-transform-none {
  text-transform: none !important;
}
.text-uppercase-inherit {
  text-transform: inherit !important;
}
.word-break-normal {
  word-break: normal;
}
.vertical-align-top {
  vertical-align: top;
}
/* Font size */
.fs-0 {
  font-size: 0;
}
.fs-9 {
  font-size: 9px;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
  line-height: 32px;
}
.fs-26 {
  font-size: 26px;
  line-height: 38px;
}
.fs-28 {
  font-size: 1.75rem;
  line-height: 2.6rem;
}
.fs-30 {
  font-size: 1.875rem;
  line-height: 2.8rem;
}
.fs-32 {
  font-size: 2rem;
  line-height: 2.5rem;
}
.fs-40 {
  font-size: 2.5rem;
  line-height: 2.5rem;
}
.fs-45 {
  font-size: 2.813rem;
  line-height: 3rem;
}
.fs-50 {
  font-size: 3.125rem;
  line-height: 3.25rem;
}
.fs-55 {
  font-size: 3.438rem;
  line-height: 3.5rem;
}
.fs-60 {
  font-size: 3.75rem;
  line-height: 3.75rem;
}
.fs-65 {
  font-size: 4.063rem;
  line-height: 4.688rem;
}
.fs-70 {
  font-size: 4.375rem;
  line-height: 4.375rem;
}
.fs-75 {
  font-size: 4.668rem;
  line-height: 4.668rem;
}
.fs-80 {
  font-size: 5rem;
  line-height: 5rem;
}
.fs-85 {
  font-size: 5.313rem;
  line-height: 5.313rem;
}
.fs-90 {
  font-size: 5.625rem;
  line-height: 5.625rem;
}
.fs-95 {
  font-size: 5.938rem;
  line-height: 5.938rem;
}
.fs-100 {
  font-size: 6.25rem;
  line-height: 6.25rem;
}
.fs-110 {
  font-size: 6.875rem;
  line-height: 6.875rem;
}
.fs-120 {
  font-size: 7.5rem;
  line-height: 7.5rem;
}
.fs-130 {
  font-size: 8.125rem;
  line-height: 8.125rem;
}
.fs-140 {
  font-size: 8.75rem;
  line-height: 8.75rem;
}
.fs-150 {
  font-size: 9.375rem;
  line-height: 9.375rem;
}
.fs-160 {
  font-size: 10rem;
  line-height: 10rem;
}
.fs-170 {
  font-size: 10.625rem;
  line-height: 10.625rem;
}
.fs-180 {
  font-size: 11.25rem;
  line-height: 11.25rem;
}
.fs-190 {
  font-size: 11.875rem;
  line-height: 11.875rem;
}
.fs-200 {
  font-size: 12.5rem;
  line-height: 12.5rem;
}
.fs-225 {
  font-size: 14.063rem;
  line-height: 14.063rem;
}
.fs-250 {
  font-size: 15.625rem;
  line-height: 15.625rem;
}
.fs-275 {
  font-size: 17.188rem;
  line-height: 17.188rem;
}
.fs-300 {
  font-size: 18.75rem;
  line-height: 18.75rem;
}
.fs-350 {
  font-size: 21.875rem;
  line-height: 21.875rem;
}
.fs-400 {
  font-size: 25rem;
  line-height: 25rem;
}
/* Line height */
.lh-0px {
  line-height: 0px;
}
.lh-10 {
  line-height: 10px;
}
.lh-11 {
  line-height: 11px;
}
.lh-12 {
  line-height: 12px;
}
.lh-13 {
  line-height: 13px;
}
.lh-14 {
  line-height: 14px;
}
.lh-15 {
  line-height: 15px;
}
.lh-16 {
  line-height: 16px;
}
.lh-18 {
  line-height: 18px;
}
.lh-19 {
  line-height: 19px;
}
.lh-20 {
  line-height: 20px;
}
.lh-22 {
  line-height: 22px;
}
.lh-24 {
  line-height: 24px;
}
.lh-26 {
  line-height: 26px;
}
.lh-28 {
  line-height: 28px;
}
.lh-30 {
  line-height: 30px;
}
.lh-32 {
  line-height: 2rem;
}
.lh-34 {
  line-height: 2.125rem;
}
.lh-36 {
  line-height: 2.25rem;
}
.lh-38 {
  line-height: 2.375rem;
}
.lh-40 {
  line-height: 2.5rem;
}
.lh-42 {
  line-height: 2.625rem;
}
.lh-44 {
  line-height: 2.75rem;
}
.lh-46 {
  line-height: 2.875rem;
}
.lh-48 {
  line-height: 3rem;
}
.lh-50 {
  line-height: 3.125rem;
}
.lh-55 {
  line-height: 3.438rem;
}
.lh-60 {
  line-height: 3.75rem;
}
.lh-65 {
  line-height: 4.063rem;
}
.lh-70 {
  line-height: 4.375rem;
}
.lh-75 {
  line-height: 4.688rem;
}
.lh-80 {
  line-height: 5rem;
}
.lh-85 {
  line-height: 5.313rem;
}
.lh-90 {
  line-height: 5.625rem;
}
.lh-95 {
  line-height: 5.938rem;
}
.lh-100 {
  line-height: 6.25rem;
}
.lh-105 {
  line-height: 6.563rem;
}
.lh-110 {
  line-height: 6.875rem;
}
.lh-115 {
  line-height: 7.188rem;
}
.lh-120 {
  line-height: 7.5rem;
}
.lh-0 {
  line-height: 0;
}
.lh-normal {
  line-height: normal;
}
.lh-initial {
  line-height: initial;
}
.lh-inherit {
  line-height: inherit;
}
/* Text color */
.text-white, .btn-link.text-white, a.text-white-hover:hover {
  color: var(--white);
}
.text-base-color-hover:hover {
  color: var(--base-color) !important;
}
.text-base-color, .btn-link.text-base-color {
  color: var(--base-color);
}
.text-black, .btn-link.text-black, a.text-black-hover:hover {
  color: var(--black);
}
.text-dark-gray, .btn-link.text-dark-gray, a.text-dark-gray-hover:hover {
  color: var(--dark-gray);
}
.text-medium-gray, .btn-link.text-medium-gray, a.text-medium-gray-hover:hover {
  color: var(--medium-gray);
}
.text-extra-medium-gray, .btn-link.text-extra-medium-gray, a.text-extra-medium-gray-hover:hover {
  color: var(--extra-medium-gray);
}
.text-light-medium-gray, .btn-link.text-light-medium-gray, a.text-light-medium-gray:hover {
  color: var(--light-medium-gray);
}
.text-light-gray, .btn-link.text-light-gray, a.text-light-gray-hover:hover {
  color: var(--light-gray);
}
.text-very-light-gray, .btn-link.text-very-light-gray, a.text-very-light-gray-hover:hover {
  color: var(--very-light-gray);
}
.text-red, .btn-link.text-red, a.text-red-hover:hover {
  color: var(--red);
}
.text-green, .btn-link.text-green, a.text-green-hover:hover {
  color: var(--green);
}
.text-yellow, .btn-link.text-yellow, a.text-yellow-hover:hover {
  color: var(--yellow);
}
.text-golden-yellow, .btn-link.text-golden-yellow, a.text-golden-yellow-hover:hover {
  color: var(--golden-yellow);
}
.text-tussock-yellow, .btn-link.text-tussock-yellow, a.text-tussock-yellow-hover:hover {
  color: var(--tussock-yellow);
}
.text-cornflower-blue, .btn-link.text-cornflower-blue, a.text-cornflower-blue-hover:hover {
  color: var(--cornflower-blue);
}
.text-jade, .btn-link.text-jade, a.text-jade-hover:hover {
  color: var(--jade);
}
.text-majorelle-blue {
  color: var(--majorelle-blue);
}
.text-orange {
  color: var(--orange);
}
.text-crusoe-green {
  color: var(--crusoe-green);
}
.text-tropical-blue {
  color: var(--tropical-blue);
}
.text-camarone {
  color: var(--camarone);
}
.text-seal-brown {
  color: var(--seal-brown);
}
.text-Wasabi {
  color: var(--Wasabi);
}
/* Text gradient color */
.text-gradient-blue-magenta-orange, .text-gradient-light-gray-white, .text-gradient-light-purple-light-orange, .text-gradient-sky-blue-pink, .text-gradient-light-blue-light-turquoise, .text-gradient-fast-blue-purple-light-orange, .text-gradient-light-pink-light-purple, .text-gradient-fast-blue-purple, .text-gradient-pink-orange, .text-gradient-fast-pink-light-yellow, .text-gradient-purple-pink, .text-gradient-light-pink-light-orange {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-light-pink-light-orange {
  background-image: linear-gradient(to right, #ffafbd, #ffb3b4, #ffb7ac, #ffbda5, #ffc3a0);
}
.text-gradient-purple-pink {
  background-image: linear-gradient(to right, #8b14b1, #ab019e, #c00c8b, #cd267b, #d53d6e);
}
.text-gradient-fast-pink-light-yellow {
  background-image: linear-gradient(to right, #f7567f, #fe7177, #ff8a74, #ffa378, #ffba83);
}
.text-gradient-pink-orange {
  background-image: linear-gradient(to right, #de347f, #e73a70, #ed4461, #f05152, #ef5f43);
}
.text-gradient-fast-blue-purple {
  background-image: linear-gradient(to right, #2a46f4, #5f42f9, #833cfc, #a233fe, #bf25ff);
}
.text-gradient-light-pink-light-purple {
  background-image: linear-gradient(to right, #ed579b, #e855ad, #df57c0, #d15cd4, #bc63e8);
}
.text-gradient-fast-blue-purple-light-orange {
  background-image: linear-gradient(to right top, #183fb9, #4f41df, #ae2eb9, #ef3f59, #e9522d);
}
.text-gradient-light-blue-light-turquoise {
  background-image: linear-gradient(to right, #18cdbb, #22d2ae, #3cd69d, #57da89, #73dc73);
}
.text-gradient-sky-blue-pink {
  background-image: linear-gradient(to right, #556fff, #556fff, #e05fc4, #f767a6, #ff798e);
}
.text-gradient-light-purple-light-orange {
  background-image: linear-gradient(to right, #b783ff, #b783ff, #fa7cc1, #ff85a6, #ff9393);
}
.text-gradient-light-gray-white {
  background-image: linear-gradient(to bottom, #efefef, #efefef, #f7f7f7, #fdfdfc, #ffffff);
}
.text-gradient-blue-magenta-orange {
  background-image: linear-gradient(to right, #002fff, #ab00ff, #ff137d, #ff562f, #ee8502);
}
/* Text shadow */
.text-shadow-large {
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.9);
}
.text-shadow-extra-large {
  text-shadow: 0 0 60px rgba(0, 0, 0, 0.9);
}
.text-shadow-double-large {
  text-shadow: 0 0 100px rgba(0, 0, 0, 0.9);
}
/* Text bottom line */
.text-decoration-line-bottom {
  border-bottom: 1px solid;
}
.text-decoration-line-bottom-medium {
  border-bottom: 2px solid;
}
.text-decoration-line-bottom-thick {
  border-bottom: 3px solid;
}
.text-decoration-line-through {
  text-decoration: line-through;
}
/* Text outline*/
.text-outline {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--white);
}
/* Text stroke width */
.text-outline-width-1px {
  -webkit-text-stroke-width: 1px;
}
.text-outline-width-2px {
  -webkit-text-stroke-width: 2px;
}
.text-outline-width-3px {
  -webkit-text-stroke-width: 3px;
}
.text-outline-width-4px {
  -webkit-text-stroke-width: 4px;
}
.text-outline-width-5px {
  -webkit-text-stroke-width: 5px;
}
/* Text stroke color */
.text-outline-color-black {
  -webkit-text-stroke-color: var(--black);
}
.text-outline-color-base-color {
  -webkit-text-stroke-color: var(--base-color);
}
.text-outline-color-red {
  -webkit-text-stroke-color: var(--red);
}
.text-outline-color-dark-gray {
  -webkit-text-stroke-color: var(--dark-gray);
}
.text-outline-color-medium-gray {
  -webkit-text-stroke-color: var(--medium-gray);
}
.text-outline-color-extra-medium-gray {
  -webkit-text-stroke-color: var(--extra-medium-gray);
}
/* Letter spacing */
.ls-0px {
  letter-spacing: 0px !important;
}
.ls-05px {
  letter-spacing: 0.5px !important;
}
.ls-1px {
  letter-spacing: 1px !important;
}
.ls-2px {
  letter-spacing: 2px !important;
}
.ls-3px {
  letter-spacing: 3px !important;
}
.ls-4px {
  letter-spacing: 4px !important;
}
.ls-5px {
  letter-spacing: 5px !important;
}
.ls-6px {
  letter-spacing: 6px !important;
}
.ls-7px {
  letter-spacing: 7px !important;
}
.ls-8px {
  letter-spacing: 8px !important;
}
.ls-9px {
  letter-spacing: 9px !important;
}
.ls-10px {
  letter-spacing: 10px !important;
}
.ls-minus-05px {
  letter-spacing: -0.5px !important;
}
.ls-minus-1px {
  letter-spacing: -1px !important;
}
.ls-minus-2px {
  letter-spacing: -2px !important;
}
.ls-minus-3px {
  letter-spacing: -3px !important;
}
.ls-minus-4px {
  letter-spacing: -4px !important;
}
.ls-minus-5px {
  letter-spacing: -5px !important;
}
.ls-minus-6px {
  letter-spacing: -6px !important;
}
.ls-minus-7px {
  letter-spacing: -7px !important;
}
.ls-minus-8px {
  letter-spacing: -8px !important;
}
.ls-minus-9px {
  letter-spacing: -9px !important;
}
.ls-minus-10px {
  letter-spacing: -10px !important;
}
/* Icon size */
.icon-extra-double-large {
  font-size: 80px;
}
.icon-double-large {
  font-size: 60px;
}
.icon-extra-large {
  font-size: 50px;
}
.icon-large {
  font-size: 42px;
}
.icon-medium {
  font-size: 34px;
}
.icon-very-medium {
  font-size: 28px;
}
.icon-extra-medium {
  font-size: 24px;
}
.icon-small {
  font-size: 18px;
}
.icon-very-small {
  font-size: 14px;
}
/* ===================================
    05. General
====================================== */
/* Separator line */
.separator-line-1px {
  height: 1px;
}
.separator-line-2px {
  height: 2px;
}
.separator-line-3px {
  height: 3px;
}
.separator-line-4px {
  height: 4px;
}
.separator-line-5px {
  height: 5px;
}
/* Separator vertical line */
.separator-line-vertical {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
/* Separator line size */
.separator-line-small {
  width: 35px;
  height: 1px;
  display: inline-block;
  margin-right: 7px;
}
.separator-line-extra-small {
  width: 18px;
  height: 1px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}
.separator-line-very-small {
  width: 12px;
  height: 1px;
  display: inline-block;
  margin-right: 9px;
  vertical-align: middle;
}
/* Opacity */
.opacity-full-dark, .opacity-full, .opacity-medium, .opacity-extra-medium, .opacity-light, .opacity-very-light {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.opacity-very-light {
  opacity: 0.2;
}
.opacity-light {
  opacity: 0.3;
}
.opacity-extra-medium {
  opacity: 0.5;
}
.opacity-medium {
  opacity: 0.75;
}
.opacity-full {
  opacity: 0.8;
}
.opacity-full-dark {
  opacity: 0.9;
}
/* Position */
.position-inherit {
  position: inherit !important;
}
.position-initial {
  position: initial !important;
}
.absolute-middle-center {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.absolute-middle-left {
  left: 0;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.absolute-middle-right {
  right: 0;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.absolute-top-center {
  left: 50%;
  top: 0;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.absolute-bottom-center {
  left: 50%;
  bottom: 0;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.absolute-bottom-left {
  left: 0;
  bottom: 0;
  position: absolute;
}
.absolute-bottom-right {
  right: 0;
  bottom: 0;
  position: absolute;
}
/* Vertical title center */
.vertical-title-center {
  display: flex;
  height: 100%;
}
.vertical-title-center .title {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  writing-mode: vertical-lr;
}
/* Skrollr */
.skrollr-parallax {
  position: relative !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  transition-timing-function: none;
  -webkit-transition-timing-function: none;
  transition-duration: 0s;
  -webkit-transition-duration: 0s;
}
.skrollr-zoom {
  transition: none !important;
}
.blur-box {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(3px);
}
/* Transform */
.transform-origin-right {
  transform-origin: right;
}
.transform-origin-left {
  transform-origin: left;
}
.btn.btn-none-transform, .btn.btn-none-transform:hover {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
/* Transition */
.transition {
  -webkit-transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
}
.transition-inner-all * {
  -webkit-transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
}
.no-transition {
  -webkit-transition: 0s;
  transition: 0s;
}
/* Pointer events */
.pointer-events-none {
  pointer-events: none;
}
.pointer-events-all {
  pointer-events: all;
}
/* Sticky column */
.sticky-wrap {
  position: fixed;
  top: 50%;
  left: 0;
  opacity: 0;
  z-index: 1;
  -webkit-transform: rotate(-90deg) translate(-50%, 50%);
  transform: rotate(-90deg) translate(-50%, 50%);
  transform-origin: 0 50%;
  transition: 1s cubic-bezier(0.7, 0, 0.3, 1) !important;
}
.sticky-wrap.shadow-in {
  left: 50px;
  opacity: 1;
  box-shadow: none !important;
}
.sticky-wrap.sticky-hidden {
  left: 0;
  opacity: 0;
}
.sticky-wrap a {
  color: var(--dark-gray) !important;
}
.sticky-wrap a:hover {
  color: var(--dark-gray) !important;
}
.sticky-wrap div, .sticky-wrap span, .sticky-wrap p {
  color: var(--dark-gray) !important;
}
.sticky-wrap .social-icon-style-10 ul li a:hover {
  color: var(--dark-gray) !important;
}
.sticky-wrap.sticky-highlight a {
  color: var(--white) !important;
}
.sticky-wrap.sticky-highlight a:hover {
  color: var(--white) !important;
}
.sticky-wrap.sticky-highlight div, .sticky-wrap.sticky-highlight span, .sticky-wrap.sticky-highlight p {
  color: var(--white) !important;
}
.sticky-wrap.sticky-highlight .separator-line-1px {
  background-color: var(--white) !important;
}
.sticky-wrap.sticky-highlight .social-icon-style-10 ul li a:hover {
  color: var(--white) !important;
}
.sticky-wrap-right {
  position: fixed;
  top: 50%;
  right: 110px;
  z-index: 1;
  -webkit-transform: rotate(-90deg) translate(50%, 100%);
  transform: rotate(-90deg) translate(50%, 100%);
  transform-origin: 100% 0%;
}
/* Overflow */
.overflow-visible {
  overflow: visible !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}
/* Overlap section */
.overlap-section {
  position: relative;
}
.overlap-height {
  transition: 0.3s height;
}
/* Outside box */
.outside-box-top-15 {
  margin-top: -15vw;
}
.outside-box-top-18 {
  margin-top: -18vw;
}
.outside-box-left-1 {
  margin-left: -1vw;
}
.outside-box-left-2 {
  margin-left: -2vw;
}
.outside-box-left-5 {
  margin-left: -5vw;
}
.outside-box-left-7 {
  margin-left: -7vw;
}
.outside-box-left-8 {
  margin-left: -8vw;
}
.outside-box-left-10 {
  margin-left: -10vw;
}
.outside-box-left-15 {
  margin-left: -15vw;
}
.outside-box-left-20 {
  margin-left: -20vw;
}
.outside-box-left-25 {
  margin-left: -25vw;
}
.outside-box-left-30 {
  margin-left: -30vw;
}
.outside-box-left-35 {
  margin-left: -35vw;
}
.outside-box-left-40 {
  margin-left: -40vw;
}
.outside-box-left-50 {
  margin-left: -50vw;
}
.outside-box-left-60 {
  margin-left: -60vw;
}
.outside-box-left-65 {
  margin-left: -65vw;
}
.outside-box-right-1 {
  margin-right: -1vw;
}
.outside-box-right-2 {
  margin-right: -2vw;
}
.outside-box-right-5 {
  margin-right: -5vw;
}
.outside-box-right-7 {
  margin-right: -7vw;
}
.outside-box-right-10 {
  margin-right: -10vw;
}
.outside-box-right-15 {
  margin-right: -15vw;
}
.outside-box-right-20 {
  margin-right: -20vw;
}
.outside-box-right-25 {
  margin-right: -25vw;
}
.outside-box-right-30 {
  margin-right: -30vw;
}
.outside-box-right-35 {
  margin-right: -35vw;
}
.outside-box-right-40 {
  margin-right: -40vw;
}
.outside-box-right-45 {
  margin-right: -45vw;
}
.outside-box-right-50 {
  margin-right: -50vw;
}
.outside-box-right-60 {
  margin-right: -60vw;
}
.outside-box-right-65 {
  margin-right: -65vw;
}
/* CSS filter */
.filter-mix-100 {
  filter: grayscale(100%) invert(100%);
}
.filter-grayscale-100 {
  filter: grayscale(100%);
}
/* Box shadow */
.box-shadow, .box-shadow-hover:hover {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  -webkit-transition: 350ms ease-in-out;
  transition: 350ms ease-in-out;
}
.box-shadow-small, .box-shadow-small-hover:hover {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
.box-shadow-medium, .box-shadow-medium-hover:hover {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
.box-shadow-medium-bottom, .box-shadow-medium-bottom-hover:hover {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
.box-shadow-large, .box-shadow-large-hover:hover {
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
.box-shadow-extra-large, .box-shadow-extra-large-hover:hover {
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
.box-shadow-double-large, .box-shadow-double-large-hover:hover {
  -webkit-box-shadow: 0 0 45px rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.09);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
.box-shadow-triple-large, .box-shadow-triple-large-hover:hover {
  -webkit-box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
.box-shadow-quadruple-large, .box-shadow-quadruple-large-hover:hover {
  -webkit-box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
.box-shadow-hover:hover, .box-shadow-large-hover:hover, .box-shadow-extra-large-hover:hover, .box-shadow-medium-hover:hover, .box-shadow-small-hover:hover, .box-shadow-double-large-hover:hover, .box-shadow-quadruple-large-hover:hover {
  -webkit-transform: translate3d(0, -3px, 0);
  transform: translate3d(0, -3px, 0);
}
.box-shadow-bottom {
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05);
}
/* Input */
input, select, textarea, .form-control, .form-select {
  padding: 12px 25px;
  width: 100%;
  max-width: 100%;
  resize: none;
  outline: none;
  font-size: 16px;
  border: 1px solid #e4e4e4;
  color: var(--medium-gray);
  line-height: inherit;
  word-break: normal;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.form-select {
  color: var(--medium-gray);
}
.form-select:focus {
  border-color: #dfdfdf;
}
input:focus, select:focus, textarea:focus, .form-control:focus {
  border: 1px solid #c2c2c2;
  box-shadow: none !important;
  color: var(--medium-gray);
}
::-webkit-input-placeholder {
  color: var(--medium-gray) !important;
  text-overflow: ellipsis;
}
::-moz-placeholder {
  color: var(--medium-gray) !important;
  text-overflow: ellipsis;
  opacity: 1;
}
:-ms-input-placeholder {
  color: var(--medium-gray) !important;
  text-overflow: ellipsis;
  opacity: 1;
}
.placeholder-light::-webkit-input-placeholder {
  color: var(--white) !important;
  text-overflow: ellipsis;
}
.placeholder-light::-moz-placeholder {
  color: var(--white) !important;
  text-overflow: ellipsis;
  opacity: 1;
}
.placeholder-light:-ms-input-placeholder {
  color: var(--white) !important;
  text-overflow: ellipsis;
  opacity: 1;
}
.search-error {
  border-color: var(--red) !important;
}
/* Select */
.select {
  position: relative;
}
.select:after {
  content: "\e842";
  font-family: feather !important;
  right: 20px;
  top: 50%;
  height: 34px;
  position: absolute;
  pointer-events: none;
  font-size: 22px;
  margin-top: -15px;
}
/* Date icon */
.date-time {
  position: relative;
}
.date-icon {
  position: relative;
}
.date-icon input[type=date]::-webkit-inner-spin-button,
.date-icon input[type=date]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  opacity: 0;
  -moz-appearance: none;
  appearance: none;
}
.date-icon:after {
  content: "\e83a";
  font-family: feather !important;
  right: 28px;
  top: 50%;
  height: 34px;
  position: absolute;
  pointer-events: none;
  font-size: 22px;
  margin-top: -15px;
}
.date-icon input[type=date]::-webkit-date-and-time-value {
  text-align: left !important;
}
/* Mozilla firefox */
@-moz-document url-prefix() {
  .date-icon:after {
    display: none;
  }
}
/* Time icon */
.time-icon {
  position: relative;
}
.time-icon input[type=time]::-webkit-inner-spin-button,
.time-icon input[type=time]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  opacity: 0;
  -moz-appearance: none;
  appearance: none;
}
.time-icon:after {
  content: "\e84d";
  font-family: feather !important;
  right: 28px;
  top: 50%;
  height: 34px;
  position: absolute;
  pointer-events: none;
  font-size: 22px;
  margin-top: -15px;
}
/* Input size */
.input-small,
.textarea-small,
.select-small {
  padding: 7px 15px;
  font-size: 12px;
}
.input-medium,
.textarea-medium,
.select-medium {
  padding: 14px 28px;
}
.input-large {
  padding: 16px 32px;
}
.form-control.is-invalid:focus {
  box-shadow: none;
}
/* Box overlay */
.box-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 400ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 400ms cubic-bezier(0.5, 1, 0.89, 1);
}
.hover-box {
  z-index: 1;
}
.hover-box * {
  -webkit-transition: all 500ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 500ms cubic-bezier(0.5, 1, 0.89, 1);
  will-change: transform;
}
.hover-box:hover .box-overlay {
  opacity: 1;
  visibility: visible;
}
.hover-box.dark-hover .border-dark-opacity {
  border-color: rgba(35, 35, 35, 0.15) !important;
}
.hover-box.dark-hover:hover a, .hover-box.dark-hover:hover p, .hover-box.dark-hover:hover span, .hover-box.dark-hover:hover .btn, .hover-box.dark-hover:hover i, .hover-box.dark-hover:hover b, .hover-box.dark-hover:hover u, .hover-box.dark-hover:hover p, .hover-box.dark-hover:hover h1, .hover-box.dark-hover:hover h2, .hover-box.dark-hover:hover h3, .hover-box.dark-hover:hover h4, .hover-box.dark-hover:hover h5, .hover-box.dark-hover:hover h6 {
  color: var(--white) !important;
  -webkit-text-stroke-color: var(--white);
}
.hover-box.dark-hover:hover [class*=separator-line] {
  background-color: var(--white);
}
.hover-box.dark-hover:hover [class*=btn-link-gradient] span {
  background: var(--white);
  background-image: none;
}
.hover-box.dark-hover:hover [class*=btn-transparent] {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--dark-gray) !important;
}
.hover-box.dark-hover:hover .text-light-opacity {
  opacity: 0.7;
}
.hover-box.dark-hover:hover .text-medium-opacity {
  opacity: 0.5;
}
.hover-box.dark-hover:hover .border-dark-opacity {
  border-color: rgba(255, 255, 255, 0.3) !important;
}
.hover-box.dark-hover:hover .content-slide-up .text-light-opacity {
  opacity: 0.7;
}
.hover-box.dark-hover:hover .btn-link {
  border-color: var(--white);
}
.hover-box.dark-hover:hover [class*=text-gradient-] {
  color: var(--white);
  background-image: none;
  -webkit-background-clip: inherit;
  -webkit-text-fill-color: var(--white);
  -webkit-text-fill-color: var(--white);
}
.hover-box.light-hover .border-light-opacity {
  border-color: rgba(255, 255, 255, 0.25) !important;
}
.hover-box.light-hover:hover a, .hover-box.light-hover:hover span, .hover-box.light-hover:hover .btn, .hover-box.light-hover:hover i, .hover-box.light-hover:hover b, .hover-box.light-hover:hover u, .hover-box.light-hover:hover p, .hover-box.light-hover:hover h1, .hover-box.light-hover:hover h2, .hover-box.light-hover:hover h3, .hover-box.light-hover:hover h4, .hover-box.light-hover:hover h5, .hover-box.light-hover:hover h6 {
  color: var(--dark-gray) !important;
}
.hover-box.light-hover:hover .text-light-opacity {
  opacity: 0.7;
}
.hover-box.light-hover:hover .text-medium-opacity {
  opacity: 0.5;
}
.hover-box.light-hover:hover .border-light-opacity {
  border-color: rgba(35, 35, 35, 0.15) !important;
}
.hover-box.light-hover:hover .content-slide-up .text-light-opacity {
  opacity: 0.7;
}
.hover-box.light-hover:hover .btn-link {
  border-color: var(--dark-gray);
}
.hover-box.light-hover:hover [class*=separator-line] {
  background-color: var(--dark-gray);
}
.hover-box.light-hover:hover [class*=text-gradient-] {
  color: var(--dark-gray);
  background-image: none;
  -webkit-background-clip: var(--dark-gray);
  -webkit-text-fill-color: var(--dark-gray);
}
/* Swiper slider pagination */
.swiper-horizontal > .swiper-pagination-bullets, .swiper-vertical > .swiper-pagination-bullets {
  bottom: 45px;
  left: 50%;
  width: auto;
  cursor: default;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.swiper-horizontal > .swiper-pagination-bullets-right, .swiper-vertical > .swiper-pagination-bullets-right {
  bottom: auto;
  top: 50%;
  right: 60px;
  left: auto;
  width: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: right;
}
.swiper-horizontal > .swiper-pagination-bullets-right .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets-right .swiper-pagination-bullet {
  display: block;
  margin: 25px 0 !important;
}
.swiper-dark-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  margin: 0 15px !important;
  opacity: 1;
  background-color: var(--light-gray);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  outline: none;
}
.swiper-dark-pagination .swiper-pagination-bullet:before {
  content: "";
  width: 30px;
  height: 30px;
  border: 1px solid var(--dark-gray);
  background: transparent;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}
.swiper-dark-pagination .swiper-pagination-bullet:hover {
  background-color: var(--dark-gray);
}
.swiper-dark-pagination .swiper-pagination-bullet:hover:before {
  opacity: 1;
}
.swiper-dark-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--dark-gray);
}
.swiper-dark-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  opacity: 1;
}
.swiper-light-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  margin: 0 15px !important;
  display: inline-block;
  vertical-align: middle;
  background-color: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  outline: none;
  opacity: 0.5;
}
.swiper-light-pagination .swiper-pagination-bullet:before {
  content: "";
  width: 30px;
  height: 30px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: transparent;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}
.swiper-light-pagination .swiper-pagination-bullet:hover {
  background-color: var(--white);
  opacity: 1;
}
.swiper-light-pagination .swiper-pagination-bullet:hover:before {
  opacity: 1;
}
.swiper-light-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--white);
  opacity: 1;
}
.swiper-light-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  opacity: 1;
}
/* Swiper pagination style 02 */
.swiper-pagination-style-2 .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  margin: 0 6px !important;
}
.swiper-pagination-style-2 .swiper-pagination-bullet:before {
  display: none;
}
/* Swiper pagination style 03 */
.swiper-pagination-style-3 .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background: transparent;
  opacity: 0.4;
  margin: 0 7px !important;
  border: 2px solid var(--white);
}
.swiper-pagination-style-3 .swiper-pagination-bullet:hover, .swiper-pagination-style-3 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--white);
  opacity: 1;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.swiper-pagination-style-3 .swiper-pagination-bullet:before {
  display: none;
}
.swiper-pagination-style-3.dark .swiper-pagination-bullet {
  border: 2px solid var(--dark-gray);
}
.swiper-pagination-style-3.dark .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--dark-gray);
}
/* Swiper pagination style 04 */
.swiper-pagination-style-4 .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: var(--white);
  opacity: 1;
  position: relative;
  margin: 0 25px !important;
  border: 0;
}
.swiper-pagination-style-4 .swiper-pagination-bullet:hover, .swiper-pagination-style-4 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--base-color);
  opacity: 1;
  border: 0;
}
.swiper-pagination-style-4.dark .swiper-pagination-bullet {
  background: var(--dark-gray);
}
.swiper-pagination-style-4.dark .swiper-pagination-bullet:hover, .swiper-pagination-style-4.dark .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--base-color);
}
/* Custom image pagination */
.slider-custom-image .slider-custom-image-pagination {
  display: flex;
  align-items: center;
  bottom: 0;
}
.slider-custom-image .slider-custom-image-pagination .swiper-pagination-bullet {
  margin: 0 15px !important;
  outline: none;
  opacity: 0.8;
  width: 100px;
  height: 100px;
  margin: 0 15px !important;
  background-size: cover;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.slider-custom-image .slider-custom-image-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 1;
}
.slider-custom-image .slider-custom-image-pagination.slider-custom-image-pagination-top {
  top: 0;
  bottom: auto;
}
/* Swiper number pagination style 01 */
.swiper-number-pagination-style-01 .swiper-number {
  text-align: left;
  bottom: 70px;
}
.swiper-number-pagination-style-01 .swiper-number .swiper-pagination-bullet {
  position: relative;
  padding-right: 10px;
  padding-left: 8px;
  margin: 0;
  color: var(--white);
  opacity: 0.7;
  width: auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: auto;
  background: transparent;
  border-radius: 0;
  outline: none;
}
.swiper-number-pagination-style-01 .swiper-number .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}
.swiper-number-pagination-style-01 .swiper-number .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  width: 70px;
}
.swiper-number-pagination-style-01 .swiper-number .swiper-pagination-bullet:after {
  width: 0;
  height: 1px;
  content: "";
  margin-left: 15px;
  display: inline-block;
  background: var(--white);
  vertical-align: middle;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
/* Swiper number pagination style 02 */
.swiper-number-pagination-style-02 .swiper-number {
  width: auto;
  left: inherit;
  right: 60px;
  padding: 0;
}
.swiper-number-pagination-style-02 .swiper-number .swiper-pagination-bullet {
  position: relative;
  font-family: var(--alt-font);
  font-size: 15px;
  display: block;
  color: var(--white);
  margin: 10px 0 !important;
  opacity: 0.7;
  width: 56px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 56px;
  background: transparent;
  border-radius: 100%;
  text-align: center;
  line-height: 56px;
  outline: none;
}
.swiper-number-pagination-style-02 .swiper-number .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}
.swiper-number-pagination-style-02 .swiper-number .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: rgba(0, 0, 0, 0.45);
  z-index: -1;
}
.swiper-number-pagination-style-02 .swiper-number .swiper-pagination-bullet:before {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}
/* Swiper line pagination style 01 */
.swiper-line-pagination-style-01 .swiper-line-pagination {
  text-align: center;
  bottom: 0;
  width: 100%;
  left: 0;
}
.swiper-line-pagination-style-01 .swiper-line-pagination .swiper-pagination-bullet {
  width: 100px;
  height: 2px;
  margin: 0 !important;
  border-radius: 0;
}
.swiper-line-pagination-style-01 .swiper-line-pagination .swiper-pagination-bullet:before {
  display: none;
}
.swiper-line-pagination-style-01 .swiper-line-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--base-color);
}
.swiper-h-pagination:after {
  display: none;
}
/* Swiper number line pagination style */
.swiper-number-pagination-progress .swiper-pagination-wrapper .number-prev, .swiper-number-pagination-progress .swiper-pagination-wrapper .number-next {
  width: 50px;
  text-align: center;
}
.swiper-number-pagination-progress .swiper-pagination-wrapper .swiper-pagination {
  position: inherit;
  width: auto;
}
.swiper-number-pagination-progress .swiper-pagination-wrapper .swiper-pagination .swiper-pagination-bullet {
  width: 60px;
  height: 1px;
  vertical-align: middle;
  margin: 0;
  background-color: var(--medium-gray);
}
/* Swiper number navigation style */
.swiper-number-navigation-style .swiper-button-prev, .swiper-number-navigation-style .swiper-button-next {
  top: inherit;
  bottom: 0;
  position: inherit;
  display: inline-block;
  vertical-align: middle;
  transform: none;
  width: auto;
  height: auto;
}
.swiper-number-navigation-style .swiper-button-prev i, .swiper-number-navigation-style .swiper-button-prev .number-prev, .swiper-number-navigation-style .swiper-button-prev .number-next, .swiper-number-navigation-style .swiper-button-next i, .swiper-number-navigation-style .swiper-button-next .number-prev, .swiper-number-navigation-style .swiper-button-next .number-next {
  display: inline-block;
  vertical-align: middle;
}
.swiper-number-navigation-style .swiper-button-prev .number-prev, .swiper-number-navigation-style .swiper-button-prev .number-next, .swiper-number-navigation-style .swiper-button-next .number-prev, .swiper-number-navigation-style .swiper-button-next .number-next {
  width: 50px;
  text-align: center;
}
/* Swiper pagination progress style */
.swiper-pagination-progress {
  width: 220px;
  height: 1px;
  position: relative;
}
.swiper-pagination-progress .swiper-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--swiper-progress, 0);
  background-color: var(--base-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
/* Swiper pagination progress style */
.swiper-number-pagination-progress-vertical .swiper-pagination-wrapper {
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
}
.swiper-number-pagination-progress-vertical .swiper-pagination-wrapper .swiper-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: var(--swiper-progress, 0);
  background-color: var(--dark-gray);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.swiper-number-pagination-progress-vertical .swiper-pagination-wrapper .pagination-progress-vertical {
  transform: rotate(180deg);
  writing-mode: vertical-lr;
}
.swiper-number-pagination-progress-vertical .swiper-pagination-wrapper .swiper-pagination-progress {
  width: 2px;
  height: 100px;
  background-color: var(--extra-medium-gray);
}
.swiper-number-pagination-progress-vertical .swiper-pagination-wrapper .number-next {
  margin-top: 15px;
  width: auto;
}
.swiper-number-pagination-progress-vertical .swiper-pagination-wrapper .number-prev {
  margin-bottom: 15px;
  width: auto;
}
/* Swiper pagination bottom */
.swiper-pagination-bottom .swiper-button-prev, .swiper-pagination-bottom .swiper-button-next {
  top: inherit;
  bottom: 0;
}
/* Swiper navigation */
.swiper-button-prev, .swiper-button-next {
  outline: none;
  cursor: pointer;
  height: 45px;
  width: 45px;
  margin-top: 0;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.swiper-button-prev:hover, .swiper-button-next:hover {
  opacity: 0.8;
}
.swiper-button-next {
  left: inherit;
}
/* Swiper navigation style 01 */
.slider-navigation-style-01 {
  position: absolute;
  top: 50%;
  left: 65px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.slider-navigation-style-01.swiper-button-next {
  right: 65px;
  left: inherit;
}
.slider-navigation-style-01:hover {
  opacity: 0.8;
}
/* Swiper navigation style 02 */
.slider-navigation-style-02 {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
}
.slider-navigation-style-02.swiper-button-next {
  right: 0;
  left: auto;
}
/* Swiper navigation style 03 */
.slider-navigation-style-03 {
  position: absolute;
  top: 50%;
  left: -60px;
  width: 40px;
  height: 40px;
  background: var(--white);
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.slider-navigation-style-03.swiper-button-next {
  right: -60px;
  left: inherit;
}
.slider-navigation-style-03:hover {
  -webkit-box-shadow: 0px 0 20px rgba(35, 35, 35, 0.1);
  box-shadow: 0px 0 20px rgba(35, 35, 35, 0.1);
}
/* Swiper navigation style 04 */
.slider-navigation-style-04 {
  height: 56px;
  width: 56px;
  border-radius: 100%;
  position: relative;
  bottom: inherit;
  left: 0;
  top: inherit;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.slider-navigation-style-04 i {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.slider-navigation-style-04:hover i {
  opacity: 0.5;
}
.slider-navigation-style-04.swiper-button-prev {
  margin-right: 5px;
}
.slider-navigation-style-04.swiper-button-next {
  margin-left: 5px;
}
/* Swiper navigation style 05 */
.slider-navigation-style-05 {
  left: -30%;
}
.slider-navigation-style-05.swiper-button-next {
  right: -30%;
  left: inherit;
}
/* Swiper navigation style 06 */
.slider-navigation-style-06 {
  width: auto;
  height: auto;
  left: 60px;
}
.slider-navigation-style-06.swiper-button-next {
  right: 60px;
  left: inherit;
}
/* Swiper navigation style 07 */
.slider-navigation-style-07 {
  left: 50%;
  bottom: 0;
  top: inherit;
  width: 75px;
  height: 75px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.slider-navigation-style-07.swiper-button-next {
  bottom: 1px;
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}
/* Swiper navigation style 08 */
.slider-navigation-style-08 {
  width: auto;
  height: auto;
  position: relative;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  left: 0;
}
.slider-navigation-style-08.swiper-button-next {
  right: 0;
  left: inherit;
}
/* Slider custom text */
.slider-custom-verticle-text > span {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.swiper-button-prev.slider-custom-text-prev, .swiper-button-prev.slider-custom-text-next, .swiper-button-next.slider-custom-text-prev, .swiper-button-next.slider-custom-text-next {
  width: auto;
  height: auto;
  min-height: 125px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.swiper-button-prev.slider-custom-text-prev:hover, .swiper-button-prev.slider-custom-text-next:hover, .swiper-button-next.slider-custom-text-prev:hover, .swiper-button-next.slider-custom-text-next:hover {
  opacity: 0.5;
}
.swiper-button-prev.slider-custom-text-prev {
  border-right: 1px solid var(--extra-medium-gray);
  padding-right: 50px;
}
.swiper-button-next.slider-custom-text-next {
  border-left: 1px solid var(--extra-medium-gray);
  padding-left: 50px;
}
/* Swiper width auto */
.swiper-width-auto .swiper-slide {
  width: auto;
}
/* Slider shadow */
.slider-shadow-right {
  mask-image: linear-gradient(to right, transparent 0%, #000000 0%, #000000 50%, transparent 80%);
  -webkit-mask-image: linear-gradient(to right, transparent 0%, #000000 0%, #000000 50%, transparent 80%);
}
.slider-shadow-left {
  mask-image: linear-gradient(to right, transparent 50%, #000000 80%, #000000 0%, transparent 0%);
  -webkit-mask-image: linear-gradient(to right, transparent 50%, #000000 80%, #000000 0%, transparent 0%);
}
.slider-shadow-left-right {
  mask-image: linear-gradient(to right, transparent 18%, #000000 22%, #000000 78%, transparent 82%);
  -webkit-mask-image: linear-gradient(to right, transparent 18%, #000000 22%, #000000 78%, transparent 82%);
}
.slider-shadow-none .swiper {
  mask-image: none !important;
  -webkit-mask-image: none !important;
}
/* Highlight separator */
.highlight-separator {
  position: relative;
  padding-bottom: 20px;
}
.highlight-separator[data-shadow-animation] {
  box-shadow: none !important;
  display: inline-block;
}
.highlight-separator[data-shadow-animation] span {
  clip-path: inset(0px 100% 0px 0px);
  float: left;
  width: 100%;
  height: 20px;
  transition: clip-path 1s;
  transition-timing-function: cubic-bezier(0.4, 0.6, 0.4, 1);
  position: absolute;
  left: 0;
  bottom: 0;
}
.highlight-separator[data-shadow-animation] span img {
  position: absolute;
  left: 0;
  bottom: 0;
  transition: none;
  width: 100%;
  height: 14px;
}
.highlight-separator.shadow-in[data-shadow-animation] span {
  clip-path: inset(0px 0 0px 0px);
}
/* Full screen */
.full-screen {
  height: 100vh;
}
.full-screen.top-space-margin {
  height: calc(100vh - 96px);
}
/* Video icons */
@-webkit-keyframes video-icon-sonar {
  0% {
    opacity: 1;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes video-icon-sonar {
  0% {
    opacity: 1;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.video-icon-box {
  z-index: 1;
}
.video-icon-box > span {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
/* HTML video play */
.html-video-play .video-icon .play-icon, .html-video-play .video-icon .pause-icon {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.html-video-play .video-icon .play-icon, .html-video-play .video-icon .pause-icon {
  opacity: 1;
  top: 50%;
  padding-left: 5px;
  visibility: visible;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.html-video-play .video-icon .pause-icon {
  top: 100%;
  padding-left: 0;
  opacity: 0;
}
.html-video-play[playing=true] .video-icon .pause-icon {
  top: 50%;
  opacity: 1;
}
.html-video-play[playing=true] .video-icon .play-icon {
  top: 0%;
  opacity: 0;
}
.video-play-icon {
  z-index: 1 !important;
}
.video-play-icon.remove-play-icon + .html-video-play {
  opacity: 0 !important;
}
.video-play-icon.remove-play-icon + .html-video-play:hover {
  opacity: 1 !important;
}
.video-play-icon.remove-play-icon:hover + .html-video-play {
  opacity: 1 !important;
}
.video-icon {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 50%;
}
.video-icon i {
  margin-left: 5px;
}
.video-icon .video-icon-sonar {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  z-index: -10;
  -webkit-animation: video-icon-sonar 2s linear infinite;
  animation: video-icon-sonar 2s linear infinite;
}
.video-icon .video-icon-sonar .video-icon-sonar-bfr {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: -25px;
  left: -25px;
  z-index: -100;
}
.video-icon .video-icon-sonar .video-icon-sonar-afr {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: -10px;
  left: -10px;
  z-index: -50;
}
.video-icon-small .video-icon {
  width: 40px;
  height: 40px;
  font-size: 11px;
}
.video-icon-small .video-icon .video-icon-sonar .video-icon-sonar-bfr {
  top: -15px;
  left: -15px;
  width: 70px;
  height: 70px;
}
.video-icon-small .video-icon .video-icon-sonar .video-icon-sonar-afr {
  top: 0px;
  left: 0px;
  width: 40px;
  height: 40px;
}
.video-icon-medium .video-icon {
  width: 50px;
  height: 50px;
  font-size: 14px;
}
.video-icon-medium .video-icon .video-icon-sonar .video-icon-sonar-bfr {
  width: 100px;
  height: 100px;
}
.video-icon-medium .video-icon .video-icon-sonar .video-icon-sonar-afr {
  width: 70px;
  height: 70px;
}
.video-icon-large.video-icon-box .video-icon i {
  margin-left: 5px;
}
.video-icon-large .video-icon {
  width: 85px;
  height: 85px;
  font-size: 20px;
}
.video-icon-large .video-icon .video-icon-sonar .video-icon-sonar-bfr {
  width: 135px;
  height: 135px;
}
.video-icon-large .video-icon .video-icon-sonar .video-icon-sonar-afr {
  width: 105px;
  height: 105px;
}
.video-icon-extra-large.video-icon-box .video-icon i {
  margin-left: 10px;
}
.video-icon-extra-large .video-icon {
  width: 120px;
  height: 120px;
  font-size: 30px;
}
.video-icon-extra-large .video-icon .video-icon-sonar .video-icon-sonar-bfr {
  width: 170px;
  height: 170px;
}
.video-icon-extra-large .video-icon .video-icon-sonar .video-icon-sonar-afr {
  width: 140px;
  height: 140px;
}
/* Will change */
.will-change-transform * {
  will-change: transform;
}
.will-change-inherit * {
  will-change: inherit !important;
}
.animation-rotation {
  -webkit-animation: rotation 8s infinite linear;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
/* Float animation */
.animation-float {
  animation: float 2000ms linear 500ms infinite alternate both;
}
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(30px);
  }
}
.animation-float-small {
  animation: float 2000ms linear 500ms infinite alternate both;
}
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(10px);
  }
}
/* Zoom animation */
.animation-zoom {
  animation: zoom-in-zoom-out 2000ms linear 500ms infinite alternate both;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
/* Box shadow animation  */
[data-shadow-animation] {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.5s cubic-bezier(0.7, 0, 0.3, 1);
}
[data-shadow-animation].shadow-in {
  box-shadow: 0 20px 100px rgba(0, 0, 0, 0.1);
}
[data-shadow-animation].shadow-in img {
  opacity: 1;
  transition-delay: 0.35s;
}
[data-shadow-animation] img {
  opacity: 0;
  transition: opacity 0.65s cubic-bezier(0.5, 1, 0.5, 1);
}
/* Parallax liquid  */
[data-parallax-liquid] {
  overflow: hidden;
  will-change: transform;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}
[data-parallax-liquid] .liquid-parallax {
  will-change: transform;
  transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  transform: translateY(0px) scale(1.201);
  position: relative;
  top: 50px;
}
/* Masked image animation */
.masked-image {
  position: absolute;
  top: -100px;
  right: 0;
}
.masked-image svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.masked-image figure {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.masked-image img {
  width: 100%;
  height: auto;
  visibility: visible;
}
/* Shape image animation */
.shape-image-animation {
  position: absolute;
  bottom: 0;
  left: 0;
}
.shape-image-animation svg {
  height: 100%;
  width: 100%;
}
.shape-image-animation svg path {
  width: 100%;
}
.page-divider-wrapper {
  margin-bottom: -1px;
}
.page-divider-wrapper svg {
  width: 100%;
}
/* Magic cursor */
.magic-cursor {
  cursor: none;
}
#ball-cursor {
  position: fixed;
  width: 70px;
  height: 70px;
  border: 0 !important;
  background-color: var(--dark-gray);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  opacity: 0 !important;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out 0.1s;
  text-align: center;
}
#ball-cursor:before, #ball-cursor:after {
  content: "\e843";
  font-family: feather !important;
  color: var(--white);
  font-size: 20px;
  line-height: 70px;
  position: absolute;
  left: 12px;
  top: 0px;
}
#ball-cursor:after {
  content: "\e844";
  right: 12px;
  left: auto;
}
.magic-cursor-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  pointer-events: none;
  z-index: 10000;
  transition: opacity 0.2s ease-in-out 0.2s;
}
.magic-cursor-wrapper.sliderhover #ball-cursor {
  opacity: 1 !important;
}
.magic-cursor-wrapper.vertical #ball-cursor:before, .magic-cursor-wrapper.vertical #ball-cursor:after {
  content: "\e845";
  left: 50%;
  top: 10px;
  line-height: normal;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.magic-cursor-wrapper.vertical #ball-cursor:after {
  content: "\e842";
  right: auto;
  bottom: 10px;
  top: auto;
}
@media (hover: none) {
  .magic-cursor-wrapper {
    display: none;
  }
}
.magic-cursor-light #ball-cursor {
  background-color: var(--white);
}
.magic-cursor-light #ball-cursor:before {
  color: var(--dark-gray);
}
.magic-cursor-light #ball-cursor:after {
  color: var(--dark-gray);
}
.magic-cursor-base-color #ball-cursor {
  background-color: var(--base-color);
}
.magic-cursor-base-color #ball-cursor:before {
  color: var(--white);
}
.magic-cursor-base-color #ball-cursor:after {
  color: var(--white);
}
.magic-view-cursor #ball-cursor {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
  width: 180px;
  height: 180px;
  box-shadow: none;
}
.magic-view-cursor #ball-cursor:after {
  display: none;
}
.magic-view-cursor #ball-cursor:before {
  content: "Explore";
  font-size: 16px;
  color: var(--white) !important;
  font-weight: 400;
  font-family: var(--alt-font) !important;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.magic-drag-cursor #ball-cursor {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
  width: 140px;
  height: 140px;
  box-shadow: none;
}
.magic-drag-cursor #ball-cursor:after {
  display: none;
}
.magic-drag-cursor #ball-cursor:before {
  content: "< DRAG >";
  font-size: 14px;
  color: var(--white) !important;
  font-weight: 600;
  font-family: var(--alt-font) !important;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  word-spacing: 4px;
  width: inherit;
}
.magic-drag-cursor.magic-cursor-light #ball-cursor {
  background-color: rgba(255, 255, 255, 0.2);
}
.magic-drag-cursor.magic-cursor-light #ball-cursor:before {
  color: var(--dark-gray);
}
.magic-drag-cursor.magic-cursor-light #ball-cursor:after {
  color: var(--dark-gray);
}
.magic-round-cursor #ball-cursor {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.2);
  width: 220px;
  height: 220px;
  box-shadow: none;
}
.magic-round-cursor #ball-cursor:before {
  display: none;
}
.magic-round-cursor #ball-cursor:after {
  display: none;
}
/* Anime animation */
[data-anime] {
  opacity: 0;
  transition: none;
}
[data-anime].appear {
  opacity: 1;
}
[data-anime].btn {
  transition: none;
}
[data-anime].btn.anime-complete {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
/* Atropos box */
.atropos-shadow {
  background-color: transparent !important;
  filter: inherit;
}

.atropos-highlight {
  background-image: inherit !important;
}
/* Only for safari browser */
@media not all and (min-resolution: 0.001dpcm) {
  [data-anime] [class*=text-shadow-] {
    text-shadow: none;
  }
  [data-anime][class*=text-shadow-] {
    text-shadow: none;
  }
  [data-fancy-text] [class*=text-shadow-] {
    text-shadow: none;
  }
  [data-fancy-text][class*=text-shadow-] {
    text-shadow: none;
  }
  /* font awesome icon gradient color */
  .review-star-icon i {
    display: inline;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* color issue */
  .text-white a[href^=tel] {
    color: var(--white);
  }
  .text-dark-gray a[href^=tel] {
    color: var(--text-dark-gray);
  }
}
.swiper-slide [data-fancy-text] {
  opacity: 0;
}
.swiper-slide [data-fancy-text].appear {
  opacity: 1;
}
.swiper-slide.swiper-slide-active [data-fancy-text] {
  opacity: 1;
}
.swiper-slide.swiper-slide-active [data-anime] {
  opacity: 1;
}
.swiper-slide [data-anime] {
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.no-animation .anime-text .whitespace {
  display: inline-block;
}
.no-animation .anime-text .whitespace::after {
  content: "r";
  opacity: 0;
  font-size: 70%;
}
.no-animation [data-anime], .no-animation [data-fancy-text] {
  opacity: 1;
  transition: none;
}
/* Data top bottom transition animation */
[data-top-bottom] {
  transition: transform 0.65s cubic-bezier(0.23, 1, 0.32, 1);
}
/* Custom cursor */
@media (hover: hover) and (pointer: fine) {
  .custom-cursor .circle-cursor {
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.65s cubic-bezier(0.23, 1, 0.32, 1);
  }
  .custom-cursor .circle-cursor-inner {
    margin-left: -3px;
    margin-top: -3px;
    width: 6px;
    height: 6px;
    z-index: 10000001;
    background-color: var(--base-color);
  }
  .custom-cursor .circle-cursor-inner.cursor-link-hover {
    margin-left: -10px;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background-color: var(--base-color);
    opacity: 0.8;
  }
  .custom-cursor .circle-cursor-outer {
    margin-left: -15px;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    border: 1px solid var(--base-color);
    z-index: 10000000;
    opacity: 0.8;
    -webkit-transition: 0.1s;
    transition: 0.1s;
  }
  .custom-cursor .cursor-link.circle-cursor-inner .cursor-link-hover {
    opacity: 1;
  }
}
.is-touchable .cursor-page-inner {
  display: none !important;
}
/* Stack box */
.stack-box {
  position: relative;
  z-index: 1;
  height: 300vh;
}
.stack-box .stack-box-contain {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.stack-box .stack-item {
  height: 100vh;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 999;
  display: flex;
  align-items: center;
}
.stack-box .stack-item.stack-item-02 {
  z-index: 99;
}
.stack-box .stack-item.stack-item-03 {
  z-index: 9;
}
.stack-box .stack-item .stack-item-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  height: 100vh;
}
.transform-3d {
  transform: translate3d(0px, 0px, 100px);
}
/* Scrolling text */
.marquees-text {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-animation: example1 50s linear infinite;
  animation: example1 50s linear infinite;
  width: 100%;
}
.looping-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 1px;
  height: 100vh;
  left: 50%;
  top: 0;
}
.looping-wrapper .el {
  position: absolute;
  opacity: 1;
  width: 2px;
  height: 24vh;
  margin-top: -18vh;
  transform-origin: 50% 100%;
  background: var(--base-color);
}
.looping-wrapper-gray {
  position: absolute;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 1px;
  height: 100vh;
  left: 50%;
  top: 0;
}
.looping-wrapper-gray .el {
  position: absolute;
  opacity: 1;
  width: 2px;
  height: 24vh;
  margin-top: -18vh;
  transform-origin: 50% 100%;
  background: #292929;
}

/* Marquee slide */
.marquee-slide {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  position: relative;
}
/* Father shadow */
.feather-shadow {
  mask-image: linear-gradient(to right, transparent 0%, #000000 20%, #000000 80%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, transparent 0%, #000000 20%, #000000 80%, transparent 100%);
}
/* Text sliding line */
.text-sliding-line {
  display: inline-block;
  position: relative;
  padding: 0;
  z-index: 1;
  background-image: linear-gradient(135deg, transparent 45%, var(--white) 45%, var(--white) 55%, transparent 0);
  background-size: 4px 4px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* Sliding line */
.bg-sliding-line {
  background-image: linear-gradient(45deg, transparent 45%, var(--white) 45%, var(--white) 55%, transparent 0);
  background-size: 0.1em 0.1em;
}
/* Feature box slider */
.feature-box-slider .feature-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 40px;
  background: var(--white);
  box-shadow: 0 5px 25px rgba(89, 101, 124, 0.15);
  border-radius: 10px;
  color: var(--dark-gray);
  font-weight: 500;
  font-size: 17px;
}
.feature-box-slider .feature-box i {
  font-size: 24px;
  margin-right: 10px;
}
.feature-box-slider .swiper-slide {
  width: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}
/* Page loader */
.page-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--white) !important;
  opacity: 1 !important;
  z-index: 99999;
}
.page-loader::after {
  background: 0 0;
  content: "" !important;
  position: absolute;
  left: calc(50% - 25px) !important;
  top: calc(50% - 25px) !important;
  width: 50px !important;
  height: 50px !important;
  opacity: 1 !important;
  right: inherit !important;
  z-index: 9;
  text-align: center;
  border: 2px solid rgba(23, 23, 23, 0.2);
  border-top-color: rgba(23, 23, 23, 0.7);
  border-radius: 50%;
  -webkit-animation: rotation 8s infinite linear;
  animation: rotation 1.5s infinite linear;
}
/* Cookie message */
.cookie-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  max-width: 370px;
  z-index: 999999;
  padding: 40px;
  display: none;
}
/* Scroll progress */
.scroll-progress {
  position: fixed;
  right: 20px;
  z-index: 111;
  top: 50%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  mix-blend-mode: difference;
}
.scroll-progress.visible {
  opacity: 1;
}
.scroll-progress .scroll-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.scroll-progress .scroll-line {
  width: 2px;
  height: 60px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.15);
  color: inherit;
  display: block;
}
.scroll-progress .scroll-point {
  display: inline-block;
  width: 2px;
  position: absolute;
  background-color: var(--white);
  top: 0px;
  left: 0px;
}
.scroll-progress .scroll-text {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  margin-bottom: 15px;
  color: var(--white);
  font-size: 11px;
  text-transform: uppercase;
}
/* for buy and demo button */
.theme-demos {
  display: none;
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: fixed;
  background-color: var(--white);
  right: -100%;
  top: 0;
  z-index: 1050;
  -webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}
.theme-demos section {
  height: 100%;
  min-height: 100%;
  position: relative;
  z-index: 11;
  padding: 0;
  width: 100%;
}
.theme-demos .demos-wrapper {
  height: 100%;
  overflow-y: hidden;
  background-color: var(--very-light-gray);
  width: 100%;
}
.theme-demos .demos-wrapper .demos-wrapper-inner {
  padding: 60px 50px;
}
.theme-demos.active {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.25);
}
.theme-demos .grid {
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
}
.theme-demos .demo-button-wrapper {
  width: auto;
  white-space: nowrap;
  position: absolute;
  right: 100%;
  display: block;
  bottom: 250px;
  cursor: pointer;
  z-index: 10;
  -webkit-transform: rotate(-90deg) translateY(-100%);
  -ms-transform: rotate(-90deg) translateY(-100%);
  transform: rotate(-90deg) translateY(-100%);
  -webkit-transform-origin: 100% 0% 0;
  -ms-transform-origin: 100% 0% 0;
  transform-origin: 100% 0% 0;
  margin-right: -1px;
  display: flex;
}
.theme-demos .buy-theme a, .theme-demos .all-demo a {
  color: var(--dark-gray);
  font-size: 11px;
  padding: 15px;
  display: block;
  text-decoration: none;
  font-weight: 500;
}
.theme-demos .buy-theme a > i, .theme-demos .all-demo a > i {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 59px;
  line-height: 55px;
  text-align: center;
  opacity: 0;
  font-size: 24px;
  transition-delay: 0.3s;
}
.theme-demos .all-demo {
  background-color: #1f242e;
}
.theme-demos .all-demo .theme-wrapper {
  padding: 0 9px;
  position: relative;
  top: 0;
  opacity: 1;
  transition-delay: 0.3s;
  line-height: 0;
}
.theme-demos .all-demo .theme-wrapper > div {
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  line-height: 15px;
  color: var(--white);
  font-size: 14px;
  text-transform: uppercase;
  line-height: 21px;
}
.theme-demos .buy-theme {
  text-align: center;
  background-color: #48a133;
  position: relative;
  z-index: 1;
}
.theme-demos .buy-theme .theme-wrapper {
  padding: 0;
  position: relative;
  top: 0;
  opacity: 1;
  transition-delay: 0.3s;
  line-height: 0;
}
.theme-demos .buy-theme .theme-wrapper span {
  font-size: 15px;
  font-weight: 600;
  margin-right: 0;
}
.theme-demos .buy-theme .theme-wrapper i {
  margin-right: 10px;
  font-size: 21px;
}
.theme-demos .buy-theme .theme-wrapper > div svg {
  margin: 0;
}
.theme-demos .mCustomScrollBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.theme-demos .mCustomScrollBox .mCSB_container {
  width: 100%;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin: 0;
  padding-right: 15px !important;
}
.theme-demos .mCustomScrollBox .mCSB_container.mCS_y_hidden.mCS_no_scrollbar_y {
  -ms-flex-item-align: center;
  align-self: center;
}
.theme-demos .mCustomScrollBox .mCSB_draggerRail {
  display: none;
}
.theme-demos .clearfix {
  clear: both;
}
.theme-demos .portfolio-wrapper .grid-item {
  flex: 0 0 auto;
}
.theme-demos .close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s ease;
  -ms-transition: -ms-transform 0.3s ease;
  transition: transform 0.3s ease;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.theme-demos .close-popup:hover {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.show-search-popup .theme-demos .theme-demos, .show-search-popup .show-menu .theme-demos {
  z-index: 1;
}
/* Move it (define the animation) */
@-moz-keyframes example1 {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}
@-webkit-keyframes example1 {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes example1 {
  0% {
    -moz-transform: translateX(100%); /* Firefox bug fix */
    -webkit-transform: translateX(100%); /* Firefox bug fix */
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%); /* Firefox bug fix */
    -webkit-transform: translateX(-100%); /* Firefox bug fix */
    transform: translateX(-100%);
  }
}
/* ===================================
    06. Background and border
====================================== */
/* Background color */
.bg-base-color {
  background-color: var(--base-color);
}
.bg-dark-gray,
.bg-dark-gray:focus {
  background-color: var(--dark-gray);
}
.bg-black {
  background-color: var(--black);
}
.bg-medium-gray {
  background-color: var(--medium-gray);
}
.bg-light-medium-gray {
  background-color: var(--light-medium-gray);
}
.bg-extra-medium-gray {
  background-color: var(--extra-medium-gray);
}
.bg-light-gray {
  background-color: var(--light-gray);
}
.bg-very-light-gray {
  background-color: var(--very-light-gray);
}
.bg-red {
  background-color: var(--red);
}
.bg-light-red {
  background-color: var(--light-red);
}
.bg-green {
  background-color: var(--green);
}
.bg-crusoe-green {
  background-color: var(--crusoe-green);
}
.bg-yellow {
  background-color: var(--yellow);
}
.bg-charcoal-blue {
  background-color: var(--charcoal-blue);
}
.bg-slate-blue {
  background-color: var(--slate-blue);
}
.bg-medium-slate-blue {
  background-color: var(--medium-slate-blue);
}
.bg-extra-medium-slate-blue {
  background-color: var(--extra-medium-slate-blue);
}
.bg-dark-slate-blue {
  background-color: var(--dark-slate-blue);
}
.bg-extra-dark-slate-blue {
  background-color: var(--extra-dark-slate-blue);
}
.bg-extra-very-slate-blue {
  background-color: var(--extra-very-slate-blue);
}
.bg-tussock-yellow {
  background-color: var(--tussock-yellow);
}
.bg-solitude-blue {
  background-color: var(--solitude-blue);
}
.bg-aluminium-grey {
  background-color: var(--aluminium-grey);
}
.bg-selago {
  background-color: var(--selago);
}
.bg-white-ice {
  background-color: var(--white-ice);
}
.bg-golden-yellow {
  background-color: var(--golden-yellow);
}
.bg-jade {
  background-color: var(--jade);
}
.bg-orange {
  background-color: var(--orange);
}
.bg-light-majorelle-blue {
  background-color: var(--light-majorelle-blue);
}
.bg-majorelle-blue {
  background-color: var(--majorelle-blue);
}
.bg-spring-wood {
  background-color: var(--spring-wood);
}
.bg-tropical-blue {
  background-color: var(--tropical-blue);
}
.bg-cornflower-blue {
  background-color: var(--cornflower-blue);
}
.bg-camarone {
  background-color: var(--camarone);
}
.bg-seal-brown {
  background-color: var(--seal-brown);
}
.bg-Wasabi {
  background-color: var(--Wasabi);
}
/* Gradient background color */
.bg-gradient-light-pink-light-orange {
  background-image: linear-gradient(to right top, #ffafbd, #ffb3b4, #ffb7ac, #ffbda5, #ffc3a0);
}
.bg-gradient-purple-pink {
  background-image: linear-gradient(to right top, #8b14b1, #ab019e, #c00c8b, #cd267b, #d53d6e);
}
.bg-gradient-fast-pink-light-yellow {
  background-image: linear-gradient(to right top, #f7567f, #fe7177, #ff8a74, #ffa378, #ffba83);
}
.bg-gradient-pink-orange {
  background-image: linear-gradient(to right top, #de347f, #e73a70, #ed4461, #f05152, #ef5f43);
}
.bg-gradient-fast-blue-purple {
  background-image: linear-gradient(to right top, #2a46f4, #5f42f9, #833cfc, #a233fe, #bf25ff);
}
.bg-gradient-light-pink-light-purple {
  background-image: linear-gradient(to right top, #ed579b, #e855ad, #df57c0, #d15cd4, #bc63e8);
}
.bg-gradient-purple-magento {
  background-image: linear-gradient(to right top, #5553e1, #a34dcc, #ce4fb5, #e75ca1, #f47291);
}
.bg-gradient-blue-green {
  background-image: linear-gradient(to right top, #18cdbb, #22d2ae, #3cd69d, #57da89, #73dc73);
}
.bg-gradient-light-purple-light-orange {
  background-image: linear-gradient(to right top, #b783ff, #e37be0, #fa7cc1, #ff85a6, #ff9393);
}
.bg-gradient-sky-blue-pink {
  background-image: linear-gradient(to right top, #5758df, #a553cb, #d055b5, #ea63a0, #f77991);
}
.bg-gradient-dark-gray-brown {
  background-image: linear-gradient(to right top, #0f130f, #1e1d15, #3d2f20, #4e3b27, #664a2f);
}
.bg-gradient-tan-geraldine {
  background-image: linear-gradient(to right top, #fb7f87, #fc8a82, #fb957f, #fa9f7f, #f7aa80);
}
.bg-gradient-very-light-gray {
  background-image: linear-gradient(to bottom, #f7f8f9, #f9fafb, #fbfbfc, #fdfdfd, #ffffff);
}
.bg-gradient-top-very-light-gray {
  background-image: linear-gradient(to top, #f7f7f7, #f7f7f7, #fcfafa, #fcfafa, #ffffff);
}
.bg-gradient-orange-transparent {
  background: linear-gradient(to right, rgb(233, 117, 34) 10%, rgba(255, 255, 255, 0) 95%);
}
.bg-gradient-blue-transparent {
  background: linear-gradient(to right, rgb(30, 163, 177) 10%, rgba(255, 255, 255, 0) 95%);
}
.bg-gradient-emerald-blue-emerald-green {
  background-image: linear-gradient(50deg, #09afea 0, #19e089 100%);
}
.bg-gradient-very-ghost-white {
  background-image: linear-gradient(to bottom, #f4f6ff, #f7f8ff, #fafaff, #fdfdff, #ffffff);
}
/* Transparent background color */
.bg-transparent {
  background-color: transparent;
}
.bg-gradient-fast-blue-purple-transparent {
  background-image: linear-gradient(to right top, rgb(42, 70, 244), rgba(95, 66, 249, 0.9), rgba(131, 60, 252, 0.9), rgba(162, 51, 254, 0.9), rgba(191, 37, 255, 0.9));
}
.bg-gradient-fast-pink-light-yellow-transparent {
  background-image: linear-gradient(to right top, rgba(247, 86, 127, 0.9), rgba(254, 113, 119, 0.9), rgba(255, 138, 116, 0.9), rgba(255, 163, 120, 0.9), rgba(255, 186, 131, 0.9));
}
.bg-gradient-sky-blue-pink-transparent {
  background-image: linear-gradient(to right top, rgba(87, 88, 223, 0.9), rgba(165, 83, 203, 0.9), rgba(208, 85, 181, 0.9), rgba(234, 99, 160, 0.9), rgba(247, 121, 145, 0.9));
}
.bg-gradient-gray-light-dark-transparent {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(32, 35, 41, 0.8)), to(transparent));
  background-image: linear-gradient(to top, rgba(32, 35, 41, 0.8) 0%, transparent 100%);
}
.bg-gradient-dark-transparent {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(7%, #141622), to(transparent));
  background: linear-gradient(to top, #141622 7%, transparent 100%);
}
.bg-gradient-dark-gray-left-transparent {
  background: -webkit-linear-gradient(right, rgba(24, 25, 28, 0.5), rgba(255, 255, 255, 0));
  background: linear-gradient(to right, rgba(24, 25, 28, 0.5), rgba(255, 255, 255, 0));
}
.bg-gradient-white-transparent {
  background: linear-gradient(to top, #ffffff 20%, rgba(255, 255, 255, 0) 80%);
}
.bg-gradient-white-dark-transparent {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(35%, #ffffff), to(transparent));
  background: linear-gradient(to top, #ffffff 30%, rgba(255, 255, 255, 0) 70%);
}
.bg-gradient-white-bottom-transparent {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(255, 255, 255) 60%);
}
.bg-gradient-black-bottom-transparent {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}
.bg-gradient-solitude-blue-transparent {
  background: -webkit-linear-gradient(bottom, rgb(240, 244, 253), rgba(255, 255, 255, 0));
  background: linear-gradient(to top, rgb(240, 244, 253), rgba(255, 255, 255, 0));
}
.bg-gradient-extra-midium-gray-transparent {
  background: -webkit-gradient(left top, left bottom, color-stop(50%, rgba(0, 0, 0, 0.5)), color-stop(150%, rgba(20, 20, 20, 0.3)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(20, 20, 20, 0.3) 150%);
}
.bg-dark-gray-transparent {
  background-color: rgba(35, 35, 35, 0.8);
}
.bg-dark-gray-transparent-light {
  background-color: rgba(35, 35, 35, 0.2);
}
.bg-dark-gray-transparent-medium {
  background-color: rgba(35, 35, 35, 0.4);
}
.bg-medium-gray-transparent {
  background-color: rgba(111, 111, 111, 0.1);
}
.bg-light-gray-transparent {
  background-color: rgb(251, 251, 251);
}
.bg-medium-gray-transparent {
  background-color: rgba(136, 136, 136, 0.3);
}
.bg-black-transparent-light {
  background-color: rgba(0, 0, 0, 0.2);
}
.bg-black-transparent-medium {
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-black-transparent {
  background-color: rgba(0, 0, 0, 0.8);
}
.bg-white-transparent-extra-light,
.bg-white-transparent-extra-light:focus {
  background-color: rgba(255, 255, 255, 0.1);
}
.bg-white-transparent-very-light {
  background-color: rgba(255, 255, 255, 0.2);
}
.bg-white-transparent-light {
  background-color: rgba(255, 255, 255, 0.4);
}
.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.7);
}
/* Background image */
.contain-background {
  background-size: contain;
}
.cover-background {
  background-repeat: no-repeat !important;
  background-size: cover;
  overflow: hidden;
  position: relative;
  background-position: center center !important;
}
.fix-background {
  position: relative;
  background-size: cover !important;
  -webkit-animation-duration: 0s;
  -webkit-animation-fill-mode: none;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
}
.background-size-inherit {
  background-size: inherit !important;
}
.background-attachment-inherit {
  background-attachment: inherit !important;
}
.background-position-left {
  background-position: left center !important;
}
.background-position-left-bottom {
  background-position: left bottom !important;
}
.background-position-left-center {
  background-position: left center !important;
}
.background-position-right {
  background-position: right center !important;
}
.background-position-right-top {
  background-position: right top !important;
}
.background-position-center-top {
  background-position: center top !important;
}
.background-position-left-top {
  background-position: left top !important;
}
.background-position-right-bottom {
  background-position: right bottom !important;
}
.background-position-center-bottom {
  background-position: center bottom 0px !important;
}
.background-repeat {
  background-repeat: repeat;
}
.background-no-repeat {
  background-repeat: no-repeat;
}
.background-no-repeat-y {
  background-repeat-y: no-repeat;
}
.background-position-center {
  background-position: center !important;
}
/* Background size */
.background-size-100 {
  background-size: 100% !important;
}
.background-size-auto-100 {
  background-size: auto 100% !important;
}
.background-size-contain {
  background-size: contain !important;
}
/* Border width */
.border-6 {
  border-width: 6px !important;
}
.border-7 {
  border-width: 7px !important;
}
.border-8 {
  border-width: 8px !important;
}
.border-9 {
  border-width: 9px !important;
}
.border-10 {
  border-width: 10px !important;
}
/* Border style */
.border-dotted {
  border-style: dotted !important;
}
.border-dashed {
  border-style: dashed !important;
}
.border-solid {
  border-style: solid !important;
}
.border-double {
  border-style: double !important;
}
.border-groove {
  border-style: groove !important;
}
.border-ridge {
  border-style: ridge !important;
}
.border-inset {
  border-style: inset !important;
}
.border-outset {
  border-style: outset !important;
}
.border-none {
  border-style: none !important;
}
.border-hidden {
  border-style: hidden !important;
}
/* Border color */
.border-color-base-color {
  border-color: var(--base-color) !important;
}
.border-color-white {
  border-color: var(--white) !important;
}
.border-color-dark-gray {
  border-color: var(--dark-gray) !important;
}
.border-color-medium-gray {
  border-color: var(--medium-gray) !important;
}
.border-color-light-gray {
  border-color: var(--light-gray) !important;
}
.border-color-very-light-gray {
  border-color: var(--very-light-gray) !important;
}
.border-color-light-medium-gray {
  border-color: var(--light-medium-gray) !important;
}
.border-color-extra-medium-gray {
  border-color: var(--extra-medium-gray) !important;
}
.border-color-yellow {
  border-color: var(--yellow) !important;
}
.border-color-orange {
  border-color: var(--orange) !important;
}
.border-color-red {
  border-color: var(--red) !important;
}
.border-color-transparent-dark-very-light {
  border-color: rgba(35, 35, 35, 0.1) !important;
}
.border-color-transparent-dark-light {
  border-color: rgba(35, 35, 35, 0.2) !important;
}
.border-color-transparent-white-very-light {
  border-color: rgba(255, 255, 255, 0.3) !important;
}
.border-color-transparent-white-light {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.border-color-transparent-white {
  border-color: rgba(255, 255, 255, 0.6) !important;
}
.border-color-transparent {
  border-color: transparent !important;
}
.border-color-transparent-on-hover:hover {
  border-color: transparent !important;
}
/* Gradient border color */
.border-gradient-light-pink-light-orange {
  border-image: linear-gradient(to right top, #ffafbd, #ffb3b4, #ffb7ac, #ffbda5, #ffc3a0);
  border-image-slice: 1;
}
.border-gradient-purple-pink {
  border-image: linear-gradient(to right, #8b14b1, #ab019e, #c00c8b, #cd267b, #d53d6e);
  border-image-slice: 1;
}
.border-gradient-fast-pink-light-yellow {
  border-image: linear-gradient(to right top, #f7567f, #fe7177, #ff8a74, #ffa378, #ffba83);
  border-image-slice: 1;
}
.border-gradient-pink-orange {
  border-image: linear-gradient(to right top, #de347f, #e73a70, #ed4461, #f05152, #ef5f43);
  border-image-slice: 1;
}
.border-gradient-fast-blue-purple {
  border-image: linear-gradient(to right top, #2a46f4, #5f42f9, #833cfc, #a233fe, #bf25ff);
  border-image-slice: 1;
}
.border-gradient-light-pink-light-purple {
  border-image: linear-gradient(to right top, #ed579b, #e855ad, #df57c0, #d15cd4, #bc63e8);
  border-image-slice: 1;
}
.border-gradient-light-green-light-perotgreen {
  border-image: linear-gradient(to right top, #5553e1, #a34dcc, #ce4fb5, #e75ca1, #f47291);
  border-image-slice: 1;
}
.border-gradient-sky-blue-pink {
  border-image: linear-gradient(to right top, #556fff, #556fff, #e05fc4, #f767a6, #ff798e);
  border-image-slice: 1;
}
/* Border radius */
.border-radius-0px {
  border-radius: 0 !important;
}
.border-radius-1px {
  border-radius: 1px !important;
}
.border-radius-2px {
  border-radius: 2px !important;
}
.border-radius-3px {
  border-radius: 3px !important;
}
.border-radius-4px {
  border-radius: 4px !important;
}
.border-radius-5px {
  border-radius: 5px !important;
}
.border-radius-6px {
  border-radius: 6px !important;
}
.border-radius-7px {
  border-radius: 7px !important;
}
.border-radius-8px {
  border-radius: 8px !important;
}
.border-radius-9px {
  border-radius: 9px !important;
}
.border-radius-10px {
  border-radius: 10px !important;
}
.border-radius-15px {
  border-radius: 15px !important;
}
.border-radius-18px {
  border-radius: 18px !important;
}
.border-radius-20px {
  border-radius: 20px !important;
}
.border-radius-22px {
  border-radius: 22px !important;
}
.border-radius-24px {
  border-radius: 24px !important;
}
.border-radius-26px {
  border-radius: 26px !important;
}
.border-radius-30px {
  border-radius: 30px !important;
}
.border-radius-40px {
  border-radius: 40px !important;
}
.border-radius-50px {
  border-radius: 50px !important;
}
.border-radius-100px {
  border-radius: 100px !important;
}
.border-radius-50 {
  border-radius: 50% !important;
}
.border-radius-100 {
  border-radius: 100% !important;
}
.no-border-radius {
  border-radius: 0 !important;
}
.border-radius-top-left {
  border-radius: 10px 0 0 10px !important;
}
/* Parallax background */
.parallax {
  position: relative !important;
  background-size: cover !important;
  overflow: hidden;
  background-attachment: fixed !important;
  transition-timing-function: none;
  -webkit-transition-timing-function: none;
  transition-duration: 0s;
  -webkit-transition-duration: 0s;
}
/* ===================================
    07. Width
====================================== */
.w-1px {
  width: 1px !important;
}
.w-2px {
  width: 2px !important;
}
.w-3px {
  width: 3px !important;
}
.w-4px {
  width: 4px !important;
}
.w-5px {
  width: 5px !important;
}
.w-6px {
  width: 6px !important;
}
.w-7px {
  width: 7px !important;
}
.w-8px {
  width: 8px !important;
}
.w-9px {
  width: 9px !important;
}
.w-10px {
  width: 10px !important;
}
.w-15px {
  width: 15px !important;
}
.w-20px {
  width: 20px !important;
}
.w-25px {
  width: 25px !important;
}
.w-30px {
  width: 30px !important;
}
.w-35px {
  width: 35px !important;
}
.w-40px {
  width: 40px !important;
}
.w-45px {
  width: 45px !important;
}
.w-50px {
  width: 50px !important;
}
.w-55px {
  width: 55px !important;
}
.w-60px {
  width: 60px !important;
}
.w-65px {
  width: 65px !important;
}
.w-70px {
  width: 70px !important;
}
.w-75px {
  width: 75px !important;
}
.w-80px {
  width: 80px !important;
}
.w-85px {
  width: 85px !important;
}
.w-90px {
  width: 90px !important;
}
.w-95px {
  width: 95px !important;
}
.w-100px {
  width: 100px !important;
}
.w-110px {
  width: 110px !important;
}
.w-120px {
  width: 120px !important;
}
.w-130px {
  width: 130px !important;
}
.w-140px {
  width: 140px !important;
}
.w-150px {
  width: 150px !important;
}
.w-160px {
  width: 160px !important;
}
.w-170px {
  width: 170px !important;
}
.w-180px {
  width: 180px !important;
}
.w-190px {
  width: 190px !important;
}
.w-200px {
  width: 200px !important;
}
.w-210px {
  width: 210px !important;
}
.w-220px {
  width: 220px !important;
}
.w-230px {
  width: 230px !important;
}
.w-240px {
  width: 240px !important;
}
.w-250px {
  width: 250px !important;
}
.w-260px {
  width: 260px !important;
}
.w-270px {
  width: 270px !important;
}
.w-280px {
  width: 280px !important;
}
.w-290px {
  width: 290px !important;
}
.w-300px {
  width: 300px !important;
}
.w-310px {
  width: 310px !important;
}
.w-320px {
  width: 320px !important;
}
.w-330px {
  width: 330px !important;
}
.w-340px {
  width: 340px !important;
}
.w-350px {
  width: 350px !important;
}
.w-360px {
  width: 360px !important;
}
.w-370px {
  width: 370px !important;
}
.w-380px {
  width: 380px !important;
}
.w-390px {
  width: 390px !important;
}
.w-400px {
  width: 400px !important;
}
.w-450px {
  width: 450px !important;
}
.w-500px {
  width: 500px !important;
}
.w-550px {
  width: 550px !important;
}
.w-600px {
  width: 600px !important;
}
.w-650px {
  width: 650px !important;
}
.w-700px {
  width: 700px !important;
}
.w-750px {
  width: 750px !important;
}
.w-800px {
  width: 800px !important;
}
.w-850px {
  width: 850px !important;
}
.w-900px {
  width: 900px !important;
}
.w-950px {
  width: 950px !important;
}
.w-1000px {
  width: 1000px !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-50 {
  width: 50% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
/* ===================================
    08. Height
====================================== */
.h-1px {
  height: 1px !important;
}
.h-2px {
  height: 2px !important;
}
.h-3px {
  height: 3px !important;
}
.h-4px {
  height: 4px !important;
}
.h-5px {
  height: 5px !important;
}
.h-6px {
  height: 6px !important;
}
.h-7px {
  height: 7px !important;
}
.h-8px {
  height: 8px !important;
}
.h-9px {
  height: 9px !important;
}
.h-10px {
  height: 10px !important;
}
.h-11px {
  height: 11px !important;
}
.h-12px {
  height: 12px !important;
}
.h-13px {
  height: 13px !important;
}
.h-14px {
  height: 14px !important;
}
.h-15px {
  height: 15px !important;
}
.h-20px {
  height: 20px !important;
}
.h-25px {
  height: 25px !important;
}
.h-30px {
  height: 30px !important;
}
.h-35px {
  height: 35px !important;
}
.h-40px {
  height: 40px !important;
}
.h-42px {
  height: 42px !important;
}
.h-45px {
  height: 45px !important;
}
.h-50px {
  height: 50px !important;
}
.h-55px {
  height: 55px !important;
}
.h-60px {
  height: 60px !important;
}
.h-65px {
  height: 65px !important;
}
.h-70px {
  height: 70px !important;
}
.h-75px {
  height: 75px !important;
}
.h-80px {
  height: 80px !important;
}
.h-85px {
  height: 85px !important;
}
.h-90px {
  height: 90px !important;
}
.h-95px {
  height: 95px !important;
}
.h-100px {
  height: 100px !important;
}
.h-110px {
  height: 110px !important;
}
.h-120px {
  height: 120px !important;
}
.h-130px {
  height: 130px !important;
}
.h-140px {
  height: 140px !important;
}
.h-150px {
  height: 150px !important;
}
.h-160px {
  height: 160px !important;
}
.h-170px {
  height: 170px !important;
}
.h-180px {
  height: 180px !important;
}
.h-190px {
  height: 190px !important;
}
.h-200px {
  height: 200px !important;
}
.h-210px {
  height: 210px !important;
}
.h-220px {
  height: 220px !important;
}
.h-230px {
  height: 230px !important;
}
.h-240px {
  height: 240px !important;
}
.h-250px {
  height: 250px !important;
}
.h-260px {
  height: 260px !important;
}
.h-270px {
  height: 270px !important;
}
.h-280px {
  height: 280px !important;
}
.h-290px {
  height: 290px !important;
}
.h-300px {
  height: 300px !important;
}
.h-350px {
  height: 350px !important;
}
.h-400px {
  height: 400px !important;
}
.h-450px {
  height: 450px !important;
}
.h-480px {
  height: 480px !important;
}
.h-500px {
  height: 500px !important;
}
.h-550px {
  height: 550px !important;
}
.h-580px {
  height: 580px !important;
}
.h-600px {
  height: 600px !important;
}
.h-650px {
  height: 650px !important;
}
.h-700px {
  height: 700px !important;
}
.h-750px {
  height: 750px !important;
}
.h-800px {
  height: 800px !important;
}
.h-850px {
  height: 850px !important;
}
.h-auto {
  height: auto !important;
}
/* Screen height */
.extra-very-small-screen {
  height: 250px !important;
}
.extra-small-screen {
  height: 300px !important;
}
.small-screen {
  height: 400px !important;
}
.one-half-screen {
  height: 600px !important;
}
.one-third-screen {
  height: 700px !important;
}
.one-fourth-screen {
  height: 800px !important;
}
.one-fifth-screen {
  height: 900px !important;
}
.one-sixth-screen {
  height: 1000px !important;
}
.one-seventh-screen {
  height: 1200px !important;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto;
  }
}
/* ===================================
    09. Loop - Padding, margin, position, font weight, min height, opacity and z-index
====================================== */
/* Padding and margin */
.p-5px {
  padding: 5px !important;
}
.p-10px {
  padding: 10px !important;
}
.p-15px {
  padding: 15px !important;
}
.p-20px {
  padding: 20px !important;
}
.p-25px {
  padding: 25px !important;
}
.p-30px {
  padding: 30px !important;
}
.p-35px {
  padding: 35px !important;
}
.p-40px {
  padding: 40px !important;
}
.p-45px {
  padding: 45px !important;
}
.p-50px {
  padding: 50px !important;
}
.p-55px {
  padding: 55px !important;
}
.p-60px {
  padding: 60px !important;
}
.p-65px {
  padding: 65px !important;
}
.p-70px {
  padding: 70px !important;
}
.p-1 {
  padding: 1% !important;
}
.p-2 {
  padding: 2% !important;
}
.p-3 {
  padding: 3% !important;
}
.p-4 {
  padding: 4% !important;
}
.p-5 {
  padding: 5% !important;
}
.p-6 {
  padding: 6% !important;
}
.p-7 {
  padding: 7% !important;
}
.p-8 {
  padding: 8% !important;
}
.p-9 {
  padding: 9% !important;
}
.p-10 {
  padding: 10% !important;
}
.p-11 {
  padding: 11% !important;
}
.p-12 {
  padding: 12% !important;
}
.p-13 {
  padding: 13% !important;
}
.p-14 {
  padding: 14% !important;
}
.p-15 {
  padding: 15% !important;
}
.p-16 {
  padding: 16% !important;
}
.p-17 {
  padding: 17% !important;
}
.p-18 {
  padding: 18% !important;
}
.p-19 {
  padding: 19% !important;
}
.p-20 {
  padding: 20% !important;
}
.p-21 {
  padding: 21% !important;
}
.p-22 {
  padding: 22% !important;
}
.p-23 {
  padding: 23% !important;
}
.p-24 {
  padding: 24% !important;
}
.p-25 {
  padding: 25% !important;
}
.p-26 {
  padding: 26% !important;
}
.p-27 {
  padding: 27% !important;
}
.p-28 {
  padding: 28% !important;
}
.p-29 {
  padding: 29% !important;
}
.p-30 {
  padding: 30% !important;
}
.pt-5px {
  padding-top: 5px !important;
}
.pt-10px {
  padding-top: 10px !important;
}
.pt-15px {
  padding-top: 15px !important;
}
.pt-20px {
  padding-top: 20px !important;
}
.pt-25px {
  padding-top: 25px !important;
}
.pt-30px {
  padding-top: 30px !important;
}
.pt-35px {
  padding-top: 35px !important;
}
.pt-40px {
  padding-top: 40px !important;
}
.pt-45px {
  padding-top: 45px !important;
}
.pt-50px {
  padding-top: 50px !important;
}
.pt-55px {
  padding-top: 55px !important;
}
.pt-60px {
  padding-top: 60px !important;
}
.pt-65px {
  padding-top: 65px !important;
}
.pt-70px {
  padding-top: 70px !important;
}
.pt-1 {
  padding-top: 1% !important;
}
.pt-2 {
  padding-top: 2% !important;
}
.pt-3 {
  padding-top: 3% !important;
}
.pt-4 {
  padding-top: 4% !important;
}
.pt-5 {
  padding-top: 5% !important;
}
.pt-6 {
  padding-top: 6% !important;
}
.pt-7 {
  padding-top: 7% !important;
}
.pt-8 {
  padding-top: 8% !important;
}
.pt-9 {
  padding-top: 9% !important;
}
.pt-10 {
  padding-top: 10% !important;
}
.pt-11 {
  padding-top: 11% !important;
}
.pt-12 {
  padding-top: 12% !important;
}
.pt-13 {
  padding-top: 13% !important;
}
.pt-14 {
  padding-top: 14% !important;
}
.pt-15 {
  padding-top: 15% !important;
}
.pt-16 {
  padding-top: 16% !important;
}
.pt-17 {
  padding-top: 17% !important;
}
.pt-18 {
  padding-top: 18% !important;
}
.pt-19 {
  padding-top: 19% !important;
}
.pt-20 {
  padding-top: 20% !important;
}
.pt-21 {
  padding-top: 21% !important;
}
.pt-22 {
  padding-top: 22% !important;
}
.pt-23 {
  padding-top: 23% !important;
}
.pt-24 {
  padding-top: 24% !important;
}
.pt-25 {
  padding-top: 25% !important;
}
.pt-26 {
  padding-top: 26% !important;
}
.pt-27 {
  padding-top: 27% !important;
}
.pt-28 {
  padding-top: 28% !important;
}
.pt-29 {
  padding-top: 29% !important;
}
.pt-30 {
  padding-top: 30% !important;
}
.pe-5px {
  padding-right: 5px !important;
}
.pe-10px {
  padding-right: 10px !important;
}
.pe-15px {
  padding-right: 15px !important;
}
.pe-20px {
  padding-right: 20px !important;
}
.pe-25px {
  padding-right: 25px !important;
}
.pe-30px {
  padding-right: 30px !important;
}
.pe-35px {
  padding-right: 35px !important;
}
.pe-40px {
  padding-right: 40px !important;
}
.pe-45px {
  padding-right: 45px !important;
}
.pe-50px {
  padding-right: 50px !important;
}
.pe-55px {
  padding-right: 55px !important;
}
.pe-60px {
  padding-right: 60px !important;
}
.pe-65px {
  padding-right: 65px !important;
}
.pe-70px {
  padding-right: 70px !important;
}
.pe-1 {
  padding-right: 1% !important;
}
.pe-2 {
  padding-right: 2% !important;
}
.pe-3 {
  padding-right: 3% !important;
}
.pe-4 {
  padding-right: 4% !important;
}
.pe-5 {
  padding-right: 5% !important;
}
.pe-6 {
  padding-right: 6% !important;
}
.pe-7 {
  padding-right: 7% !important;
}
.pe-8 {
  padding-right: 8% !important;
}
.pe-9 {
  padding-right: 9% !important;
}
.pe-10 {
  padding-right: 10% !important;
}
.pe-11 {
  padding-right: 11% !important;
}
.pe-12 {
  padding-right: 12% !important;
}
.pe-13 {
  padding-right: 13% !important;
}
.pe-14 {
  padding-right: 14% !important;
}
.pe-15 {
  padding-right: 15% !important;
}
.pe-16 {
  padding-right: 16% !important;
}
.pe-17 {
  padding-right: 17% !important;
}
.pe-18 {
  padding-right: 18% !important;
}
.pe-19 {
  padding-right: 19% !important;
}
.pe-20 {
  padding-right: 20% !important;
}
.pe-21 {
  padding-right: 21% !important;
}
.pe-22 {
  padding-right: 22% !important;
}
.pe-23 {
  padding-right: 23% !important;
}
.pe-24 {
  padding-right: 24% !important;
}
.pe-25 {
  padding-right: 25% !important;
}
.pe-26 {
  padding-right: 26% !important;
}
.pe-27 {
  padding-right: 27% !important;
}
.pe-28 {
  padding-right: 28% !important;
}
.pe-29 {
  padding-right: 29% !important;
}
.pe-30 {
  padding-right: 30% !important;
}
.pb-5px {
  padding-bottom: 5px !important;
}
.pb-10px {
  padding-bottom: 10px !important;
}
.pb-15px {
  padding-bottom: 15px !important;
}
.pb-20px {
  padding-bottom: 20px !important;
}
.pb-25px {
  padding-bottom: 25px !important;
}
.pb-30px {
  padding-bottom: 30px !important;
}
.pb-35px {
  padding-bottom: 35px !important;
}
.pb-40px {
  padding-bottom: 40px !important;
}
.pb-45px {
  padding-bottom: 45px !important;
}
.pb-50px {
  padding-bottom: 50px !important;
}
.pb-55px {
  padding-bottom: 55px !important;
}
.pb-60px {
  padding-bottom: 60px !important;
}
.pb-65px {
  padding-bottom: 65px !important;
}
.pb-70px {
  padding-bottom: 70px !important;
}
.pb-1 {
  padding-bottom: 1% !important;
}
.pb-2 {
  padding-bottom: 2% !important;
}
.pb-3 {
  padding-bottom: 3% !important;
}
.pb-4 {
  padding-bottom: 4% !important;
}
.pb-5 {
  padding-bottom: 5% !important;
}
.pb-6 {
  padding-bottom: 6% !important;
}
.pb-7 {
  padding-bottom: 7% !important;
}
.pb-8 {
  padding-bottom: 8% !important;
}
.pb-9 {
  padding-bottom: 9% !important;
}
.pb-10 {
  padding-bottom: 10% !important;
}
.pb-11 {
  padding-bottom: 11% !important;
}
.pb-12 {
  padding-bottom: 12% !important;
}
.pb-13 {
  padding-bottom: 13% !important;
}
.pb-14 {
  padding-bottom: 14% !important;
}
.pb-15 {
  padding-bottom: 15% !important;
}
.pb-16 {
  padding-bottom: 16% !important;
}
.pb-17 {
  padding-bottom: 17% !important;
}
.pb-18 {
  padding-bottom: 18% !important;
}
.pb-19 {
  padding-bottom: 19% !important;
}
.pb-20 {
  padding-bottom: 20% !important;
}
.pb-21 {
  padding-bottom: 21% !important;
}
.pb-22 {
  padding-bottom: 22% !important;
}
.pb-23 {
  padding-bottom: 23% !important;
}
.pb-24 {
  padding-bottom: 24% !important;
}
.pb-25 {
  padding-bottom: 25% !important;
}
.pb-26 {
  padding-bottom: 26% !important;
}
.pb-27 {
  padding-bottom: 27% !important;
}
.pb-28 {
  padding-bottom: 28% !important;
}
.pb-29 {
  padding-bottom: 29% !important;
}
.pb-30 {
  padding-bottom: 30% !important;
}
.ps-5px {
  padding-left: 5px !important;
}
.ps-10px {
  padding-left: 10px !important;
}
.ps-15px {
  padding-left: 15px !important;
}
.ps-20px {
  padding-left: 20px !important;
}
.ps-25px {
  padding-left: 25px !important;
}
.ps-30px {
  padding-left: 30px !important;
}
.ps-35px {
  padding-left: 35px !important;
}
.ps-40px {
  padding-left: 40px !important;
}
.ps-45px {
  padding-left: 45px !important;
}
.ps-50px {
  padding-left: 50px !important;
}
.ps-55px {
  padding-left: 55px !important;
}
.ps-60px {
  padding-left: 60px !important;
}
.ps-65px {
  padding-left: 65px !important;
}
.ps-70px {
  padding-left: 70px !important;
}
.ps-1 {
  padding-left: 1% !important;
}
.ps-2 {
  padding-left: 2% !important;
}
.ps-3 {
  padding-left: 3% !important;
}
.ps-4 {
  padding-left: 4% !important;
}
.ps-5 {
  padding-left: 5% !important;
}
.ps-6 {
  padding-left: 6% !important;
}
.ps-7 {
  padding-left: 7% !important;
}
.ps-8 {
  padding-left: 8% !important;
}
.ps-9 {
  padding-left: 9% !important;
}
.ps-10 {
  padding-left: 10% !important;
}
.ps-11 {
  padding-left: 11% !important;
}
.ps-12 {
  padding-left: 12% !important;
}
.ps-13 {
  padding-left: 13% !important;
}
.ps-14 {
  padding-left: 14% !important;
}
.ps-15 {
  padding-left: 15% !important;
}
.ps-16 {
  padding-left: 16% !important;
}
.ps-17 {
  padding-left: 17% !important;
}
.ps-18 {
  padding-left: 18% !important;
}
.ps-19 {
  padding-left: 19% !important;
}
.ps-20 {
  padding-left: 20% !important;
}
.ps-21 {
  padding-left: 21% !important;
}
.ps-22 {
  padding-left: 22% !important;
}
.ps-23 {
  padding-left: 23% !important;
}
.ps-24 {
  padding-left: 24% !important;
}
.ps-25 {
  padding-left: 25% !important;
}
.ps-26 {
  padding-left: 26% !important;
}
.ps-27 {
  padding-left: 27% !important;
}
.ps-28 {
  padding-left: 28% !important;
}
.ps-29 {
  padding-left: 29% !important;
}
.ps-30 {
  padding-left: 30% !important;
}
.m-5px {
  margin: 5px !important;
}
.m-10px {
  margin: 10px !important;
}
.m-15px {
  margin: 15px !important;
}
.m-20px {
  margin: 20px !important;
}
.m-25px {
  margin: 25px !important;
}
.m-30px {
  margin: 30px !important;
}
.m-35px {
  margin: 35px !important;
}
.m-40px {
  margin: 40px !important;
}
.m-45px {
  margin: 45px !important;
}
.m-50px {
  margin: 50px !important;
}
.m-55px {
  margin: 55px !important;
}
.m-60px {
  margin: 60px !important;
}
.m-65px {
  margin: 65px !important;
}
.m-70px {
  margin: 70px !important;
}
.m-1 {
  margin: 1% !important;
}
.m-2 {
  margin: 2% !important;
}
.m-3 {
  margin: 3% !important;
}
.m-4 {
  margin: 4% !important;
}
.m-5 {
  margin: 5% !important;
}
.m-6 {
  margin: 6% !important;
}
.m-7 {
  margin: 7% !important;
}
.m-8 {
  margin: 8% !important;
}
.m-9 {
  margin: 9% !important;
}
.m-10 {
  margin: 10% !important;
}
.m-11 {
  margin: 11% !important;
}
.m-12 {
  margin: 12% !important;
}
.m-13 {
  margin: 13% !important;
}
.m-14 {
  margin: 14% !important;
}
.m-15 {
  margin: 15% !important;
}
.m-16 {
  margin: 16% !important;
}
.m-17 {
  margin: 17% !important;
}
.m-18 {
  margin: 18% !important;
}
.m-19 {
  margin: 19% !important;
}
.m-20 {
  margin: 20% !important;
}
.m-21 {
  margin: 21% !important;
}
.m-22 {
  margin: 22% !important;
}
.m-23 {
  margin: 23% !important;
}
.m-24 {
  margin: 24% !important;
}
.m-25 {
  margin: 25% !important;
}
.m-26 {
  margin: 26% !important;
}
.m-27 {
  margin: 27% !important;
}
.m-28 {
  margin: 28% !important;
}
.m-29 {
  margin: 29% !important;
}
.m-30 {
  margin: 30% !important;
}
.mt-5px {
  margin-top: 5px !important;
}
.mt-10px {
  margin-top: 10px !important;
}
.mt-15px {
  margin-top: 15px !important;
}
.mt-20px {
  margin-top: 20px !important;
}
.mt-25px {
  margin-top: 25px !important;
}
.mt-30px {
  margin-top: 30px !important;
}
.mt-35px {
  margin-top: 35px !important;
}
.mt-40px {
  margin-top: 40px !important;
}
.mt-45px {
  margin-top: 45px !important;
}
.mt-50px {
  margin-top: 50px !important;
}
.mt-55px {
  margin-top: 55px !important;
}
.mt-60px {
  margin-top: 60px !important;
}
.mt-65px {
  margin-top: 65px !important;
}
.mt-70px {
  margin-top: 70px !important;
}
.mt-1 {
  margin-top: 1% !important;
}
.mt-2 {
  margin-top: 2% !important;
}
.mt-3 {
  margin-top: 3% !important;
}
.mt-4 {
  margin-top: 4% !important;
}
.mt-5 {
  margin-top: 5% !important;
}
.mt-6 {
  margin-top: 6% !important;
}
.mt-7 {
  margin-top: 7% !important;
}
.mt-8 {
  margin-top: 8% !important;
}
.mt-9 {
  margin-top: 9% !important;
}
.mt-10 {
  margin-top: 10% !important;
}
.mt-11 {
  margin-top: 11% !important;
}
.mt-12 {
  margin-top: 12% !important;
}
.mt-13 {
  margin-top: 13% !important;
}
.mt-14 {
  margin-top: 14% !important;
}
.mt-15 {
  margin-top: 15% !important;
}
.mt-16 {
  margin-top: 16% !important;
}
.mt-17 {
  margin-top: 17% !important;
}
.mt-18 {
  margin-top: 18% !important;
}
.mt-19 {
  margin-top: 19% !important;
}
.mt-20 {
  margin-top: 20% !important;
}
.mt-21 {
  margin-top: 21% !important;
}
.mt-22 {
  margin-top: 22% !important;
}
.mt-23 {
  margin-top: 23% !important;
}
.mt-24 {
  margin-top: 24% !important;
}
.mt-25 {
  margin-top: 25% !important;
}
.mt-26 {
  margin-top: 26% !important;
}
.mt-27 {
  margin-top: 27% !important;
}
.mt-28 {
  margin-top: 28% !important;
}
.mt-29 {
  margin-top: 29% !important;
}
.mt-30 {
  margin-top: 30% !important;
}
.me-5px {
  margin-right: 5px !important;
}
.me-10px {
  margin-right: 10px !important;
}
.me-15px {
  margin-right: 15px !important;
}
.me-20px {
  margin-right: 20px !important;
}
.me-25px {
  margin-right: 25px !important;
}
.me-30px {
  margin-right: 30px !important;
}
.me-35px {
  margin-right: 35px !important;
}
.me-40px {
  margin-right: 40px !important;
}
.me-45px {
  margin-right: 45px !important;
}
.me-50px {
  margin-right: 50px !important;
}
.me-55px {
  margin-right: 55px !important;
}
.me-60px {
  margin-right: 60px !important;
}
.me-65px {
  margin-right: 65px !important;
}
.me-70px {
  margin-right: 70px !important;
}
.me-1 {
  margin-right: 1% !important;
}
.me-2 {
  margin-right: 2% !important;
}
.me-3 {
  margin-right: 3% !important;
}
.me-4 {
  margin-right: 4% !important;
}
.me-5 {
  margin-right: 5% !important;
}
.me-6 {
  margin-right: 6% !important;
}
.me-7 {
  margin-right: 7% !important;
}
.me-8 {
  margin-right: 8% !important;
}
.me-9 {
  margin-right: 9% !important;
}
.me-10 {
  margin-right: 10% !important;
}
.me-11 {
  margin-right: 11% !important;
}
.me-12 {
  margin-right: 12% !important;
}
.me-13 {
  margin-right: 13% !important;
}
.me-14 {
  margin-right: 14% !important;
}
.me-15 {
  margin-right: 15% !important;
}
.me-16 {
  margin-right: 16% !important;
}
.me-17 {
  margin-right: 17% !important;
}
.me-18 {
  margin-right: 18% !important;
}
.me-19 {
  margin-right: 19% !important;
}
.me-20 {
  margin-right: 20% !important;
}
.me-21 {
  margin-right: 21% !important;
}
.me-22 {
  margin-right: 22% !important;
}
.me-23 {
  margin-right: 23% !important;
}
.me-24 {
  margin-right: 24% !important;
}
.me-25 {
  margin-right: 25% !important;
}
.me-26 {
  margin-right: 26% !important;
}
.me-27 {
  margin-right: 27% !important;
}
.me-28 {
  margin-right: 28% !important;
}
.me-29 {
  margin-right: 29% !important;
}
.me-30 {
  margin-right: 30% !important;
}
.mb-5px {
  margin-bottom: 5px !important;
}
.mb-10px {
  margin-bottom: 10px !important;
}
.mb-15px {
  margin-bottom: 15px !important;
}
.mb-20px {
  margin-bottom: 20px !important;
}
.mb-25px {
  margin-bottom: 25px !important;
}
.mb-30px {
  margin-bottom: 30px !important;
}
.mb-35px {
  margin-bottom: 35px !important;
}
.mb-40px {
  margin-bottom: 40px !important;
}
.mb-45px {
  margin-bottom: 45px !important;
}
.mb-50px {
  margin-bottom: 50px !important;
}
.mb-55px {
  margin-bottom: 55px !important;
}
.mb-60px {
  margin-bottom: 60px !important;
}
.mb-65px {
  margin-bottom: 65px !important;
}
.mb-70px {
  margin-bottom: 70px !important;
}
.mb-1 {
  margin-bottom: 1% !important;
}
.mb-2 {
  margin-bottom: 2% !important;
}
.mb-3 {
  margin-bottom: 3% !important;
}
.mb-4 {
  margin-bottom: 4% !important;
}
.mb-5 {
  margin-bottom: 5% !important;
}
.mb-6 {
  margin-bottom: 6% !important;
}
.mb-7 {
  margin-bottom: 7% !important;
}
.mb-8 {
  margin-bottom: 8% !important;
}
.mb-9 {
  margin-bottom: 9% !important;
}
.mb-10 {
  margin-bottom: 10% !important;
}
.mb-11 {
  margin-bottom: 11% !important;
}
.mb-12 {
  margin-bottom: 12% !important;
}
.mb-13 {
  margin-bottom: 13% !important;
}
.mb-14 {
  margin-bottom: 14% !important;
}
.mb-15 {
  margin-bottom: 15% !important;
}
.mb-16 {
  margin-bottom: 16% !important;
}
.mb-17 {
  margin-bottom: 17% !important;
}
.mb-18 {
  margin-bottom: 18% !important;
}
.mb-19 {
  margin-bottom: 19% !important;
}
.mb-20 {
  margin-bottom: 20% !important;
}
.mb-21 {
  margin-bottom: 21% !important;
}
.mb-22 {
  margin-bottom: 22% !important;
}
.mb-23 {
  margin-bottom: 23% !important;
}
.mb-24 {
  margin-bottom: 24% !important;
}
.mb-25 {
  margin-bottom: 25% !important;
}
.mb-26 {
  margin-bottom: 26% !important;
}
.mb-27 {
  margin-bottom: 27% !important;
}
.mb-28 {
  margin-bottom: 28% !important;
}
.mb-29 {
  margin-bottom: 29% !important;
}
.mb-30 {
  margin-bottom: 30% !important;
}
.ms-5px {
  margin-left: 5px !important;
}
.ms-10px {
  margin-left: 10px !important;
}
.ms-15px {
  margin-left: 15px !important;
}
.ms-20px {
  margin-left: 20px !important;
}
.ms-25px {
  margin-left: 25px !important;
}
.ms-30px {
  margin-left: 30px !important;
}
.ms-35px {
  margin-left: 35px !important;
}
.ms-40px {
  margin-left: 40px !important;
}
.ms-45px {
  margin-left: 45px !important;
}
.ms-50px {
  margin-left: 50px !important;
}
.ms-55px {
  margin-left: 55px !important;
}
.ms-60px {
  margin-left: 60px !important;
}
.ms-65px {
  margin-left: 65px !important;
}
.ms-70px {
  margin-left: 70px !important;
}
.ms-1 {
  margin-left: 1% !important;
}
.ms-2 {
  margin-left: 2% !important;
}
.ms-3 {
  margin-left: 3% !important;
}
.ms-4 {
  margin-left: 4% !important;
}
.ms-5 {
  margin-left: 5% !important;
}
.ms-6 {
  margin-left: 6% !important;
}
.ms-7 {
  margin-left: 7% !important;
}
.ms-8 {
  margin-left: 8% !important;
}
.ms-9 {
  margin-left: 9% !important;
}
.ms-10 {
  margin-left: 10% !important;
}
.ms-11 {
  margin-left: 11% !important;
}
.ms-12 {
  margin-left: 12% !important;
}
.ms-13 {
  margin-left: 13% !important;
}
.ms-14 {
  margin-left: 14% !important;
}
.ms-15 {
  margin-left: 15% !important;
}
.ms-16 {
  margin-left: 16% !important;
}
.ms-17 {
  margin-left: 17% !important;
}
.ms-18 {
  margin-left: 18% !important;
}
.ms-19 {
  margin-left: 19% !important;
}
.ms-20 {
  margin-left: 20% !important;
}
.ms-21 {
  margin-left: 21% !important;
}
.ms-22 {
  margin-left: 22% !important;
}
.ms-23 {
  margin-left: 23% !important;
}
.ms-24 {
  margin-left: 24% !important;
}
.ms-25 {
  margin-left: 25% !important;
}
.ms-26 {
  margin-left: 26% !important;
}
.ms-27 {
  margin-left: 27% !important;
}
.ms-28 {
  margin-left: 28% !important;
}
.ms-29 {
  margin-left: 29% !important;
}
.ms-30 {
  margin-left: 30% !important;
}
/* Position */
.top-0px {
  top: 0px;
}
.top-1px {
  top: 1px;
}
.top-2px {
  top: 2px;
}
.top-3px {
  top: 3px;
}
.top-4px {
  top: 4px;
}
.top-5px {
  top: 5px;
}
.top-6px {
  top: 6px;
}
.top-7px {
  top: 7px;
}
.top-8px {
  top: 8px;
}
.top-9px {
  top: 9px;
}
.top-10px {
  top: 10px;
}
.top-11px {
  top: 11px;
}
.top-12px {
  top: 12px;
}
.top-13px {
  top: 13px;
}
.top-14px {
  top: 14px;
}
.top-15px {
  top: 15px;
}
.top-16px {
  top: 16px;
}
.top-17px {
  top: 17px;
}
.top-18px {
  top: 18px;
}
.top-19px {
  top: 19px;
}
.top-20px {
  top: 20px;
}
.top-21px {
  top: 21px;
}
.top-22px {
  top: 22px;
}
.top-23px {
  top: 23px;
}
.top-24px {
  top: 24px;
}
.top-25px {
  top: 25px;
}
.top-26px {
  top: 26px;
}
.top-27px {
  top: 27px;
}
.top-28px {
  top: 28px;
}
.top-29px {
  top: 29px;
}
.top-30px {
  top: 30px;
}
.top-40px {
  top: 40px;
}
.top-50px {
  top: 50px;
}
.top-60px {
  top: 60px;
}
.top-70px {
  top: 70px;
}
.top-80px {
  top: 80px;
}
.top-90px {
  top: 90px;
}
.top-100px {
  top: 100px;
}
.top-110px {
  top: 110px;
}
.top-120px {
  top: 120px;
}
.top-130px {
  top: 130px;
}
.top-140px {
  top: 140px;
}
.top-150px {
  top: 150px;
}
.right-0px {
  right: 0px;
}
.right-1px {
  right: 1px;
}
.right-2px {
  right: 2px;
}
.right-3px {
  right: 3px;
}
.right-4px {
  right: 4px;
}
.right-5px {
  right: 5px;
}
.right-6px {
  right: 6px;
}
.right-7px {
  right: 7px;
}
.right-8px {
  right: 8px;
}
.right-9px {
  right: 9px;
}
.right-10px {
  right: 10px;
}
.right-11px {
  right: 11px;
}
.right-12px {
  right: 12px;
}
.right-13px {
  right: 13px;
}
.right-14px {
  right: 14px;
}
.right-15px {
  right: 15px;
}
.right-16px {
  right: 16px;
}
.right-17px {
  right: 17px;
}
.right-18px {
  right: 18px;
}
.right-19px {
  right: 19px;
}
.right-20px {
  right: 20px;
}
.right-21px {
  right: 21px;
}
.right-22px {
  right: 22px;
}
.right-23px {
  right: 23px;
}
.right-24px {
  right: 24px;
}
.right-25px {
  right: 25px;
}
.right-26px {
  right: 26px;
}
.right-27px {
  right: 27px;
}
.right-28px {
  right: 28px;
}
.right-29px {
  right: 29px;
}
.right-30px {
  right: 30px;
}
.right-40px {
  right: 40px;
}
.right-50px {
  right: 50px;
}
.right-60px {
  right: 60px;
}
.right-70px {
  right: 70px;
}
.right-80px {
  right: 80px;
}
.right-90px {
  right: 90px;
}
.right-100px {
  right: 100px;
}
.right-110px {
  right: 110px;
}
.right-120px {
  right: 120px;
}
.right-130px {
  right: 130px;
}
.right-140px {
  right: 140px;
}
.right-150px {
  right: 150px;
}
.bottom-0px {
  bottom: 0px;
}
.bottom-1px {
  bottom: 1px;
}
.bottom-2px {
  bottom: 2px;
}
.bottom-3px {
  bottom: 3px;
}
.bottom-4px {
  bottom: 4px;
}
.bottom-5px {
  bottom: 5px;
}
.bottom-6px {
  bottom: 6px;
}
.bottom-7px {
  bottom: 7px;
}
.bottom-8px {
  bottom: 8px;
}
.bottom-9px {
  bottom: 9px;
}
.bottom-10px {
  bottom: 10px;
}
.bottom-11px {
  bottom: 11px;
}
.bottom-12px {
  bottom: 12px;
}
.bottom-13px {
  bottom: 13px;
}
.bottom-14px {
  bottom: 14px;
}
.bottom-15px {
  bottom: 15px;
}
.bottom-16px {
  bottom: 16px;
}
.bottom-17px {
  bottom: 17px;
}
.bottom-18px {
  bottom: 18px;
}
.bottom-19px {
  bottom: 19px;
}
.bottom-20px {
  bottom: 20px;
}
.bottom-21px {
  bottom: 21px;
}
.bottom-22px {
  bottom: 22px;
}
.bottom-23px {
  bottom: 23px;
}
.bottom-24px {
  bottom: 24px;
}
.bottom-25px {
  bottom: 25px;
}
.bottom-26px {
  bottom: 26px;
}
.bottom-27px {
  bottom: 27px;
}
.bottom-28px {
  bottom: 28px;
}
.bottom-29px {
  bottom: 29px;
}
.bottom-30px {
  bottom: 30px;
}
.bottom-40px {
  bottom: 40px;
}
.bottom-50px {
  bottom: 50px;
}
.bottom-60px {
  bottom: 60px;
}
.bottom-70px {
  bottom: 70px;
}
.bottom-80px {
  bottom: 80px;
}
.bottom-90px {
  bottom: 90px;
}
.bottom-100px {
  bottom: 100px;
}
.bottom-110px {
  bottom: 110px;
}
.bottom-120px {
  bottom: 120px;
}
.bottom-130px {
  bottom: 130px;
}
.bottom-140px {
  bottom: 140px;
}
.bottom-150px {
  bottom: 150px;
}
.left-0px {
  left: 0px;
}
.left-1px {
  left: 1px;
}
.left-2px {
  left: 2px;
}
.left-3px {
  left: 3px;
}
.left-4px {
  left: 4px;
}
.left-5px {
  left: 5px;
}
.left-6px {
  left: 6px;
}
.left-7px {
  left: 7px;
}
.left-8px {
  left: 8px;
}
.left-9px {
  left: 9px;
}
.left-10px {
  left: 10px;
}
.left-11px {
  left: 11px;
}
.left-12px {
  left: 12px;
}
.left-13px {
  left: 13px;
}
.left-14px {
  left: 14px;
}
.left-15px {
  left: 15px;
}
.left-16px {
  left: 16px;
}
.left-17px {
  left: 17px;
}
.left-18px {
  left: 18px;
}
.left-19px {
  left: 19px;
}
.left-20px {
  left: 20px;
}
.left-21px {
  left: 21px;
}
.left-22px {
  left: 22px;
}
.left-23px {
  left: 23px;
}
.left-24px {
  left: 24px;
}
.left-25px {
  left: 25px;
}
.left-26px {
  left: 26px;
}
.left-27px {
  left: 27px;
}
.left-28px {
  left: 28px;
}
.left-29px {
  left: 29px;
}
.left-30px {
  left: 30px;
}
.left-40px {
  left: 40px;
}
.left-50px {
  left: 50px;
}
.left-60px {
  left: 60px;
}
.left-70px {
  left: 70px;
}
.left-80px {
  left: 80px;
}
.left-90px {
  left: 90px;
}
.left-100px {
  left: 100px;
}
.left-110px {
  left: 110px;
}
.left-120px {
  left: 120px;
}
.left-130px {
  left: 130px;
}
.left-140px {
  left: 140px;
}
.left-150px {
  left: 150px;
}
.top-minus-0px {
  top: -0px;
}
.top-minus-1px {
  top: -1px;
}
.top-minus-2px {
  top: -2px;
}
.top-minus-3px {
  top: -3px;
}
.top-minus-4px {
  top: -4px;
}
.top-minus-5px {
  top: -5px;
}
.top-minus-6px {
  top: -6px;
}
.top-minus-7px {
  top: -7px;
}
.top-minus-8px {
  top: -8px;
}
.top-minus-9px {
  top: -9px;
}
.top-minus-10px {
  top: -10px;
}
.top-minus-11px {
  top: -11px;
}
.top-minus-12px {
  top: -12px;
}
.top-minus-13px {
  top: -13px;
}
.top-minus-14px {
  top: -14px;
}
.top-minus-15px {
  top: -15px;
}
.top-minus-16px {
  top: -16px;
}
.top-minus-17px {
  top: -17px;
}
.top-minus-18px {
  top: -18px;
}
.top-minus-19px {
  top: -19px;
}
.top-minus-20px {
  top: -20px;
}
.top-minus-21px {
  top: -21px;
}
.top-minus-22px {
  top: -22px;
}
.top-minus-23px {
  top: -23px;
}
.top-minus-24px {
  top: -24px;
}
.top-minus-25px {
  top: -25px;
}
.top-minus-26px {
  top: -26px;
}
.top-minus-27px {
  top: -27px;
}
.top-minus-28px {
  top: -28px;
}
.top-minus-29px {
  top: -29px;
}
.top-minus-30px {
  top: -30px;
}
.top-minus-40px {
  top: -40px;
}
.top-minus-50px {
  top: -50px;
}
.top-minus-60px {
  top: -60px;
}
.top-minus-70px {
  top: -70px;
}
.top-minus-80px {
  top: -80px;
}
.top-minus-90px {
  top: -90px;
}
.top-minus-100px {
  top: -100px;
}
.top-minus-110px {
  top: -110px;
}
.top-minus-120px {
  top: -120px;
}
.top-minus-130px {
  top: -130px;
}
.top-minus-140px {
  top: -140px;
}
.top-minus-150px {
  top: -150px;
}
.right-minus-0px {
  right: -0px;
}
.right-minus-1px {
  right: -1px;
}
.right-minus-2px {
  right: -2px;
}
.right-minus-3px {
  right: -3px;
}
.right-minus-4px {
  right: -4px;
}
.right-minus-5px {
  right: -5px;
}
.right-minus-6px {
  right: -6px;
}
.right-minus-7px {
  right: -7px;
}
.right-minus-8px {
  right: -8px;
}
.right-minus-9px {
  right: -9px;
}
.right-minus-10px {
  right: -10px;
}
.right-minus-11px {
  right: -11px;
}
.right-minus-12px {
  right: -12px;
}
.right-minus-13px {
  right: -13px;
}
.right-minus-14px {
  right: -14px;
}
.right-minus-15px {
  right: -15px;
}
.right-minus-16px {
  right: -16px;
}
.right-minus-17px {
  right: -17px;
}
.right-minus-18px {
  right: -18px;
}
.right-minus-19px {
  right: -19px;
}
.right-minus-20px {
  right: -20px;
}
.right-minus-21px {
  right: -21px;
}
.right-minus-22px {
  right: -22px;
}
.right-minus-23px {
  right: -23px;
}
.right-minus-24px {
  right: -24px;
}
.right-minus-25px {
  right: -25px;
}
.right-minus-26px {
  right: -26px;
}
.right-minus-27px {
  right: -27px;
}
.right-minus-28px {
  right: -28px;
}
.right-minus-29px {
  right: -29px;
}
.right-minus-30px {
  right: -30px;
}
.right-minus-40px {
  right: -40px;
}
.right-minus-50px {
  right: -50px;
}
.right-minus-60px {
  right: -60px;
}
.right-minus-70px {
  right: -70px;
}
.right-minus-80px {
  right: -80px;
}
.right-minus-90px {
  right: -90px;
}
.right-minus-100px {
  right: -100px;
}
.right-minus-110px {
  right: -110px;
}
.right-minus-120px {
  right: -120px;
}
.right-minus-130px {
  right: -130px;
}
.right-minus-140px {
  right: -140px;
}
.right-minus-150px {
  right: -150px;
}
.bottom-minus-0px {
  bottom: -0px;
}
.bottom-minus-1px {
  bottom: -1px;
}
.bottom-minus-2px {
  bottom: -2px;
}
.bottom-minus-3px {
  bottom: -3px;
}
.bottom-minus-4px {
  bottom: -4px;
}
.bottom-minus-5px {
  bottom: -5px;
}
.bottom-minus-6px {
  bottom: -6px;
}
.bottom-minus-7px {
  bottom: -7px;
}
.bottom-minus-8px {
  bottom: -8px;
}
.bottom-minus-9px {
  bottom: -9px;
}
.bottom-minus-10px {
  bottom: -10px;
}
.bottom-minus-11px {
  bottom: -11px;
}
.bottom-minus-12px {
  bottom: -12px;
}
.bottom-minus-13px {
  bottom: -13px;
}
.bottom-minus-14px {
  bottom: -14px;
}
.bottom-minus-15px {
  bottom: -15px;
}
.bottom-minus-16px {
  bottom: -16px;
}
.bottom-minus-17px {
  bottom: -17px;
}
.bottom-minus-18px {
  bottom: -18px;
}
.bottom-minus-19px {
  bottom: -19px;
}
.bottom-minus-20px {
  bottom: -20px;
}
.bottom-minus-21px {
  bottom: -21px;
}
.bottom-minus-22px {
  bottom: -22px;
}
.bottom-minus-23px {
  bottom: -23px;
}
.bottom-minus-24px {
  bottom: -24px;
}
.bottom-minus-25px {
  bottom: -25px;
}
.bottom-minus-26px {
  bottom: -26px;
}
.bottom-minus-27px {
  bottom: -27px;
}
.bottom-minus-28px {
  bottom: -28px;
}
.bottom-minus-29px {
  bottom: -29px;
}
.bottom-minus-30px {
  bottom: -30px;
}
.bottom-minus-40px {
  bottom: -40px;
}
.bottom-minus-50px {
  bottom: -50px;
}
.bottom-minus-60px {
  bottom: -60px;
}
.bottom-minus-70px {
  bottom: -70px;
}
.bottom-minus-80px {
  bottom: -80px;
}
.bottom-minus-90px {
  bottom: -90px;
}
.bottom-minus-100px {
  bottom: -100px;
}
.bottom-minus-110px {
  bottom: -110px;
}
.bottom-minus-120px {
  bottom: -120px;
}
.bottom-minus-130px {
  bottom: -130px;
}
.bottom-minus-140px {
  bottom: -140px;
}
.bottom-minus-150px {
  bottom: -150px;
}
.left-minus-0px {
  left: -0px;
}
.left-minus-1px {
  left: -1px;
}
.left-minus-2px {
  left: -2px;
}
.left-minus-3px {
  left: -3px;
}
.left-minus-4px {
  left: -4px;
}
.left-minus-5px {
  left: -5px;
}
.left-minus-6px {
  left: -6px;
}
.left-minus-7px {
  left: -7px;
}
.left-minus-8px {
  left: -8px;
}
.left-minus-9px {
  left: -9px;
}
.left-minus-10px {
  left: -10px;
}
.left-minus-11px {
  left: -11px;
}
.left-minus-12px {
  left: -12px;
}
.left-minus-13px {
  left: -13px;
}
.left-minus-14px {
  left: -14px;
}
.left-minus-15px {
  left: -15px;
}
.left-minus-16px {
  left: -16px;
}
.left-minus-17px {
  left: -17px;
}
.left-minus-18px {
  left: -18px;
}
.left-minus-19px {
  left: -19px;
}
.left-minus-20px {
  left: -20px;
}
.left-minus-21px {
  left: -21px;
}
.left-minus-22px {
  left: -22px;
}
.left-minus-23px {
  left: -23px;
}
.left-minus-24px {
  left: -24px;
}
.left-minus-25px {
  left: -25px;
}
.left-minus-26px {
  left: -26px;
}
.left-minus-27px {
  left: -27px;
}
.left-minus-28px {
  left: -28px;
}
.left-minus-29px {
  left: -29px;
}
.left-minus-30px {
  left: -30px;
}
.left-minus-40px {
  left: -40px;
}
.left-minus-50px {
  left: -50px;
}
.left-minus-60px {
  left: -60px;
}
.left-minus-70px {
  left: -70px;
}
.left-minus-80px {
  left: -80px;
}
.left-minus-90px {
  left: -90px;
}
.left-minus-100px {
  left: -100px;
}
.left-minus-110px {
  left: -110px;
}
.left-minus-120px {
  left: -120px;
}
.left-minus-130px {
  left: -130px;
}
.left-minus-140px {
  left: -140px;
}
.left-minus-150px {
  left: -150px;
}
/* Font weight */
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
/* Min height */
.min-h-100px {
  min-height: 100px !important;
}
.min-h-150px {
  min-height: 150px !important;
}
.min-h-200px {
  min-height: 200px !important;
}
.min-h-250px {
  min-height: 250px !important;
}
.min-h-300px {
  min-height: 300px !important;
}
.min-h-350px {
  min-height: 350px !important;
}
.min-h-400px {
  min-height: 400px !important;
}
.min-h-450px {
  min-height: 450px !important;
}
.min-h-500px {
  min-height: 500px !important;
}
.min-h-550px {
  min-height: 550px !important;
}
.min-h-600px {
  min-height: 600px !important;
}
.min-h-650px {
  min-height: 650px !important;
}
.min-h-700px {
  min-height: 700px !important;
}
.min-h-750px {
  min-height: 750px !important;
}
.min-h-800px {
  min-height: 800px !important;
}
.min-h-850px {
  min-height: 850px !important;
}
.min-h-900px {
  min-height: 900px !important;
}
.min-h-950px {
  min-height: 950px !important;
}
/* Opacity */
.opacity-0 {
  opacity: .0;
}
.opacity-1 {
  opacity: .1;
}
.opacity-2 {
  opacity: .2;
}
.opacity-3 {
  opacity: .3;
}
.opacity-4 {
  opacity: .4;
}
.opacity-5 {
  opacity: .5;
}
.opacity-6 {
  opacity: .6;
}
.opacity-7 {
  opacity: .7;
}
.opacity-8 {
  opacity: .8;
}
.opacity-9 {
  opacity: .9;
}
/* Z-index */
.z-index-0 {
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.z-index-4 {
  z-index: 4;
}
.z-index-5 {
  z-index: 5;
}
.z-index-6 {
  z-index: 6;
}
.z-index-7 {
  z-index: 7;
}
.z-index-8 {
  z-index: 8;
}
.z-index-9 {
  z-index: 9;
}
.z-index-minus-1 {
  z-index: -1;
}
.z-index-minus-2 {
  z-index: -2;
}
/* ===================================
   10. Header
====================================== */
header .header-top-bar [class*=col-] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header .header-top-bar [class*=col-].text-end {
  justify-content: flex-end !important;
}
header .header-top-bar [class*=col-] .header-icon {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
header .navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
header .navbar [class*=col-] {
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
header .navbar [class*=col-].text-end {
  justify-content: flex-end !important;
}
header .navbar [class*=col-] .navbar-nav {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header .navbar [class*=col-] .navbar-nav .nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header .navbar [class*=col-] .navbar-nav .nav-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header .container-fluid {
  padding-left: 45px;
  padding-right: 45px;
}
header.sticky .navbar-brand {
  padding: 20px 0;
}
header.sticky .navbar {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
header.sticky .navbar.sticky-header {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
header.sticky .navbar.fixed-header {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
header.sticky .header-light:not(.disable-fixed) {
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.1);
}
header.sticky.sticky-active .header-reverse {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  border-color: transparent !important;
}
header.sticky.sticky-active .header-reverse.glass-effect {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.85) !important;
}
header.sticky.sticky-active .header-reverse.header-dark.glass-effect {
  background-color: rgba(35, 35, 35, 0.7) !important;
}
header.sticky.sticky-active .navbar-brand .alt-logo {
  visibility: visible;
  opacity: 1;
  width: auto;
}
header.sticky.sticky-active .navbar-brand .default-logo {
  visibility: hidden;
  opacity: 0;
  width: 0;
}
header.sticky.sticky-active [data-header-hover=dark] {
  background-color: var(--dark-gray) !important;
}
header.sticky.sticky-active [data-header-hover=dark] .navbar-nav .nav-item:hover .nav-link {
  color: var(--white);
  opacity: 0.6;
}
header.sticky.sticky-active [data-header-hover=dark] .navbar-nav .nav-link {
  color: var(--white);
}
header.sticky.sticky-active [data-header-hover=dark] .icon > a {
  color: var(--white);
}
header.sticky.sticky-active [data-header-hover=dark] .icon > a:hover {
  color: var(--white);
  opacity: 0.6;
}
header.sticky.sticky-active [data-header-hover=dark] .icon .push-button span {
  background-color: var(--white);
}
header.sticky.sticky-active [data-header-hover=dark] .icon .header-language > a, header.sticky.sticky-active [data-header-hover=dark] .icon .header-cart > a {
  color: var(--white);
}
header.sticky.sticky-active [data-header-hover=dark] .icon .header-language > a:hover, header.sticky.sticky-active [data-header-hover=dark] .icon .header-cart > a:hover {
  color: var(--white);
}
header.sticky.sticky-active [data-header-hover=dark] .header-search-style i {
  color: var(--white);
}
header.sticky.sticky-active [data-header-hover=dark] .header-language-icon .header-language .language-dropdown {
  background-color: var(--dark-gray);
}
header.sticky.sticky-active [data-header-hover=dark] .header-language-icon .header-language .language-dropdown a {
  color: var(--light-gray);
}
header.sticky.sticky-active [data-header-hover=dark] .header-language-icon .header-language .language-dropdown a:hover {
  color: var(--white);
  opacity: 1;
}
header.sticky.sticky-active [data-header-hover=light] {
  background-color: var(--white) !important;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}
header.sticky.sticky-active [data-header-hover=light] .navbar-nav .nav-link {
  color: var(--dark-gray);
}
header.sticky.sticky-active [data-header-hover=light] .icon > a {
  color: var(--dark-gray);
}
header.sticky.sticky-active [data-header-hover=light] .icon > a:hover {
  color: var(--medium-gray);
  opacity: 1;
}
header.sticky.sticky-active [data-header-hover=light] .icon .push-button span {
  background-color: var(--dark-gray);
}
header.sticky.sticky-active [data-header-hover=light] .icon .header-language > a, header.sticky.sticky-active [data-header-hover=light] .icon .header-cart > a {
  color: var(--dark-gray);
}
header.sticky.sticky-active [data-header-hover=light] .icon .header-language > a:hover, header.sticky.sticky-active [data-header-hover=light] .icon .header-cart > a:hover {
  color: var(--medium-gray);
  opacity: 1;
}
header.sticky.sticky-active [data-header-hover=light] .header-search-style i {
  color: var(--dark-gray);
}
header.sticky.sticky-active [data-header-hover=light] .header-language-icon .language-dropdown li a {
  color: var(--medium-gray);
}
header.sticky.sticky-active [data-header-hover=light] .header-language-icon .language-dropdown li a:hover {
  color: var(--dark-gray);
  opacity: 1;
}
header.sticky.sticky-active [data-header-hover=light] .widget-text {
  color: var(--dark-gray);
}
header.sticky.sticky-active [data-header-hover=light] .widget-text a {
  color: var(--dark-gray);
}
header.sticky.sticky-active [data-header-hover=light].disable-fixed.header-demo {
  background-color: transparent !important;
}
header.sticky .header-icon .push-button {
  padding: 0;
}
header.sticky .center-logo .navbar-nav .nav-link {
  padding: 26px 20px;
}
header.sticky .top-logo .navbar-brand {
  padding: 15px 0;
}
header.sticky .top-logo.fixed-header .navbar-brand, header.sticky .top-logo.disable-fixed .navbar-brand {
  padding: 22px 0;
}
header.sticky .top-logo.responsive-sticky .navbar-nav .nav-link {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
header.sticky .top-logo.responsive-sticky .header-icon .header-cart-icon .header-cart a i {
  padding-top: 22px;
  padding-bottom: 22px;
}
header.sticky .top-logo.responsive-sticky .header-icon .header-language-icon .header-cart > a i {
  padding-top: 22px;
  padding-bottom: 22px;
}
header.sticky .mini-header .header-icon .header-language a i {
  padding-top: 16px;
  padding-bottom: 16px;
}
header.sticky .mini-header .header-icon .header-cart-icon .header-cart a i {
  padding-top: 16px;
  padding-bottom: 16px;
}
header.sticky .fixed-header .navbar-brand, header.sticky .disable-fixed .navbar-brand {
  padding: 30px 0;
}
header .navbar {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
header .navbar.mini-header .navbar-brand {
  padding: 20px 0;
}
header .navbar.mini-header .navbar-brand img {
  max-height: 22px;
}
header .navbar-brand {
  margin-right: 0;
  vertical-align: middle;
  padding: 30px 0;
  display: inline-block;
  font-size: 0;
}
header .navbar-brand .default-logo {
  visibility: visible;
  opacity: 1;
  width: auto;
}
header .navbar-brand .alt-logo, header .navbar-brand .mobile-logo {
  visibility: hidden;
  opacity: 0;
  width: 0;
}
header .navbar-brand img {
  max-height: 46px;
}
header .header-top-bar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  left: 0;
  z-index: 100;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
header .header-top-bar + .navbar {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  top: 40px;
}
header .header-top-bar + .navbar.disable-fixed {
  top: 0;
}
header .header-top-bar .widget {
  display: inline-block;
}
header .header-top-bar .widget i {
  padding-right: 5px;
}
header .header-top-bar .header-icon .header-social-icon {
  top: 0;
}
header .header-top-bar .header-icon .header-social-icon ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
header .header-top-bar .header-icon .header-social-icon ul li {
  display: inline-block;
  margin: 0 15px 0 0;
}
header .header-top-bar .header-icon .header-social-icon ul li:last-child {
  margin-right: 0;
}
header .header-top-bar .header-icon .header-social-icon a {
  font-size: 12px;
}
header .header-top-bar .header-icon .header-social-text {
  top: 0;
}
header .header-top-bar .header-icon .header-social-text ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
header .header-top-bar .header-icon .header-social-text ul li {
  display: inline-block;
  margin: 0 30px 0 0;
}
header .header-top-bar .header-icon .header-social-text ul li:last-child {
  margin-right: 0;
}
header .left-nav .navbar-nav:before {
  height: 20px;
  width: 1px;
  background-color: var(--medium-gray);
  opacity: 0.5;
  content: "";
  -webkit-transform: translate(-7px, 0px);
  transform: translate(-7px, 0px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
header .disable-fixed {
  position: absolute;
}
header.sticky .responsive-sticky {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
header.sticky .navbar.disable-fixed {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  box-shadow: none;
}

/* Navbar */
.navbar {
  padding: 0;
}
.navbar .navbar-nav .nav-item > i {
  display: none;
}
.navbar .navbar-nav .nav-item:hover .nav-link, .navbar .navbar-nav .nav-item.active .nav-link {
  opacity: 0.6;
}
.navbar .navbar-nav .nav-item .nav-link.active {
  opacity: 0.6;
}
.navbar .navbar-nav .nav-link {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 20px;
  position: relative;
  font-weight: 500;
  opacity: 1;
  word-break: normal;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: var(--white);
}
.navbar .navbar-nav .nav-link .label {
  display: inline-block;
  padding: 1px 11px;
  font-size: 12px;
  margin-left: 8px;
  margin-top: 0px;
  vertical-align: middle;
}
.navbar .navbar-nav .dropdown.submenu {
  position: inherit;
}
.navbar .navbar-nav .dropdown .dropdown-menu {
  margin: 0;
  padding: 60px 0 65px;
  border: 0;
  top: 100%;
  background-color: var(--white);
  box-shadow: 0 45px 45px 0 rgba(0, 0, 0, 0.05);
  -webkit-animation: hide-animation 0.5s;
  animation: hide-animation 0.5s;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.navbar .navbar-nav .dropdown .dropdown-menu li:last-child > a {
  padding-bottom: 0;
}
.navbar .navbar-nav .dropdown .dropdown-menu li.active > a {
  color: var(--dark-gray);
}
.navbar .navbar-nav .dropdown .dropdown-menu li a:hover {
  padding-left: 3px;
}
.navbar .navbar-nav .dropdown .dropdown-menu a {
  color: var(--medium-gray);
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  padding-bottom: 10px;
  display: block;
}
.navbar .navbar-nav .dropdown .dropdown-menu a:hover {
  color: var(--dark-gray);
}
.navbar .navbar-nav .dropdown .dropdown-menu a i {
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
  text-align: center;
}
.navbar .navbar-nav .dropdown .dropdown-menu a .label {
  display: inline-block;
  padding: 5px 10px;
  font-size: 10px;
  line-height: initial;
  margin-left: 8px;
  margin-top: 0px;
  vertical-align: middle;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}
.navbar .navbar-nav .dropdown.open .dropdown-menu {
  display: block;
  -webkit-animation: show-animation 0.5s;
  animation: show-animation 0.5s;
}
.navbar .navbar-nav .submenu-content {
  width: 100%;
  border-top: 1px solid #e7e7e7 !important;
  left: 0;
}
.navbar .navbar-nav .submenu-content ul {
  padding: 0 30px 0 0;
  list-style: none;
}
.navbar .navbar-nav .submenu-content ul:nth-child(4n+1) {
  clear: both;
}
.navbar .navbar-nav .submenu-content ul .sub-title {
  color: var(--dark-gray);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.navbar .navbar-nav .submenu-content ul:last-child:before {
  display: none;
}
.navbar .navbar-nav .submenu-content .mega-menu {
  width: 1190px;
}
.navbar .navbar-nav .simple-dropdown .dropdown-menu {
  display: none;
  width: 245px;
  padding: 35px 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}
.navbar .navbar-nav .simple-dropdown .dropdown-menu li {
  padding: 0 45px 0 40px;
}
.navbar .navbar-nav .simple-dropdown .dropdown-menu li a {
  padding-bottom: 12px;
}
.navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown {
  position: relative;
}
.navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown i {
  position: absolute;
  right: 35px;
  top: 6px;
  width: auto;
  font-size: 13px;
}
.navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown.open > a {
  color: var(--dark-gray);
}
.navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown.open > .dropdown-menu {
  display: block;
}
.navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown.menu-left .dropdown-menu {
  left: calc(-100% - 1px);
}
.navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown .dropdown-menu {
  -webkit-animation: none;
  animation: none;
}
.navbar .navbar-nav .simple-dropdown .dropdown-menu .dropdown-menu {
  display: none;
  position: absolute;
  left: calc(100% + 1px);
  top: -35px;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu {
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
  width: 260px;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li {
  padding: 0;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li a {
  font-size: 15px;
  line-height: 26px;
  border-bottom: 1px solid rgba(23, 23, 23, 0.1);
  padding: 17px 20px;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li a i {
  font-size: 19px;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li a img {
  width: 35px;
  margin-right: 15px;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li:last-child a {
  border-bottom: 0;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu {
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 310px;
  padding: 20px 35px;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li {
  justify-content: center;
  display: flex;
  border-bottom: 0;
  padding: 0;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li:first-child a {
  border-top: 0;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a {
  margin: 0;
  border-top: 1px solid rgba(23, 23, 23, 0.1);
  padding: 15px 30px 15px 0;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a i {
  width: 40px;
  font-size: 36px;
  margin-right: 15px;
  color: var(--base-color);
  flex-shrink: 0;
  text-align: left;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a span {
  color: var(--dark-gray);
  font-weight: 600;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a p {
  margin: 0;
  font-size: 13px;
  color: var(--medium-gray);
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a img {
  border-radius: 100%;
  width: 45px;
  margin-right: 15px;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a:before {
  content: "\f135";
  color: var(--black);
  font-family: bootstrap-icons;
  font-weight: 400;
  font-size: 23px;
  position: absolute;
  right: 6px;
  opacity: 0;
  -webkit-transition: all 300ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 300ms cubic-bezier(0.61, 1, 0.88, 1);
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a:hover:before {
  right: 0px;
  opacity: 1;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a.btn {
  padding: 15px 20px;
  justify-content: center;
  margin-top: 10px;
  line-height: normal;
  border-top: 0;
  font-size: 14px;
  margin-bottom: 5px;
}
.navbar .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a.btn:before {
  display: none;
}

/* Header hover submenu dark */
[data-header-hover=dark] .navbar-nav .dropdown .dropdown-menu li.active > a {
  color: var(--white);
}
[data-header-hover=dark] .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
[data-header-hover=dark] .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
[data-header-hover=dark] .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a span {
  color: var(--white) !important;
}
[data-header-hover=dark] .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a:before {
  color: var(--white);
}
[data-header-hover=dark] .navbar-nav .simple-dropdown .dropdown-menu, [data-header-hover=dark] .navbar-nav .dropdown-with-icon-style02 .dropdown-menu, [data-header-hover=dark] .navbar-nav .dropdown-with-icon .dropdown-menu {
  background-color: var(--dark-gray);
}
[data-header-hover=dark] .navbar-nav .simple-dropdown .dropdown-menu a, [data-header-hover=dark] .navbar-nav .dropdown-with-icon-style02 .dropdown-menu a, [data-header-hover=dark] .navbar-nav .dropdown-with-icon .dropdown-menu a {
  color: var(--light-gray);
}
[data-header-hover=dark] .navbar-nav .simple-dropdown .dropdown-menu a:hover, [data-header-hover=dark] .navbar-nav .dropdown-with-icon-style02 .dropdown-menu a:hover, [data-header-hover=dark] .navbar-nav .dropdown-with-icon .dropdown-menu a:hover {
  color: var(--white);
}
[data-header-hover=dark] .navbar-nav .simple-dropdown .dropdown-menu .dropdown:hover > a, [data-header-hover=dark] .navbar-nav .dropdown-with-icon-style02 .dropdown-menu .dropdown:hover > a, [data-header-hover=dark] .navbar-nav .dropdown-with-icon .dropdown-menu .dropdown:hover > a {
  color: var(--white);
}
[data-header-hover=dark] .header-cart-icon .header-cart .cart-item-list {
  background-color: var(--dark-gray);
}
[data-header-hover=dark] .header-cart-icon .header-cart .cart-item-list .cart-item {
  border-color: #3e3e3e;
}
[data-header-hover=dark] .header-cart-icon .header-cart .cart-item-list .cart-item.cart-total {
  background-color: var(--black);
}
[data-header-hover=dark] .header-cart-icon .header-cart .cart-item-list .cart-item.cart-total span {
  color: var(--white);
}
[data-header-hover=dark] .header-cart-icon .header-cart .cart-item-list .cart-item .product-detail a {
  color: var(--medium-gray);
}
[data-header-hover=dark] .header-cart-icon .header-cart .cart-item-list .cart-item .product-detail a:hover {
  color: var(--white);
}
[data-header-hover=dark] .header-cart-icon .header-cart .cart-item-list .cart-item .close {
  color: var(--white);
}
[data-header-hover=dark] .header-language-icon .header-language .language-dropdown {
  background-color: var(--dark-gray);
}
[data-header-hover=dark] .header-language-icon .header-language .language-dropdown a {
  color: var(--light-gray);
}
[data-header-hover=dark] .header-language-icon .header-language .language-dropdown a:hover {
  color: var(--white);
  opacity: 1;
}
.submenu-dark {
  background-color: var(--dark-gray) !important;
}
.submenu-dark .navbar-brand .alt-logo {
  visibility: visible;
  opacity: 1;
  width: auto;
}
.submenu-dark .navbar-brand .default-logo {
  visibility: hidden;
  opacity: 0;
  width: 0;
}
.submenu-dark .navbar-nav .dropdown .dropdown-menu {
  background-color: var(--dark-gray);
}
.submenu-dark .navbar-nav .dropdown .dropdown-menu a {
  color: var(--light-gray);
}
.submenu-dark .navbar-nav .dropdown .dropdown-menu a:hover {
  color: var(--white);
}
.submenu-dark .navbar-nav .dropdown .dropdown-menu li.active > a {
  color: var(--white);
}
.submenu-dark .navbar-nav .submenu-content {
  border-color: #424242 !important;
}
.submenu-dark .navbar-nav .submenu-content ul .sub-title {
  color: var(--white);
}
.submenu-dark .navbar-nav .submenu-content ul:before {
  background-color: var(--medium-gray);
}
.submenu-dark .navbar-nav .simple-dropdown .dropdown-menu {
  background-color: var(--dark-gray);
}
.submenu-dark .navbar-nav .simple-dropdown .dropdown-menu a {
  color: var(--light-gray);
}
.submenu-dark .navbar-nav .simple-dropdown .dropdown-menu a:hover {
  color: var(--white);
}
.submenu-dark .navbar-nav .simple-dropdown .dropdown-menu .dropdown:hover > a {
  color: var(--white);
}
.submenu-dark .header-cart-icon .header-cart .cart-item-list {
  background-color: var(--dark-gray);
}
.submenu-dark .header-cart-icon .header-cart .cart-item-list .cart-item {
  border-color: #3e3e3e;
}
.submenu-dark .header-cart-icon .header-cart .cart-item-list .cart-item.cart-total {
  background-color: var(--black);
}
.submenu-dark .header-cart-icon .header-cart .cart-item-list .cart-item.cart-total span {
  color: var(--white);
}
.submenu-dark .header-cart-icon .header-cart .cart-item-list .cart-item .product-detail a {
  color: var(--medium-gray);
}
.submenu-dark .header-cart-icon .header-cart .cart-item-list .cart-item .product-detail a:hover {
  color: var(--white);
}
.submenu-dark .header-cart-icon .header-cart .cart-item-list .cart-item .close {
  color: var(--white);
}
.submenu-dark .header-cart-icon .header-language .language-dropdown {
  background-color: var(--dark-gray);
}
.submenu-dark .header-cart-icon .header-language .language-dropdown a {
  color: var(--light-gray);
}
.submenu-dark .header-cart-icon .header-language .language-dropdown a:hover {
  color: var(--white);
  opacity: 1;
}
/* Header hover submenu light */
.submenu-light {
  background-color: var(--white) !important;
}
.submenu-light .navbar-brand .alt-logo {
  visibility: visible;
  opacity: 1;
  width: auto;
}
.submenu-light .navbar-brand .default-logo {
  visibility: hidden;
  opacity: 0;
  width: 0;
}
.submenu-light .navbar-nav .nav-link {
  color: var(--dark-gray);
}
.submenu-light .header-icon .icon > a {
  color: var(--dark-gray);
}
.submenu-light .header-icon .icon > a:hover {
  color: var(--medium-gray);
}
.submenu-light .header-icon .icon .push-button span {
  background-color: var(--dark-gray);
}
.submenu-light .header-icon .icon .header-language > a, .submenu-light .header-icon .icon .header-cart > a {
  color: var(--dark-gray);
}
.submenu-light .header-icon .icon .header-language > a:hover, .submenu-light .header-icon .icon .header-cart > a:hover {
  color: var(--medium-gray);
}
.submenu-light .menu-item-list .nav-link {
  color: var(--dark-gray);
}
.submenu-light .menu-item-list .nav-link:hover {
  color: var(--dark-gray);
  opacity: 0.6;
}
.submenu-light .menu-item-list .sub-menu a {
  color: var(--dark-gray);
  opacity: 0.6;
}
.submenu-light .menu-item-list .menu-item .toggle {
  color: var(--dark-gray);
}
.submenu-light .widget-text {
  color: var(--dark-gray);
}
.submenu-light .widget-text a {
  color: var(--dark-gray);
}
/* Header dark */
.header-dark .navbar-nav .dropdown .dropdown-menu {
  background-color: var(--dark-gray);
}
.header-dark .navbar-nav .dropdown .dropdown-menu a {
  color: var(--light-gray);
}
.header-dark .navbar-nav .dropdown .dropdown-menu a:hover {
  color: var(--white);
}
.header-dark .navbar-nav .dropdown .dropdown-menu li.active > a {
  color: var(--white);
}
.header-dark .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.header-dark .navbar-nav .dropdown.dropdown-with-icon-style02 .dropdown-menu li:last-child a {
  border-bottom: 0;
}
.header-dark .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.header-dark .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a span {
  color: var(--white);
}
.header-dark .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a p {
  color: var(--light-gray);
}
.header-dark .navbar-nav .dropdown.dropdown-with-icon .dropdown-menu li a:before {
  color: var(--white);
}
.header-dark .navbar-nav .submenu-content {
  border-color: #424242 !important;
}
.header-dark .navbar-nav .submenu-content ul .sub-title {
  color: var(--white);
}
.header-dark .navbar-nav .submenu-content ul:before {
  background-color: var(--medium-gray);
}
.header-dark .navbar-nav .simple-dropdown .dropdown-menu {
  background-color: var(--dark-gray);
}
.header-dark .navbar-nav .simple-dropdown .dropdown-menu a {
  color: var(--light-gray);
}
.header-dark .navbar-nav .simple-dropdown .dropdown-menu a:hover {
  color: var(--white);
}
.header-dark .navbar-nav .simple-dropdown .dropdown-menu .dropdown.open > a {
  color: var(--white);
}
.header-dark .navbar-nav .simple-dropdown .dropdown-menu .dropdown:hover > a {
  color: var(--white);
}
.header-dark .header-cart-icon .header-cart .cart-item-list {
  background-color: var(--dark-gray);
}
.header-dark .header-cart-icon .header-cart .cart-item-list .cart-item {
  border-color: #3e3e3e;
}
.header-dark .header-cart-icon .header-cart .cart-item-list .cart-item.cart-total {
  background-color: var(--black);
}
.header-dark .header-cart-icon .header-cart .cart-item-list .cart-item.cart-total span {
  color: var(--white);
}
.header-dark .header-cart-icon .header-cart .cart-item-list .cart-item .product-detail a {
  color: var(--medium-gray);
}
.header-dark .header-cart-icon .header-cart .cart-item-list .cart-item .product-detail a:hover {
  color: var(--white);
}
.header-dark .header-cart-icon .header-cart .cart-item-list .cart-item .close {
  color: var(--white);
}
.header-dark .header-language-icon .header-language .language-dropdown {
  background-color: var(--dark-gray);
}
.header-dark .header-language-icon .header-language .language-dropdown a {
  color: var(--light-gray);
}
.header-dark .header-language-icon .header-language .language-dropdown a:hover {
  color: var(--white);
  opacity: 1;
}
/* Header light */
.header-light .navbar-nav .nav-link {
  color: var(--dark-gray);
}
.header-light .header-icon .icon > a {
  color: var(--dark-gray);
}
.header-light .header-icon .icon > a:hover {
  color: var(--dark-gray);
  opacity: 0.6;
}
.header-light .header-icon .icon .push-button span {
  background-color: var(--dark-gray);
}
.header-light .header-icon .icon .header-language > a, .header-light .header-icon .icon .header-cart > a {
  color: var(--dark-gray);
}
.header-light .header-icon .icon .header-language > a:hover, .header-light .header-icon .icon .header-cart > a:hover {
  color: var(--dark-gray);
  opacity: 0.6;
}
.header-light .menu-item-list .nav-link {
  color: var(--dark-gray);
}
.header-light .menu-item-list .nav-link:hover {
  color: var(--dark-gray);
  opacity: 0.6;
}
.header-light .menu-item-list .sub-menu a {
  color: var(--medium-gray);
  opacity: 1;
}
.header-light .menu-item-list .sub-menu a:hover {
  color: var(--dark-gray);
}
.header-light .menu-item-list .sub-menu .active > a {
  color: var(--dark-gray);
}
.header-light .menu-item-list .menu-item .menu-toggle:before, .header-light .menu-item-list .menu-item .menu-toggle:after {
  background-color: var(--dark-gray);
}
.header-light .widget-text {
  color: var(--dark-gray);
}
.header-light .widget-text a {
  color: var(--dark-gray);
}
/* Header icon */
.header-icon {
  display: flex;
  align-items: center;
}
.header-icon .icon {
  position: relative;
  line-height: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-icon .icon > a {
  padding-left: 16px;
  font-size: 17px;
  color: var(--white);
}
.header-icon .icon > a:hover {
  color: var(--white);
  opacity: 0.6;
}
.header-icon .icon:first-child a:first-child {
  padding-left: 0;
}
.header-icon .icon .header-language, .header-icon .icon .header-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-icon .icon .header-language > a, .header-icon .icon .header-cart > a {
  padding-left: 14px;
  color: var(--white);
  font-size: 17px;
}
.header-icon .icon .header-language > a:hover, .header-icon .icon .header-cart > a:hover {
  color: var(--white);
  opacity: 0.6;
}
.header-icon .header-mail {
  padding-right: 35px;
  top: 0;
}
.header-icon .header-mail .mail {
  font-size: 14px;
}
.header-icon .header-mail .mail i {
  vertical-align: middle;
  padding-right: 5px;
}
.header-icon .header-social-icon a {
  font-size: 17px;
  padding-left: 20px;
  flex-shrink: 0;
}
/* Header cart */
.header-cart-icon .header-cart {
  display: inline-block;
}
.header-cart-icon .header-cart .cart-item-list {
  display: none;
  left: auto;
  right: 0;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
  background-color: var(--white);
  border: 0;
  padding: 0;
  min-width: 250px;
  margin: 0;
  position: absolute;
  z-index: 1;
  top: 100%;
}
.header-cart-icon .header-cart .cart-item-list .cart-item {
  display: flex;
  padding: 17px;
  border-top: 1px solid #ededed;
  position: relative;
}
.header-cart-icon .header-cart .cart-item-list .cart-item .close {
  font-size: 16px;
  position: absolute;
  right: 15px;
  top: 15px;
  font-weight: 400;
  color: var(--dark-gray);
}
.header-cart-icon .header-cart .cart-item-list .cart-item .product-image {
  max-width: 50px;
  width: 30%;
  display: inline-block;
}
.header-cart-icon .header-cart .cart-item-list .cart-item .product-detail {
  padding-left: 15px;
  width: 70%;
  text-align: left;
  display: inline-block;
}
.header-cart-icon .header-cart .cart-item-list .cart-item .product-detail a {
  color: var(--dark-gray);
  font-size: 12px;
  display: inline-block;
  line-height: 16px;
  padding: 0;
}
.header-cart-icon .header-cart .cart-item-list .cart-item .product-detail .item-ammount {
  display: block;
  font-size: 11px;
  color: var(--medium-gray);
  line-height: normal;
}
.header-cart-icon .header-cart .cart-item-list .cart-item:first-child {
  border-top: 0;
}
.header-cart-icon .header-cart .cart-item-list .cart-total {
  background-color: var(--very-light-gray);
  flex-direction: column !important;
  color: var(--dark-gray);
}
.header-cart-icon .header-cart .cart-item-list .cart-total span {
  line-height: 20px;
  display: inline-block;
}
.header-cart-icon .header-cart .cart-item-list .cart-total .btn {
  width: 100%;
  display: block;
  margin: 8px 0;
}
.header-cart-icon .header-cart.open .cart-item-list {
  display: block;
  -webkit-animation: show-animation 0.5s;
  animation: show-animation 0.5s;
}
.header-cart-icon a {
  position: relative;
}
.header-cart-icon .cart-count {
  position: absolute;
  top: -7px;
  right: -11px;
  width: 17px;
  height: 17px;
  font-weight: 600;
  text-align: center;
  font-size: 10px;
  line-height: 18px;
  background-color: var(--base-color);
  color: var(--white);
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
/* Header language */
.header-language-icon .header-language {
  display: inline-block;
}
.header-language-icon .header-language .language-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  padding: 20px;
  list-style: none;
  background-color: var(--white);
  display: none;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-animation: hide-animation 0.5s;
  animation: hide-animation 0.5s;
  z-index: 9;
  text-align: left;
  margin: 0;
  min-width: 140px;
}
.header-language-icon .header-language .language-dropdown li a {
  color: var(--medium-gray);
  font-size: 12px;
  line-height: 28px;
  padding: 0;
}
.header-language-icon .header-language .language-dropdown li a .icon-country {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin: 0 10px 0 5px;
}
.header-language-icon .header-language .language-dropdown li a:hover {
  color: var(--dark-gray);
  opacity: 1;
}
.header-language-icon .header-language.open .language-dropdown {
  display: block;
  -webkit-animation: show-animation 0.5s;
  animation: show-animation 0.5s;
}
/* Header widget */
.widget-text {
  color: var(--white);
}
header a.widget-text:hover {
  opacity: 0.6;
}
/* Header push button */
.header-push-button {
  padding-left: 16px;
}
.header-push-button .push-button {
  position: relative;
  display: block;
  width: 22px;
  height: 14px;
  padding: 0;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.header-push-button .push-button span {
  background: var(--white);
  height: 2px;
  width: 22px;
  content: "";
  display: block;
  border-radius: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
.header-push-button .push-button span:nth-child(2) {
  top: 6px;
}
.header-push-button .push-button span:nth-child(3) {
  top: 6px;
}
.header-push-button .push-button span:nth-child(4) {
  top: 12px;
  width: 14px;
  left: -8px;
}
.header-push-button .push-button:hover {
  opacity: 0.6;
}

.show-menu .header-push-button .push-button span:nth-child(1) {
  top: 7px;
  width: 0%;
}
.show-menu .header-push-button .push-button span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.show-menu .header-push-button .push-button span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.show-menu .header-push-button .push-button span:nth-child(4) {
  top: 7px;
  width: 0%;
}
.navbar-modern-inner {
  display: none;
}
.navbar-modern-inner .navbar-toggler {
  display: none;
}
/* Header push menu */
.show-menu {
  overflow: hidden;
}
.show-menu .push-menu {
  right: 0;
  -webkit-box-shadow: 0 0 60px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.3);
}
.show-menu .push-menu .close-menu {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
  visibility: visible;
  cursor: pointer;
}
.show-menu .push-menu.push-menu-left {
  right: auto;
  left: 0;
}
.push-menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 400px;
  height: 100%;
  z-index: 1031;
  overflow-y: auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.push-menu.push-menu-left {
  right: auto;
  left: -100%;
}
.push-menu .left-circle {
  width: 500px !important;
  height: 500px;
  position: absolute;
  left: -160px;
  top: -80px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.push-menu .left-circle:before, .push-menu .left-circle:after {
  width: 100%;
  height: 100%;
  border: 1px solid var(--light-medium-gray);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10px, 5px);
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.push-menu .left-circle:after {
  transform: translate(5px, 5px);
}
.push-menu .close-menu {
  height: 40px;
  width: 40px;
  text-align: center;
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 9;
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.push-menu .push-menu-wrapper {
  height: 100%;
  overflow-y: hidden;
  padding: 20px 0;
}
.push-menu .push-menu-wrapper .mCustomScrollBox {
  display: flex;
}
.push-menu .push-menu-wrapper .mCustomScrollBox .mCSB_container.mCS_y_hidden.mCS_no_scrollbar_y {
  align-self: center;
}
.push-menu .push-menu-wrapper .mCustomScrollBox .mCSB_container {
  width: 100%;
  align-self: flex-start;
}
.push-menu .push-menu-wrapper .mCSB_inside > .mCSB_container {
  margin: 0 !important;
  padding: 0 15px !important;
}
.push-menu.push-menu-style-2 .push-menu-wrapper .mCustomScrollBox .mCSB_container.mCS_y_hidden.mCS_no_scrollbar_y {
  align-self: start;
}
.push-menu.push-menu-style-3 .push-menu-wrapper .mCustomScrollBox .mCSB_container.mCS_y_hidden.mCS_no_scrollbar_y {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
/* Header center logo */
.center-logo .navbar-nav .nav-link {
  padding: 38px 20px;
}
.center-logo .menu-logo .d-none {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.center-logo .navbar-brand {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: none;
}
.center-logo .navbar-left, .center-logo .navbar-right {
  width: 42%;
}
/* Header top logo */
.top-logo .navbar-brand {
  padding: 22px 0;
}
.top-logo .navbar-nav .nav-link {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
/* Header left menu simple */
.left-sidebar-wrapper {
  padding-left: 300px;
  position: relative;
  min-height: 300px;
  width: 100%;
}
.left-sidebar-wrapper .collapse:not(.show) {
  display: block !important;
}
header.sticky .left-sidebar-nav .navbar-nav .nav-link {
  padding: 0;
}
.left-sidebar-header {
  position: absolute;
  left: 0;
  width: 301px;
  top: -100%;
  bottom: 0;
  z-index: 999;
  display: block;
}
.left-sidebar-header .menu-item-list .menu-item .nav-link {
  font-size: 16px;
  line-height: 20px;
}
.left-sidebar-header .menu-item-list .menu-item .menu-toggle {
  top: 10px;
}
.left-sidebar-header .menu-item-list .menu-item .sub-menu .menu-toggle {
  top: 0;
}
.left-sidebar-nav {
  width: 300px;
  padding: 80px 50px;
  left: 0;
  position: fixed;
  overflow: hidden;
  display: block;
  height: inherit;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.left-sidebar-nav .left-menu-bottom {
  width: 100%;
}
.left-sidebar-nav .left-menu-bottom .header-icon .icon a {
  padding: 0 15px;
}
.left-sidebar-nav .navbar-brand {
  padding: 0 !important;
}
.left-sidebar-nav .side-menu {
  margin-top: 10.5rem;
  margin-bottom: 10.5rem;
  width: 100%;
}
.left-sidebar-nav .navbar-toggler {
  display: none;
}
.social-text {
  width: 100%;
  display: inline-block;
  color: var(--medium-gray);
  font-size: 13px;
  line-height: 16px;
}
.connect-me a {
  color: var(--dark-gray);
  display: table;
}
.connect-me a:hover {
  opacity: 0.6;
}
.connect-me .widget-text {
  color: var(--dark-gray);
  display: table;
}
/* Menu list */
.menu-item-list {
  display: block;
  width: 100%;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.menu-item-list .menu-item {
  position: relative;
  margin: 0;
}
.menu-item-list .menu-item ul {
  list-style: none;
  padding: 0;
}
.menu-item-list .menu-item .menu-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 14px;
  cursor: pointer;
  z-index: 1;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.menu-item-list .menu-item .menu-toggle:before, .menu-item-list .menu-item .menu-toggle:after {
  content: "";
  display: block;
  position: absolute;
  right: 10px;
  top: 15px;
  height: 1px;
  width: 11px;
  background-color: var(--white);
}
.menu-item-list .menu-item .menu-toggle:after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.menu-item-list .menu-item .menu-toggle.show:after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.menu-item-list .menu-item .sub-menu .menu-toggle {
  top: 0;
}
.menu-item-list .menu-item .sub-menu .menu-toggle:before, .menu-item-list .menu-item .sub-menu .menu-toggle:after {
  width: 7px;
}
.menu-item-list .menu-item .sub-menu .active > a {
  opacity: 1;
}
.menu-item-list .active .nav-link {
  opacity: 0.6;
}
.menu-item-list .nav-link {
  padding: 5px 20px 5px 0;
  font-size: 20px;
  line-height: 28px;
  display: inline-block;
  width: 100%;
  margin: 9px 0;
  color: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.menu-item-list .nav-link:hover {
  color: var(--white);
  opacity: 0.6;
}
.menu-item-list .sub-menu {
  padding: 0;
  display: block;
  height: 0;
  overflow: hidden;
  list-style: none;
  margin: 0;
  position: relative !important;
  transform: none !important;
  background-color: transparent;
  border: 0;
}
.menu-item-list .sub-menu li {
  position: relative;
  margin: 0;
  padding: 5px 0;
}
.menu-item-list .sub-menu li .sub-menu {
  margin: 0;
  padding-left: 15px;
}
.menu-item-list .sub-menu li .sub-menu li {
  padding: 10px 0 0;
}
.menu-item-list .sub-menu li .sub-menu li a {
  padding: 0;
}
.menu-item-list .sub-menu li .sub-menu li a i {
  margin-right: 10px;
}
.menu-item-list .sub-menu li .sub-menu li:first-child {
  margin-top: 10px;
}
.menu-item-list .sub-menu li .sub-menu li:last-child {
  margin-bottom: 10px;
}
.menu-item-list .sub-menu li:last-child {
  margin-bottom: 0;
}
.menu-item-list .sub-menu li:last-child .sub-menu li:last-child {
  margin-bottom: 0;
}
.menu-item-list .sub-menu a {
  font-size: 15px;
  line-height: 22px;
  display: block;
  color: var(--light-gray);
  margin: 0;
  padding-right: 20px;
}
.menu-item-list .sub-menu a:hover {
  color: var(--white);
}
.menu-item-list .sub-menu a .label {
  display: inline-block;
  padding: 5px 10px;
  font-size: 10px;
  line-height: initial;
  margin-left: 8px;
  margin-top: 0px;
  vertical-align: middle;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}
.menu-item-list .sub-menu.show {
  height: auto;
  overflow: visible;
}
/* Header left menu modern */
.left-modern-menu {
  padding-left: 75px;
}
.left-modern-menu .collapse:not(.show) {
  display: block !important;
}
.left-modern-menu .left-modern-header .navbar-brand {
  position: relative;
  z-index: 9;
  width: 100%;
  padding: 0 !important;
  text-align: center;
}
.left-modern-menu .left-modern-header .navbar-brand img {
  max-height: 45px;
}
.left-modern-menu .left-modern-header .modern-sidebar-nav {
  position: fixed;
  height: 100%;
  width: 380px;
  overflow-y: auto;
  top: 0;
  left: -400px;
  transition-duration: 0.3s;
  z-index: 9;
}
.left-modern-menu .left-modern-header .modern-sidebar-nav:hover .side-menu-hover-background-color {
  opacity: 1;
}
.left-modern-menu .left-modern-header .modern-side-menu {
  padding: 75px 40px;
  height: 100%;
  overflow-y: hidden;
}
.left-modern-menu .left-modern-header .modern-side-menu .mCustomScrollBox {
  display: flex;
  width: 100%;
}
.left-modern-menu .left-modern-header .modern-side-menu .mCustomScrollBox .mCSB_container {
  align-self: flex-start;
  width: 100%;
  margin: 0;
  padding: 0 30px;
}
.left-modern-menu .left-modern-header .modern-side-menu .mCustomScrollBox .mCSB_container.mCS_no_scrollbar_y {
  align-self: center;
}
.left-modern-menu .left-modern-header.show .modern-sidebar-nav {
  left: 75px;
  box-shadow: 0 0 150px rgba(0, 0, 0, 0.2);
}
.left-modern-menu .left-modern-header.show .navbar-toggler-line:nth-child(1) {
  top: 7px;
  width: 0%;
}
.left-modern-menu .left-modern-header.show .navbar-toggler-line:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.left-modern-menu .left-modern-header.show .navbar-toggler-line:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.left-modern-menu .left-modern-header.show .navbar-toggler-line:nth-child(4) {
  top: 7px;
  width: 0%;
}
.side-menu-hover-background-color {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}
/* Header left menu modern dark */
.header-dark .social-text {
  color: var(--white);
}
.header-dark .connect-me .widget-text {
  color: var(--white);
}
.header-dark .connect-me a {
  color: var(--white);
}
.header-dark .connect-me a:hover {
  opacity: 0.6;
}
.header-dark.left-modern-sidebar .navbar-toggler-line {
  background-color: var(--white);
}
.vertical-title {
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: flex-end;
  flex-direction: column;
  -webkit-transform: translateY(-75px);
  transform: translateY(-75px);
}
.vertical-title .title {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  white-space: nowrap;
  text-transform: uppercase;
}
.menu-list-wrapper {
  overflow: auto;
  height: calc(100vh - 150px);
  padding: 50px 0;
}
.menu-list-wrapper .mCustomScrollBox {
  display: flex;
  width: 100%;
}
.menu-list-wrapper .mCustomScrollBox .mCSB_container {
  align-self: flex-start;
  width: 100%;
  margin: 0;
}
.menu-list-wrapper .mCustomScrollBox .mCSB_container.mCS_no_scrollbar_y {
  align-self: center;
}
.left-modern-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  width: 75px;
  padding: 35px 0;
}
.left-modern-sidebar .toggle-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  -webkit-transform: translate(-12px, -50%);
  transform: translate(-12px, -50%);
}
.left-modern-sidebar .toggle-icon .navbar-toggler {
  margin: 0;
  position: relative;
  margin: 0 auto;
  z-index: 99;
  cursor: pointer;
}
/* Hamburger menu */
.hamburger-nav {
  width: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.hamburger-nav .menu-item-list {
  margin-bottom: 0;
  padding: 0 30px;
}
.hamburger-nav .menu-item-list .menu-item {
  margin: 0;
}
.hamburger-menu .header-icon {
  position: absolute;
  left: 0;
  bottom: -50px;
}
/* Hamburger menu half */
.show-menu .hamburger-menu-half .hamburger-logo {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.2s;
}
.show-menu .hamburger-menu-half .menu-address {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.4s;
}
.show-menu .hamburger-menu-half .menu-text {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.8s;
}
.show-menu .hamburger-menu-half .elements-social {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.8s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  opacity: 1;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(1) {
  transition-delay: 0.27s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(2) {
  transition-delay: 0.34s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(3) {
  transition-delay: 0.41s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(4) {
  transition-delay: 0.48s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(5) {
  transition-delay: 0.55s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(6) {
  transition-delay: 0.62s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(7) {
  transition-delay: 0.69s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(8) {
  transition-delay: 0.76s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(9) {
  transition-delay: 0.83s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(10) {
  transition-delay: 0.9s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(11) {
  transition-delay: 0.97s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(12) {
  transition-delay: 1.04s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(13) {
  transition-delay: 1.11s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(14) {
  transition-delay: 1.18s;
}
.show-menu .hamburger-menu-half .menu-item-list .menu-item:nth-child(15) {
  transition-delay: 1.25s;
}
.hamburger-menu-half .hamburger-logo {
  transform: translateY(30px);
  opacity: 0;
  transition: transform 0.8s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.hamburger-menu-half .menu-address {
  transform: translateY(30px);
  opacity: 0;
  transition: transform 0.8s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.hamburger-menu-half .elements-social {
  transform: translateY(30px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.hamburger-menu-half .menu-item-list .menu-item {
  transform: translateY(30px);
  opacity: 0;
  transition: transform 0.8s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.4s cubic-bezier(0.8, 0.01, 0.165, 0.99);
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(1) {
  transition-delay: 0.49s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(2) {
  transition-delay: 0.42s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(3) {
  transition-delay: 0.35s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(4) {
  transition-delay: 0.28s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(5) {
  transition-delay: 0.21s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(6) {
  transition-delay: 0.14s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(7) {
  transition-delay: 0.07s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(8) {
  transition-delay: 0s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(9) {
  transition-delay: -0.07s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(10) {
  transition-delay: -0.14s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(11) {
  transition-delay: -0.21s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(12) {
  transition-delay: -0.28s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(13) {
  transition-delay: -0.35s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(14) {
  transition-delay: -0.42s;
}
.hamburger-menu-half .menu-item-list .menu-item:nth-child(15) {
  transition-delay: -0.49s;
}
/* Hamburger menu simple */
.show-menu .hamburger-menu-simple {
  opacity: 1;
  visibility: visible;
  top: 0px;
}
.show-menu .hamburger-menu-simple .hamburger-menu {
  opacity: 1;
}
.show-menu .hamburger-menu-simple .hamburger-logo {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.2s;
}
.show-menu .hamburger-menu-simple .menu-address {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.4s;
}
.show-menu .hamburger-menu-simple .menu-text {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.8s;
}
.show-menu .hamburger-menu-simple .elements-social {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.8s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item {
  transform: scale(1) translateY(0px);
  -webkit-transform: scale(1) translateY(0px);
  opacity: 1;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(1) {
  transition-delay: 0.27s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(2) {
  transition-delay: 0.34s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(3) {
  transition-delay: 0.41s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(4) {
  transition-delay: 0.48s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(5) {
  transition-delay: 0.55s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(6) {
  transition-delay: 0.62s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(7) {
  transition-delay: 0.69s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(8) {
  transition-delay: 0.76s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(9) {
  transition-delay: 0.83s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(10) {
  transition-delay: 0.9s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(11) {
  transition-delay: 0.97s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(12) {
  transition-delay: 1.04s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(13) {
  transition-delay: 1.11s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(14) {
  transition-delay: 1.18s;
}
.show-menu .hamburger-menu-simple .menu-item-list .menu-item:nth-child(15) {
  transition-delay: 1.25s;
}
.hamburger-menu-simple {
  width: 100%;
  right: 0;
  opacity: 1;
  visibility: collapse;
  height: 100vh;
  max-height: 100dvh;
  top: -100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.hamburger-menu-simple .navbar-brand {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
}
.hamburger-menu-simple .hamburger-menu {
  padding: 30px 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.4s;
  opacity: 0;
}
.hamburger-menu-simple .hamburger-menu .header-icon {
  bottom: 100px;
}
.hamburger-menu-simple .hamburger-logo {
  transform: translateY(-50px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.hamburger-menu-simple .menu-address {
  transform: translateY(-50px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.hamburger-menu-simple .menu-text {
  transform: translateY(-50px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.hamburger-menu-simple .elements-social {
  transform: translateY(-50px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.hamburger-menu-simple .menu-item-list .menu-item {
  transform: scale(1.15) translateY(-30px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(1) {
  transition-delay: 0.49s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(2) {
  transition-delay: 0.42s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(3) {
  transition-delay: 0.35s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(4) {
  transition-delay: 0.28s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(5) {
  transition-delay: 0.21s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(6) {
  transition-delay: 0.14s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(7) {
  transition-delay: 0.07s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(8) {
  transition-delay: 0s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(9) {
  transition-delay: -0.07s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(10) {
  transition-delay: -0.14s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(11) {
  transition-delay: -0.21s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(12) {
  transition-delay: -0.28s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(13) {
  transition-delay: -0.35s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(14) {
  transition-delay: -0.42s;
}
.hamburger-menu-simple .menu-item-list .menu-item:nth-child(15) {
  transition-delay: -0.49s;
}
.hamburger-menu-simple .menu-item-list .nav-link {
  font-size: 20px;
  width: auto;
  position: relative;
}
.hamburger-menu-simple .menu-item-list .nav-link:hover {
  opacity: 0.6;
}
.hamburger-logo img {
  max-height: 45px;
}
/* Right push menu */
.right-push-menu .menu-item-list .menu-item .toggle {
  top: 18px;
}
.right-push-menu .menu-item-list .sub-menu li .toggle {
  top: 4px;
}
.right-push-menu .menu-item-list .nav-link {
  font-size: 1.5rem;
  line-height: 2.5rem;
}
.right-push-menu .left-menu-bottom {
  margin-top: 30px;
}
.right-push-menu .left-menu-bottom .header-icon {
  position: relative;
  bottom: 0;
}
/* Header search popup */
.show-search-popup {
  overflow: hidden;
}
.show-search-popup header .navbar {
  z-index: 110;
}
.show-search-popup .search-form-wrapper {
  opacity: 1;
  visibility: visible;
  cursor: url(../images/mfg-close.png), auto;
}
.show-search-popup .search-form-wrapper .search-form {
  height: 50%;
  cursor: default;
  visibility: visible;
  opacity: 1;
}
.search-form-wrapper {
  background: rgba(35, 35, 35, 0.9);
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden !important;
  display: block !important;
  height: 100vh;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.search-form-wrapper .search-form {
  height: 0;
  background-color: var(--white);
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
.search-form-wrapper .search-form .search-input {
  outline: none;
  border: 0;
  border-bottom: 1px solid rgba(23, 23, 23, 0.2);
  color: var(--dark-gray);
  font-size: 18px;
  padding: 20px 0;
  background: transparent;
  line-height: 2.5rem;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  height: 80px;
  width: 100%;
}
.search-form-wrapper .search-form .search-input::-moz-placeholder {
  color: var(--light-gray);
  opacity: 1;
}
.search-form-wrapper .search-form .search-input::-webkit-input-placeholder {
  color: var(--light-gray);
}
.search-form-wrapper .search-form .search-button {
  color: var(--dark-gray);
  bottom: 24px;
  position: absolute;
  font-size: 25px;
  right: 0;
  line-height: normal;
  outline: none;
  cursor: pointer;
  z-index: 0;
  margin-right: 0;
  border: none;
  background: none;
}
.search-form-wrapper .search-form:hover {
  color: var(--light-gray);
}
.search-form-wrapper .search-close {
  color: var(--black);
  position: fixed;
  top: 30px;
  right: 35px;
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  font-size: 40px;
  font-weight: 300;
  width: 44px;
  height: 44px;
  line-height: 44px;
}
.search-form-wrapper .search-form-box {
  position: relative;
  width: 45%;
}
/* Custom hide animation */
@-webkit-keyframes hide-animation {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Custom slide animation */
@-webkit-keyframes show-animation {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
button:focus {
  outline: none;
}
/* Header toggle button  */
.navbar-toggler {
  font-size: 24px;
  width: 22px;
  height: 14px;
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  vertical-align: middle;
  border-radius: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler-line {
  background: var(--dark-gray);
  height: 2px;
  width: 22px;
  content: "";
  display: block;
  border-radius: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
.navbar-toggler-line:nth-child(1) {
  top: 0px;
  width: 14px;
}
.navbar-toggler-line:nth-child(2) {
  top: 6px;
}
.navbar-toggler-line:nth-child(3) {
  top: 6px;
}
.navbar-toggler-line:nth-child(4) {
  top: 12px;
  width: 14px;
}
.navbar-collapse-show .navbar-toggler-line:nth-child(1) {
  top: 7px;
  width: 0%;
}
.navbar-collapse-show .navbar-toggler-line:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.navbar-collapse-show .navbar-toggler-line:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.navbar-collapse-show .navbar-toggler-line:nth-child(4) {
  top: 7px;
  width: 0%;
}
.navbar-modern-inner {
  display: none;
}
.navbar-modern-inner .navbar-toggler {
  display: none;
}
.header-with-topbar + .top-space-margin {
  margin-top: 136px;
}
.header-with-topbar + .top-space-padding {
  padding-top: 136px;
}
.navbar-modern-inner, .navbar-full-screen-menu-inner {
  display: none;
}
.top-space-margin {
  margin-top: 96px;
}
.top-space-padding {
  padding-top: 96px;
}
/* Landing page header */
.header-demo.navbar .navbar-nav .premium-element {
  padding: 35px 40px;
  border-radius: 13px;
}
.header-demo.navbar .navbar-nav .premium-element a {
  color: #585858;
  padding-bottom: 8px;
}
.header-demo.navbar .navbar-nav .premium-element .sub-title {
  font-size: 18px;
}
.header-demo.navbar .navbar-nav .nav-item:first-child .dropdown-menu a {
  color: var(--dark-gray);
}
.header-demo.navbar .navbar-nav .nav-link {
  font-weight: 600;
}
.header-demo.navbar .navbar-nav .dropdown .dropdown-menu a {
  font-weight: 500;
}
.header-demo.navbar.header-dark .navbar-nav .nav-item:first-child .dropdown-menu a {
  color: var(--white);
}
/* ===================================
   11. Page title
====================================== */
/* Breadcrumb */
.breadcrumb {
  margin: 0;
}
.breadcrumb ul {
  margin: 0;
  padding: 0;
}
.breadcrumb-style-01 ul li {
  display: inline-block;
}
.breadcrumb-style-01 ul li:after {
  content: "\e844";
  font-family: "feather";
  font-size: 13px;
  font-weight: 900;
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px 0 10px;
}
.breadcrumb-style-01 ul li:last-child:after {
  content: "";
  padding: 0;
}
/* Page title style */
.page-title-double-large h1 {
  font-size: 3.6rem;
  line-height: 3.3rem;
}
.page-title-double-large h2 {
  font-size: 17px;
  line-height: 26px;
}
.page-title-extra-large h1 {
  font-size: 3.3rem;
  line-height: 3.3rem;
}
.page-title-extra-large h2 {
  font-size: 18px;
  line-height: 26px;
}
.page-title-large h1 {
  font-size: 2.8rem;
  line-height: 3rem;
}
.page-title-large h2 {
  font-size: 16px;
  line-height: 22px;
}
.page-title-medium h1 {
  font-size: 2rem;
  line-height: 2.2rem;
}
.page-title-medium h2 {
  font-size: 16px;
  line-height: 22px;
}
.page-title-small h1 {
  font-size: 1.625rem;
  line-height: 1.875rem;
}
.page-title-small h2 {
  font-size: 16px;
  line-height: 22px;
}
.page-title-extra-small h1 {
  font-size: 17px;
  line-height: 20px;
}
.page-title-extra-small h2 {
  font-size: 3.8rem;
  line-height: 3.8rem;
}
.page-title-button-style.page-title-button-style {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.page-title-button-style h1 {
  padding: 13px 30px;
  display: inline-block;
}
.page-title-mini-version h1 {
  font-size: 20px;
  line-height: 30px;
}
/* Down section icon */
.down-section {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  width: 100%;
}
.down-section a {
  display: inline-block;
}
.down-section.down-bottom {
  bottom: 0;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
/* Down section line */
.down-section-line .section-line {
  position: relative;
}
.down-section-line .section-line:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 0;
  width: 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-color: var(--base-color);
}
.down-section-line:hover .section-line:before {
  height: 100%;
}
/* ===================================
   12. Layout
====================================== */
/* Sidebar */
.sidebar-list li {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  line-height: 18px;
}
.sidebar-list li a {
  display: inline-block;
  text-align: left;
}
.sidebar-list li a:hover {
  color: var(--base-color) !important;
}
.sidebar-list li .item-qty {
  font-size: 14px;
  position: absolute;
  top: 1px;
  right: 0;
  text-align: right;
}
.sidebar-list li:last-child {
  margin-bottom: 0;
}
.latest-post-sidebar {
  list-style: none;
  padding: 0;
  margin: 0;
}
.latest-post-sidebar li {
  margin-bottom: 25px;
}
.latest-post-sidebar li figure {
  height: 65px;
  width: 80px;
  margin: 0;
}
.latest-post-sidebar li .media-body {
  line-height: normal;
  padding-left: 30px;
  position: relative;
  top: -3px;
}
.latest-post-sidebar li:last-child {
  margin-bottom: 0;
}
.popular-post-sidebar {
  list-style: none;
  padding: 0;
  margin: 0;
}
.popular-post-sidebar li {
  margin-bottom: 25px;
}
.popular-post-sidebar li figure {
  height: 100px;
  width: 100px;
  margin: 0;
}
.popular-post-sidebar li .media-body {
  line-height: normal;
  padding-left: 30px;
  position: relative;
}
.popular-post-sidebar li:last-child {
  margin-bottom: 0;
}
.category-list-sidebar {
  list-style: none;
  padding: 0;
  margin: 0;
}
.category-list-sidebar li {
  margin-bottom: 15px;
}
.category-list-sidebar li .btn {
  right: -10%;
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all 300ms cubic-bezier(0.37, 0.8, 0.63, 1);
  transition: all 300ms cubic-bezier(0.37, 0.8, 0.63, 1);
}
.category-list-sidebar li:last-child {
  margin-bottom: 0;
}
.category-list-sidebar li:hover .btn {
  right: -8%;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.post-sidebar.blog-side-image li {
  margin-bottom: 35px;
  width: 100%;
}
.post-sidebar.blog-side-image li .blog-box .blog-image {
  min-height: 1px;
}
.post-sidebar.blog-side-image li:last-child {
  margin-bottom: 0;
}
.tag-cloud a {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  margin: 0 6px 10px 0;
  padding: 7px 18px;
  border-radius: 4px;
  border: 1px solid var(--extra-medium-gray);
}
.tag-cloud a:hover, .tag-cloud a.active {
  box-shadow: 0 0 10px rgba(23, 23, 23, 0.1);
  color: var(--dark-gray);
  background-color: var(--white);
}
/* Grid */
.grid {
  list-style: none;
  padding: 0;
  margin: 0;
}
/* Grid column and gutter spacing */
.grid li img {
  width: 100%;
}
.grid .grid-sizer {
  padding: 0 !important;
  margin: 0 !important;
}
.grid.gutter-very-small li {
  padding: 3px 3px;
}
.grid.gutter-small li {
  padding: 5px 5px;
}
.grid.gutter-medium li {
  padding: 7px 7px;
}
.grid.gutter-large li {
  padding: 10px 10px;
}
.grid.gutter-extra-large li {
  padding: 15px;
}
.grid.gutter-double-extra-large li {
  padding: 20px;
}
.grid.gutter-triple-extra-large li {
  padding: 30px;
}
.grid.grid-6col li {
  width: 16.67%;
}
.grid.grid-6col li.grid-item-double {
  width: 33.33%;
}
.grid.grid-6col li.grid-item-single {
  width: 100%;
}
.grid.grid-5col li {
  width: 20%;
}
.grid.grid-5col li.grid-item-double {
  width: 40%;
}
.grid.grid-5col li.grid-item-single {
  width: 100%;
}
.grid.grid-4col li {
  width: 25%;
}
.grid.grid-4col li.grid-item-double {
  width: 50%;
}
.grid.grid-4col li.grid-item-single {
  width: 100%;
}
.grid.grid-3col li {
  width: 33.33%;
}
.grid.grid-3col li.grid-item-double {
  width: 66.67%;
}
.grid.grid-3col li.grid-item-single {
  width: 100%;
}
.grid.grid-2col li {
  width: 50%;
}
.grid.grid-2col li.grid-item-double {
  width: 100%;
}
.grid.grid-2col li.grid-item-single {
  width: 100%;
}
.grid .gutter-large {
  margin-left: 10px;
  margin-right: 10px;
  margin: 0 10px;
}
/* ===================================
    13. Elements
====================================== */
/* Accordion */
.accordion .accordion-item {
  color: inherit;
  border: none;
}
.accordion .accordion-item .accordion-header {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 0;
}
.accordion .accordion-item .accordion-header .accordion-title {
  cursor: pointer;
  padding-right: 25px;
}
.accordion .accordion-item .accordion-header .accordion-title i {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.accordion .accordion-item .accordion-collapse {
  border: 0;
}
/* Accordion style 01 */
.accordion-style-01 .accordion-item {
  margin-bottom: 20px;
  overflow: inherit;
  padding: 20px 35px;
  -webkit-border-radius: 4px !important;
  border-radius: 4px !important;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(111, 111, 111, 0.15);
  box-shadow: 0px 0px 20px 0px rgba(111, 111, 111, 0.15);
}
.accordion-style-01 .accordion-item:last-child {
  margin-bottom: 0;
}
.accordion-style-01 .accordion-item .collapse {
  overflow: hidden;
}
.accordion-style-01 .accordion-item .accordion-body {
  padding: 12px 45px 10px 0px;
}
.accordion-style-01 .accordion-item .accordion-header .accordion-title > i {
  position: static;
  -webkit-transform: none;
  transform: none;
}
/* Accordion style 02 */
.accordion-style-02 .accordion-item {
  background-color: transparent;
}
.accordion-style-02 .accordion-item .accordion-header {
  margin-bottom: 0;
  padding: 15px 0;
}
.accordion-style-02 .accordion-item .accordion-header .accordion-title i {
  right: 0;
}
.accordion-style-02 .accordion-item.active-accordion .accordion-header {
  border-bottom-color: transparent !important;
}
.accordion-style-02 .accordion-item .accordion-body {
  padding: 0 25px 30px 0;
}
.accordion-style-02 .right-icon {
  background-color: var(--white);
  color: var(--dark-gray);
}
.accordion-style-02 .right-icon:hover {
  background-color: var(--base-color);
  color: var(--white);
}
/* Accordion style 03 */
.accordion-style-03 .accordion-item {
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color: transparent;
}
.accordion-style-03 .accordion-item .accordion-header {
  padding: 15px 30px;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.accordion-style-03 .accordion-item .accordion-body {
  padding: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.15) !important;
}
/* Accordion style 04 */
.accordion-style-04 .accordion-item {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color: transparent;
}
.accordion-style-04 .accordion-item .accordion-header {
  padding: 18px 35px;
}
.accordion-style-04 .accordion-item .accordion-body {
  padding: 25px 40px 30px;
}
.accordion-style-04 .accordion-item.active-accordion {
  -webkit-box-shadow: 0px 5px 25px 0px rgba(111, 111, 111, 0.15);
  box-shadow: 0px 5px 25px 0px rgba(111, 111, 111, 0.15);
  background: var(--white);
}
/* Accordion style 05 */
.accordion-style-05 .accordion-item {
  margin-bottom: 20px;
  overflow: hidden;
  padding: 22px 35px 22px 65px;
  position: relative;
  -webkit-border-radius: 4px !important;
  border-radius: 4px !important;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-box-shadow: 0px 0px 45px 0px rgba(111, 111, 111, 0.15);
  box-shadow: 0px 0px 45px 0px rgba(111, 111, 111, 0.15);
}
.accordion-style-05 .accordion-item:last-child {
  margin-bottom: 0;
}
.accordion-style-05 .accordion-item .collapse {
  overflow: hidden;
}
.accordion-style-05 .accordion-item .accordion-body {
  padding: 10px 45px 10px 0px;
}
.accordion-style-05 .accordion-item .number {
  position: absolute;
  left: -13px;
  top: 15px;
}
.accordion-style-05 .accordion-item.active-accordion {
  -webkit-box-shadow: 0px 15px 40px 5px rgba(111, 111, 111, 0.15);
  box-shadow: 0px 15px 40px 5px rgba(111, 111, 111, 0.15);
}
.accordion-style-05 .accordion-item.active-accordion .number {
  -webkit-text-fill-color: var(--base-color);
  -webkit-text-stroke-color: var(--base-color);
}
/* Accordion style 06 */
.accordion-style-06 .accordion-item {
  margin-bottom: 0;
  overflow: hidden;
  padding: 15px 100px 15px 45px;
  position: relative;
  -webkit-border-radius: 4px !important;
  border-radius: 4px !important;
}
.accordion-style-06 .accordion-item:last-child {
  margin-bottom: 0;
}
.accordion-style-06 .accordion-item .collapse {
  overflow: hidden;
  border-radius: 0;
}
.accordion-style-06 .accordion-item .accordion-body {
  padding: 10px 0 10px 0px;
}
.accordion-style-06 .accordion-item i {
  position: absolute;
  right: -32px;
  top: 50%;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: var(--dark-gray);
  -webkit-transform: rotate(-35deg) translateY(-50%);
  transform: rotate(-35deg) translateY(-50%);
}
.accordion-style-06 .accordion-item .collapsing i {
  opacity: 0;
}
.accordion-style-06 .accordion-item.active-accordion {
  padding-top: 30px;
  padding-bottom: 30px;
  background-image: linear-gradient(to bottom, #f2f8f7, #f5fafa, #f9fbfd, #fcfdfe, #ffffff);
}
.accordion-style-06 .accordion-item.active-accordion i {
  opacity: 0.15;
}
/* Alert box */
.alert-success {
  background-color: #f1fbec;
  color: #54b783;
  border-color: #cce8b3;
  padding: 10px 20px 10px 20px;
}
.alert {
  padding: 20px 25px 20px 30px;
  margin-bottom: 30px;
  line-height: 22px;
  position: relative;
  z-index: 1;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.alert.alert-success {
  background-color: #f1fbec;
  color: #54b783;
  border-color: #cce8b3;
}
.alert.alert-info {
  background-color: #ebf5f9;
  color: #4278bb;
  border-color: #bce8f1;
}
.alert.alert-warning {
  background-color: #fcf8e3;
  color: #e2ad4b;
  border-color: #faebcc;
}
.alert.alert-danger {
  background-color: #fbf1f1;
  color: #e65656;
  border-color: #efe1e3;
}
.alert strong {
  font-weight: 500;
}
@keyframes hvr-icon-push {
  50% {
    -webkit-trasfrom: scale(0.5);
    trasfrom: scale(0.5);
  }
}
.alert .close {
  opacity: 1;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.34);
  text-shadow: none;
  float: right;
}
.alert .close i {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.alert .close:hover i {
  -webkit-animation-name: hvr-icon-push;
  animation-name: hvr-icon-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.alert .close:not(:disabled), .alert .close:not(.disabled):focus, .alert .close:not(.disabled):hover {
  opacity: 1;
}
/* Alert box style 02 */
.alert-box-style-02 .alert {
  padding: 22px 60px 22px 30px;
  border: none;
  line-height: 22px;
  -webkit-box-shadow: 0px 0 10px 0 rgba(111, 111, 111, 0.1);
  box-shadow: 0px 0 10px 0 rgba(111, 111, 111, 0.1);
}
.alert-box-style-02 .alert.alert-success {
  color: #54b783;
}
.alert-box-style-02 .alert.alert-info {
  color: #4278bb;
}
.alert-box-style-02 .alert.alert-warning {
  color: #e2ad4b;
}
.alert-box-style-02 .alert.alert-danger {
  color: #e65656;
}
.alert-box-style-02 .alert .close {
  height: 100%;
  position: absolute;
  right: 0;
  width: 60px;
  text-align: center;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid var(--light-medium-gray);
  font-size: 18px;
}
/* Alert box style 03 */
.alert-box-style-03 .alert {
  padding: 20px 30px 20px 55px;
  border: 0;
}
.alert-box-style-03 .alert:before {
  font-family: bootstrap-icons;
  display: inline-block;
  position: absolute;
  left: 25px;
  font-size: 18px;
}
.alert-box-style-03 .alert.alert-success {
  background-color: #54b783;
}
.alert-box-style-03 .alert.alert-success:before {
  content: "\f26b";
}
.alert-box-style-03 .alert.alert-info {
  background-color: #4278bb;
}
.alert-box-style-03 .alert.alert-info:before {
  content: "\f431";
}
.alert-box-style-03 .alert.alert-warning {
  background-color: #e2ad4b;
}
.alert-box-style-03 .alert.alert-warning:before {
  content: "\f567";
}
.alert-box-style-03 .alert.alert-danger {
  background-color: #e65656;
}
.alert-box-style-03 .alert.alert-danger:before {
  content: "\f623";
}
.alert-box-style-03 .alert .close {
  right: -10px;
  position: relative;
}
/* Alert box style 04 */
.alert-box-style-04 .alert {
  padding: 20px 25px 20px 30px;
  border-radius: 0;
  border: none;
  border-left: 3px solid;
}
.alert-box-style-04 .alert:hover {
  -webkit-box-shadow: 0px 0 10px 0 rgba(111, 111, 111, 0.1);
  box-shadow: 0px 0 10px 0 rgba(111, 111, 111, 0.1);
}
.alert-box-style-04 .alert.alert-success {
  color: #54b783;
  border-color: #54b783;
}
.alert-box-style-04 .alert.alert-info {
  color: #4278bb;
  border-color: #4278bb;
}
.alert-box-style-04 .alert.alert-warning {
  color: #e2ad4b;
  border-color: #e2ad4b;
}
.alert-box-style-04 .alert.alert-danger {
  color: #e65656;
  border-color: #e65656;
}
/* Alert box style 05 */
.alert-box-style-05 .alert {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  border: none;
  border-bottom: 2px solid;
  color: var(--dark-gray);
  padding: 10px 0;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.alert-box-style-05 .alert.alert-success {
  border-color: #54b783;
}
.alert-box-style-05 .alert.alert-info {
  border-color: #4278bb;
}
.alert-box-style-05 .alert.alert-warning {
  border-color: #e2ad4b;
}
.alert-box-style-05 .alert.alert-danger {
  border-color: #e65656;
}
/* Blockquote */
blockquote footer {
  padding: 0;
}
/* Blockquote style 05 */
.blockquote-style-04 .blockquote-img img {
  margin-top: -114px;
}
.blockquote-style-04 .blockquote-content {
  padding: 5% 0 5% 26%;
  margin-left: -8vw;
  -webkit-border-radius: 50px 50px 125px 50px;
  border-radius: 50px 50px 125px 50px;
}
/* Use for mac desktop pc */
@media only screen and (min-width: 1921px) {
  .blockquote-style-04 .blockquote-content {
    margin-left: -6vw;
  }
}
/* Button */
.btn-box-shadow {
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
}
.btn-box-shadow:hover {
  -webkit-box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}
.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
  border-color: initial;
  color: initial;
}
.btn {
  display: inline-block;
  border: 2px solid transparent;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  width: auto;
  font-family: var(--alt-font);
  font-weight: 500;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn:hover {
  background-color: inherit;
}
.btn i {
  position: relative;
  margin-left: 6px;
}
.btn.btn-extra-large {
  font-size: 16px;
  padding: 24px 46px;
}
.btn.btn-large {
  font-size: 14px;
  padding: 18px 34px;
}
.btn.btn-medium {
  font-size: 13px;
  padding: 16px 30px;
}
.btn.btn-small {
  font-size: 12px;
  padding: 13px 26px;
}
.btn.btn-very-small {
  font-size: 11px;
  padding: 10px 20px;
}
.btn.btn-rounded.btn-extra-large {
  padding: 18px 46px;
}
.btn.btn-rounded.btn-large {
  padding: 14px 36px;
}
.btn.btn-rounded.btn-medium {
  padding: 12px 32px;
}
.btn.btn-rounded.btn-small {
  padding: 10px 26px;
}
.btn.btn-rounded.btn-very-small {
  padding: 8px 20px;
}
.btn.btn-rounded.btn-rounded {
  -webkit-border-radius: 50px;
  border-radius: 50px;
}
.btn.btn-round-edge {
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.btn.btn-round-edge-small {
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.btn.btn-link {
  padding: 0 0 2px;
  position: relative;
  text-decoration: none;
  border: 0;
  border-bottom: 2px solid;
  letter-spacing: 0;
  background-color: transparent;
}
.btn.btn-link.thin {
  border-bottom: 1px solid;
}
.btn.btn-link:hover {
  opacity: 0.7;
}
.btn.btn-link:focus-visible {
  box-shadow: none;
}
.btn.btn-link-gradient {
  padding: 0 0 3px !important;
  position: relative;
  border: 0;
  background-color: transparent;
}
.btn.btn-link-gradient > span {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
}
.btn.btn-link-gradient.thin span {
  height: 1px;
}
.btn.btn-link-gradient.expand span {
  width: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.btn-link-gradient:hover {
  opacity: 0.7;
}
.btn.btn-link-gradient:hover.expand:hover span {
  width: 100%;
}
.btn.btn-expand {
  position: relative;
  z-index: 1;
}
.btn.btn-expand span {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.btn.btn-expand:hover, .btn.btn-expand:active {
  border-color: transparent;
}
.btn.btn-expand:hover span, .btn.btn-expand:active span {
  width: 100%;
}
.btn.btn-expand-ltr {
  position: relative;
  z-index: 1;
  background-color: transparent;
  border: 0;
  color: initial;
}
.btn.btn-expand-ltr:active {
  background-color: transparent;
  border: 0;
  color: initial;
}
.btn.btn-expand-ltr span {
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: -1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn.btn-expand-ltr:hover span {
  width: 100% !important;
}
.btn.btn-expand-ltr.btn-extra-large {
  padding: 18px 28px;
}
.btn.btn-expand-ltr.btn-extra-large span {
  width: 60px;
  height: 60px;
}
.btn.btn-expand-ltr.btn-large {
  padding: 14px 26px;
}
.btn.btn-expand-ltr.btn-large span {
  width: 50px;
  height: 50px;
}
.btn.btn-expand-ltr.btn-medium {
  padding: 12px 24px;
}
.btn.btn-expand-ltr.btn-medium span {
  width: 44px;
  height: 44px;
}
.btn.btn-expand-ltr.btn-small {
  padding: 10px 22px;
}
.btn.btn-expand-ltr.btn-small span {
  width: 40px;
  height: 40px;
}
.btn.btn-expand-ltr.btn-very-small {
  padding: 8px 18px;
}
.btn.btn-expand-ltr.btn-very-small span {
  width: 38px;
  height: 38px;
}
.btn.btn-hover-animation-switch.btn-link {
  border-bottom: 0;
  padding-bottom: 0;
}
.btn.btn-hover-animation-switch.btn-link:hover {
  opacity: 1;
}
.btn.btn-hover-animation-switch > span {
  display: inline-flex;
  align-items: center;
}
.btn.btn-hover-animation-switch .btn-text {
  margin-left: -13px;
  position: relative;
  z-index: 3;
  transition: transform 0.6s 0.125s cubic-bezier(0.1, 0.75, 0.25, 1);
}
.btn.btn-hover-animation-switch .btn-icon {
  margin-left: 6px;
  position: relative;
  transition: opacity 0.4s 0.25s, transform 0.6s 0.25s;
  transition-timing-function: cubic-bezier(0.1, 0.75, 0.25, 1);
  line-height: 0;
  z-index: 3;
}
.btn.btn-hover-animation-switch .btn-icon i {
  margin: 0;
  line-height: 0;
}
.btn.btn-hover-animation-switch .btn-icon + .btn-icon {
  order: -2;
  opacity: 0;
  transition-delay: 0s;
  margin-left: 0;
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}
.btn.btn-hover-animation-switch:hover .btn-text {
  transition-delay: 0.1s;
  transform: translateX(23px);
}
.btn.btn-hover-animation-switch:hover .btn-icon {
  transition-delay: 0s;
  transform: translateX(10px);
  opacity: 0;
}
.btn.btn-hover-animation-switch:hover .btn-icon + .btn-icon {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 0.225s;
}
.btn.btn-hover-animation-switch.btn-icon-left .btn-text {
  margin-right: -15px;
  margin-left: 0px;
  position: relative;
  z-index: 3;
}
.btn.btn-hover-animation-switch.btn-icon-left .btn-icon {
  opacity: 0;
  margin-left: 0;
  transition-delay: 0s;
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}
.btn.btn-hover-animation-switch.btn-icon-left .btn-icon + .btn-icon {
  opacity: 1;
  margin-left: 0;
  margin-right: 6px;
  transition: opacity 0.4s 0.25s, transform 0.6s 0.25s;
  transition-timing-function: cubic-bezier(0.1, 0.75, 0.25, 1);
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}
.btn.btn-hover-animation-switch.btn-icon-left:hover .btn-text {
  transition-delay: 0.1s;
  transform: translateX(-20px);
}
.btn.btn-hover-animation-switch.btn-icon-left:hover .btn-icon {
  transform: translateX(0px);
  opacity: 1;
  transition-delay: 0.225s;
}
.btn.btn-hover-animation-switch.btn-icon-left:hover .btn-icon + .btn-icon {
  opacity: 0;
  transition-delay: 0s;
  transform: translateX(-10px);
}
.btn.btn-hover-animation.btn-link {
  border-bottom: 0;
  padding-bottom: 0;
}
.btn.btn-hover-animation.btn-link:hover {
  opacity: 1;
}
.btn.btn-hover-animation > span {
  display: inline-flex;
  align-items: center;
}
.btn.btn-hover-animation .btn-text {
  margin-left: 0px;
  position: relative;
  z-index: 3;
  transition: transform 0.5s;
}
.btn.btn-hover-animation .btn-icon {
  display: inline-flex;
  align-items: center;
  margin-left: -24px;
}
.btn.btn-hover-animation .btn-icon i {
  display: inline-block;
  opacity: 0;
  top: 0;
  transform: translateX(0.24px);
  transition: opacity 0.25s, transform 0.5s;
}
.btn.btn-hover-animation:hover .btn-text {
  -webkit-transform: translateX(-12px);
  transform: translateX(-12px);
}
.btn.btn-hover-animation:hover .btn-icon i {
  opacity: 1;
  -webkit-transform: translateX(12px);
  transform: translateX(12px);
}
.btn.btn-hover-animation:active {
  border-color: transparent !important;
}
.btn.btn-hover-animation.btn-icon-left .btn-icon {
  margin-right: -24px;
  margin-left: 0;
}
.btn.btn-hover-animation.btn-icon-left .btn-icon i {
  transform: translateX(0.24px);
}
.btn.btn-hover-animation.btn-icon-left .btn-text {
  margin-right: 0;
  margin-left: 0;
}
.btn.btn-hover-animation.btn-icon-left:hover .btn-text {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}
.btn.btn-hover-animation.btn-icon-left:hover .btn-icon i {
  opacity: 1;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}
.btn.btn-slide-up span {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.btn.btn-slide-down span {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.btn.btn-slide-left span {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.btn.btn-slide-right span {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.btn.btn-slide-up, .btn.btn-slide-down, .btn.btn-slide-left, .btn.btn-slide-right {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.btn.btn-slide-up span, .btn.btn-slide-down span, .btn.btn-slide-left span, .btn.btn-slide-right span {
  position: absolute;
  height: 100%;
  width: 102%;
  z-index: -1;
  left: -2px;
  top: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.btn.btn-slide-up:hover span, .btn.btn-slide-down:hover span, .btn.btn-slide-left:hover span, .btn.btn-slide-right:hover span {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.btn.btn-radius {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.btn.btn-radius:hover {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.btn.arrow-bounce-right {
  position: relative;
}
.btn.arrow-bounce-right:before {
  content: "\f108";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: inherit;
  left: 80%;
  transition: all 0.3s;
  opacity: 0;
  font-weight: 900;
  margin-top: 3px;
}
.btn.arrow-bounce-right:hover:before {
  left: 85%;
  opacity: 1;
}
.btn.underline-on-hover {
  position: relative;
  padding: 0 0 2px;
  border: none;
  opacity: 1 !important;
}
.btn.underline-on-hover:after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-bottom: 2px solid;
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(7px);
  transform: translateY(7px);
}
.btn.underline-on-hover:hover:after {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.btn.underline-on-hover.btn-medium, .btn.underline-on-hover.btn-small, .btn.underline-on-hover.btn-very-small {
  padding: 0 0 3px;
}
.btn.hover-text-dark {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin: 0;
  padding: 0;
  position: relative;
  border-bottom: none;
  opacity: 0.4;
  display: inline-block;
}
.btn.hover-text-dark:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  content: attr(data-hover);
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;
}
.btn.hover-text-dark:hover {
  opacity: 1;
}
.btn.hover-text-dark:hover:after {
  max-height: 100%;
}
.btn.hover-text-light {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin: 0;
  padding: 0;
  position: relative;
  border-bottom: none;
  opacity: 1;
  display: inline-block;
}
.btn.hover-text-light:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  content: attr(data-hover);
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;
}
.btn.hover-text-light:hover {
  opacity: 0.6;
}
.btn.hover-text-light:hover:after {
  max-height: 100%;
}
.btn.btn-base-color {
  background-color: var(--base-color);
  color: var(--white);
}
.btn.btn-base-color:hover, .btn.btn-base-color:active {
  background-color: transparent;
  border-color: var(--base-color);
  color: var(--base-color);
}
.btn.btn-dark-gray {
  background-color: var(--dark-gray);
  color: var(--white);
}
.btn.btn-dark-gray:hover, .btn.btn-dark-gray:active {
  background-color: transparent;
  border-color: var(--dark-gray);
  color: var(--dark-gray);
}
.btn.btn-black {
  background-color: var(--black);
  color: var(--white);
}
.btn.btn-black:hover, .btn.btn-black:active {
  background-color: transparent;
  border-color: var(--black);
  color: var(--black);
}
.btn.btn-white {
  background-color: var(--white);
  color: var(--dark-gray);
}
.btn.btn-white:hover, .btn.btn-white:active {
  background-color: transparent;
  border-color: var(--white);
  color: var(--white);
}
.btn.btn-cornflower-blue {
  background-color: var(--cornflower-blue);
  color: var(--white);
}
.btn.btn-cornflower-blue:hover, .btn.btn-cornflower-blue:active {
  background-color: transparent;
  border-color: var(--cornflower-blue);
  color: var(--cornflower-blue);
}
.btn.btn-jade {
  background-color: var(--jade);
  color: var(--white);
}
.btn.btn-jade:hover, .btn.btn-jade:active {
  background-color: transparent;
  border-color: var(--jade);
  color: var(--jade);
}
.btn.btn-transparent-black {
  background-color: transparent;
  border-color: var(--black);
  color: var(--black);
}
.btn.btn-transparent-black:hover, .btn.btn-transparent-black:active {
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}
.btn.btn-transparent-white {
  background-color: transparent;
  border-color: var(--white);
  color: var(--white);
}
.btn.btn-transparent-white:hover, .btn.btn-transparent-white:active {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--dark-gray);
}
.btn.btn-transparent-white-light {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.3);
  color: var(--white);
}
.btn.btn-transparent-white-light:hover, .btn.btn-transparent-white-light:active {
  background-color: var(--white);
  border-color: rgba(255, 255, 255, 0.3);
  color: var(--dark-gray);
}
.btn.btn-transparent-base-color {
  background-color: transparent;
  border-color: var(--base-color);
  color: var(--base-color);
}
.btn.btn-transparent-base-color:hover, .btn.btn-transparent-base-color:active {
  background-color: var(--base-color);
  border-color: var(--base-color);
  color: var(--white);
}
.btn.btn-transparent-dark-gray {
  background-color: transparent;
  border-color: var(--dark-gray);
  color: var(--dark-gray);
}
.btn.btn-transparent-dark-gray:hover, .btn.btn-transparent-dark-gray:active {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
  color: var(--white);
}
.btn.btn-transparent-light-gray {
  background-color: transparent;
  border-color: var(--extra-medium-gray);
  color: var(--dark-gray);
}
.btn.btn-transparent-light-gray:hover, .btn.btn-transparent-light-gray:active {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
  color: var(--white);
}
.btn.btn-double-border.btn-extra-large {
  padding: 0;
}
.btn.btn-double-border.btn-extra-large > span {
  padding: 24px 50px;
}
.btn.btn-double-border.btn-large {
  padding: 0;
}
.btn.btn-double-border.btn-large > span {
  padding: 18px 42px;
}
.btn.btn-double-border.btn-medium {
  padding: 0;
}
.btn.btn-double-border.btn-medium > span {
  padding: 16px 34px;
}
.btn.btn-double-border.btn-small {
  padding: 0;
}
.btn.btn-double-border.btn-small > span {
  padding: 13px 32px;
}
.btn.btn-double-border.btn-very-small {
  padding: 0;
}
.btn.btn-double-border.btn-very-small > span {
  padding: 10px 22px;
}
.btn.btn-double-border > span {
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.btn.btn-double-border .btn-double-text {
  transition: opacity 0.65s, transform 0.65s;
  transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
}
.btn.btn-double-border .btn-double-text:before {
  content: attr(data-text);
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  top: 50%;
  opacity: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  transition: opacity 0.5s, transform 0.8s;
  transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
}
.btn.btn-double-border:hover .btn-double-text {
  transform: translateY(-200%);
}
.btn.btn-double-border:hover .btn-double-text:before {
  transform: translate(-50%, 150%);
  opacity: 1;
}
.btn.btn-double-border:after {
  display: inline-block;
  content: "";
  position: absolute;
  border: 1px solid;
  width: calc(100% - 8px);
  height: calc(100% + 12px);
  top: -6px;
  left: 4px;
  transform: translateX(0) translateY(0);
  z-index: 0;
}
.btn.btn-double-border.btn-border-base-color {
  position: relative;
  border: 1px solid var(--base-color);
  color: var(--base-color);
}
.btn.btn-double-border.btn-border-base-color:hover {
  border-color: var(--dark-gray);
  color: var(--dark-gray);
}
.btn.btn-double-border.btn-border-base-color:hover:after {
  border-color: var(--dark-gray);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.btn-double-border.btn-border-base-color.hover-border-color-white:hover {
  border-color: var(--white);
  color: var(--white);
}
.btn.btn-double-border.btn-border-base-color.hover-border-color-white:hover:after {
  border-color: var(--white);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.btn-double-border.btn-border-color-dark-gray {
  position: relative;
  border: 1px solid var(--dark-gray);
  color: var(--dark-gray);
}
.btn.btn-double-border.btn-border-color-dark-gray:hover {
  border-color: var(--base-color);
  color: var(--base-color);
}
.btn.btn-double-border.btn-border-color-dark-gray:hover:after {
  border-color: var(--base-color);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.btn-double-border.btn-border-color-white {
  position: relative;
  border: 1px solid var(--white);
  color: var(--white);
}
.btn.btn-double-border.btn-border-color-white:hover {
  border-color: var(--base-color);
  color: var(--base-color);
}
.btn.btn-double-border.btn-border-color-white:hover:after {
  border-color: var(--base-color);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.btn-double-border.btn-border-color-transparent-white {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--white);
}
.btn.btn-double-border.btn-border-color-transparent-white:after {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.btn.btn-double-border.btn-border-color-transparent-white:hover {
  border-color: rgb(255, 255, 255);
  color: var(--white);
}
.btn.btn-double-border.btn-border-color-transparent-white:hover:after {
  border-color: rgb(255, 255, 255);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.btn-double-border.btn-border-color-transparent-dark {
  position: relative;
  border: 1px solid rgba(35, 35, 35, 0.2);
  color: var(--dark-gray);
}
.btn.btn-double-border.btn-border-color-transparent-dark:after {
  border: 1px solid rgba(35, 35, 35, 0.2);
}
.btn.btn-double-border.btn-border-color-transparent-dark:hover {
  border-color: rgb(35, 35, 35);
  color: var(--dark-gray);
}
.btn.btn-double-border.btn-border-color-transparent-dark:hover:after {
  border-color: rgb(35, 35, 35);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.btn-switch-text.btn-extra-large {
  padding: 0;
}
.btn.btn-switch-text.btn-extra-large > span {
  padding: 22px 40px;
}
.btn.btn-switch-text.btn-large {
  padding: 0;
}
.btn.btn-switch-text.btn-large > span {
  padding: 18px 34px;
}
.btn.btn-switch-text.btn-medium {
  padding: 0;
}
.btn.btn-switch-text.btn-medium > span {
  padding: 16px 30px;
}
.btn.btn-switch-text.btn-small {
  padding: 0;
}
.btn.btn-switch-text.btn-small > span {
  padding: 13px 24px;
}
.btn.btn-switch-text.btn-very-small {
  padding: 0;
}
.btn.btn-switch-text.btn-very-small > span {
  padding: 10px 20px;
}
.btn.btn-switch-text > span {
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.btn.btn-switch-text .btn-double-text {
  transition: opacity 0.65s, transform 0.85s;
  transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
}
.btn.btn-switch-text .btn-double-text:before {
  content: attr(data-text);
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  top: 50%;
  opacity: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  transition: opacity 0.5s, transform 0.8s;
  transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
}
.btn.btn-switch-text:hover .btn-double-text, .btn.btn-switch-text:active .btn-double-text {
  transform: translateY(-200%);
}
.btn.btn-switch-text:hover .btn-double-text:before, .btn.btn-switch-text:active .btn-double-text:before {
  transform: translate(-50%, 150%);
  opacity: 1;
}
.btn.btn-switch-text.purchase-envato.btn-small {
  padding: 0;
}
.btn.btn-switch-text.purchase-envato.btn-small > span {
  padding: 11px 30px;
}
.btn.base-color-hover:hover, .btn.base-color-hover:active {
  background-color: var(--base-color);
  color: var(--white);
}
.btn.white-hover:hover, .btn.white-hover:active {
  background-color: var(--white);
  color: var(--dark-gray);
}
.btn.black-hover:hover, .btn.black-hover:active {
  background-color: var(--dark-gray);
  color: var(--white);
}
.btn.btn-box-shadow.btn-white:hover, .btn.btn-box-shadow.btn-white:active {
  background: var(--white);
  color: var(--dark-gray);
}
.btn.btn-box-shadow.btn-black:hover, .btn.btn-box-shadow.btn-black:active {
  background: var(--black);
  color: var(--white);
}
.btn.btn-box-shadow.btn-dark-gray:hover, .btn.btn-box-shadow.btn-dark-gray:active {
  background: var(--dark-gray);
  color: var(--white);
}
.btn.btn-box-shadow.btn-base-color:hover, .btn.btn-box-shadow.btn-base-color:active {
  background: var(--base-color);
  color: var(--white);
}
.btn.btn-box-shadow.btn-cornflower-blue:hover, .btn.btn-box-shadow.btn-cornflower-blue:active {
  background-color: var(--cornflower-blue);
  color: var(--white);
}
.btn.btn-box-shadow.btn-jade:hover, .btn.btn-box-shadow.btn-jade:active {
  background-color: var(--jade);
  color: var(--white);
}
.btn.with-rounded {
  position: relative;
}
.btn.with-rounded > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  position: absolute;
  border-radius: 100%;
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);
}
.btn.with-rounded > span i {
  margin: 0;
}
.btn.with-rounded.btn-extra-large {
  padding-right: 70px;
}
.btn.with-rounded.btn-large {
  padding-right: 55px;
}
.btn.with-rounded.btn-large > span {
  width: 34px;
  height: 34px;
  right: 8px;
}
.btn.with-rounded.btn-medium {
  padding-right: 50px;
}
.btn.with-rounded.btn-medium > span {
  width: 32px;
  height: 32px;
  right: 8px;
}
.btn.with-rounded.btn-small {
  padding-right: 45px;
}
.btn.with-rounded.btn-small > span {
  width: 28px;
  height: 28px;
  right: 6px;
}
.btn.with-rounded.btn-very-small {
  padding-right: 37px;
}
.btn.with-rounded.btn-very-small > span {
  width: 26px;
  height: 26px;
  right: 5px;
}
/* Button icon position */
.left-icon i {
  margin-right: 6px;
  margin-left: 0;
}
[class*=btn-transparent], [class*=" btn-transparent"] {
  font-weight: 600;
}
/* Gradient button */
[class*=btn-gradient-] {
  background-color: transparent;
  border-color: transparent;
}
[class*=btn-gradient-]:active {
  background-color: transparent;
  border-color: transparent !important;
  color: var(--white) !important;
}
[class*=btn-gradient-]:hover {
  background-position: right center;
  color: var(--white);
}
.btn-gradient-purple-pink {
  background-image: linear-gradient(to right, #8b14b1, #d53d6e, #8b14b1);
  background-size: 200% auto;
  color: var(--white);
}
.btn-gradient-fast-pink-light-yellow {
  background-image: linear-gradient(to right, #f7567f, #ffba83, #f7567f);
  background-size: 200% auto;
  color: var(--white);
}
.btn-gradient-pink-orange {
  background-image: linear-gradient(to right, #de347f, #ef5f43, #de347f);
  background-size: 200% auto;
  color: var(--white);
}
.btn-gradient-fast-blue-purple {
  background-image: linear-gradient(to right, #2a46f4, #bf25ff, #2a46f4);
  background-size: 200% auto;
  color: var(--white);
}
.btn-gradient-light-pink-light-purple {
  background-image: linear-gradient(to right, #ed579b, #bc63e8, #ed579b);
  background-size: 200% auto;
  color: var(--white);
}
.btn-gradient-sky-blue-pink {
  background-image: linear-gradient(to right, #5758df, #f77991, #5758df);
  background-size: 200% auto;
  color: var(--white);
}
.btn-dual .btn {
  margin-left: 10px;
  margin-right: 10px;
}
.btn-dual .btn.btn-link, .btn-dual .btn.btn-underline, .btn-dual .btn.btn-zoom, .btn-dual .btn.btn-link-gradient {
  margin-left: 30px;
  margin-right: 30px;
}
/* Blog button */
.categories-btn {
  padding: 4px 12px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-left: 5px;
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
}
/* Clients style 01 */
.clients-style-01 .client-image:hover > img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
/* Clients style 02 */
.clients-style-02 .swiper-slide {
  -webkit-transition: all 350ms ease-in-out;
  transition: all 350ms ease-in-out;
}
.clients-style-02 .swiper-slide:hover {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.clients-style-02 .swiper-button-prev {
  left: -110px;
}
.clients-style-02 .swiper-button-next {
  right: -110px;
}
/* Clients style 03 */
.clients-style-03 .client-box {
  -webkit-transition: all 350ms cubic-bezier(0.12, 0, 0.39, 0);
  transition: all 350ms cubic-bezier(0.12, 0, 0.39, 0);
  will-change: transform;
}
.clients-style-03 .client-box:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* Clients style 04 */
.clients-style-04 .client-box:hover {
  opacity: 0.5;
}
/* Clients style 05 */
.clients-style-05 .client-box {
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
}
.clients-style-05 .client-box img {
  z-index: 2;
  position: relative;
}
.clients-style-05 .client-box:hover {
  opacity: 0.5;
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
/* Clients style 06 */
.clients-style-06 .client-box {
  -webkit-transition: all 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: all 0.3s cubic-bezier(0.12, 0, 0.39, 0);
}
.clients-style-06 .client-box img {
  max-width: inherit;
}
.clients-style-06:hover .client-box {
  opacity: 0.4;
}
.clients-style-06:hover .client-box:hover {
  opacity: 1;
}
/* Clients style 07 */
.clients-style-07 .client-box .client-info {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.clients-style-07 .client-box .client-info .client-content {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  overflow: hidden;
}
.clients-style-07 .client-box .client-info .client-content .client-link {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  right: 0;
}
.clients-style-07 .client-box .client-info:hover .client-content {
  margin: 0;
  width: 100% !important;
  height: 100% !important;
  border-radius: 0;
}
.clients-style-07 .client-box .client-info:hover .client-content .client-icon {
  opacity: 0;
  visibility: hidden;
}
.clients-style-07 .client-box .client-info:hover .client-content .client-link {
  opacity: 1;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.clients-style-07 .client-box .client-info:hover .client-content .box-overlay {
  opacity: 1;
  visibility: visible;
}
/* Clients style 08 */
.clients-style-08 .client-box {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0.5;
}
.clients-style-08 .client-box:hover {
  opacity: 1;
}
/* Columns */
.show-grid [class^=col-] {
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
/* Contact form */
.select-style select {
  background-image: url("../images/contact-form-down-arrow.jpg");
  background-repeat: no-repeat;
  background-position: top 25px right 30px;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
}
.terms-condition-box label {
  display: block;
}
.terms-condition-box input[type=checkbox] {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 4px;
  left: 0;
}
.terms-condition-box input[type=checkbox]:checked + .box:after {
  display: block;
}
.terms-condition-box input[type=checkbox].is-invalid + .box:before {
  border-color: #dc3545;
}
.terms-condition-box .box {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
}
.terms-condition-box .box:before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 1px solid var(--extra-medium-gray);
  position: absolute;
  top: 5px;
  left: 0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.terms-condition-box .box:after {
  position: absolute;
  left: 7px;
  top: 9px;
  content: "";
  width: 6px;
  height: 10px;
  display: none;
  border: solid var(--dark-gray);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Contact form style 01 */
.contact-form-style-01 input, .contact-form-style-01 textarea {
  padding-left: 55px;
}
.contact-form-style-01 .form-group .form-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 2px;
  left: 25px;
}
.contact-form-style-01 .form-group.form-textarea .form-icon {
  top: 12px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
/* Contact form style 03 */
.contact-form-style-03 input, .contact-form-style-03 textarea {
  outline: none;
  border: none;
  border-bottom: 1px solid;
  background: transparent;
}
.contact-form-style-03 select {
  outline: none;
  border: none;
  border-bottom: 1px solid;
}
.contact-form-style-03 input:focus, .contact-form-style-03 select:focus, .contact-form-style-03 textarea:focus, .contact-form-style-03 .form-control:focus {
  border: 0;
  border-bottom: 1px solid #c2c2c2;
}
.contact-form-style-03 textarea {
  resize: none;
}
.contact-form-style-03 .form-group .form-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.contact-form-style-03 .form-group.form-textarea .form-icon {
  top: 10px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.contact-form-style-03 .form-control, .contact-form-style-03 .form-control.is-invalid, .contact-form-style-03 .was-validated .form-control:invalid {
  background-position: right 35px center !important;
}
/* Contact form style 06 */
.contact-form-style-06 input, .contact-form-style-06 textarea {
  color: var(--white);
}
.contact-form-style-06 input:focus, .contact-form-style-06 textarea:focus {
  color: var(--white);
}
.contact-form-style-06 input::placeholder, .contact-form-style-06 textarea::placeholder {
  color: var(--light-medium-gray);
}
.contact-form-style-06 .select-style select {
  color: var(--light-medium-gray);
  background-image: url("../images/contact-form-arrow-white.png");
  background-position: top 25px right 20px;
  padding-right: 45px;
}
.contact-form-style-06 .select-style select option {
  color: var(--dark-gray);
}
/* Contact form style 07 */
.contact-form-style-07 input, .contact-form-style-07 textarea {
  outline: none;
  border: none;
  border-bottom: 1px solid;
  background: transparent;
}
.contact-form-style-07 select {
  outline: none;
  border: none;
  border-bottom: 1px solid;
}
.contact-form-style-07 input:focus, .contact-form-style-07 select:focus, .contact-form-style-07 textarea:focus, .contact-form-style-07 .form-control:focus {
  border: 0;
  border-bottom: 1px solid #c2c2c2;
}
.contact-form-style-07 textarea {
  resize: none;
}
.contact-form-style-07 .form-group .form-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.contact-form-style-07 .form-group.form-textarea .form-icon {
  top: 10px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.contact-form-style-07 .form-control, .contact-form-style-07 .form-control.is-invalid, .contact-form-style-07 .was-validated .form-control:invalid {
  background-position: right 35px center !important;
}
.swiper-button-next, .swiper-button-prev {
  color: var(--dark-gray);
}
.swiper-button-next:after, .swiper-button-prev:after {
  display: none;
  content: "";
}
/* Text slider 02 */
.text-slider-style-02 .swiper-slide {
  opacity: 0.2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.text-slider-style-02 .swiper-slide.swiper-slide-active {
  opacity: 1;
}
/* Text slider 03 */
.text-slider-style-03 .swiper-slide > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
/* Text slider 05 */
.text-slider-style-05 .swiper-slide {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.text-slider-style-05 .swiper-slide .services-box-style-01:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.text-slider-style-05 .swiper-slide .services-box-style-01:hover .icon-box {
  opacity: 0;
}
.text-slider-style-05 .swiper-slide.swiper-slide-next {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}
.text-slider-style-05 .swiper-slide.swiper-slide-next .services-box-style-01 .icon-box {
  opacity: 1;
}
/* Countdown style 01 */
.countdown-style-01 .counter-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.countdown-style-01 .counter-container .countdown-box {
  font-family: var(--alt-font);
  color: var(--dark-gray);
  background-color: var(--white);
  width: 130px;
  min-height: 130px;
  padding: 25px 20px 20px;
  margin: 0 15px;
  text-align: center;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.countdown-style-01 .counter-container .countdown-box .number {
  font-size: 2.375rem;
  line-height: 2.5rem;
  font-weight: 600;
  margin-bottom: 5px;
  letter-spacing: -1px;
}
.countdown-style-01 .counter-container .countdown-box span {
  font-size: 14px;
  line-height: 14px;
  color: var(--medium-gray);
  text-transform: uppercase;
}
/* Countdown style 02 */
.countdown-style-02 .countdown-box {
  position: relative;
  width: 165px;
  padding: 0 15px;
  color: var(--white);
  font-family: var(--alt-font);
  display: inline-block;
  text-align: center;
}
.countdown-style-02 .countdown-box:before {
  content: ":";
  position: absolute;
  top: 50%;
  right: -5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--white);
  text-align: center;
  font-size: 3rem;
  margin-top: -4px;
}
.countdown-style-02 .countdown-box:last-child:before {
  display: none;
}
.countdown-style-02 .countdown-box .number {
  font-size: 3.5rem;
  line-height: 3.75rem;
  font-weight: 600;
  letter-spacing: -1px;
}
.countdown-style-02 .countdown-box span {
  font-size: 15px;
  line-height: 14px;
  text-transform: uppercase;
  position: absolute;
  top: -26px;
  left: 0;
  right: 0;
  color: var(--white);
  font-weight: 500;
}
/* Countdown style 03 */
.countdown-style-03 .countdown-box {
  width: 130px;
  display: inline-block;
  margin: 0 15px;
  text-align: center;
}
.countdown-style-03 .countdown-box .number {
  color: var(--dark-gray);
  font-size: 4.3rem;
  line-height: 4.3rem;
  font-family: var(--alt-font);
  letter-spacing: -1px;
  font-weight: 700;
}
.countdown-style-03 .countdown-box span {
  color: var(--medium-gray);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}
/* Countdown style 04 */
.countdown-style-04 .countdown-box {
  width: 160px;
  display: inline-block;
  margin: 0 7px;
  text-align: center;
  position: relative;
}
.countdown-style-04 .countdown-box:before {
  content: ":";
  position: absolute;
  top: 20px;
  font-size: 2rem;
  color: var(--white);
  right: -10px;
  margin-top: -5px;
  font-weight: 400;
  opacity: 0.7;
}
.countdown-style-04 .countdown-box:last-child:before {
  display: none;
}
.countdown-style-04 .countdown-box .number {
  color: #ffffff;
  font-size: 4.5rem;
  line-height: 4.5rem;
  font-family: var(--alt-font);
  font-weight: 600;
}
.countdown-style-04 .countdown-box span {
  color: var(--very-light-gray);
  font-size: 15px;
}
/* Counter style 03 */
.counter-style-03 .feature-box i {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.counter-style-03 .feature-box:hover i {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}
/* Counter style 04 */
.counter-style-04 .vertical-counter:after {
  content: attr(data-text);
  display: inline-block;
  margin-left: 3px;
  word-break: normal;
}
/* Counter style 06 */
.counter-style-06 .feature-box-content .counter-title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.counter-style-06 .feature-box-content:after {
  content: "";
  display: block;
  height: 40%;
  right: 0;
  top: 50%;
  width: 1px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: var(--extra-medium-gray);
}
.counter-style-06 .feature-box:last-child .feature-box-content:after {
  display: none;
}
/* Counter style 07 */
.counter-style-07 .vertical-counter-number {
  z-index: 1;
}
/* Vertical counter */
.vertical-counter-number {
  overflow: hidden;
  width: 100%;
  height: auto;
}
.vertical-counter-number ul {
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  will-change: transform;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
/* Divider style 01 */
.divider-style-01 .divider-shadow:after, .divider-style-01 .divider-shadow:before {
  flex: 1 1 auto;
  -webkit-box-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  content: "";
  height: 10px;
  margin-top: 7px;
  box-shadow: 0 6px 10px -10px #8c8b8b inset;
}
.divider-style-01 .divider-border:after, .divider-style-01 .divider-border:before {
  flex: 1 1 auto;
  -webkit-box-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  content: "";
  height: 10px;
  margin-top: 7px;
  border-top: 1px solid #E4E4E4;
}
/* Divider style 02 */
.divider-style-02 .divider-dot {
  position: relative;
}
.divider-style-02 .divider-dot span {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -13px;
  width: 25px;
  height: 25px;
  box-shadow: inset 0 0 0 6px white;
}
.divider-style-02 .divider-dot:after {
  top: 0;
  content: "";
  height: 100%;
  width: 1px;
  flex: 1 1 auto;
  -webkit-box-flex: 1 1 1px;
  -ms-flex: 1 1 1px;
  border-left: 1px solid #E4E4E4;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
/* Divider style 03 */
.divider-style-03.divider-style-03-01 {
  border-top: 1px solid;
  width: 100%;
}
.divider-style-03.divider-style-03-02 {
  border-top: 1px dashed;
}
.divider-style-03.divider-style-03-03 {
  border-top: 4px double;
}
.divider-style-03.divider-style-03-04 {
  border-top: 2px dashed;
}
.divider-style-03.divider-style-03-05 {
  border-top: 2px dotted;
}
/* First letter */
.first-letter {
  float: left;
  font-size: 4.5rem;
  line-height: 4.5rem;
  font-weight: 600;
  text-align: center;
  margin: 0 25px 0 0;
}
/* First letter block */
.first-letter-block {
  font-size: 4.5rem;
  line-height: 100px;
  font-weight: 600;
  width: 100px;
  height: 100px;
  margin: 8px 35px 0 0;
}
/* First letter round */
.first-letter-round {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 3.5rem;
}
/* First letter big */
.first-letter-big {
  float: left;
  font-size: 9rem;
  line-height: 9rem;
  padding: 0;
  text-align: center;
  position: relative;
}
/* First letter small */
.first-letter-small {
  font-size: 3.438rem;
  line-height: 4rem;
  font-weight: 600;
  width: 65px;
  height: 65px;
  margin: 8px 30px 0 0;
}
/* Event style 02 */
.time-table {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  flex: 1 0;
}
.time-table + .time-table .time-table-box {
  border-top-width: 0 !important;
}
.time-table-box {
  -webkit-box-align: stretch;
  align-items: stretch;
  text-align: center;
  flex-basis: 12.5%;
  padding: 30px 10px;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 100px;
}
.time-table-box .inner-box {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  flex: 1 auto;
  z-index: 9;
}
.time-table-box .inner-box > div {
  line-height: initial;
  margin-top: 7px;
}
.time-table-box + .time-table-box {
  border-left-width: 0 !important;
}
.time-table-box + .time-table-box.name-box:before {
  position: absolute;
  content: "";
  transition: opacity 0.3s;
  opacity: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--base-color);
}
.time-table-box + .time-table-box.name-box:hover .inner-box span, .time-table-box + .time-table-box.name-box:hover .inner-box div {
  color: var(--white);
}
.time-table-box + .time-table-box.name-box:hover:before {
  opacity: 1;
}
.time-table-box + .time-table-box.name-box:hover .hover-detail {
  visibility: visible;
  opacity: 1;
}
.time-table-box.day {
  padding: 15px 10px;
  min-height: 1px;
}
.time-table-box .hover-detail {
  position: absolute;
  left: 50%;
  top: 100%;
  padding: 25px 30px;
  border-radius: 6px;
  width: 250px;
  z-index: 111;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-box-shadow: 0 0 60px rgba(0, 0, 0, 0.14);
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.14);
  -webkit-transition: 0.05s;
  transition: 0.05s;
}
.time-table-box .hover-detail:after {
  content: "";
  top: -21px;
  left: 50%;
  height: 0;
  width: 0;
  z-index: 9;
  margin-left: -14px;
  position: absolute;
  pointer-events: none;
  border: solid transparent;
  border-width: 11px;
  border-bottom-color: var(--white);
}
.time-table-box .hover-detail.hover-detail-left {
  left: auto;
  right: 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.time-table-box .hover-detail.hover-detail-left:after {
  right: 60px;
  left: auto;
}
.time-table-box .hover-detail.hover-detail-top {
  top: auto;
  bottom: 100%;
}
.time-table-box .hover-detail.hover-detail-top:after {
  top: auto;
  bottom: -21px;
  border-top-color: var(--white);
  border-bottom-color: transparent;
}
.name-box span {
  line-height: 26px;
  transition: 0.2s;
}
.name-box div {
  line-height: 26px;
  transition: 0.2s;
}
/* Event style 03 */
.event-style-03 .nav-tabs {
  border-radius: 6px 6px 0 0px;
}
.event-style-03 .nav-tabs .nav-link {
  color: var(--white);
  line-height: normal;
  padding: 17px 15px 20px;
  border: 0;
  border-radius: 0;
}
.event-style-03 .nav-tabs .nav-link.active, .event-style-03 .nav-tabs .nav-link.show, .event-style-03 .nav-tabs .nav-link:hover {
  color: var(--dark-gray);
  background-color: var(--white);
}
.event-style-03 .nav-tabs > li.nav-item {
  padding: 0;
  width: 100%;
}
.event-style-03 .nav-tabs > li.nav-item:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.event-style-03 .accordion .accordion-title .event-time {
  min-width: 354px;
}
.event-style-03 .accordion .accordion-title .event-time i {
  position: static;
  -webkit-transform: none;
  transform: none;
}
.event-style-03 .accordion .trainer-box {
  flex: 0 0 auto;
  width: 270px;
  margin-right: 85px;
  margin-top: 15px;
}
/* Text image mask */
.image-mask {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
/* Fancy text box style 01 */
.fancy-text-box-style-01 {
  border-bottom: 1px solid;
  border-right: 1px solid;
  overflow: hidden;
}
.fancy-text-box-style-01 .text-box-wrapper .text-box, .fancy-text-box-style-01 .text-box-wrapper .text-box-hover {
  display: block;
  width: 100%;
  -webkit-transition: all 0.6s cubic-bezier(0.12, 0, 0.39, 0);
  transition: all 0.6s cubic-bezier(0.12, 0, 0.39, 0);
}
.fancy-text-box-style-01 .text-box-wrapper .text-box-hover {
  opacity: 0;
  position: absolute;
}
.fancy-text-box-style-01 .text-box-wrapper:hover .text-box {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.6s cubic-bezier(0.12, 0, 0.39, 0);
  transition: all 0.6s cubic-bezier(0.12, 0, 0.39, 0);
}
.fancy-text-box-style-01 .text-box-wrapper:hover .text-box-hover {
  opacity: 1;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.6s cubic-bezier(0.12, 0, 0.39, 0);
  transition: all 0.6s cubic-bezier(0.12, 0, 0.39, 0);
}
.fancy-text-box-style-01:last-child {
  border-right: 0;
}
/* Google map */
#map {
  height: 550px;
}
.arrow_box {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  -webkit-border-top-left-radius: 100%;
  -webkit-border-top-right-radius: 100%;
  -webkit-border-bottom-right-radius: 100%;
  -webkit-border-bottom-left-radius: 100%;
  position: relative;
  opacity: 0.8;
  cursor: pointer;
}
.arrow_box span {
  width: 100%;
  height: 100%;
  border: 1px solid;
  opacity: 0.2;
  display: inline-block;
  border-radius: 100%;
  -webkit-border-top-left-radius: 100%;
  -webkit-border-top-right-radius: 100%;
  -webkit-border-bottom-right-radius: 100%;
  -webkit-border-bottom-left-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.arrow_box span:first-child {
  animation: markerWave 1.5s 0.45s cubic-bezier(0.4, 0, 0.2, 1) infinite forwards;
  -webkit-animation: markerWave 1.5s 0.45s cubic-bezier(0.4, 0, 0.2, 1) infinite forwards;
}
.arrow_box span:first-child + span {
  animation: markerWave 1.5s 0.45s cubic-bezier(0, 0, 0.2, 1) infinite forwards;
  -webkit-animation: markerWave 1.5s 0.45s cubic-bezier(0, 0, 0.2, 1) infinite forwards;
}
.arrow_box span:last-child {
  opacity: 0.1;
}
.arrow_box.marker02 {
  width: 18px;
  height: 18px;
}
.arrow_box.marker02 span {
  border: 0;
}
.arrow_box.marker02:after {
  content: "";
  width: 45px;
  height: 62px;
  background: url("../images/marker02.png") left center no-repeat;
  background-size: cover;
  position: absolute;
  left: -15px;
  top: -58px;
  float: left;
}
.arrow_box.marker03 {
  width: 16px;
  height: 16px;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.arrow_box.marker03 span {
  background-color: transparent !important;
}
@keyframes markerWave {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  20% {
    opacity: 0.8;
  }
  100% {
    transform: scale(5.5);
    opacity: 0;
  }
}
@-webkit-keyframes markerWave {
  0% {
    -webkit-transform: scale(1);
    opacity: 0.8;
  }
  20% {
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(5.5);
    opacity: 0;
  }
}
.infowindow {
  color: var(--dark-gray);
  font-weight: 400;
  line-height: 26px;
  font-size: 15px;
  letter-spacing: -0.5px;
  padding: 25px 25px 25px 25px !important;
  text-align: center;
}
.infowindow .mb-3 {
  margin-bottom: 5px !important;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.25px;
}
.infowindow p {
  color: var(--medium-gray);
  font-size: 15px;
  margin-bottom: 5px;
  letter-spacing: -0.25px;
  line-height: 24px;
}
.infowindow :last-child {
  margin-bottom: 0;
}
.gm-style .gm-style-iw-c {
  padding: 0 !important;
  border-radius: 0;
  max-width: 230px !important;
  -webkit-box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
}
.gm-style .gm-style-iw-d {
  overflow: auto !important;
}
.gm-style .gm-style-iw-t::after {
  background: var(--dark-gray);
  -webkit-box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
}
.gm-ui-hover-effect {
  opacity: 0.8;
  top: -2px !important;
  right: 2px !important;
  width: 25px !important;
  display: none !important;
  height: 25px !important;
}
.gm-ui-hover-effect img {
  width: 17px !important;
  height: 17px !important;
  margin: 6px !important;
}
.google-maps-link {
  text-align: center;
}
.google-maps-link a {
  font-size: 12px;
  font-weight: 400;
  display: block;
  background-color: var(--dark-gray);
  color: var(--white);
  padding: 15px;
  letter-spacing: 0.5px;
}
.google-maps-link a:hover {
  text-decoration: none;
}
.gmnoprint .gm-style-mtc,
.gmnoprint .gm-svpc {
  display: none;
}
.gm-style a[title] {
  display: none !important;
}
.gmnoprint .gm-style-cc,
.gmnoprint.gm-style-cc,
.gm-style-cc,
.gmnoscreen {
  display: none !important;
}
.gm-style .gm-style-iw-tc::after {
  background: var(--dark-gray);
}
@media screen {
  .gm-style .gmnoscreen,
  .gmnoscreen {
    display: none;
  }
}
/* Text highlight */
.text-highlight {
  position: relative;
  z-index: 1;
}
.text-highlight span {
  display: inline-block;
  left: -3px;
  position: absolute;
  z-index: -1;
  width: calc(100% + 6px);
}
/* Separator animation */
.separator-animation {
  width: 0 !important;
  -webkit-transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.shadow-in .separator-animation {
  width: calc(100% + 6px) !important;
}
/* Feature box style */
.feature-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 1;
}
.feature-box .feature-box-icon i:not([class*=text-gradient-]) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.feature-box.feature-box-left-icon, .feature-box.feature-box-left-icon-middle {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: left;
}
.feature-box.feature-box-left-icon .feature-box-icon, .feature-box.feature-box-left-icon-middle .feature-box-icon {
  margin-right: 30px;
}
.feature-box.feature-box-left-icon .feature-box-content, .feature-box.feature-box-left-icon-middle .feature-box-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.feature-box.feature-box-left-icon .content-slide-up, .feature-box.feature-box-left-icon-middle .content-slide-up {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.feature-box.feature-box-left-icon-middle {
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-align: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  text-align: left;
}
.feature-box.feature-box-left-icon-middle .content-slide-up {
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
.feature-box.text-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}
.feature-box.text-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.feature-box .feature-box-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
.feature-box .content-slide-up {
  will-change: transform;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
}
.feature-box .content-slide-up .text-visible {
  opacity: 0;
}
.feature-box .border-hover {
  left: 0;
  bottom: -1px;
  width: calc(100% + 0px);
  height: 5px;
  position: absolute;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  will-change: transform;
}
.feature-box .feature-box-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -9;
  opacity: 0;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.feature-box .feature-box-icon-rounded {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.feature-box .feature-box-icon-rounded .feature-box-icon-hover {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
.feature-box .feature-box-icon-rounded i {
  position: relative;
  z-index: 1;
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
.feature-box .box-move-bottom-top {
  -webkit-transform: translateY(28px);
  transform: translateY(28px);
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
.feature-box .move-bottom-top {
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  opacity: 0;
}
.feature-box:hover .box-move-bottom-top {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.feature-box:hover .box-move-bottom-top .move-bottom-top {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}
.feature-box:hover .border-hover {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.feature-box:hover .feature-box-overlay {
  opacity: 1;
}
.feature-box:hover .content-slide-up {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.feature-box:hover .content-slide-up .text-visible {
  opacity: 1;
}
.feature-box:hover .content-slide-up.content-scale .feature-box-overlay {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.feature-box:hover .feature-box-bg-overlay {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.feature-box:hover .feature-box-icon-rounded.border-color-transparent-on-hover {
  border-color: transparent !important;
}
.feature-box:hover .feature-box-icon-rounded .feature-box-icon-hover {
  opacity: 1;
}
.feature-box:hover .feature-box-content .underline-on-hover {
  color: var(--base-color);
}
.feature-box:hover .feature-box-content .underline-on-hover:after {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.feature-box:hover .dark-hover i, .feature-box:hover .dark-hover span {
  color: #fff;
}
.feature-box:hover .dark-hover [class*=text-gradient-] {
  color: var(--white);
  background-image: none;
  -webkit-background-clip: inherit;
  -webkit-text-fill-color: var(--white);
  -webkit-text-fill-color: var(--white);
}
.feature-box.border-bottom .feature-box-overlay {
  height: calc(100% + 1px);
}
.feature-box.border-top .feature-box-overlay {
  height: calc(100% + 1px);
}
/* Icon with text style 03 */
.icon-with-text-style-03 * {
  word-break: initial;
}
/* Icon with text style 05 */
.icon-with-text-style-05 .feature-box {
  height: 100%;
}
/* Icon with text style 07 */
.icon-with-text-style-07 .hover-box:hover .feature-box-title {
  left: -25%;
}
.icon-with-text-style-07 .hover-box:hover .feature-box-title.text-outline-color-dark-gray {
  -webkit-text-stroke-color: var(--base-color);
  opacity: 1;
}
.icon-with-text-style-07 .feature-box-title {
  position: absolute;
  top: 30px;
  left: 50%;
  word-break: normal;
  width: 100%;
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
/* Icon with text style 10 */
.icon-with-text-style-10 .feature-box-icon:before {
  width: 100%;
  height: 50%;
  background-color: var(--base-color);
  opacity: 0.12;
  z-index: -1;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  transform: rotate(-40deg);
  -webkit-transform-origin: bottom center;
  -ms-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
.icon-with-text-style-10:nth-child(even) .feature-box-icon:before {
  -webkit-transform: rotate(140deg);
  -ms-transform: rotate(140deg);
  transform: rotate(140deg);
}
.icon-with-text-style-10:hover .feature-box-icon {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 300ms cubic-bezier(0.37, 0.8, 0.63, 1.2);
  transition: all 300ms cubic-bezier(0.37, 0.8, 0.63, 1.2);
}
.icon-with-text-style-10:hover .feature-box-icon i {
  color: var(--white);
}
.icon-with-text-style-10:hover .feature-box-icon:before {
  height: 100%;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  opacity: 1;
}
/* Icon with text style 11 */
.icon-with-text-style-11 {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.icon-with-text-style-11 .text-box {
  opacity: 1;
}
.icon-with-text-style-11 .text-box-hover {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.icon-with-text-style-11:hover {
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
}
.icon-with-text-style-11:hover .text-box-hover {
  opacity: 1;
}
.icon-with-text-style-11:hover .text-box {
  opacity: 0;
}
/* Image gallery style 01 */
.image-gallery-style-01 .gallery-box .gallery-image {
  will-change: transform;
}
.image-gallery-style-01 .gallery-box:hover img {
  opacity: 0.5;
  -webkit-filter: blur(2px);
  filter: blur(2px);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* Image gallery style 02 */
.image-gallery-style-02 .gallery-box:hover img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.3;
}
/* Image gallery style 03 */
.image-gallery-style-03 .gallery-box i {
  opacity: 0;
  visibility: hidden;
}
.image-gallery-style-03 .gallery-box img {
  -webkit-transform: scale(1.13) translateZ(0);
  transform: scale(1.13) translateZ(0);
}
.image-gallery-style-03 .gallery-box:hover i {
  opacity: 1;
  visibility: visible;
}
.image-gallery-style-03 .gallery-box:hover img {
  opacity: 0.2;
  -webkit-transform: scale(1) translateZ(0);
  transform: scale(1) translateZ(0);
}
/* Image gallery style 04 */
.image-gallery-style-04 .gallery-box {
  will-change: transform;
}
.image-gallery-style-04 .gallery-box img {
  width: 100%;
}
.image-gallery-style-04 .gallery-box:hover img {
  opacity: 0.2;
}
/* Image gallery style 05 */
.image-gallery-style-05 .gallery-box {
  will-change: transform;
}
.image-gallery-style-05 .gallery-box:hover img {
  opacity: 0.2;
}
/* Image gallery style 06 */
.image-gallery-style-06 .gallery-box {
  will-change: transform;
}
.image-gallery-style-06 .gallery-box img {
  width: 100%;
}
.image-gallery-style-06 .gallery-box .gallery-hover {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.image-gallery-style-06 .gallery-box .gallery-hover .gallery-title {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}
.image-gallery-style-06 .gallery-box:hover img {
  opacity: 0.2;
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}
.image-gallery-style-06 .gallery-box:hover .gallery-hover {
  opacity: 1;
  visibility: visible;
}
.image-gallery-style-06 .gallery-box:hover .gallery-hover .gallery-title {
  -webkit-transform: scale(1);
  transform: scale(1);
}
/* Instagram */
.instafeed-grid {
  list-style: none;
  text-align: center;
}
.instafeed-grid figure {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  border-radius: 4px;
}
.instafeed-grid figure a .insta-icon {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(35, 35, 35, 0.7);
  width: 100%;
  height: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.instafeed-grid figure a .insta-icon i {
  margin-right: 5px;
  margin-left: 5px;
  position: relative;
  display: block;
  font-size: 26px;
  color: var(--white);
  top: 50%;
  -webkit-transform: translateY(-50%) scale(1.5, 1.5);
  transform: translateY(-50%) scale(1.5, 1.5);
  -webkit-transition: all 200ms cubic-bezier(0.37, 0.8, 0.63, 1);
  transition: all 200ms cubic-bezier(0.37, 0.8, 0.63, 1);
}
.instafeed-grid figure:hover a .insta-icon {
  opacity: 1;
}
.instafeed-grid figure:hover a .insta-icon i {
  -webkit-transform: translateY(-50%) scale(1, 1);
  transform: translateY(-50%) scale(1, 1);
}
.instagram-follow-api .instagram-button {
  z-index: 1;
  white-space: nowrap;
  padding: 12px 32px;
  box-shadow: 0 0 35px rgba(23, 23, 23, 0.4);
}
/* Interactive banner style 01 */
.interactive-banner-style-01 figure {
  -webkit-transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
}
.interactive-banner-style-01 figure .icon-box {
  opacity: 0;
  position: relative;
  top: 15px;
}
.interactive-banner-style-01 figure figcaption {
  position: absolute;
  bottom: 0;
}
.interactive-banner-style-01 figure:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.interactive-banner-style-01 figure:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
}
.interactive-banner-style-01 figure:hover .icon-box {
  opacity: 1;
  top: 0;
}
.interactive-banner-style-01 figure:hover .box-overlay {
  opacity: 0.5;
}
/* Interactive banner style 02 */
.interactive-banner-style-02.hover-box * {
  will-change: auto !important;
}
.interactive-banner-style-02 figure figcaption .features-icon {
  margin-bottom: -45px;
  opacity: 0;
  visibility: hidden;
}
.interactive-banner-style-02:hover figure img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.interactive-banner-style-02:hover figure figcaption .features-icon {
  margin-bottom: 20px;
  opacity: 1;
  visibility: visible;
}
/* Interactive banner style 03 */
.interactive-banner-style-03 figure {
  position: relative;
}
.interactive-banner-style-03 figure img {
  -webkit-transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
}
.interactive-banner-style-03 figure figcaption {
  position: absolute;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.interactive-banner-style-03 figure:hover img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}
/* Interactive banner style 04 */
.interactive-banner-style-04 figure {
  -webkit-transition: all 700ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 700ms cubic-bezier(0.5, 1, 0.89, 1);
}
.interactive-banner-style-04 figure * {
  -webkit-transition: all 700ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 700ms cubic-bezier(0.5, 1, 0.89, 1);
}
.interactive-banner-style-04 figure figcaption {
  position: absolute;
  bottom: 0;
}
.interactive-banner-style-04 figure figcaption .features-content {
  position: absolute;
  left: 25px;
  top: 25px;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 500ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 500ms cubic-bezier(0.5, 1, 0.89, 1);
}
.interactive-banner-style-04 figure figcaption .features-content .box-overlay {
  height: 0%;
  -webkit-transition: all 400ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 400ms cubic-bezier(0.5, 1, 0.89, 1);
}
.interactive-banner-style-04 figure figcaption .box-button {
  opacity: 1;
  visibility: visible;
  position: relative;
  bottom: 0;
  -webkit-transition: all 500ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 500ms cubic-bezier(0.5, 1, 0.89, 1);
}
.interactive-banner-style-04 figure:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.interactive-banner-style-04 figure:hover figcaption .features-content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.interactive-banner-style-04 figure:hover figcaption .box-overlay {
  height: 100%;
}
.interactive-banner-style-04 figure:hover figcaption .box-button {
  opacity: 0;
  visibility: hidden;
  position: relative;
  bottom: -70px;
}
/* Interactive banner style 05 */
.interactive-banner-style-05 figure img {
  -webkit-transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
}
.interactive-banner-style-05 figure figcaption .btn {
  margin-bottom: -50px;
  visibility: hidden;
  opacity: 0;
  transition-timing-function: linear;
  -webkit-transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
}
.interactive-banner-style-05 figure figcaption .content-arrow {
  position: absolute;
  right: 0;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
}
.interactive-banner-style-05 figure:hover img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}
.interactive-banner-style-05 figure:hover figcaption img {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.interactive-banner-style-05 figure:hover figcaption .content-arrow {
  opacity: 1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.interactive-banner-style-05 figure:hover figcaption .btn {
  margin-bottom: 0;
  visibility: visible;
  opacity: 1;
}
.interactive-banner-style-05 .atropos:hover .box-overlay {
  opacity: 1;
  visibility: visible;
}
.interactive-banner-style-05 .atropos:hover img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}
/* Interactive banner style 06 */
.interactive-banner-style-06 {
  position: relative;
  overflow: hidden;
}
.interactive-banner-style-06 .banners-icon {
  z-index: 9;
  -webkit-transition: 350ms cubic-bezier(0.12, 0, 0.39, 0);
  transition: 350ms cubic-bezier(0.12, 0, 0.39, 0);
}
.interactive-banner-style-06 img {
  -webkit-transition: 1s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 1s cubic-bezier(0.12, 0, 0.39, 0);
  width: 100%;
}
.interactive-banner-style-06 .interactive-banners-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  transform: translateY(calc(100% - 155px));
  -webkit-transition: 550ms cubic-bezier(0.12, 0, 0.1, 0);
  transition: 550ms cubic-bezier(0.12, 0, 0.1, 0);
}
.interactive-banner-style-06 .interactive-banners-content .interactive-banners-content-text {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 350ms cubic-bezier(0.12, 0, 0.39, 0);
  transition: 350ms cubic-bezier(0.12, 0, 0.39, 0);
}
.interactive-banner-style-06 .box-overlay {
  height: 25%;
  bottom: 0;
  top: auto;
  visibility: visible;
  opacity: 0;
  -webkit-transition: 350ms cubic-bezier(0.12, 0, 0.39, 0);
  transition: 350ms cubic-bezier(0.12, 0, 0.39, 0);
}
.interactive-banner-style-06:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: 3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 3s cubic-bezier(0.12, 0, 0.39, 0);
}
.interactive-banner-style-06:hover .icon-hover-base-color {
  color: var(--base-color) !important;
}
.interactive-banner-style-06:hover .interactive-banners-content {
  transform: translateY(0);
}
.interactive-banner-style-06:hover .interactive-banners-content .interactive-banners-content-text {
  opacity: 1;
  visibility: visible;
}
.interactive-banner-style-06:hover .box-overlay {
  height: 100%;
  visibility: visible;
  opacity: 1;
}
/* Interactive banner style 07 */
.interactive-banner-style-07 figure {
  position: relative;
  overflow: hidden;
}
.interactive-banner-style-07 figure > img {
  -webkit-transition: 0.6s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.6s cubic-bezier(0.12, 0, 0.39, 0);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.interactive-banner-style-07 figure .overlay-bg {
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
.interactive-banner-style-07 figure figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.interactive-banner-style-07 figure .hover-hide-content {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  -webkit-transition: 1s;
  transition: 1s;
}
.interactive-banner-style-07 figure .hover-show-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 50px;
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.interactive-banner-style-07 figure:hover > img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.interactive-banner-style-07 figure:hover .overlay-bg {
  opacity: 0.7;
}
.interactive-banner-style-07 figure:hover .hover-hide-content {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}
.interactive-banner-style-07 figure:hover .hover-show-content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
/* Interactive banner style 08 */
.interactive-banner-style-08 figure:hover img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
  -webkit-transition: 1.5s;
  transition: 1.5s;
}
.interactive-banner-style-08 figure:hover .box-overlay {
  opacity: 1;
  visibility: visible;
}
.interactive-banner-style-08 figure:hover figcaption .circle-box {
  background-color: var(--white) !important;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 500ms cubic-bezier(0.61, 1, 0.88, 1.5);
  transition: all 500ms cubic-bezier(0.61, 1, 0.88, 1.5);
}
/* Interactive banner style 09 */
.interactive-banner-style-09 img {
  -webkit-transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.interactive-banner-style-09 .image-content {
  position: absolute;
  top: 0;
}
.interactive-banner-style-09 .image-content .content-title {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  opacity: 0.6;
  visibility: visible;
  will-change: transform;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
.interactive-banner-style-09 .image-content .content-title-hover {
  position: absolute;
  width: auto;
  left: 0;
  bottom: 0;
  visibility: hidden;
  will-change: transform;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
.interactive-banner-style-09 .image-content .content-arrow {
  will-change: transform;
  position: absolute;
  right: 0;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: all 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}
.interactive-banner-style-09 .image-content .label {
  -webkit-transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
}
.interactive-banner-style-09 .image-content .hover-label-icon i {
  opacity: 0;
  position: absolute;
  top: 20px;
  left: 0;
  -webkit-transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
}
.interactive-banner-style-09 .image-content:hover .content-arrow {
  opacity: 1;
  -webkit-transform: translateY(-42%);
  transform: translateY(-42%);
}
.interactive-banner-style-09 .image-content:hover .content-title {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}
.interactive-banner-style-09 .image-content:hover .content-title-hover {
  visibility: visible;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
.interactive-banner-style-09 .image-content:hover .box-overlay {
  opacity: 1;
  visibility: visible;
}
.interactive-banner-style-09 .image-content:hover .hover-label-icon .label {
  opacity: 0;
  margin-top: -20px;
}
.interactive-banner-style-09 .image-content:hover .hover-label-icon i {
  opacity: 1;
  top: 0;
}
.interactive-banner-style-09:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* List style 01 */
.list-style-01 {
  list-style: none;
}
.list-style-01 li {
  border-bottom: 1px solid;
}
.list-style-01 li:last-child {
  border-bottom: 0px;
}
/* List style 02 */
.list-style-02 {
  list-style: none;
}
.list-style-02 li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}
.list-style-02 li:last-child {
  margin-bottom: 0;
}
.list-style-02 li i {
  line-height: 32px;
}
/* List style 03 */
.list-style-03 {
  counter-reset: li;
  list-style-type: none;
}
.list-style-03 li {
  position: relative;
  padding: 0 0 0 38px;
  margin: 0 0 9px 0;
}
.list-style-03 li:before {
  content: counter(li, decimal-leading-zero) ".";
  counter-increment: li;
  display: inline-block;
  position: absolute;
  left: 0;
}
/* List style 04 */
.list-style-04 {
  padding-left: 16px;
}
.list-style-04 li {
  margin: 0 0 10px;
  list-style: disc;
}
/* List style 05 */
.list-style-05 {
  padding: 0;
  margin: 0;
}
.list-style-05 li {
  margin: 0;
  display: inline-block;
}
.list-style-05 li:before {
  border-radius: 100%;
  content: "";
  background-color: var(--medium-gray);
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 15px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.list-style-05 li:first-child:before {
  display: none;
}
/* HTML video */
.html-video {
  background-color: #000;
  left: 0%;
  position: absolute;
  top: 50%;
  z-index: -3;
  object-fit: cover;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* Background video */
.bg-video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}
.bg-video-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* Modal popup */
#popup-form {
  cursor: default;
}
.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: var(--white);
  background: transparent;
}
.mfp-iframe-holder .mfp-content {
  max-width: 1050px;
}
.mfp-close, .mfp-close:active {
  opacity: 1;
  background-color: transparent;
  right: 20px;
  top: 20px;
  height: 30px;
  width: 30px;
  font-size: 13px;
  line-height: 31px;
  position: absolute;
  color: var(--dark-gray);
}
.lightbox-gallery a {
  position: relative;
  display: block;
}
.lightbox-gallery .gallary-over-layer {
  position: absolute;
  top: 0;
  left: 0;
}
.show-search-popup > section {
  filter: grayscale(100%);
}
.show-search-popup .mfp-search.mfp-bg.mfp-ready {
  opacity: 0.5;
}
.mfp-arrow {
  opacity: 1;
}
.mfp-wrap {
  cursor: url("../images/mfg-close.png"), auto;
}
.mfp-wrap .mfp-container {
  cursor: auto;
}
.show-search-popup .mfp-close, .show-search-popup .mfp-close:active {
  color: var(--dark-gray);
}
.show-search-popup .search-form .search-input {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.show-search-popup .search-form .search-button {
  color: var(--dark-gray);
}
.show-search-popup .mfp-container {
  position: fixed;
  height: 300px;
  background-color: var(--white);
  -webkit-animation-name: "ani-mfp";
  animation-name: "ani-mfp";
  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  -o-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
.show-search-popup .mfp-removing .mfp-container {
  -webkit-animation-name: "ani-mfp-close";
  animation-name: "ani-mfp-close";
  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  -o-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
.show-search-popup .search-form .search-input {
  color: var(--dark-gray);
}
.lightbox-gallery a {
  position: relative;
  display: block;
}
.lightbox-gallery .gallary-over-layer {
  position: absolute;
  top: 0;
  left: 0;
}
.mfp-arrow-right:after,
.mfp-arrow-left:after {
  display: none;
}
.mfp-arrow-left {
  left: 30px;
}
.mfp-arrow-right {
  right: 30px;
}
.mfp-arrow-left:before {
  border-right: none;
  content: "\e629";
  font-size: 20px;
  font-family: "themify";
  color: var(--white);
  line-height: 60px;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  opacity: 1;
}
.mfp-arrow-right:before {
  border-left: none;
  content: "\e628";
  font-size: 20px;
  font-family: "themify";
  color: var(--white);
  line-height: 60px;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  opacity: 1;
}
.mfp-bottom-bar {
  margin-top: -24px;
  font-size: 12px;
}
.mfp-close {
  font-size: 28px;
  font-family: var(--alt-font);
}
.mfp-figure {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  -ms-animation-duration: 0.4s;
  -o-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}
/* Zoom animation */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: scale3d(0.9, 0.9, 1);
  -o-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
}
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  -o-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}
/* Fade animation */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  transform: translateY(-20px) perspective(600px) rotateX(10deg);
}
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -o-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0);
}
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  transform: translateY(-10px) perspective(600px) rotateX(10deg);
}
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}
/* Slide up animation */
.my-mfp-slide-up .slide-up-popup {
  -webkit-transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
  -webkit-transform: translate3d(0, 30px, 0);
  transform: translate3d(0, 30px, 0);
}
.my-mfp-slide-up.mfp-ready .slide-up-popup {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  -o-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}
.my-mfp-slide-up.mfp-removing .slide-up-popup {
  opacity: 0;
  -webkit-transform: translate3d(0, 30px, 0);
  transform: translate3d(0, 30px, 0);
}
.my-mfp-slide-up.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.my-mfp-slide-up.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.my-mfp-slide-up.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-bg {
  background: var(--dark-gray);
  opacity: 0.9;
}
.mfp-bg, .mfp-wrap {
  z-index: 10007;
}
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.93;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.mfp-ajax-holder {
  width: 70%;
  height: calc(100% - 100px);
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
button.mfp-close,
button.mfp-close:active {
  height: 40px;
  width: 40px;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
}
button.mfp-close:before,
button.mfp-close:active:before {
  content: "\f00d";
  color: var(--black);
  font-family: "Font Awesome 6 Free";
  font-size: 20px;
  font-weight: 900;
}
/* Images gallery */
.mfp-gallery button.mfp-close,
.mfp-iframe-holder ~ button.mfp-close {
  right: 40px;
  top: 40px;
  background-color: var(--white);
}
.mfp-gallery button.mfp-close:before,
.mfp-iframe-holder ~ button.mfp-close:before {
  color: var(--dark-gray);
}
/* Modal popup */
.modal-popup-main {
  position: relative;
}
/* Auto newsletter popup */
.subscribe-popup .mfp-close,
.subscribe-popup .mfp-close:active {
  background-color: transparent;
  font-size: 13px;
  line-height: 30px;
  top: 20px;
  right: 20px;
}
.subscribe-popup .mfp-close:before {
  color: var(--dark-gray);
}
.subscribe-popup .newsletter-style-02 input {
  padding-right: 70px;
}
.subscribe-popup .newsletter-style-02 .btn {
  padding: 6px 25px 9px 25px;
}
.newsletter-popup label {
  cursor: pointer;
}
/* Parallax background */
[data-parallax-background-ratio] {
  position: relative !important;
  background-size: cover !important;
  overflow: hidden;
  background-attachment: fixed !important;
  transition-duration: 0s;
  -webkit-transition-duration: 0s;
}
/* Video icon effect  */
.video-icon-effect {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: var(--white);
}
.video-icon-effect:before {
  position: absolute;
  content: "";
  animation: border-transform 7s linear infinite alternate forwards;
  background-color: var(--white);
  width: 100px;
  height: 100px;
  top: -15px;
  left: -15px;
  opacity: 0.2;
}
@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 36% 64% 59% 41%/40% 45% 55% 60%;
  }
  24% {
    border-radius: 37% 63% 60% 40%/46% 52% 48% 54%;
  }
  32% {
    border-radius: 40% 60% 54% 46%/52% 56% 44% 48%;
  }
  42% {
    border-radius: 52% 48% 62% 38%/54% 54% 46% 46%;
  }
  56% {
    border-radius: 60% 40% 65% 35%/54% 50% 50% 46%;
  }
  70% {
    border-radius: 44% 56% 43% 57%/42% 50% 50% 58%;
  }
}
@keyframes border-transform {
  0%, 100% {
    border-radius: 36% 64% 59% 41%/40% 45% 55% 60%;
  }
  24% {
    border-radius: 37% 63% 60% 40%/46% 52% 48% 54%;
  }
  32% {
    border-radius: 40% 60% 54% 46%/52% 56% 44% 48%;
  }
  42% {
    border-radius: 52% 48% 62% 38%/54% 54% 46% 46%;
  }
  56% {
    border-radius: 60% 40% 65% 35%/54% 50% 50% 46%;
  }
  70% {
    border-radius: 44% 56% 43% 57%/42% 50% 50% 58%;
  }
}
/* Particles */
canvas {
  display: block;
  vertical-align: bottom;
}
/* Pie chart style 01 */
.pie-chart-style-01 {
  position: relative;
  display: inline-block;
}
.pie-chart-style-01 .percent {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* Pricing table style 01 */
.pricing-table-style-01.popular-item {
  margin-top: -30px;
}
/* Pricing table style 02 */
.pricing-table-style-02 .popular-item {
  left: -10px;
  position: relative;
  z-index: 1;
  width: calc(100% + 20px);
}
.pricing-table-style-02 .popular-label {
  position: absolute;
  top: 0;
  left: 50%;
  padding: 1px 20px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* Pricing table style 04 */
.pricing-table-style-04 .accordion-item {
  border-radius: 5px !important;
  padding: 16px 35px;
}
.pricing-table-style-04 .accordion-item .accordion-body {
  padding: 10px 0;
}
.pricing-table-style-04 .accordion-item .icon-round {
  position: absolute;
  top: 50%;
  right: 0;
  text-align: center;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.pricing-table-style-04 .accordion-item .icon-round i {
  left: 0;
  font-size: 13px;
}
.pricing-table-style-04 .accordion-item.active-accordion {
  padding: 30px 40px;
  background-color: var(--base-color) !important;
  color: var(--white) !important;
}
.pricing-table-style-04 .accordion-item.active-accordion .accordion-header .accordion-title {
  color: var(--white) !important;
}
.pricing-table-style-04 .accordion-item.active-accordion .icon-round {
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--white) !important;
}

/* Pricing table style 07 */
.pricing-table-style-07 .nav-tabs {
  padding: 0;
}
.pricing-table-style-07 .nav-tabs .nav-item {
  width: 100%;
  padding: 0 15px;
}
.pricing-table-style-07 .nav-tabs .nav-item .nav-link {
  border: 0;
  color: var(--medium-gray);
}
.pricing-table-style-07 .nav-tabs .nav-item .nav-link .icon {
  border-color: rgba(23, 23, 23, 0.2) !important;
}
.pricing-table-style-07 .nav-tabs .nav-item .nav-link .icon i {
  opacity: 0;
}
.pricing-table-style-07 .nav-tabs .nav-item .nav-link .text-dark-gray {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pricing-table-style-07 .nav-tabs .nav-item .nav-link:hover, .pricing-table-style-07 .nav-tabs .nav-item .nav-link.active {
  box-shadow: none;
  background-color: var(--base-color);
  color: var(--white) !important;
}
.pricing-table-style-07 .nav-tabs .nav-item .nav-link:hover .text-dark-gray, .pricing-table-style-07 .nav-tabs .nav-item .nav-link.active .text-dark-gray {
  color: var(--white) !important;
}
.pricing-table-style-07 .nav-tabs .nav-item .nav-link:hover .icon i, .pricing-table-style-07 .nav-tabs .nav-item .nav-link.active .icon i {
  opacity: 1;
}
.pricing-table-style-07 .nav-tabs .nav-item .nav-link:hover .offer, .pricing-table-style-07 .nav-tabs .nav-item .nav-link.active .offer {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray) !important;
}
.pricing-table-style-07 .nav-tabs .nav-item .nav-link.active .icon {
  border-color: rgb(255, 255, 255) !important;
}
.pricing-table-style-07 .nav-tabs .nav-item .nav-link.active .offer {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray) !important;
}
/* Pricing table style 08 */
.pricing-table-style-08 .nav-tabs {
  position: relative;
}
.pricing-table-style-08 .nav-tabs > li.nav-item {
  padding: 0;
  margin: 0;
}
.pricing-table-style-08 .nav-tabs > li.nav-item > a.nav-link {
  position: relative;
  padding: 0;
  border: 0;
  color: var(--medium-gray);
}
.pricing-table-style-08 .nav-tabs > li.nav-item > a.nav-link .tab-nav-text {
  margin: 0 15px;
}
.pricing-table-style-08 .nav-tabs > li.nav-item > a:before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 5px;
  left: calc(50% - 15px);
  opacity: 0;
  border-radius: 50em;
  background-color: #fff;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  transition: transform 0.65s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.65s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.pricing-table-style-08 .nav-tabs > li.nav-item > a:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 32px;
  background-color: #152bca;
}
.pricing-table-style-08 .nav-tabs > li.nav-item > a.active {
  color: var(--dark-gray);
  background-color: transparent;
}
.pricing-table-style-08 .nav-tabs > li.nav-item > a.active:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.pricing-table-style-08 .nav-tabs > li.nav-item:first-child a {
  flex-direction: row;
}
.pricing-table-style-08 .nav-tabs > li.nav-item:first-child a:before {
  left: auto;
  right: 0px;
}
.pricing-table-style-08 .nav-tabs > li.nav-item:first-child a:after {
  border-radius: 50em 0 0 50em;
  width: 28px;
}
.pricing-table-style-08 .nav-tabs > li.nav-item:last-child a {
  flex-direction: row-reverse;
}
.pricing-table-style-08 .nav-tabs > li.nav-item:last-child a:after {
  width: 30px;
  border-radius: 0 50em 50em 0;
}
.pricing-table-style-08 .nav-tabs > li.nav-item:last-child a:before {
  left: 2px;
}
/* Pricing table style 09 */
.pricing-table-style-09 .row {
  -webkit-transition: all 400ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 400ms cubic-bezier(0.61, 1, 0.88, 1);
}
.pricing-table-style-09 .row img, .pricing-table-style-09 .row i {
  -webkit-transition: all 400ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 400ms cubic-bezier(0.61, 1, 0.88, 1);
}
.pricing-table-style-09 .row:hover {
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.08);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.08);
}
.pricing-table-style-09 .row:hover img, .pricing-table-style-09 .row:hover i {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.pricing-table-style-09 .action {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pricing-table-style-09 .action:hover {
  margin-right: -5px;
}
/* Pricing table style 10 */
.pricing-table-style-10 .cover-background {
  filter: grayscale(0);
  -webkit-transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 700ms cubic-bezier(0.61, 1, 0.88, 1);
}
.pricing-table-style-10 .popular-label {
  position: absolute;
  top: 0;
  right: 50px;
  z-index: 1;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.pricing-table-style-10:hover .cover-background {
  filter: grayscale(100%);
}
/* Pricing table style 11 */
.pricing-table-style-11 li {
  list-style: none;
  padding: 25px 0;
  position: relative;
  -webkit-transition: all 200ms cubic-bezier(0.37, 0.8, 0.63, 1);
  transition: all 200ms cubic-bezier(0.37, 0.8, 0.63, 1);
}
.pricing-table-style-11 li .popular-label {
  position: absolute;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-table-style-11 li:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
/* Pricing table style 12 */
.pricing-table-style-12 {
  padding: 0;
  margin: 0;
  list-style: none;
}
.pricing-table-style-12 li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 0;
  -webkit-transition: all 500ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 500ms cubic-bezier(0.61, 1, 0.88, 1);
}
.pricing-table-style-12 li:last-child {
  padding-bottom: 0;
}
.pricing-table-style-12 li:hover {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}
/* Pricing table style 13 */
.pricing-table-style-13 {
  padding: 0;
  margin: 0;
  list-style: none;
}
.pricing-table-style-13 li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 0;
  -webkit-transition: all 500ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 500ms cubic-bezier(0.61, 1, 0.88, 1);
}
.pricing-table-style-13 li:last-child {
  padding-bottom: 0;
}
.pricing-table-style-13 li:hover {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}
/* Process step style 01 */
.process-step-style-01 .progress-step-separator {
  position: absolute;
  top: 50%;
  left: calc(100% + 20px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.process-step-style-01 .process-step-icon {
  position: relative;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}
.process-step-style-01:hover .box-overlay {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.process-step-style-01:hover .number, .process-step-style-01:hover .icon {
  color: var(--white);
}
.process-step-style-01:hover .border-color-transparent-on-hover {
  border-color: transparent !important;
}
/* Process step style 02 */
.process-step-style-02 .process-step-icon {
  overflow: hidden;
  position: relative;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  will-change: transform;
}
.process-step-style-02 .progress-step-separator {
  position: absolute;
  top: 50%;
  left: calc(100% + 15px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.process-step-style-02:hover .process-step-icon {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  will-change: transform;
}
/* Process step style 03 */
.process-step-style-03.hover-box * {
  will-change: auto;
  vertical-align: top;
}
.process-step-style-03 .process-step-icon-box .progress-step-separator {
  position: absolute;
  top: 50%;
  left: calc(100% + 15px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.process-step-style-03 .process-step-icon-box .number {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  top: 55%;
}
.process-step-style-03:hover .process-step-icon-box .box-overlay {
  opacity: 0.9;
}
.process-step-style-03:hover .process-step-icon-box .number {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
.process-step-style-03:hover .process-step-icon-box img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.process-step-style-03:hover .process-step-icon-box .progress-image {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* Process step style 04 */
.process-step-style-04 .process-step-icon-box {
  will-change: transform;
}
.process-step-style-04 .process-step-icon-box .progress-step-separator {
  position: absolute;
  top: 50%;
  left: calc(100% + 15px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.process-step-style-04:hover .process-step-icon-box .number, .process-step-style-04:hover .process-step-icon-box .icon {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
/* Process step style 05 */
.process-step-style-05 .process-step-item {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: flex-start;
  text-align: left;
}
.process-step-style-05 .process-content {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}
.process-step-style-05 .progress-step-separator {
  position: absolute;
  bottom: 15px;
  width: 1px;
  left: 50%;
  height: calc(100% - 100px);
}
.process-step-style-05 .process-step-icon {
  will-change: transform;
  -webkit-transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1.7);
  transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1.7);
}
.process-step-style-05:hover .process-step-icon {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.process-step-style-05:hover .number, .process-step-style-05:hover .icon {
  color: var(--white);
}
/* Process step style 07 */
.process-step-style-06 .process-step-icon-box .progress-step-separator {
  position: absolute;
  top: 50%;
  left: 0;
}
.process-step-style-06:hover .process-step-icon-box .step-box {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
/* Process step style 08 */
.process-step-style-08 .process-step-item {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 350ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 350ms cubic-bezier(0.23, 1, 0.32, 1);
}
.process-step-style-08 .process-step-item:hover {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
/* Process step style 09 */
.process-step-style-09 .progress-right-icon {
  position: absolute;
  top: 50%;
  left: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.process-step-style-09 .process-step-icon {
  position: relative;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}
.process-step-style-09:hover .box-overlay {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.process-step-style-09:hover .border-color-transparent-on-hover {
  border-color: transparent !important;
}
/* Process step style 10 */
.process-step-style-10 .process-step-icon-box {
  -webkit-transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1);
}
.process-step-style-10:hover .process-step-icon-box {
  -webkit-transform: translateX(20px) scale(1.1);
  transform: translateX(20px) scale(1.1);
}
.process-step-style-10.hover-box .hover-content {
  opacity: 0;
  position: absolute;
  left: 0;
  bottom: -15px;
}
.process-step-style-10.hover-box:hover .hover-content {
  opacity: 1;
  bottom: -5px;
}
/* Process step style 11 */
.process-step-style-11 .process-step-icon-wrap {
  z-index: 1;
  background-color: var(--base-color);
  background-size: 4px 4px;
  -webkit-transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1);
}
.process-step-style-11:hover .process-step-icon-wrap {
  background-color: var(--dark-gray);
  background-image: none;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1);
}
/* Progress bar */
.progress {
  position: relative;
  overflow: visible;
}
.progress .progress-bar {
  width: 0px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
  -webkit-transition: all 1s cubic-bezier(0.5, 1, 0.5, 1);
  transition: all 1s cubic-bezier(0.5, 1, 0.5, 1);
}
.progress .progress-bar.appear .progress-bar-percent {
  opacity: 1;
}
.progress-bar-percent {
  opacity: 0;
}
/* Progress bar style 01 */
.progress-bar-style-01 .progress {
  height: 4px;
  border-radius: 0;
}
.progress-bar-style-01 .progress .progress-bar-title {
  position: absolute;
  bottom: 10px;
}
.progress-bar-style-01 .progress .progress-bar-percent {
  position: absolute;
  bottom: 17px;
  right: -20px;
  padding: 7px;
  min-width: 40px;
  min-height: 26px;
  border-radius: 3px;
}
.progress-bar-style-01 .progress .progress-bar-percent:after {
  content: "";
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
  z-index: 9;
  margin-left: -4px;
  border-width: 4px;
  position: absolute;
  pointer-events: none;
  border: solid transparent;
  border-top-color: #232323;
}
.progress-bar-style-01 .progress .progress-bar {
  height: 100%;
}
/* Progress bar style 02 */
.progress-bar-style-02 .progress {
  height: 30px;
  overflow: hidden;
}
.progress-bar-style-02 .progress .progress-bar-percent {
  position: absolute;
  top: 9px;
  right: 20px;
  min-width: 26px;
}
.progress-bar-style-02 .progress .progress-bar-title {
  position: absolute;
  top: 9px;
  left: 20px;
}
.progress-bar-style-02 .progress .progress-bar {
  width: 0px;
  height: 100%;
  position: absolute;
}
/* Progress bar style 03 */
.progress-bar-style-03 .progress {
  height: 4px;
}
.progress-bar-style-03 .progress .progress-bar-title {
  position: absolute;
  bottom: 14px;
}
.progress-bar-style-03 .progress .progress-bar {
  height: 100%;
}
.progress-bar-style-03 .progress .progress-bar-percent {
  position: absolute;
  bottom: 14px;
  right: 0;
  opacity: 1;
}
/* Progress bar style 04 */
.progress-bar-style-04 .progress {
  height: 8px;
  border-radius: 6px;
}
.progress-bar-style-04 .progress .progress-bar-title {
  position: absolute;
  bottom: 15px;
}
.progress-bar-style-04 .progress .progress-bar {
  height: 100%;
  border-radius: 6px;
}
.progress-bar-style-04 .progress .progress-bar-percent {
  position: absolute;
  bottom: 15px;
  right: 0px;
}
.review-star-icon i {
  color: #fd961e;
  letter-spacing: 1px;
}
.review-star-icon.review-star-icon-white i {
  color: var(--white);
}
/* Review style 03 */
.review-style-03 .slider-review-image-thumbs {
  padding: 10px;
  height: auto;
}
.review-style-03 .slider-review-image-thumbs .swiper-slide:not(.swiper-slide-thumb-active) img {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.review-style-03 .slider-review-image-thumbs img {
  border: 6px solid transparent;
  margin: 0 auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.review-style-03 .slider-review-image-thumbs .swiper-slide-thumb-active img {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  border: 6px solid #ffffff;
}
.review-style-03 .slider-review-image-thumbs .swiper-slide {
  cursor: pointer;
}
/* Review style 08 */
.swiper-fade .review-style-08.swiper-slide {
  opacity: 0 !important;
}
.swiper-fade .review-style-08.swiper-slide.swiper-slide-active {
  opacity: 1 !important;
}
/* Rotate box */
.rotate-box {
  will-change: transform;
  min-height: 250px;
  -webkit-perspective: 1000;
  perspective: 1000;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box p {
  color: rgba(255, 255, 255, 0.7);
}
.rotate-box .front-side, .rotate-box .back-side {
  position: absolute;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
}
.rotate-box .rotate-content-front, .rotate-box .rotate-content-back {
  position: relative;
  top: 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
}
.rotate-box.to-left .front-side, .rotate-box.to-right .front-side {
  z-index: 1;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box.to-left .rotate-content-front, .rotate-box.to-right .rotate-content-front {
  -webkit-transform: translateY(-50%) translateX(0px) scale(1);
  transform: translateY(-50%) translateX(0px) scale(1);
}
.rotate-box.to-left:hover .back-side, .rotate-box.to-right:hover .back-side {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box.to-left:hover .rotate-content-back, .rotate-box.to-right:hover .rotate-content-back {
  -webkit-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
  transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box.to-left .back-side {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}
.rotate-box.to-left .rotate-content-back {
  -webkit-transform: translateY(-50%) translateX(400px) translateZ(60px) scale(0.88);
  transform: translateY(-50%) translateX(400px) translateZ(60px) scale(0.88);
}
.rotate-box.to-left:hover .front-side {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box.to-left:hover .rotate-content-front {
  -webkit-transform: translateY(-50%) translateX(-400px) scale(0.88);
  transform: translateY(-50%) translateX(-400px) scale(0.88);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box.to-right .back-side {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}
.rotate-box.to-right .rotate-content-back {
  -webkit-transform: translateY(-50%) translateX(-400px) translateZ(60px) scale(0.88);
  transform: translateY(-50%) translateX(-400px) translateZ(60px) scale(0.88);
}
.rotate-box.to-right:hover .front-side {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box.to-right:hover .rotate-content-front {
  -webkit-transform: translateY(-50%) translateX(400px) scale(0.88);
  transform: translateY(-50%) translateX(400px) scale(0.88);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box.to-top .front-side, .rotate-box.to-bottom .front-side {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box.to-top .rotate-content-front, .rotate-box.to-bottom .rotate-content-front {
  -webkit-transform: translateY(-50%) translateX(0px) scale(1);
  transform: translateY(-50%) translateX(0px) scale(1);
}
.rotate-box.to-top:hover .back-side, .rotate-box.to-bottom:hover .back-side {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}
.rotate-box.to-top:hover .rotate-content-front, .rotate-box.to-bottom:hover .rotate-content-front {
  -webkit-transform: translateY(-50%) translateY(400px) scale(0.88);
  transform: translateY(-50%) translateY(400px) scale(0.88);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box.to-top:hover .rotate-content-back, .rotate-box.to-bottom:hover .rotate-content-back {
  -webkit-transform: translateY(-50%) translateY(0px) translateZ(0px) scale(1);
  transform: translateY(-50%) translateY(0px) translateZ(0px) scale(1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box.to-top .back-side {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}
.rotate-box.to-top .rotate-content-back {
  -webkit-transform: translateY(-50%) translateY(-400px) translateZ(60px) scale(0.88);
  transform: translateY(-50%) translateY(-400px) translateZ(60px) scale(0.88);
}
.rotate-box.to-top:hover .front-side {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.rotate-box.to-bottom .back-side {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}
.rotate-box.to-bottom .rotate-content-back {
  -webkit-transform: translateY(-50%) translateY(400px) translateZ(60px) scale(0.88);
  transform: translateY(-50%) translateY(400px) translateZ(60px) scale(0.88);
}
.rotate-box.to-bottom:hover .front-side {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.to-top {
  animation: toTop 0.3s linear forwards;
}
.to-right {
  animation: toRight 0.3s linear forwards;
}
.to-bottom {
  animation: toBottom 0.3s linear forwards;
}
.to-left {
  animation: toLeft 0.3s linear forwards;
}
/* Rotate box style 01 */
.rotate-box-style-01 * {
  will-change: transform;
}
.rotate-box-style-01 .rm-rotate-box {
  position: relative;
}
.rotate-box-style-01 .flipper {
  position: relative;
}
.rotate-box-style-01 .flipper:hover .front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.rotate-box-style-01 .flipper:hover .back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.rotate-box-style-01 .flipper.to-right .front {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.rotate-box-style-01 .flipper.to-right .back {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.rotate-box-style-01 .flipper.to-right:hover .front {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.rotate-box-style-01 .flipper.to-right:hover .back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.rotate-box-style-01 .flipper.to-top .front {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.rotate-box-style-01 .flipper.to-top .back {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}
.rotate-box-style-01 .flipper.to-top:hover .front {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.rotate-box-style-01 .flipper.to-top:hover .back {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.rotate-box-style-01 .flipper.to-bottom .front {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.rotate-box-style-01 .flipper.to-bottom .back {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.rotate-box-style-01 .flipper.to-bottom:hover .front {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}
.rotate-box-style-01 .flipper.to-bottom:hover .back {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.rotate-box-style-01 .thumb-wrap {
  perspective: 1000px;
}
.rotate-box-style-01 .content-wrap {
  position: absolute;
  width: 100%;
  display: block;
  transform-style: preserve-3d;
  perspective: inherit;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  z-index: 2;
  bottom: 55px;
  left: 0;
  transform: translate3d(0, 0, 0) scale(1);
}
.rotate-box-style-01 .front {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  background-size: cover;
  z-index: 1;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: inherit;
  transition: transform 0.8s cubic-bezier(0.5, 0.2, 0.2, 0.8);
}
.rotate-box-style-01 .back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: inherit;
  transition: transform 0.8s cubic-bezier(0.5, 0.2, 0.2, 0.8);
}
.rotate-box-style-01 .back .content-wrap {
  top: 50%;
  bottom: inherit;
  left: 0;
  transform: translate3d(0, -50%, 0) scale(1);
}
.rotate-box-style-01 .back .box-overlay {
  visibility: visible;
  opacity: 1;
}
/* Rotate box style 02 */
.rotate-box-style-02 .rotate-box {
  min-height: 350px;
}
/* Services box style 01 */
.services-box-style-01 .icon-box {
  opacity: 0;
  margin-top: 15px;
}
.services-box-style-01 .box-image {
  overflow: hidden;
}
.services-box-style-01 .box-image img {
  width: 100%;
}
.services-box-style-01 .box-overlay {
  z-index: 1;
  -webkit-transition: all 500ms cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 500ms cubic-bezier(0.61, 1, 0.88, 1);
}
.services-box-style-01:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 500ms cubic-bezier(61, 10, 88, 10);
  transition: all 500ms cubic-bezier(61, 10, 88, 10);
}
.services-box-style-01:hover .icon-box {
  opacity: 1;
  margin-top: 0;
}
.services-box-style-01:hover .box-overlay {
  opacity: 0.8;
}
/* Services box style 02 */
.services-box-style-02 .box-overlay {
  z-index: 1;
  -webkit-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}
.services-box-style-02.hover-box:hover .box-overlay {
  opacity: 1;
}
/* Services box style 05 */
.services-box-style-05 img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
}
.services-box-style-05 figure {
  position: relative;
}
.services-box-style-05 figure figcaption {
  position: absolute;
  top: 0;
}
.services-box-style-05 figure figcaption a {
  display: inline-block;
  color: var(--dark-gray);
}
.services-box-style-05 figure figcaption .content-box {
  margin-bottom: -80px;
}
.services-box-style-05 figure:hover figcaption .btn-link {
  color: var(--white);
  border-color: var(--white);
}
.services-box-style-05 figure:hover figcaption .box-title {
  color: var(--white);
}
.services-box-style-05 figure:hover figcaption .box-overlay {
  opacity: 1;
  visibility: visible;
}
/* Services box style 06 */
.services-box-style-06 .services-text:after {
  content: "\f309";
  display: inline-block;
  font-family: bootstrap-icons;
  vertical-align: middle;
  margin: -2px 2px 0;
  font-size: 22px;
  color: var(--medium-gray);
  opacity: 0.7;
}
.services-box-style-06:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 500ms cubic-bezier(61, 10, 88, 10);
  transition: all 500ms cubic-bezier(61, 10, 88, 10);
}
/* Services box style 07 */
.services-box-style-07 .btn-hover {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.services-box-style-07 .text-down {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.services-box-style-07:hover .btn-hover {
  top: 0;
}
.services-box-style-07:hover .text-down {
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}
/* Sliding box zoom effect */
@keyframes scale-effect {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes scale-effect {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* Sliding box fade effect */
@keyframes fade {
  0% {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
/* Sliding box right effect */
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
/* Sliding box */
.sliding-box * {
  will-change: transform;
}
.sliding-box .sliding-box-item {
  margin-left: 15px;
  margin-right: 15px;
  float: left;
  overflow: hidden;
  position: relative;
  -webkit-transition: width 0.7s;
  transition: width 0.7s;
}
.sliding-box .sliding-box-item .sliding-box-content {
  top: 0;
  height: 100%;
  position: absolute;
}
/* Sliding box style 01 */
.sliding-box-style-01 .sliding-box-item .sliding-box-content:after {
  content: "";
  right: 100%;
  top: 50%;
  height: 0;
  width: 0;
  opacity: 0;
  z-index: 9;
  margin-top: -10px;
  position: absolute;
  border: solid transparent;
  border-right-color: var(--white);
  border-width: 12px;
  pointer-events: none;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.sliding-box-style-01 .sliding-box-item.active .sliding-box-content:after, .sliding-box-style-01 .sliding-box-item:hover .sliding-box-content:after {
  opacity: 1;
}
/* Sliding box style 02 */
.sliding-box-style-02 .sliding-box-item .sliding-box-img {
  border-radius: 4px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.sliding-box-style-02 .sliding-box-item.active .sliding-box-img {
  border-radius: 4px 0 0 4px;
}
.sliding-box-style-02 .sliding-box-item.active .sliding-box-content {
  border-radius: 0 4px 4px 0;
}
.sliding-box-style-02 .sliding-box-item.active .content-hover {
  animation: fade 1s;
}
/* Sliding box style 03 */
.sliding-box-style-03 .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.sliding-box-style-03 .overlay-contnet {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.sliding-box-style-03 .number {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  text-align: center;
}
.sliding-box-style-03 .sliding-box-content:after {
  display: none;
}
.sliding-box-style-03.active .overlay {
  opacity: 0;
  visibility: hidden;
}
.sliding-box-style-03.active .overlay-contnet {
  opacity: 0;
  visibility: hidden;
}
/* Social media icon */
.elements-social .behance:hover {
  color: #1769ff;
}
.elements-social .behance-square:hover {
  color: #1769ff;
}
.elements-social .blogger:hover {
  color: #ff8000;
}
.elements-social .codepen:hover {
  color: #0ebeff;
}
.elements-social .deviantart:hover {
  color: #05cc47;
}
.elements-social .digg:hover {
  color: #262626;
}
.elements-social .dribbble:hover {
  color: #ea4c89;
}
.elements-social .delicious:hover {
  color: #205cc0;
}
.elements-social .email:hover {
  color: #7d7d7d;
}
.elements-social .facebook:hover {
  color: #3b5998;
}
.elements-social .facebook-square:hover {
  color: #3b5998;
}
.elements-social .flickr:hover {
  color: #ff0084;
}
.elements-social .flipboard:hover {
  color: #e12828;
}
.elements-social .github:hover {
  color: #4078c0;
}
.elements-social .google:hover {
  color: #dc4a38;
}
.elements-social .google-plus:hover {
  color: #dd4b39;
}
.elements-social .instagram:hover {
  color: #FE1F49;
}
.elements-social .jsfiddle:hover {
  color: #0084FF;
}
.elements-social .linkedin:hover {
  color: #0077b5;
}
.elements-social .livejournal:hover {
  color: #00b0ea;
}
.elements-social .medium:hover {
  color: #00ab6c;
}
.elements-social .meneame:hover {
  color: #ff6400;
}
.elements-social .mailru:hover {
  color: #168de2;
}
.elements-social .odnoklassniki:hover {
  color: #d7772d;
}
.elements-social .paypal:hover {
  color: #003087;
}
.elements-social .pinterest:hover {
  color: #CB2027;
}
.elements-social .pinterest-p:hover {
  color: #CB2027;
}
.elements-social .print:hover {
  color: #222222;
}
.elements-social .reddit:hover {
  color: #ff4500;
}
.elements-social .reddit-square:hover {
  color: #ff4500;
}
.elements-social .rss:hover {
  color: #f2890a;
}
.elements-social .skype:hover {
  color: #00aff0;
}
.elements-social .slack:hover {
  color: #6ecadc;
}
.elements-social .snapchat:hover {
  color: #fffc00;
}
.elements-social .soundcloud:hover {
  color: #ff8800;
}
.elements-social .spotify:hover {
  color: #1db954;
}
.elements-social .sms:hover {
  color: #ffbd00;
}
.elements-social .sharethis:hover {
  color: #95D03A;
}
.elements-social .stack-overflow:hover {
  color: #f48024;
}
.elements-social .stumbleupon:hover {
  color: #eb4924;
}
.elements-social .telegram:hover {
  color: #0088cc;
}
.elements-social .trello:hover {
  color: #0079bf;
}
.elements-social .tumblr:hover {
  color: #32506d;
}
.elements-social .twitch:hover {
  color: #6441a5;
}
.elements-social .twitter:hover {
  color: #00aced;
}
.elements-social .twitter-square:hover {
  color: #00aced;
}
.elements-social .vimeo:hover {
  color: #1ab7ea;
}
.elements-social .vine:hover {
  color: #00bf8f;
}
.elements-social .vk:hover {
  color: #4c6c91;
}
.elements-social .wordpress:hover {
  color: #21759b;
}
.elements-social .weibo:hover {
  color: #ff9933;
}
.elements-social .whatsapp:hover {
  color: #25d366;
}
.elements-social .xing:hover {
  color: #1a7576;
}
.elements-social .youtube:hover {
  color: #ff0000;
}
.elements-social .youtube-play:hover {
  color: #ff0000;
}
.elements-social ul {
  margin-bottom: 0;
  list-style: none;
  padding: 0;
}
.elements-social ul li {
  display: inline-block;
}
.elements-social ul li:first-child {
  margin-left: 0 !important;
}
.elements-social ul li:last-child {
  margin-right: 0 !important;
}
.elements-social ul li a {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
}
.extra-small-icon li {
  margin: 0 2px;
}
.extra-small-icon a {
  font-size: 14px;
  width: 30px;
  height: 30px;
}
.small-icon li {
  margin: 0 5px;
}
.small-icon a {
  font-size: 18px;
  width: 40px;
  height: 40px;
}
.medium-icon li {
  margin: 0 8px;
}
.medium-icon a {
  font-size: 20px;
  width: 50px;
  height: 50px;
}
.large-icon li {
  margin: 0 10px;
}
.large-icon a {
  font-size: 24px;
  width: 60px;
  height: 60px;
}
.extra-large-icon li {
  margin: 0 12px;
}
.extra-large-icon a {
  font-size: 30px;
  width: 70px;
  height: 70px;
}
.social-icon a {
  margin: 0 15px;
}
.social-icon a:first-child {
  margin-left: 0;
}
.social-icon a:last-child {
  margin-right: 0;
}
[class^=social-icon-] ul, [class*=" social-icon-"] ul {
  list-style: none;
}
/*  Social icon dark and light */
.elements-social .light li a {
  color: var(--white);
}
.elements-social .dark li a {
  color: var(--dark-gray);
}
.elements-social .icon-with-animation a:hover {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
/* Social icon style 01 */
.social-icon-style-01 ul li a:hover {
  color: var(--base-color) !important;
}
/* Social icon style 03 */
.social-icon-style-03 ul li a {
  color: var(--white);
  border-radius: 100%;
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
.social-icon-style-03 ul li a:hover {
  background-color: var(--white) !important;
  box-shadow: 0 0 30px rgba(23, 23, 23, 0.25);
  transform: translate3d(0, -3px, 0);
}
.social-icon-style-03 ul li a.behance {
  background-color: #1769ff;
}
.social-icon-style-03 ul li a.behance-square {
  background-color: #1769ff;
}
.social-icon-style-03 ul li a.blogger {
  background-color: #ff8000;
}
.social-icon-style-03 ul li a.codepen {
  background-color: #0ebeff;
}
.social-icon-style-03 ul li a.deviantart {
  background-color: #05cc47;
}
.social-icon-style-03 ul li a.digg {
  background-color: #262626;
}
.social-icon-style-03 ul li a.dribbble {
  background-color: #ea4c89;
}
.social-icon-style-03 ul li a.delicious {
  background-color: #205cc0;
}
.social-icon-style-03 ul li a.email {
  background-color: #7d7d7d;
}
.social-icon-style-03 ul li a.facebook {
  background-color: #3b5998;
}
.social-icon-style-03 ul li a.facebook-square {
  background-color: #3b5998;
}
.social-icon-style-03 ul li a.flickr {
  background-color: #ff0084;
}
.social-icon-style-03 ul li a.flipboard {
  background-color: #e12828;
}
.social-icon-style-03 ul li a.github {
  background-color: #4078c0;
}
.social-icon-style-03 ul li a.google {
  background-color: #dc4a38;
}
.social-icon-style-03 ul li a.google-plus {
  background-color: #dd4b39;
}
.social-icon-style-03 ul li a.instagram {
  background-color: #FE1F49;
}
.social-icon-style-03 ul li a.jsfiddle {
  background-color: #0084FF;
}
.social-icon-style-03 ul li a.linkedin {
  background-color: #0077b5;
}
.social-icon-style-03 ul li a.livejournal {
  background-color: #00b0ea;
}
.social-icon-style-03 ul li a.medium {
  background-color: #00ab6c;
}
.social-icon-style-03 ul li a.meneame {
  background-color: #ff6400;
}
.social-icon-style-03 ul li a.mailru {
  background-color: #168de2;
}
.social-icon-style-03 ul li a.odnoklassniki {
  background-color: #d7772d;
}
.social-icon-style-03 ul li a.paypal {
  background-color: #003087;
}
.social-icon-style-03 ul li a.pinterest {
  background-color: #CB2027;
}
.social-icon-style-03 ul li a.pinterest-p {
  background-color: #CB2027;
}
.social-icon-style-03 ul li a.print {
  background-color: #222222;
}
.social-icon-style-03 ul li a.reddit {
  background-color: #ff4500;
}
.social-icon-style-03 ul li a.reddit-square {
  background-color: #ff4500;
}
.social-icon-style-03 ul li a.rss {
  background-color: #f2890a;
}
.social-icon-style-03 ul li a.skype {
  background-color: #00aff0;
}
.social-icon-style-03 ul li a.slack {
  background-color: #6ecadc;
}
.social-icon-style-03 ul li a.snapchat {
  background-color: #fffc00;
}
.social-icon-style-03 ul li a.soundcloud {
  background-color: #ff8800;
}
.social-icon-style-03 ul li a.spotify {
  background-color: #1db954;
}
.social-icon-style-03 ul li a.sms {
  background-color: #ffbd00;
}
.social-icon-style-03 ul li a.sharethis {
  background-color: #95D03A;
}
.social-icon-style-03 ul li a.stack-overflow {
  background-color: #f48024;
}
.social-icon-style-03 ul li a.stumbleupon {
  background-color: #eb4924;
}
.social-icon-style-03 ul li a.telegram {
  background-color: #0088cc;
}
.social-icon-style-03 ul li a.trello {
  background-color: #0079bf;
}
.social-icon-style-03 ul li a.tumblr {
  background-color: #32506d;
}
.social-icon-style-03 ul li a.twitch {
  background-color: #6441a5;
}
.social-icon-style-03 ul li a.twitter {
  background-color: #00aced;
}
.social-icon-style-03 ul li a.twitter-square {
  background-color: #00aced;
}
.social-icon-style-03 ul li a.vimeo {
  background-color: #1ab7ea;
}
.social-icon-style-03 ul li a.vine {
  background-color: #00bf8f;
}
.social-icon-style-03 ul li a.vk {
  background-color: #4c6c91;
}
.social-icon-style-03 ul li a.wordpress {
  background-color: #21759b;
}
.social-icon-style-03 ul li a.weibo {
  background-color: #ff9933;
}
.social-icon-style-03 ul li a.whatsapp {
  background-color: #25d366;
}
.social-icon-style-03 ul li a.xing {
  background-color: #1a7576;
}
.social-icon-style-03 ul li a.youtube {
  background-color: #ff0000;
}
.social-icon-style-03 ul li a.youtube-play {
  background-color: #ff0000;
}
/* Social icon style 04 */
.social-icon-style-04 ul li a {
  z-index: 9;
  position: relative;
}
.social-icon-style-04 ul li a:hover {
  color: var(--white) !important;
}
.social-icon-style-04 ul li a:hover span {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  top: 0;
  left: 0;
}
.social-icon-style-04 ul li a span {
  border-radius: 0;
  display: block;
  height: 0;
  width: 0;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  z-index: -1;
  background-color: var(--dark-gray);
}
.social-icon-style-04 ul li a.behance span {
  background-color: #1769ff;
}
.social-icon-style-04 ul li a.behance-square span {
  background-color: #1769ff;
}
.social-icon-style-04 ul li a.blogger span {
  background-color: #ff8000;
}
.social-icon-style-04 ul li a.codepen span {
  background-color: #0ebeff;
}
.social-icon-style-04 ul li a.deviantart span {
  background-color: #05cc47;
}
.social-icon-style-04 ul li a.digg span {
  background-color: #262626;
}
.social-icon-style-04 ul li a.dribbble span {
  background-color: #ea4c89;
}
.social-icon-style-04 ul li a.delicious span {
  background-color: #205cc0;
}
.social-icon-style-04 ul li a.email span {
  background-color: #7d7d7d;
}
.social-icon-style-04 ul li a.facebook span {
  background-color: #3b5998;
}
.social-icon-style-04 ul li a.facebook-square span {
  background-color: #3b5998;
}
.social-icon-style-04 ul li a.flickr span {
  background-color: #ff0084;
}
.social-icon-style-04 ul li a.flipboard span {
  background-color: #e12828;
}
.social-icon-style-04 ul li a.github span {
  background-color: #4078c0;
}
.social-icon-style-04 ul li a.google span {
  background-color: #dc4a38;
}
.social-icon-style-04 ul li a.google-plus span {
  background-color: #dd4b39;
}
.social-icon-style-04 ul li a.instagram span {
  background-color: #FE1F49;
}
.social-icon-style-04 ul li a.jsfiddle span {
  background-color: #0084FF;
}
.social-icon-style-04 ul li a.linkedin span {
  background-color: #0077b5;
}
.social-icon-style-04 ul li a.livejournal span {
  background-color: #00b0ea;
}
.social-icon-style-04 ul li a.medium span {
  background-color: #00ab6c;
}
.social-icon-style-04 ul li a.meneame span {
  background-color: #ff6400;
}
.social-icon-style-04 ul li a.mailru span {
  background-color: #168de2;
}
.social-icon-style-04 ul li a.odnoklassniki span {
  background-color: #d7772d;
}
.social-icon-style-04 ul li a.paypal span {
  background-color: #003087;
}
.social-icon-style-04 ul li a.pinterest span {
  background-color: #CB2027;
}
.social-icon-style-04 ul li a.pinterest-p span {
  background-color: #CB2027;
}
.social-icon-style-04 ul li a.print span {
  background-color: #222222;
}
.social-icon-style-04 ul li a.reddit span {
  background-color: #ff4500;
}
.social-icon-style-04 ul li a.reddit-square span {
  background-color: #ff4500;
}
.social-icon-style-04 ul li a.rss span {
  background-color: #f2890a;
}
.social-icon-style-04 ul li a.skype span {
  background-color: #00aff0;
}
.social-icon-style-04 ul li a.slack span {
  background-color: #6ecadc;
}
.social-icon-style-04 ul li a.snapchat span {
  background-color: #fffc00;
}
.social-icon-style-04 ul li a.soundcloud span {
  background-color: #ff8800;
}
.social-icon-style-04 ul li a.spotify span {
  background-color: #1db954;
}
.social-icon-style-04 ul li a.sms span {
  background-color: #ffbd00;
}
.social-icon-style-04 ul li a.sharethis span {
  background-color: #95D03A;
}
.social-icon-style-04 ul li a.stack-overflow span {
  background-color: #f48024;
}
.social-icon-style-04 ul li a.stumbleupon span {
  background-color: #eb4924;
}
.social-icon-style-04 ul li a.telegram span {
  background-color: #0088cc;
}
.social-icon-style-04 ul li a.trello span {
  background-color: #0079bf;
}
.social-icon-style-04 ul li a.tumblr span {
  background-color: #32506d;
}
.social-icon-style-04 ul li a.twitch span {
  background-color: #6441a5;
}
.social-icon-style-04 ul li a.twitter span {
  background-color: #00aced;
}
.social-icon-style-04 ul li a.twitter-square span {
  background-color: #00aced;
}
.social-icon-style-04 ul li a.vimeo span {
  background-color: #1ab7ea;
}
.social-icon-style-04 ul li a.vine span {
  background-color: #00bf8f;
}
.social-icon-style-04 ul li a.vk span {
  background-color: #4c6c91;
}
.social-icon-style-04 ul li a.wordpress span {
  background-color: #21759b;
}
.social-icon-style-04 ul li a.weibo span {
  background-color: #ff9933;
}
.social-icon-style-04 ul li a.whatsapp span {
  background-color: #25d366;
}
.social-icon-style-04 ul li a.xing span {
  background-color: #1a7576;
}
.social-icon-style-04 ul li a.youtube span {
  background-color: #ff0000;
}
.social-icon-style-04 ul li a.youtube-play span {
  background-color: #ff0000;
}
/* Social icon style 05 */
.social-icon-style-05 ul li a:hover {
  color: var(--white) !important;
}
.social-icon-style-05 ul li a.behance {
  color: #1769ff;
}
.social-icon-style-05 ul li a.behance-square {
  color: #1769ff;
}
.social-icon-style-05 ul li a.blogger {
  color: #ff8000;
}
.social-icon-style-05 ul li a.codepen {
  color: #0ebeff;
}
.social-icon-style-05 ul li a.deviantart {
  color: #05cc47;
}
.social-icon-style-05 ul li a.digg {
  color: #262626;
}
.social-icon-style-05 ul li a.dribbble {
  color: #ea4c89;
}
.social-icon-style-05 ul li a.delicious {
  color: #205cc0;
}
.social-icon-style-05 ul li a.email {
  color: #7d7d7d;
}
.social-icon-style-05 ul li a.facebook {
  color: #3b5998;
}
.social-icon-style-05 ul li a.facebook-square {
  color: #3b5998;
}
.social-icon-style-05 ul li a.flickr {
  color: #ff0084;
}
.social-icon-style-05 ul li a.flipboard {
  color: #e12828;
}
.social-icon-style-05 ul li a.github {
  color: #4078c0;
}
.social-icon-style-05 ul li a.google {
  color: #dc4a38;
}
.social-icon-style-05 ul li a.google-plus {
  color: #dd4b39;
}
.social-icon-style-05 ul li a.instagram {
  color: #FE1F49;
}
.social-icon-style-05 ul li a.jsfiddle {
  color: #0084FF;
}
.social-icon-style-05 ul li a.linkedin {
  color: #0077b5;
}
.social-icon-style-05 ul li a.livejournal {
  color: #00b0ea;
}
.social-icon-style-05 ul li a.medium {
  color: #00ab6c;
}
.social-icon-style-05 ul li a.meneame {
  color: #ff6400;
}
.social-icon-style-05 ul li a.mailru {
  color: #168de2;
}
.social-icon-style-05 ul li a.odnoklassniki {
  color: #d7772d;
}
.social-icon-style-05 ul li a.paypal {
  color: #003087;
}
.social-icon-style-05 ul li a.pinterest {
  color: #CB2027;
}
.social-icon-style-05 ul li a.pinterest-p {
  color: #CB2027;
}
.social-icon-style-05 ul li a.print {
  color: #222222;
}
.social-icon-style-05 ul li a.reddit {
  color: #ff4500;
}
.social-icon-style-05 ul li a.reddit-square {
  color: #ff4500;
}
.social-icon-style-05 ul li a.rss {
  color: #f2890a;
}
.social-icon-style-05 ul li a.skype {
  color: #00aff0;
}
.social-icon-style-05 ul li a.slack {
  color: #6ecadc;
}
.social-icon-style-05 ul li a.snapchat {
  color: #fffc00;
}
.social-icon-style-05 ul li a.soundcloud {
  color: #ff8800;
}
.social-icon-style-05 ul li a.spotify {
  color: #1db954;
}
.social-icon-style-05 ul li a.sms {
  color: #ffbd00;
}
.social-icon-style-05 ul li a.sharethis {
  color: #95D03A;
}
.social-icon-style-05 ul li a.stack-overflow {
  color: #f48024;
}
.social-icon-style-05 ul li a.stumbleupon {
  color: #eb4924;
}
.social-icon-style-05 ul li a.telegram {
  color: #0088cc;
}
.social-icon-style-05 ul li a.trello {
  color: #0079bf;
}
.social-icon-style-05 ul li a.tumblr {
  color: #32506d;
}
.social-icon-style-05 ul li a.twitch {
  color: #6441a5;
}
.social-icon-style-05 ul li a.twitter {
  color: #00aced;
}
.social-icon-style-05 ul li a.twitter-square {
  color: #00aced;
}
.social-icon-style-05 ul li a.vimeo {
  color: #1ab7ea;
}
.social-icon-style-05 ul li a.vine {
  color: #00bf8f;
}
.social-icon-style-05 ul li a.vk {
  color: #4c6c91;
}
.social-icon-style-05 ul li a.wordpress {
  color: #21759b;
}
.social-icon-style-05 ul li a.weibo {
  color: #ff9933;
}
.social-icon-style-05 ul li a.whatsapp {
  color: #25d366;
}
.social-icon-style-05 ul li a.xing {
  color: #1a7576;
}
.social-icon-style-05 ul li a.youtube {
  color: #ff0000;
}
.social-icon-style-05 ul li a.youtube-play {
  color: #ff0000;
}
.social-icon-style-05 ul.dark li a:hover {
  color: var(--dark-gray) !important;
}
/* Social icon style 06 */
.social-icon-style-06 ul li a {
  display: inline-block;
  height: auto;
  width: auto;
  padding: 0 24px;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
}
.social-icon-style-06 ul li a .brand-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 100%;
  transition-duration: 0.3s;
}
.social-icon-style-06 ul li a > span {
  line-height: inherit;
  top: 0px;
  position: relative;
  transition: 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275) all;
}
.social-icon-style-06 ul li a:hover .brand-label {
  top: -50px;
  transition: 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275) all;
}
.social-icon-style-06 ul li a:hover .brand-icon {
  top: 0;
  transition-duration: 0.3s;
}
.social-icon-style-06 ul li a.behance {
  color: #1769ff;
}
.social-icon-style-06 ul li a.behance-square {
  color: #1769ff;
}
.social-icon-style-06 ul li a.blogger {
  color: #ff8000;
}
.social-icon-style-06 ul li a.codepen {
  color: #0ebeff;
}
.social-icon-style-06 ul li a.deviantart {
  color: #05cc47;
}
.social-icon-style-06 ul li a.digg {
  color: #262626;
}
.social-icon-style-06 ul li a.dribbble {
  color: #ea4c89;
}
.social-icon-style-06 ul li a.delicious {
  color: #205cc0;
}
.social-icon-style-06 ul li a.email {
  color: #7d7d7d;
}
.social-icon-style-06 ul li a.facebook {
  color: #3b5998;
}
.social-icon-style-06 ul li a.facebook-square {
  color: #3b5998;
}
.social-icon-style-06 ul li a.flickr {
  color: #ff0084;
}
.social-icon-style-06 ul li a.flipboard {
  color: #e12828;
}
.social-icon-style-06 ul li a.github {
  color: #4078c0;
}
.social-icon-style-06 ul li a.google {
  color: #dc4a38;
}
.social-icon-style-06 ul li a.google-plus {
  color: #dd4b39;
}
.social-icon-style-06 ul li a.instagram {
  color: #FE1F49;
}
.social-icon-style-06 ul li a.jsfiddle {
  color: #0084FF;
}
.social-icon-style-06 ul li a.linkedin {
  color: #0077b5;
}
.social-icon-style-06 ul li a.livejournal {
  color: #00b0ea;
}
.social-icon-style-06 ul li a.medium {
  color: #00ab6c;
}
.social-icon-style-06 ul li a.meneame {
  color: #ff6400;
}
.social-icon-style-06 ul li a.mailru {
  color: #168de2;
}
.social-icon-style-06 ul li a.odnoklassniki {
  color: #d7772d;
}
.social-icon-style-06 ul li a.paypal {
  color: #003087;
}
.social-icon-style-06 ul li a.pinterest {
  color: #CB2027;
}
.social-icon-style-06 ul li a.pinterest-p {
  color: #CB2027;
}
.social-icon-style-06 ul li a.print {
  color: #222222;
}
.social-icon-style-06 ul li a.reddit {
  color: #ff4500;
}
.social-icon-style-06 ul li a.reddit-square {
  color: #ff4500;
}
.social-icon-style-06 ul li a.rss {
  color: #f2890a;
}
.social-icon-style-06 ul li a.skype {
  color: #00aff0;
}
.social-icon-style-06 ul li a.slack {
  color: #6ecadc;
}
.social-icon-style-06 ul li a.snapchat {
  color: #fffc00;
}
.social-icon-style-06 ul li a.soundcloud {
  color: #ff8800;
}
.social-icon-style-06 ul li a.spotify {
  color: #1db954;
}
.social-icon-style-06 ul li a.sms {
  color: #ffbd00;
}
.social-icon-style-06 ul li a.sharethis {
  color: #95D03A;
}
.social-icon-style-06 ul li a.stack-overflow {
  color: #f48024;
}
.social-icon-style-06 ul li a.stumbleupon {
  color: #eb4924;
}
.social-icon-style-06 ul li a.telegram {
  color: #0088cc;
}
.social-icon-style-06 ul li a.trello {
  color: #0079bf;
}
.social-icon-style-06 ul li a.tumblr {
  color: #32506d;
}
.social-icon-style-06 ul li a.twitch {
  color: #6441a5;
}
.social-icon-style-06 ul li a.twitter {
  color: #00aced;
}
.social-icon-style-06 ul li a.twitter-square {
  color: #00aced;
}
.social-icon-style-06 ul li a.vimeo {
  color: #1ab7ea;
}
.social-icon-style-06 ul li a.vine {
  color: #00bf8f;
}
.social-icon-style-06 ul li a.vk {
  color: #4c6c91;
}
.social-icon-style-06 ul li a.wordpress {
  color: #21759b;
}
.social-icon-style-06 ul li a.weibo {
  color: #ff9933;
}
.social-icon-style-06 ul li a.whatsapp {
  color: #25d366;
}
.social-icon-style-06 ul li a.xing {
  color: #1a7576;
}
.social-icon-style-06 ul li a.youtube {
  color: #ff0000;
}
.social-icon-style-06 ul li a.youtube-play {
  color: #ff0000;
}
/* Social icon style 07 */
.social-icon-style-07 ul li a {
  position: relative;
  z-index: 3;
  overflow: hidden;
  vertical-align: middle;
}
.social-icon-style-07 ul li a:hover {
  color: var(--white) !important;
}
.social-icon-style-07 ul li a:hover > span {
  top: 0;
}
.social-icon-style-07 ul li a > span {
  position: absolute;
  top: -100%;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  -webkit-transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
}
.social-icon-style-07 ul li a.behance {
  color: #1769ff;
}
.social-icon-style-07 ul li a.behance span {
  background-color: #1769ff;
}
.social-icon-style-07 ul li a.behance-square {
  color: #1769ff;
}
.social-icon-style-07 ul li a.behance-square span {
  background-color: #1769ff;
}
.social-icon-style-07 ul li a.blogger {
  color: #ff8000;
}
.social-icon-style-07 ul li a.blogger span {
  background-color: #ff8000;
}
.social-icon-style-07 ul li a.codepen {
  color: #0ebeff;
}
.social-icon-style-07 ul li a.codepen span {
  background-color: #0ebeff;
}
.social-icon-style-07 ul li a.deviantart {
  color: #05cc47;
}
.social-icon-style-07 ul li a.deviantart span {
  background-color: #05cc47;
}
.social-icon-style-07 ul li a.digg {
  color: #262626;
}
.social-icon-style-07 ul li a.digg span {
  background-color: #262626;
}
.social-icon-style-07 ul li a.dribbble {
  color: #ea4c89;
}
.social-icon-style-07 ul li a.dribbble span {
  background-color: #ea4c89;
}
.social-icon-style-07 ul li a.delicious {
  color: #205cc0;
}
.social-icon-style-07 ul li a.delicious span {
  background-color: #205cc0;
}
.social-icon-style-07 ul li a.email {
  color: #7d7d7d;
}
.social-icon-style-07 ul li a.email span {
  background-color: #7d7d7d;
}
.social-icon-style-07 ul li a.facebook {
  color: #3b5998;
}
.social-icon-style-07 ul li a.facebook span {
  background-color: #3b5998;
}
.social-icon-style-07 ul li a.facebook-square {
  color: #3b5998;
}
.social-icon-style-07 ul li a.facebook-square span {
  background-color: #3b5998;
}
.social-icon-style-07 ul li a.flickr {
  color: #ff0084;
}
.social-icon-style-07 ul li a.flickr span {
  background-color: #ff0084;
}
.social-icon-style-07 ul li a.flipboard {
  color: #e12828;
}
.social-icon-style-07 ul li a.flipboard span {
  background-color: #e12828;
}
.social-icon-style-07 ul li a.github {
  color: #4078c0;
}
.social-icon-style-07 ul li a.github span {
  background-color: #4078c0;
}
.social-icon-style-07 ul li a.google {
  color: #dc4a38;
}
.social-icon-style-07 ul li a.google span {
  background-color: #dc4a38;
}
.social-icon-style-07 ul li a.google-plus {
  color: #dd4b39;
}
.social-icon-style-07 ul li a.google-plus span {
  background-color: #dd4b39;
}
.social-icon-style-07 ul li a.instagram {
  color: #FE1F49;
}
.social-icon-style-07 ul li a.instagram span {
  background-color: #FE1F49;
}
.social-icon-style-07 ul li a.jsfiddle {
  color: #0084FF;
}
.social-icon-style-07 ul li a.jsfiddle span {
  background-color: #0084FF;
}
.social-icon-style-07 ul li a.linkedin {
  color: #0077b5;
}
.social-icon-style-07 ul li a.linkedin span {
  background-color: #0077b5;
}
.social-icon-style-07 ul li a.livejournal {
  color: #00b0ea;
}
.social-icon-style-07 ul li a.livejournal span {
  background-color: #00b0ea;
}
.social-icon-style-07 ul li a.medium {
  color: #00ab6c;
}
.social-icon-style-07 ul li a.medium span {
  background-color: #00ab6c;
}
.social-icon-style-07 ul li a.meneame {
  color: #ff6400;
}
.social-icon-style-07 ul li a.meneame span {
  background-color: #ff6400;
}
.social-icon-style-07 ul li a.mailru {
  color: #168de2;
}
.social-icon-style-07 ul li a.mailru span {
  background-color: #168de2;
}
.social-icon-style-07 ul li a.odnoklassniki {
  color: #d7772d;
}
.social-icon-style-07 ul li a.odnoklassniki span {
  background-color: #d7772d;
}
.social-icon-style-07 ul li a.paypal {
  color: #003087;
}
.social-icon-style-07 ul li a.paypal span {
  background-color: #003087;
}
.social-icon-style-07 ul li a.pinterest {
  color: #CB2027;
}
.social-icon-style-07 ul li a.pinterest span {
  background-color: #CB2027;
}
.social-icon-style-07 ul li a.pinterest-p {
  color: #CB2027;
}
.social-icon-style-07 ul li a.pinterest-p span {
  background-color: #CB2027;
}
.social-icon-style-07 ul li a.print {
  color: #222222;
}
.social-icon-style-07 ul li a.print span {
  background-color: #222222;
}
.social-icon-style-07 ul li a.reddit {
  color: #ff4500;
}
.social-icon-style-07 ul li a.reddit span {
  background-color: #ff4500;
}
.social-icon-style-07 ul li a.reddit-square {
  color: #ff4500;
}
.social-icon-style-07 ul li a.reddit-square span {
  background-color: #ff4500;
}
.social-icon-style-07 ul li a.rss {
  color: #f2890a;
}
.social-icon-style-07 ul li a.rss span {
  background-color: #f2890a;
}
.social-icon-style-07 ul li a.skype {
  color: #00aff0;
}
.social-icon-style-07 ul li a.skype span {
  background-color: #00aff0;
}
.social-icon-style-07 ul li a.slack {
  color: #6ecadc;
}
.social-icon-style-07 ul li a.slack span {
  background-color: #6ecadc;
}
.social-icon-style-07 ul li a.snapchat {
  color: #fffc00;
}
.social-icon-style-07 ul li a.snapchat span {
  background-color: #fffc00;
}
.social-icon-style-07 ul li a.soundcloud {
  color: #ff8800;
}
.social-icon-style-07 ul li a.soundcloud span {
  background-color: #ff8800;
}
.social-icon-style-07 ul li a.spotify {
  color: #1db954;
}
.social-icon-style-07 ul li a.spotify span {
  background-color: #1db954;
}
.social-icon-style-07 ul li a.sms {
  color: #ffbd00;
}
.social-icon-style-07 ul li a.sms span {
  background-color: #ffbd00;
}
.social-icon-style-07 ul li a.sharethis {
  color: #95D03A;
}
.social-icon-style-07 ul li a.sharethis span {
  background-color: #95D03A;
}
.social-icon-style-07 ul li a.stack-overflow {
  color: #f48024;
}
.social-icon-style-07 ul li a.stack-overflow span {
  background-color: #f48024;
}
.social-icon-style-07 ul li a.stumbleupon {
  color: #eb4924;
}
.social-icon-style-07 ul li a.stumbleupon span {
  background-color: #eb4924;
}
.social-icon-style-07 ul li a.telegram {
  color: #0088cc;
}
.social-icon-style-07 ul li a.telegram span {
  background-color: #0088cc;
}
.social-icon-style-07 ul li a.trello {
  color: #0079bf;
}
.social-icon-style-07 ul li a.trello span {
  background-color: #0079bf;
}
.social-icon-style-07 ul li a.tumblr {
  color: #32506d;
}
.social-icon-style-07 ul li a.tumblr span {
  background-color: #32506d;
}
.social-icon-style-07 ul li a.twitch {
  color: #6441a5;
}
.social-icon-style-07 ul li a.twitch span {
  background-color: #6441a5;
}
.social-icon-style-07 ul li a.twitter {
  color: #00aced;
}
.social-icon-style-07 ul li a.twitter span {
  background-color: #00aced;
}
.social-icon-style-07 ul li a.twitter-square {
  color: #00aced;
}
.social-icon-style-07 ul li a.twitter-square span {
  background-color: #00aced;
}
.social-icon-style-07 ul li a.vimeo {
  color: #1ab7ea;
}
.social-icon-style-07 ul li a.vimeo span {
  background-color: #1ab7ea;
}
.social-icon-style-07 ul li a.vine {
  color: #00bf8f;
}
.social-icon-style-07 ul li a.vine span {
  background-color: #00bf8f;
}
.social-icon-style-07 ul li a.vk {
  color: #4c6c91;
}
.social-icon-style-07 ul li a.vk span {
  background-color: #4c6c91;
}
.social-icon-style-07 ul li a.wordpress {
  color: #21759b;
}
.social-icon-style-07 ul li a.wordpress span {
  background-color: #21759b;
}
.social-icon-style-07 ul li a.weibo {
  color: #ff9933;
}
.social-icon-style-07 ul li a.weibo span {
  background-color: #ff9933;
}
.social-icon-style-07 ul li a.whatsapp {
  color: #25d366;
}
.social-icon-style-07 ul li a.whatsapp span {
  background-color: #25d366;
}
.social-icon-style-07 ul li a.xing {
  color: #1a7576;
}
.social-icon-style-07 ul li a.xing span {
  background-color: #1a7576;
}
.social-icon-style-07 ul li a.youtube {
  color: #ff0000;
}
.social-icon-style-07 ul li a.youtube span {
  background-color: #ff0000;
}
.social-icon-style-07 ul li a.youtube-play {
  color: #ff0000;
}
.social-icon-style-07 ul li a.youtube-play span {
  background-color: #ff0000;
}
/* Social icon style 08 */
.social-icon-style-08 ul li a:hover {
  opacity: 0.5;
  color: var(--dark-gray) !important;
}
.social-icon-style-08 ul.light li a:hover {
  opacity: 0.5;
  color: var(--white) !important;
}
/* Social icon style 09 */
.social-icon-style-09 ul li a {
  z-index: 9;
  border: 1px solid rgba(35, 35, 35, 0.15);
  border-radius: 100%;
  position: relative;
}
.social-icon-style-09 ul li a:hover {
  color: var(--white) !important;
}
.social-icon-style-09 ul li a:hover span {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.social-icon-style-09 ul li a span {
  display: block;
  border-radius: 100%;
  height: 100%;
  width: 100%;
  top: -1px;
  left: -1px;
  padding: 1px;
  box-sizing: content-box;
  margin: 0;
  position: absolute;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
  -webkit-transition: 0.5s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.5s cubic-bezier(0.12, 0, 0.39, 0);
  pointer-events: none;
  z-index: -1;
  background-color: var(--dark-gray);
}
.social-icon-style-09 ul.light li a {
  border-color: rgba(255, 255, 255, 0.3);
}
.social-icon-style-09 ul.light li a:hover {
  color: var(--dark-gray) !important;
}
.social-icon-style-09 ul.light li a span {
  background-color: var(--white);
}
/* Social icon style 10 */
.social-icon-style-10 ul li a {
  color: var(--dark-gray);
}
.social-icon-style-10 ul li a:hover {
  color: var(--base-color) !important;
}
.social-icon-style-10 .default-color a.behance {
  color: #1769ff;
}
.social-icon-style-10 .default-color a.behance-square {
  color: #1769ff;
}
.social-icon-style-10 .default-color a.blogger {
  color: #ff8000;
}
.social-icon-style-10 .default-color a.codepen {
  color: #0ebeff;
}
.social-icon-style-10 .default-color a.deviantart {
  color: #05cc47;
}
.social-icon-style-10 .default-color a.digg {
  color: #262626;
}
.social-icon-style-10 .default-color a.dribbble {
  color: #ea4c89;
}
.social-icon-style-10 .default-color a.delicious {
  color: #205cc0;
}
.social-icon-style-10 .default-color a.email {
  color: #7d7d7d;
}
.social-icon-style-10 .default-color a.facebook {
  color: #3b5998;
}
.social-icon-style-10 .default-color a.facebook-square {
  color: #3b5998;
}
.social-icon-style-10 .default-color a.flickr {
  color: #ff0084;
}
.social-icon-style-10 .default-color a.flipboard {
  color: #e12828;
}
.social-icon-style-10 .default-color a.github {
  color: #4078c0;
}
.social-icon-style-10 .default-color a.google {
  color: #dc4a38;
}
.social-icon-style-10 .default-color a.google-plus {
  color: #dd4b39;
}
.social-icon-style-10 .default-color a.instagram {
  color: #FE1F49;
}
.social-icon-style-10 .default-color a.jsfiddle {
  color: #0084FF;
}
.social-icon-style-10 .default-color a.linkedin {
  color: #0077b5;
}
.social-icon-style-10 .default-color a.livejournal {
  color: #00b0ea;
}
.social-icon-style-10 .default-color a.medium {
  color: #00ab6c;
}
.social-icon-style-10 .default-color a.meneame {
  color: #ff6400;
}
.social-icon-style-10 .default-color a.mailru {
  color: #168de2;
}
.social-icon-style-10 .default-color a.odnoklassniki {
  color: #d7772d;
}
.social-icon-style-10 .default-color a.paypal {
  color: #003087;
}
.social-icon-style-10 .default-color a.pinterest {
  color: #CB2027;
}
.social-icon-style-10 .default-color a.pinterest-p {
  color: #CB2027;
}
.social-icon-style-10 .default-color a.print {
  color: #222222;
}
.social-icon-style-10 .default-color a.reddit {
  color: #ff4500;
}
.social-icon-style-10 .default-color a.reddit-square {
  color: #ff4500;
}
.social-icon-style-10 .default-color a.rss {
  color: #f2890a;
}
.social-icon-style-10 .default-color a.skype {
  color: #00aff0;
}
.social-icon-style-10 .default-color a.slack {
  color: #6ecadc;
}
.social-icon-style-10 .default-color a.snapchat {
  color: #fffc00;
}
.social-icon-style-10 .default-color a.soundcloud {
  color: #ff8800;
}
.social-icon-style-10 .default-color a.spotify {
  color: #1db954;
}
.social-icon-style-10 .default-color a.sms {
  color: #ffbd00;
}
.social-icon-style-10 .default-color a.sharethis {
  color: #95D03A;
}
.social-icon-style-10 .default-color a.stack-overflow {
  color: #f48024;
}
.social-icon-style-10 .default-color a.stumbleupon {
  color: #eb4924;
}
.social-icon-style-10 .default-color a.telegram {
  color: #0088cc;
}
.social-icon-style-10 .default-color a.trello {
  color: #0079bf;
}
.social-icon-style-10 .default-color a.tumblr {
  color: #32506d;
}
.social-icon-style-10 .default-color a.twitch {
  color: #6441a5;
}
.social-icon-style-10 .default-color a.twitter {
  color: #00aced;
}
.social-icon-style-10 .default-color a.twitter-square {
  color: #00aced;
}
.social-icon-style-10 .default-color a.vimeo {
  color: #1ab7ea;
}
.social-icon-style-10 .default-color a.vine {
  color: #00bf8f;
}
.social-icon-style-10 .default-color a.vk {
  color: #4c6c91;
}
.social-icon-style-10 .default-color a.wordpress {
  color: #21759b;
}
.social-icon-style-10 .default-color a.weibo {
  color: #ff9933;
}
.social-icon-style-10 .default-color a.whatsapp {
  color: #25d366;
}
.social-icon-style-10 .default-color a.xing {
  color: #1a7576;
}
.social-icon-style-10 .default-color a.youtube {
  color: #ff0000;
}
.social-icon-style-10 .default-color a.youtube-play {
  color: #ff0000;
}
.social-icon-style-10 .default-color a:hover {
  color: var(--dark-gray) !important;
}
/* Social text style 01 */
.social-text-style-01 ul li a {
  width: auto;
  height: auto;
  line-height: normal;
}
/* Subcribe style 01 */
.newsletter-style-01 input {
  border-radius: 4px;
  padding-right: 160px;
}
.newsletter-style-01 input.is-invalid {
  padding-right: 160px;
}
.newsletter-style-01 .btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 0 4px 4px 0;
}
.newsletter-style-01 .btn i {
  top: 1px;
}
/* Subcribe style 02 */
.newsletter-style-02 input {
  border-radius: 4px;
  padding-right: 60px !important;
}
.newsletter-style-02 .btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 0 4px 4px 0;
  padding: 10px 25px 9px 25px;
  display: inline-block;
}
.newsletter-style-02 .btn i {
  margin-left: 0;
  top: inherit;
}
.newsletter-style-02 .btn:active {
  border-color: transparent;
}
/* Subcribe style 03 */
.newsletter-style-03 input {
  padding-right: 165px !important;
  padding-left: 30px !important;
}
.newsletter-style-03 .btn {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 100px 100px 0;
  height: 100%;
  padding: 12px 35px 12px 30px;
}
.newsletter-style-03 .btn:active {
  border-color: transparent;
}
/* Subcribe style 04 */
.newsletter-style-04 input {
  padding-left: 0;
  padding-right: 40px;
  border-radius: 0;
}
.newsletter-style-04 .btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0;
  border: none;
}
.newsletter-style-04 .btn i {
  margin-left: 0;
}
.newsletter-style-01 .form-control, .newsletter-style-01 .form-control.is-invalid, .newsletter-style-01 .was-validated .form-control:invalid, .newsletter-style-02 .form-control, .newsletter-style-02 .form-control.is-invalid, .newsletter-style-02 .was-validated .form-control:invalid, .newsletter-style-03 .form-control, .newsletter-style-03 .form-control.is-invalid, .newsletter-style-03 .was-validated .form-control:invalid, .newsletter-style-04 .form-control, .newsletter-style-04 .form-control.is-invalid, .newsletter-style-04 .was-validated .form-control:invalid {
  background-image: inherit;
}

/* Tab */
.nav-tabs > li.nav-item {
  display: inline-block;
  float: none;
  margin: 0;
  padding: 0 17px;
}
/* Tab style 03 */
.tab-style-03 .nav-tabs .nav-link {
  padding: 10px 33px;
  color: var(--medium-gray);
  border: 1px solid var(--light-medium-gray);
  border-bottom: none;
  -webkit-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
.tab-style-03 .nav-tabs .nav-link.active, .tab-style-03 .nav-tabs .nav-link.show, .tab-style-03 .nav-tabs .nav-link:hover {
  color: var(--dark-gray);
  background-color: var(--white);
}
.tab-style-03 .nav-tabs > li.nav-item {
  padding: 0 8px;
}
/* Tab style 02 */
.tab-style-02 .nav-tabs {
  border-bottom: none;
}
.tab-style-02 .nav-tabs > li.nav-item {
  margin: 0 34px;
}
.tab-style-02 .nav-tabs > li.nav-item > a.nav-link {
  border: none;
  padding: 0;
  color: var(--medium-gray);
  border-bottom: 2px solid transparent;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.tab-style-02 .nav-tabs > li.nav-item > a.nav-link i {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  will-change: transform;
}
.tab-style-02 .nav-tabs > li.nav-item > a.nav-link:hover {
  color: var(--dark-gray);
  border-bottom: 2px solid;
}
.tab-style-02 .nav-tabs > li.nav-item > a.nav-link:hover i {
  color: var(--base-color);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.tab-style-02 .nav-tabs > li.nav-item > a.nav-link.active {
  color: var(--dark-gray);
  border-bottom: 2px solid;
  background: transparent;
}
.tab-style-02 .nav-tabs > li.nav-item > a.nav-link.active i {
  color: var(--base-color);
}
/* Tab style 03 */
.tab-style-01 .nav-tabs .nav-link {
  border: none;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  padding: 10px 40px;
  color: var(--medium-gray);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.tab-style-01 .nav-tabs .nav-link.show, .tab-style-01 .nav-tabs .nav-link.active, .tab-style-01 .nav-tabs .nav-link:hover {
  color: var(--dark-gray);
  background-color: var(--white);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  -webkit-transform: translate3d(0, -3px, 0);
  transform: translate3d(0, -3px, 0);
}
.tab-style-01 .nav-tabs > li.nav-item {
  padding: 0 4px;
}
/* Tab style 04 */
.tab-style-04 .nav-tabs .nav-item {
  padding: 0 35px;
}
.tab-style-04 .nav-tabs .nav-item .nav-link {
  padding: 0;
  border: none;
  background: transparent;
  position: relative;
  color: var(--medium-gray);
}
.tab-style-04 .nav-tabs .nav-item .nav-link .tab-border {
  width: 100%;
  height: 2px;
  display: block;
  -wekit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}
.tab-style-04 .nav-tabs .nav-item .nav-link.active, .tab-style-04 .nav-tabs .nav-item .nav-link.show, .tab-style-04 .nav-tabs .nav-item .nav-link:hover {
  color: var(--dark-gray);
}
.tab-style-04 .nav-tabs .nav-item .nav-link.active .tab-border, .tab-style-04 .nav-tabs .nav-item .nav-link.show .tab-border, .tab-style-04 .nav-tabs .nav-item .nav-link:hover .tab-border {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
/* Tab style 05 */
.tab-style-05 .nav-tabs > li {
  width: 100%;
}
.tab-style-05 .nav-tabs > li.nav-item {
  padding: 0;
}
.tab-style-05 .nav-tabs .nav-item .nav-link {
  border: none;
  padding: 20px 28px 22px;
  margin-bottom: 15px;
  background: transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: all 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  display: flex;
  align-items: center;
}
.tab-style-05 .nav-tabs .nav-item .nav-link i {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}
.tab-style-05 .nav-tabs .nav-item .nav-link span {
  position: relative;
  top: 1px;
  color: var(--dark-gray);
  -webkit-transition: all 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: all 0.3s cubic-bezier(0.12, 0, 0.39, 0);
}
.tab-style-05 .nav-tabs .nav-item .nav-link:hover, .tab-style-05 .nav-tabs .nav-item .nav-link.show, .tab-style-05 .nav-tabs .nav-item .nav-link.active {
  background: var(--white);
  color: var(--base-color);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}
.tab-style-05 .nav-tabs .nav-item .nav-link:hover i, .tab-style-05 .nav-tabs .nav-item .nav-link.show i, .tab-style-05 .nav-tabs .nav-item .nav-link.active i {
  color: var(--base-color);
}
.tab-style-05 .nav-tabs .nav-item .nav-link:hover span, .tab-style-05 .nav-tabs .nav-item .nav-link.show span, .tab-style-05 .nav-tabs .nav-item .nav-link.active span {
  color: var(--base-color);
}
.tab-style-05 .nav-tabs .nav-item:last-child .nav-link {
  border-bottom: none;
}
/* Tab style 06 */
.tab-style-06 .nav-tabs > li.nav-item {
  position: relative;
  z-index: 1;
  padding: 0;
  border: 1px solid;
  overflow: hidden;
  border-radius: 4px 0 0 4px;
}
.tab-style-06 .nav-tabs > li.nav-item:last-child {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}
.tab-style-06 .nav-tabs > li.nav-item > a.nav-link {
  border-bottom: none;
  border-radius: 0;
  line-height: 18px;
  padding: 16px 40px;
  text-transform: uppercase;
  z-index: 1;
  background-color: transparent;
  color: var(--medium-gray);
  border: 0;
}
.tab-style-06 .nav-tabs > li.nav-item > a.nav-link.active {
  color: #fff !important;
}
.tab-style-06 .nav-tabs > li.nav-item > a.nav-link.active + .tab-bg-active {
  opacity: 1;
}
.tab-style-06 .tab-bg-active {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  z-index: -1;
  opacity: 0;
}
/* Tab style 07 */
.tab-style-07 .nav-tabs > li {
  width: 100%;
}
.tab-style-07 .nav-tabs > li.nav-item {
  padding: 0;
}
.tab-style-07 .nav-tabs .nav-item .nav-link {
  border: none;
  border-bottom: 1px solid;
  border-color: var(--extra-medium-gray);
  padding: 38px 60px 38px;
  background: transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
}
.tab-style-07 .nav-tabs .nav-item .nav-link span {
  color: var(--dark-gray);
  z-index: 9;
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
}
.tab-style-07 .nav-tabs .nav-item .nav-link:hover, .tab-style-07 .nav-tabs .nav-item .nav-link.show, .tab-style-07 .nav-tabs .nav-item .nav-link.active {
  color: var(--white);
  isolation: inherit;
}
.tab-style-07 .nav-tabs .nav-item .nav-link:hover span, .tab-style-07 .nav-tabs .nav-item .nav-link.show span, .tab-style-07 .nav-tabs .nav-item .nav-link.active span {
  color: var(--white);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.tab-style-07 .nav-tabs .nav-item .nav-link:hover .number-box, .tab-style-07 .nav-tabs .nav-item .nav-link.show .number-box, .tab-style-07 .nav-tabs .nav-item .nav-link.active .number-box {
  opacity: 1;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  transition: opacity 0.4s, transform 0.4s;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}
.tab-style-07 .nav-tabs .nav-item .nav-link:hover .bg-hover, .tab-style-07 .nav-tabs .nav-item .nav-link.show .bg-hover, .tab-style-07 .nav-tabs .nav-item .nav-link.active .bg-hover {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.tab-style-07 .nav-tabs .nav-item .nav-link .number-box {
  position: absolute;
  top: 50%;
  z-index: 9;
  right: 0;
  opacity: 0;
  -webkit-transform: translate(25%, -50%);
  transform: translate(25%, -50%);
  transition: opacity 0s, transform 0s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.tab-style-07 .nav-tabs .nav-item .nav-link .bg-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transform-origin: left;
  -webkit-transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  z-index: 1;
}
.tab-style-07 .nav-tabs .nav-item:last-child .nav-link {
  border-bottom: none;
}
/* Tab style 08 */
.tab-style-08 ul .nav-item {
  padding: 0;
  background-color: transparent;
  border-right: 1px solid var(--light-medium-gray);
  flex-grow: 1;
  flex-basis: 0;
}
.tab-style-08 ul .nav-item .nav-link {
  border: 0;
  margin-bottom: 0;
  padding: 30px 15px;
  background-color: transparent;
  position: relative;
  color: var(--medium-gray);
}
.tab-style-08 ul .nav-item .nav-link.active, .tab-style-08 ul .nav-item .nav-link.show, .tab-style-08 ul .nav-item .nav-link:hover {
  border: 0;
  color: var(--base-color);
}
.tab-style-08 ul .nav-item .nav-link.active .tab-border, .tab-style-08 ul .nav-item .nav-link.show .tab-border, .tab-style-08 ul .nav-item .nav-link:hover .tab-border {
  -wekit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.tab-style-08 ul .nav-item .tab-border {
  width: calc(100% + 2px);
  height: 3px;
  display: block;
  -wekit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  position: absolute;
  left: -1px;
  top: 0;
}
.tab-style-08 ul .nav-item:last-child {
  border: 0;
}
.tab-style-08 ul.active-tab-border-bottom .nav-item .tab-border {
  bottom: 0;
  top: auto;
}
/* Team style 01 */
.team-style-01 figure figcaption {
  position: absolute;
  bottom: -1px;
}
.team-style-01 figure figcaption .social-icon {
  display: block !important;
  height: auto !important;
  margin-bottom: -50px !important;
  overflow: visible !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.team-style-01 figure figcaption .social-icon a {
  margin: 0 10px;
}
.team-style-01 figure figcaption .social-icon a:last-child {
  margin-right: 0;
}
.team-style-01 figure figcaption .social-icon a:first-child {
  margin-left: 0;
}
.team-style-01 figure .text-light-opacity:hover {
  opacity: 1 !important;
}
.team-style-01 figure:hover figcaption .social-icon {
  margin-bottom: 0px !important;
}
/* Team style 02 */
.team-style-02 figure figcaption {
  position: absolute;
  opacity: 0;
  bottom: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.12, 0, 0.39, 0);
  transition: all 0.5s cubic-bezier(0.12, 0, 0.39, 0);
}
.team-style-02 figure figcaption .social-icon a {
  margin: 0 12px;
  opacity: 0;
  -webkit-transition: 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  transition: 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translateY(-25px);
  transform: translateY(-25px);
  display: inline-block;
}
.team-style-02 figure figcaption .social-icon a:nth-child(1) {
  -webkit-transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure figcaption .social-icon a:nth-child(2) {
  -webkit-transition: all 0.4s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.4s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure figcaption .social-icon a:nth-child(3) {
  -webkit-transition: all 0.6s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.6s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure figcaption .social-icon a:nth-child(4) {
  -webkit-transition: all 0.8s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.8s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure figcaption .social-icon a:nth-child(5) {
  -webkit-transition: all 1s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 1s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure figcaption .team-member-name {
  opacity: 0;
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0);
  -webkit-transition: all 1s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 1s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure figcaption .member-designation {
  opacity: 0;
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0);
  -webkit-transition: all 0.7s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.7s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure:hover figcaption {
  opacity: 1;
}
.team-style-02 figure:hover figcaption .team-member-name {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.4s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure:hover figcaption .member-designation {
  opacity: 0.7;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.7s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.7s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure:hover figcaption a {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.team-style-02 figure:hover figcaption a:nth-child(1) {
  -webkit-transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure:hover figcaption a:nth-child(2) {
  -webkit-transition: all 0.4s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.4s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure:hover figcaption a:nth-child(3) {
  -webkit-transition: all 0.6s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.6s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure:hover figcaption a:nth-child(4) {
  -webkit-transition: all 0.8s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.8s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-02 figure:hover figcaption a:nth-child(5) {
  -webkit-transition: all 1s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 1s cubic-bezier(0.2, 0, 0.3, 1);
}
/* Team style 04 */
.team-style-04 .team-content {
  position: absolute;
  bottom: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}
.team-style-04 figure figcaption {
  position: absolute;
  bottom: -20px;
  -webkit-transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}
.team-style-04 figure figcaption .social-icon {
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
}
.team-style-04 figure figcaption .social-icon a {
  margin: 0 10px;
}
.team-style-04 figure:hover figcaption {
  bottom: 0px;
}
/* Team style 05 */
.team-style-05 .team-content {
  position: absolute;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: all 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
.team-style-05 .team-content p {
  top: 55%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.team-style-05 .social-icon a {
  opacity: 0;
  -webkit-transition: 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  transition: 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
  margin: 0 10px;
  display: inline-block;
}
.team-style-05 .social-icon a:nth-child(1) {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.team-style-05 .social-icon a:nth-child(2) {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.team-style-05 .social-icon a:nth-child(3) {
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.team-style-05 .social-icon a:nth-child(4) {
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
}
.team-style-05 .social-icon a:nth-child(5) {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
.team-style-05:hover .team-content {
  opacity: 1;
}
.team-style-05:hover .team-content p {
  top: 50%;
}
.team-style-05:hover .social-icon a {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.team-style-05:hover .social-icon a:nth-child(1) {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.team-style-05:hover .social-icon a:nth-child(2) {
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.team-style-05:hover .social-icon a:nth-child(3) {
  -webkit-transition: all 0.9s ease;
  transition: all 0.9s ease;
}
.team-style-05:hover .social-icon a:nth-child(4) {
  -webkit-transition: all 1.1s ease;
  transition: all 1.1s ease;
}
.team-style-05:hover .social-icon a:nth-child(5) {
  -webkit-transition: all 1.4s ease;
  transition: all 1.4s ease;
}
/* Team style 08 */
.team-style-08 figure {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-08 figure figcaption {
  position: absolute;
  opacity: 0;
  bottom: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.team-style-08 figure figcaption .social-icon a {
  margin: 0 0 10px;
  opacity: 0;
  display: inline-block;
  background-color: var(--dark-gray);
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0);
  -webkit-transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.team-style-08 figure figcaption .social-icon a:last-child {
  margin-bottom: 0;
}
.team-style-08 figure figcaption .team-member-name {
  opacity: 0;
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0);
  -webkit-transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-08 figure figcaption .member-designation {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
  -webkit-transition: all 0.7s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.7s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-08:hover figure {
  -webkit-transform: translate3d(0, -8px, 0);
  transform: translate3d(0, -8px, 0);
}
.team-style-08:hover figure figcaption {
  opacity: 1;
}
.team-style-08:hover figure figcaption .social-icon a:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.team-style-08:hover figure figcaption .team-member-name {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.team-style-08:hover figure figcaption .member-designation {
  opacity: 0.6;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.team-style-08:hover figure figcaption a {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
/* Team style 09 */
.team-style-09 .nav-tabs > li.nav-item {
  padding: 0;
}
.team-style-09 .nav-tabs > li.nav-item .number {
  color: var(--dark-gray);
  -webkit-transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.4s cubic-bezier(0.12, 0, 0.39, 0);
}
.team-style-09 .nav-tabs > li.nav-item:last-child .nav-link {
  border-bottom: 0;
}
.team-style-09 .nav-tabs .nav-link {
  border: 0;
  display: flex;
  border-bottom: 1px solid var(--extra-medium-gray);
  padding: 20px 0;
  margin-bottom: 0;
  background-color: transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
  transition: 0.3s cubic-bezier(0.12, 0, 0.39, 0);
}
.team-style-09 .nav-tabs .nav-link:hover {
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}
.team-style-09 .nav-tabs .nav-link.active, .team-style-09 .nav-tabs .nav-link:hover {
  border-bottom: 1px solid var(--dark-gray);
}
/* Team style 10 */
.team-style-10 figure {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-10 figure .hover-switch-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.2s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-10 figure figcaption {
  position: absolute;
  opacity: 0;
  bottom: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.team-style-10 figure figcaption .social-icon a {
  margin: 0 0 10px;
  opacity: 0;
  display: inline-block;
  background-color: var(--dark-gray);
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform;
  -webkit-transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.team-style-10 figure figcaption .social-icon a:last-child {
  margin-bottom: 0;
}
.team-style-10 figure figcaption .team-member-strip {
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
  -webkit-transition: all 0.3s cubic-bezier(0.2, 0, 0.8, 1);
  transition: all 0.3s cubic-bezier(0.2, 0, 0.8, 1);
}
.team-style-10 figure figcaption .team-member-strip .team-member-name {
  opacity: 0;
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0);
  -webkit-transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-10 figure figcaption .team-member-strip .member-designation {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
  -webkit-transition: all 0.7s cubic-bezier(0.2, 0, 0.3, 1);
  transition: all 0.7s cubic-bezier(0.2, 0, 0.3, 1);
}
.team-style-10:hover figure .hover-switch-image {
  left: 0;
  opacity: 1;
}
.team-style-10:hover figure figcaption {
  opacity: 1;
}
.team-style-10:hover figure figcaption .social-icon a {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.team-style-10:hover figure figcaption .social-icon a:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.team-style-10:hover figure figcaption .team-member-strip {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.team-style-10:hover figure figcaption .team-member-strip .team-member-name {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.team-style-10:hover figure figcaption .team-member-strip .member-designation {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.team-style-10:hover figure figcaption a {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
/* Testimonial style 01 */
.testimonials-style-01 .testimonial-arrow:after {
  content: "";
  top: 100%;
  left: 50px;
  height: 0;
  width: 0;
  z-index: 9;
  margin-left: -14px;
  position: absolute;
  pointer-events: none;
  border: solid transparent;
  border-width: 14px;
  border-top-color: var(--white);
}
.testimonials-style-01 .author-detail img {
  width: 60px;
  height: 60px;
}
/* Testimonial style 04 */
.testimonials-style-04.swiper-horizontal-3d.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.testimonials-style-04.swiper-horizontal-3d .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  box-sizing: content-box;
}
.testimonials-style-04.swiper-horizontal-3d.swiper-pagination-bottom .swiper-wrapper {
  margin-bottom: 0;
}
.testimonials-style-04.swiper-horizontal-3d .swiper-slide {
  filter: grayscale(100%);
  opacity: 0;
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
.testimonials-style-04.swiper-horizontal-3d .swiper-slide.swiper-slide-prev, .testimonials-style-04.swiper-horizontal-3d .swiper-slide.swiper-slide-next {
  opacity: 0.7;
}
.testimonials-style-04.swiper-horizontal-3d .swiper-slide.swiper-slide-active {
  opacity: 1;
  filter: grayscale(0);
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.17);
}
.testimonials-style-04.swiper-horizontal-3d .swiper-slide.swiper-slide-active .slider-image:hover .preview-icon {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  z-index: 1;
}
.testimonials-style-04.swiper-horizontal-3d .swiper-slide img {
  opacity: 0;
}
.testimonials-style-04.swiper-horizontal-3d.swiper {
  width: 100%;
  padding-bottom: 60px;
}
.testimonials-style-04.swiper-horizontal-3d.swiper-3d .swiper-slide-shadow-left, .testimonials-style-04.swiper-horizontal-3d.swiper-3d .swiper-slide-shadow-right {
  background-image: none;
}
.testimonials-style-04.swiper-horizontal-3d .swiper-slide-active img {
  opacity: 1;
}
/* Testimonial style 05 */
.testimonials-style-05 .testimonials-bubble {
  position: relative;
  border: 1px solid;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.testimonials-style-05 .testimonials-bubble:after, .testimonials-style-05 .testimonials-bubble:before {
  top: 100%;
  left: 50px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.testimonials-style-05 .testimonials-bubble:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: var(--white);
  border-width: 15px 15px 0 0;
  margin-left: 1px;
}
.testimonials-style-05 .testimonials-bubble:before {
  border-color: rgba(237, 237, 237, 0);
  border-top-color: var(--extra-medium-gray);
  border-width: 18px 18px 0 0;
}
/* Testimonial style 12 */
.testimonials-style-12 .swiper-button-next-nav {
  right: -8px;
}
.testimonials-style-12 .swiper-button-next-nav:hover {
  opacity: 1;
}
.testimonials-style-12 .swiper-button-previous-nav {
  left: -8px;
}
.testimonials-style-12 .swiper-button-previous-nav:hover {
  opacity: 1;
}
/* Testimonial style 13  */
.testimonials-style-13 .swiper-slide {
  opacity: 0.1;
}
.testimonials-style-13 .swiper-slide.swiper-slide-active {
  opacity: 1;
}
.testimonials-style-13 .swiper-slide.swiper-slide-next {
  opacity: 0.4;
}
/* ===================================
   14. Blog
====================================== */
/* Blog grid */
.blog-grid .card {
  color: inherit;
  -webkit-transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
}
.blog-grid .card .blog-image img {
  -webkit-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.blog-grid .card .card-body {
  color: inherit;
}
.blog-grid .card .card-body p {
  margin-bottom: 15px;
}
.blog-grid .card .card-body .author {
  will-change: transform;
}
.blog-grid .card .card-body .author .blog-date {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 500ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 500ms cubic-bezier(0.37, 0, 0.63, 1);
}
.blog-grid .card .card-body .author .like-count a i {
  margin-right: 4px;
  vertical-align: middle;
  -webkit-transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
}
.blog-grid .card .card-body .author .like-count a:hover i {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.blog-grid .card .card-body .author .author-name {
  position: absolute;
  width: auto;
  left: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(32px);
  transform: translateY(32px);
  -webkit-transition: all 500ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 500ms cubic-bezier(0.37, 0, 0.63, 1);
}
.blog-grid .card .blog-categories {
  position: absolute;
  top: 17px;
  right: 20px;
}
.blog-grid .card .blog-categories .categories-btn {
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
}
.blog-grid .card:hover .author .blog-date {
  position: relative;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-32px);
  transform: translateY(-32px);
}
.blog-grid .card:hover .author .author-name {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
/* Blog classic */
.blog-classic .card {
  color: inherit;
}
.blog-classic .card .blog-image img {
  -webkit-transition: all 600ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 600ms cubic-bezier(0.37, 0, 0.63, 1);
  will-change: transform;
}
.blog-classic .card .card-body {
  color: inherit;
}
.blog-classic .card .card-body .categories-text:after {
  content: "\f309";
  display: inline-block;
  font-family: bootstrap-icons;
  vertical-align: middle;
  margin: -2px 1px 0;
  font-size: 22px;
  color: var(--medium-gray);
  opacity: 0.7;
}
.blog-classic .card .card-body .card-link i {
  opacity: 0;
  visibility: hidden;
  transform-origin: center left;
  -webkit-transform: translateX(-10px) scaleX(0);
  transform: translateX(-10px) scaleX(0);
  -webkit-transition: all 300ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 300ms cubic-bezier(0.5, 1, 0.89, 1);
  vertical-align: middle;
  margin-left: 3px;
}
.blog-classic .card:hover .blog-image img {
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
}
.blog-classic .card:hover .card-body .card-link i {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0) scaleX(1);
  transform: translateX(0) scaleX(1);
}
/* Blog only text */
.blog-only-text {
  counter-reset: card-body;
}
.blog-only-text .card-body {
  color: inherit;
  position: relative;
  overflow: hidden;
}
.blog-only-text .card-body .hover-img {
  position: absolute;
  left: 0;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 300ms cubic-bezier(0.37, 0, 0.63, 1);
  -webkit-transform: scale(1.1) rotate(-3deg);
  transform: scale(1.1) rotate(-3deg);
}
.blog-only-text .card-body:after {
  counter-increment: card-body;
  content: counter(card-body, decimal-leading-zero);
  position: absolute;
  font-size: 70px;
  line-height: 70px;
  letter-spacing: -3px;
  top: 30px;
  right: -8px;
  font-weight: 600;
  opacity: 0.1;
  color: var(--dark-gray);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
.blog-only-text .card-body:hover .hover-img {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
.blog-only-text .card-body:hover:after {
  opacity: 0.2;
  color: var(--white);
  right: 30px;
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
.blog-only-text .card-body:hover .card-title {
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.9);
}
.blog-only-text .card-body:hover .categories-btn {
  background: var(--white);
  color: var(--dark-gray) !important;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.6);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.6);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
/* Blog metro */
.blog-metro figure .blog-image .blog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #1f232c;
  background: linear-gradient(0deg, rgba(23, 23, 23, 0.8) 0, rgba(23, 23, 23, 0) 70%);
  -webkit-transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
}
.blog-metro figure .categories-btn {
  border: 1px solid transparent;
}
.blog-metro figure img {
  -webkit-transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  width: 100%;
}
.blog-metro figure figcaption {
  position: absolute;
  top: 0;
  left: 0;
}
.blog-metro figure .card-title {
  margin-bottom: 0;
}
.blog-metro figure:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.5;
}
.blog-metro figure:hover .categories-btn {
  background: transparent !important;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.blog-metro figure:hover .categories-btn.bg-base-color {
  color: var(--base-color) !important;
  border-color: var(--base-color);
}
/* Blog masonry */
.blog-masonry .card {
  color: inherit;
}
.blog-masonry .card .card-top {
  position: relative;
  padding: 15px 20px;
}
.blog-masonry .card .card-top:after {
  content: "";
  position: absolute;
  left: 25px;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
  clear: both;
  z-index: 1;
}
.blog-masonry .card .card-top .avtar {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  position: relative;
  border-radius: 100%;
}
.blog-masonry .card .blog-image img {
  -webkit-transition: all 1000ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 1000ms cubic-bezier(0.5, 1, 0.89, 1);
}
.blog-masonry .card .blog-image .categories-btn {
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
}
.blog-masonry .card .like-count a i {
  margin-right: 5px;
  -webkit-transition: all 400ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 400ms cubic-bezier(0.5, 1, 0.89, 1);
  will-change: transform;
}
.blog-masonry .card .like-count a:hover i {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.blog-masonry .card .blog-date i {
  margin-right: 8px;
}
.blog-masonry .card .blog-categories {
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.blog-masonry .card:hover {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.blog-masonry .card:hover .blog-image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* Blog simple */
.blog-simple figure .blog-image {
  overflow: hidden;
}
.blog-simple figure .blog-hover-icon {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  opacity: 0;
}
.blog-simple figure figcaption {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.blog-simple figure:hover .blog-hover-icon {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.blog-simple figure:hover .post-content .card-title a:hover {
  color: var(--base-color);
}
.blog-simple figure:hover .blog-image img {
  -webkit-filter: blur(2px);
  filter: blur(2px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.blog-simple figure:hover .blog-image .box-overlay {
  opacity: 0.3;
}
.blog-simple figure:hover .box-overlay {
  opacity: 1;
  visibility: visible;
}
/* Blog date */
.blog-date .feature-box {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.blog-date .feature-box .feature-box-icon {
  margin-right: 40px !important;
}
.blog-date .feature-box .post-date {
  min-width: 75px;
  padding: 20px 0 17px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.blog-date .feature-box .post-date span {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.blog-date .feature-box:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
/* Blog side image */
.blog-side-image .blog-box .blog-image {
  min-height: 300px;
  will-change: transform;
  -webkit-transition: all 600ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 600ms cubic-bezier(0.5, 1, 0.89, 1);
}
.blog-side-image .blog-box .blog-image .blog-post-image-overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 600ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 600ms cubic-bezier(0.5, 1, 0.89, 1);
}
.blog-side-image .blog-box .blog-content {
  position: relative;
  z-index: 1;
}
.blog-side-image .blog-box .blog-content .separator {
  width: 10px;
  height: 1px;
  -webkit-transition: all 600ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 600ms cubic-bezier(0.5, 1, 0.89, 1);
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}
.blog-side-image .blog-box .blog-content .categories-btn {
  -webkit-border-radius: 50px;
  border-radius: 50px;
  margin-left: 0;
  margin-right: 5px;
  line-height: 20px;
  padding: 4px 15px;
}
.blog-side-image .blog-box:hover .blog-image {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.blog-side-image .blog-box:hover .blog-image .blog-post-image-overlay {
  opacity: 0.4;
}
.blog-side-image .blog-box:hover .blog-content .separator {
  width: 30px;
}
/* Blog modern */
.blog-modern figure .blog-image {
  margin-bottom: 48px;
}
.blog-modern figure .blog-image img {
  -webkit-transition: all 900ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 900ms cubic-bezier(0.5, 1, 0.89, 1);
}
.blog-modern figure .post-content-wrapper {
  position: absolute;
  bottom: -51px;
  width: 85%;
  margin: 0 auto;
  left: 0;
  right: 0;
  -webkit-box-shadow: 0px 4px 10px rgba(111, 111, 111, 0.1);
  box-shadow: 0px 4px 10px rgba(111, 111, 111, 0.1);
}
.blog-modern figure .post-content-wrapper .post-content {
  -webkit-transition: all 400ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: all 400ms cubic-bezier(0.5, 1, 0.89, 1);
}
.blog-modern figure .post-content-wrapper .separator {
  margin: 0 15px;
  opacity: 0.7;
  font-weight: 300;
}
.blog-modern figure:hover .blog-image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.blog-modern figure:hover .post-content-wrapper .post-content .card-title {
  color: var(--white);
}
.blog-modern figure:hover .post-content-wrapper .post-content .card-title:hover {
  color: var(--white);
}
.blog-modern figure:hover .post-content-wrapper .post-content .box-overlay {
  opacity: 1;
  visibility: visible;
}
/* Blog standard */
.blog-standard .blog-image {
  position: relative;
}
.blog-standard .blog-image .blog-categories {
  position: absolute;
  right: 40px;
  top: 40px;
}
.blog-standard .entry-meta span {
  position: relative;
  margin-right: 20px;
}
.blog-standard .entry-meta span:last-child {
  margin-right: 0;
}
.blog-standard .entry-meta span i {
  margin-right: 7px;
}
/* Blog comment */
.blog-comment {
  padding-left: 0;
  margin-bottom: 0;
}
.blog-comment li {
  padding-bottom: 65px;
  position: relative;
  list-style: none;
}
.blog-comment li:last-child {
  border-bottom: medium none;
  padding-bottom: 0;
}
.blog-comment li .btn-reply {
  float: right;
  font-size: 11px;
  line-height: initial;
  padding: 7px 20px 6px;
  border-radius: 50px;
  border: 1px solid rgba(44, 44, 44, 0.25);
  background-color: var(--white);
}
.blog-comment li .btn-reply:hover {
  border-color: var(--base-color);
  background: var(--base-color);
  color: var(--white);
}
.blog-comment li ul.child-comment {
  list-style: outside none none;
  margin-left: 70px;
}
.blog-comment li ul.child-comment li {
  margin-top: 60px;
  padding-bottom: 0;
}
.blog-comment > li:first-child {
  padding-top: 0;
}
/* Pagination style 01 */
.pagination-style-01 .page-item .page-link {
  border: 0;
  padding: 0 2px;
  margin: 0 7px;
  background: 0 0;
  color: var(--medium-gray);
  min-width: 45px;
  font-size: inherit;
  text-align: center;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  line-height: 45px;
  min-height: 45px;
}
.pagination-style-01 .page-item .page-link i {
  line-height: 40px;
}
.pagination-style-01 .page-item .page-link:hover {
  background: var(--white);
  color: var(--dark-gray);
  box-shadow: 0 0 10px rgba(23, 23, 23, 0.15) !important;
}
.pagination-style-01 .page-item .page-link:focus {
  box-shadow: none;
}
.pagination-style-01 .page-item.active .page-link {
  background: var(--dark-gray);
  color: var(--white);
  box-shadow: 0 0 10px rgba(23, 23, 23, 0.15) !important;
}
.pagination-style-01 .page-item:first-child .page-link, .pagination-style-01 .page-item:last-child .page-link {
  background: transparent;
  box-shadow: none !important;
}
.pagination-style-01.light .page-item:first-child .page-link, .pagination-style-01.light .page-item:last-child .page-link {
  background: transparent;
  box-shadow: none !important;
}
.pagination-style-01.light .page-item:first-child .page-link:hover, .pagination-style-01.light .page-item:last-child .page-link:hover {
  color: var(--white);
}
/* Blog clean */
.blog-clean .card {
  color: inherit;
}
.blog-clean .card .blog-image img {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  will-change: transform;
}
.blog-clean .card .card-body {
  color: inherit;
}
.blog-clean .card .card-body .blog-categories {
  top: -17px;
}
.blog-clean .card .card-body .blog-categories .categories-btn {
  margin: 0 5px 0 0;
  font-size: 12px;
  border-radius: 0;
}
.blog-clean .card .card-body .card-link i {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  vertical-align: middle;
  margin-left: 10px;
  line-height: 11px;
}
.blog-clean .card:hover .blog-image img {
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
}
.blog-clean .card:hover .card-body .card-link i {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
/* ===================================
   15. Portfolio
====================================== */
/* Portfolio grid */
.grid-item .portfolio-image img {
  width: 100%;
}
.portfolio-grid .work-1col li {
  width: 100%;
}
/* Portfolio filter */
.portfolio-filter li {
  padding: 0 25px;
}
.portfolio-filter li:first-child {
  padding-left: 0;
}
.portfolio-filter li:last-child {
  padding-right: 0;
}
.portfolio-filter li a {
  color: var(--medium-gray);
  border-bottom: 2px solid transparent;
}
.portfolio-filter li:hover a, .portfolio-filter li:focus a, .portfolio-filter li.active a {
  color: var(--dark-gray);
  border-color: var(--dark-gray);
}
.portfolio-filter.filter-btn li {
  padding: 0 12px;
}
.portfolio-filter.filter-btn li a {
  padding: 6px 30px;
  border-radius: 40px;
}
.portfolio-filter.filter-btn li a:hover, .portfolio-filter.filter-btn li a:focus, .portfolio-filter.filter-btn li a.active {
  background-color: var(--white);
  color: var(--dark-gray);
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
}
.portfolio-filter.filter-btn li.active a {
  background-color: var(--white);
  color: var(--dark-gray);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}
/* Portfolio classic */
.portfolio-classic .portfolio-box {
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}
.portfolio-classic .portfolio-box .portfolio-hover {
  opacity: 0;
  transform: translate3d(0, 45px, 0) skewY(-15deg);
}
.portfolio-classic .portfolio-box:hover {
  -webkit-clip-path: inset(2% 2% 2% 2%);
  clip-path: inset(2% 2% 2% 2%);
}
.portfolio-classic .portfolio-box:hover .portfolio-image img {
  opacity: 0.15;
  -webkit-filter: blur(4px);
  filter: blur(4px);
}
.portfolio-classic .portfolio-box:hover .portfolio-hover {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
/* Portfolio boxed */
.portfolio-boxed .portfolio-box {
  will-change: transform;
}
.portfolio-boxed .portfolio-box .portfolio-icon a {
  margin: 0 4px;
  position: relative;
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
}
.portfolio-boxed .portfolio-box .portfolio-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.portfolio-boxed .portfolio-box:hover {
  -webkit-box-shadow: 10px 0 70px rgba(0, 0, 0, 0.15);
  box-shadow: 10px 0 70px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.portfolio-boxed .portfolio-box:hover .portfolio-image img {
  opacity: 0.3;
}
.portfolio-boxed .portfolio-box:hover .portfolio-overlay {
  opacity: 1;
  visibility: visible;
}
/* Mousetip */
.mousetip-wrapper .caption {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9;
}
.mousetip-wrapper:hover .caption {
  opacity: 1;
  visibility: visible;
  display: block;
}
/* Portfolio transform */
.portfolio-transform .portfolio-box {
  will-change: auto;
  overflow: visible;
}
.portfolio-transform .portfolio-box .caption {
  padding: 22px 28px 11px;
  white-space: nowrap;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}
.portfolio-transform .portfolio-box .portfolio-image {
  overflow: visible;
}
.portfolio-transform .portfolio-box .portfolio-image img {
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.portfolio-transform .portfolio-box:hover .portfolio-image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* Portfolio creative */
.portfolio-creative .portfolio-box {
  will-change: transform;
}
.portfolio-creative .portfolio-box .portfolio-title {
  -webkit-transform: scale(2);
  transform: scale(2);
  display: block;
  width: 100%;
}
.portfolio-creative .portfolio-box .portfolio-title::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.portfolio-creative .portfolio-box .portfolio-title::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  right: 0;
  top: -20px;
  font-size: 110px;
  letter-spacing: -6px;
  color: rgba(0, 0, 0, 0.1);
  font-weight: 800;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  animation: glitch-skew 1s infinite linear alternate-reverse;
}
.portfolio-creative .portfolio-box .portfolio-hover span {
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
}
.portfolio-creative .portfolio-box:hover .portfolio-image img {
  opacity: 0;
}
.portfolio-creative .portfolio-box:hover .portfolio-title {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.portfolio-creative .portfolio-box:hover .portfolio-hover span {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
/* Portfolio simple */
.portfolio-simple .portfolio-box .portfolio-icon a {
  margin: 0 5px;
  position: relative;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
.portfolio-simple .portfolio-box .portfolio-icon a:last-child {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.portfolio-simple .portfolio-box:hover .portfolio-image img {
  opacity: 0.5;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* Portfolio modern */
.portfolio-modern .portfolio-box {
  position: relative;
  will-change: transform;
}
.portfolio-modern .portfolio-box .portfolio-hover {
  opacity: 0;
  left: 20px;
  bottom: 20px;
  width: calc(100% - 40px);
  height: auto;
  top: inherit;
  -webkit-transform: translateY(0) scale(1, 0);
  transform: translateY(0) scale(1, 0);
  transform-origin: bottom center;
}
.portfolio-modern .portfolio-box .portfolio-hover > * > * > * {
  opacity: 0;
  transition-delay: 0.25s;
  transform: translateY(15px);
}
.portfolio-modern .portfolio-box .portfolio-hover > * > * > * + * {
  opacity: 0;
  transition-delay: 0.35s;
}
.portfolio-modern .portfolio-box .portfolio-hover > * > * + * {
  opacity: 0;
  transform: translateY(15px);
  transition-delay: 0.45s;
}
.portfolio-modern .portfolio-box:hover {
  opacity: 1;
  -webkit-filter: blur(0);
  filter: blur(0);
}
.portfolio-modern .portfolio-box:hover .portfolio-image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.portfolio-modern .portfolio-box:hover .portfolio-hover {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(0) scale(1, 1);
  transform: translateY(0) scale(1, 1);
  opacity: 1;
}
.portfolio-modern .portfolio-box:hover .portfolio-hover > * > * > * {
  opacity: 1;
  transform: translateY(0);
}
.portfolio-modern .portfolio-box:hover .portfolio-hover > * > * > * + * {
  opacity: 1;
}
.portfolio-modern .portfolio-box:hover .portfolio-hover > * > * + * {
  opacity: 1;
  transform: translateY(0);
}
/* Portfolio attractive */
.portfolio-attractive .portfolio-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 9999;
  cursor: pointer;
}
.portfolio-attractive .portfolio-box {
  position: relative;
}
.portfolio-attractive .portfolio-box .portfolio-image img {
  -webkit-transition: all 500ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 500ms cubic-bezier(0.37, 0, 0.63, 1);
}
.portfolio-attractive .portfolio-box .portfolio-hover .icon-box {
  margin-top: 0px;
  transform-style: inherit;
}
.portfolio-attractive .grid-item:hover .portfolio-hover {
  opacity: 1;
}
.portfolio-attractive .grid-item:hover .portfolio-hover .icon-box {
  margin-top: -10px;
}
.portfolio-attractive .grid-item:hover .portfolio-image img {
  opacity: 0.3;
}
.portfolio-attractive .grid-item:hover .move-bottom-top-self span,
.portfolio-attractive .grid-item:hover .move-top-bottom-self span {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
/* Portfolio simple */
.portfolio-slider .portfolio-box {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  transition: all 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}
.portfolio-slider .portfolio-box .portfolio-image img {
  -webkit-transition: all 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  transition: all 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}
.portfolio-slider .portfolio-box .portfolio-hover {
  opacity: 1;
}
.portfolio-slider .portfolio-box .portfolio-hover .portfolio-link .category {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.portfolio-slider .portfolio-box .portfolio-hover .portfolio-link i {
  position: absolute;
  right: 0;
  will-change: transform;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 0;
}
.portfolio-slider .portfolio-box:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.portfolio-slider .portfolio-box:hover .portfolio-image img {
  opacity: 0.6;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.portfolio-slider .portfolio-box:hover .portfolio-hover.dark-hover .portfolio-title {
  color: var(--white) !important;
}
.portfolio-slider .portfolio-box:hover .portfolio-hover.dark-hover .portfolio-link a, .portfolio-slider .portfolio-box:hover .portfolio-hover.dark-hover .portfolio-link i, .portfolio-slider .portfolio-box:hover .portfolio-hover.dark-hover .portfolio-link span, .portfolio-slider .portfolio-box:hover .portfolio-hover.dark-hover .portfolio-link div {
  color: var(--white) !important;
}
.portfolio-slider .portfolio-box:hover .portfolio-hover.light-hover .portfolio-title {
  color: var(--dark-gray) !important;
}
.portfolio-slider .portfolio-box:hover .portfolio-hover.light-hover .portfolio-link a, .portfolio-slider .portfolio-box:hover .portfolio-hover.light-hover .portfolio-link i, .portfolio-slider .portfolio-box:hover .portfolio-hover.light-hover .portfolio-link span, .portfolio-slider .portfolio-box:hover .portfolio-hover.light-hover .portfolio-link div {
  color: var(--dark-gray) !important;
}
.portfolio-slider .portfolio-box:hover .portfolio-hover .portfolio-link .category {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
  visibility: hidden;
}
.portfolio-slider .portfolio-box:hover .portfolio-hover .portfolio-link i {
  -webkit-transform: translateY(-28px);
  transform: translateY(-28px);
  opacity: 1;
}
.portfolio-slider .portfolio-box:hover .portfolio-hover .box-overlay {
  opacity: 1;
  visibility: visible;
}
/* Portfolio clean */
.portfolio-clean .portfolio-box {
  position: relative;
}
.portfolio-clean .portfolio-box .portfolio-image {
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
  -webkit-transition: all 600ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 600ms cubic-bezier(0.37, 0, 0.63, 1);
}
.portfolio-clean .portfolio-box .portfolio-hover {
  -webkit-transform: translateY(35px);
  transform: translateY(35px);
  -webkit-transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 400ms cubic-bezier(0.37, 0, 0.63, 1);
}
.portfolio-clean .portfolio-box .portfolio-hover .portfolio-title {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.portfolio-clean .portfolio-box:hover .portfolio-image {
  -webkit-clip-path: inset(0 0 70px 0);
  clip-path: inset(0 0 70px 0);
}
.portfolio-clean .portfolio-box:hover .portfolio-image img {
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
}
.portfolio-clean .portfolio-box:hover .portfolio-hover {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 600ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 600ms cubic-bezier(0.37, 0, 0.63, 1);
}
/* Portfolio clean */
.portfolio-switch-image .portfolio-box .portfolio-image .hover-switch-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}
.portfolio-switch-image .portfolio-box:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.portfolio-switch-image .portfolio-box:hover .portfolio-image .hover-switch-image {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
/* Portfolio and gallery translate animation */
.portfolio-box, .gallery-box {
  overflow: hidden;
  will-change: transform;
}
.portfolio-box .move-top-bottom, .gallery-box .move-top-bottom {
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
  opacity: 0;
}
.portfolio-box .move-bottom-top, .gallery-box .move-bottom-top {
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
  opacity: 0;
}
.portfolio-box .move-left-right, .gallery-box .move-left-right {
  -webkit-transform: translateX(-15px);
  transform: translateX(-15px);
  opacity: 0;
}
.portfolio-box .move-right-left, .gallery-box .move-right-left {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
  opacity: 0;
}
.portfolio-box .move-bottom-top-self, .gallery-box .move-bottom-top-self {
  overflow: hidden;
}
.portfolio-box .move-bottom-top-self span, .gallery-box .move-bottom-top-self span {
  opacity: 0;
  display: block;
  will-change: transform;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}
.portfolio-box .move-top-bottom-self, .gallery-box .move-top-bottom-self {
  overflow: hidden;
}
.portfolio-box .move-top-bottom-self span, .gallery-box .move-top-bottom-self span {
  opacity: 0;
  display: block;
  will-change: transform;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}
.portfolio-box .move-left-right-self, .gallery-box .move-left-right-self {
  overflow: hidden;
}
.portfolio-box .move-left-right-self span, .gallery-box .move-left-right-self span {
  opacity: 0;
  display: block;
  will-change: transform;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}
.portfolio-box .move-right-left-self, .gallery-box .move-right-left-self {
  overflow: hidden;
}
.portfolio-box .move-right-left-self span, .gallery-box .move-right-left-self span {
  opacity: 0;
  display: block;
  will-change: transform;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
  -webkit-transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: all 0.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}
.portfolio-box .portfolio-hover, .gallery-box .portfolio-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}
.portfolio-box .portfolio-image, .gallery-box .portfolio-image {
  position: relative;
  overflow: hidden;
}
.portfolio-box:hover .move-top-bottom, .gallery-box:hover .move-top-bottom {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.portfolio-box:hover .move-bottom-top, .gallery-box:hover .move-bottom-top {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.portfolio-box:hover .move-left-right, .gallery-box:hover .move-left-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}
.portfolio-box:hover .move-right-left, .gallery-box:hover .move-right-left {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}
.portfolio-box:hover .move-bottom-top-self span, .gallery-box:hover .move-bottom-top-self span {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.portfolio-box:hover .move-top-bottom-self span, .gallery-box:hover .move-top-bottom-self span {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.portfolio-box:hover .move-left-right-self span, .gallery-box:hover .move-left-right-self span {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}
.portfolio-box:hover .move-right-left-self span, .gallery-box:hover .move-right-left-self span {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.portfolio-box:hover .portfolio-hover, .gallery-box:hover .portfolio-hover {
  opacity: 1;
}
/* Portfolio pagination */
.portfolio-pagination {
  position: relative;
  z-index: 1;
}
.portfolio-pagination .bg-fancy-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  visibility: hidden;
  background-size: cover;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.portfolio-pagination .btn-slide-icon-left .separator, .portfolio-pagination .btn-slide-icon-right .separator {
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.portfolio-pagination .btn-slide-icon-left .separator:after, .portfolio-pagination .btn-slide-icon-left .separator:before, .portfolio-pagination .btn-slide-icon-right .separator:after, .portfolio-pagination .btn-slide-icon-right .separator:before {
  content: "";
  display: block;
  position: absolute;
  left: -2px;
  top: -4px;
  height: 2px;
  width: 11px;
  background-color: var(--dark-gray);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.portfolio-pagination .btn-slide-icon-left .separator:after, .portfolio-pagination .btn-slide-icon-right .separator:after {
  -webkit-transform: rotate(132deg);
  transform: rotate(132deg);
}
.portfolio-pagination .btn-slide-icon-left .separator:before, .portfolio-pagination .btn-slide-icon-right .separator:before {
  -webkit-transform: rotate(228deg);
  transform: rotate(228deg);
  top: 4px !important;
}
.portfolio-pagination .btn-slide-icon-right .separator:after, .portfolio-pagination .btn-slide-icon-right .separator:before {
  right: -2px;
  left: auto;
}
.portfolio-pagination .btn-slide-icon-right .separator:before {
  transform: rotate(132deg);
  -webkit-transform: rotate(132deg);
  transform: rotate(132deg);
}
.portfolio-pagination .btn-slide-icon-right .separator:after {
  transform: rotate(48deg);
  -webkit-transform: rotate(48deg);
  transform: rotate(48deg);
}
.portfolio-pagination h6, .portfolio-pagination span {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.portfolio-pagination:hover .bg-fancy-image {
  opacity: 1;
  visibility: visible;
}
.portfolio-pagination:hover .separator {
  width: 100px !important;
  background-color: var(--white);
}
.portfolio-pagination:hover .separator:after {
  color: var(--white);
}
.portfolio-pagination:hover span {
  color: var(--white);
}
.portfolio-pagination:hover .btn-slide-icon-left .separator:after, .portfolio-pagination:hover .btn-slide-icon-left .separator:before, .portfolio-pagination:hover .btn-slide-icon-right .separator:after, .portfolio-pagination:hover .btn-slide-icon-right .separator:before {
  background-color: var(--white);
}
/* Grid loading */
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.grid-loading {
  overflow: hidden;
  position: relative;
  opacity: 1 !important;
  transition: all 0s ease-in-out !important;
  -webkit-transition: all 0s ease-in-out !important;
  z-index: 99;
}
.grid-loading::after {
  background: none;
  content: "" !important;
  position: absolute;
  top: 100px !important;
  left: calc(50% - 25px) !important;
  width: 50px !important;
  height: 50px !important;
  opacity: 1 !important;
  right: inherit !important;
  z-index: 9;
  text-align: center;
  border: 3px solid rgba(23, 23, 23, 0.2);
  border-top-color: rgba(23, 23, 23, 0.7);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}
.grid-loading:before {
  content: "";
  position: absolute;
  right: 2px;
  top: 1px;
  width: calc(100% - 4px);
  height: calc(100% - 2px);
  border-radius: inherit;
  z-index: 1;
}
.grid-loading.grid-loading-white::after {
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-top-color: rgba(255, 255, 255, 0.7);
}
.grid-loading .grid-item {
  visibility: hidden;
}
.grid-loading li .grid-item {
  overflow: hidden;
  opacity: 0;
}
/* Portfolio minimal */
.sticky-image-distortion-wrapper {
  position: static;
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.sticky-image-distortion-wrapper .svg-wrapper {
  position: fixed;
  right: 55px;
  height: 100%;
  top: 0;
  width: 50%;
  z-index: -1;
}
.sticky-image-distortion-wrapper .menu {
  width: 50%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 100px 0 100px 100px;
}
.sticky-image-distortion-wrapper .menu::-webkit-scrollbar {
  display: none;
}
.sticky-image-distortion-wrapper .menu .menu__link {
  display: flex;
  align-items: center;
  width: 100%;
}
.sticky-image-distortion-wrapper .menu .menu__link .sticky-image-title a:hover {
  opacity: 1 !important;
}
.sticky-image-distortion-wrapper .menu .menu__link svg {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
  height: 100%;
}
.sticky-image-distortion-wrapper .menu .menu__link.active svg {
  opacity: 1;
  visibility: visible;
}
.sticky-image-distortion-wrapper .menu .menu__link.active .sticky-image-title a {
  padding-left: 70px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.sticky-image-distortion-wrapper .menu .menu__link.active .sticky-image-title .title {
  opacity: 0.25;
  left: 95px;
}
.sticky-image-distortion-wrapper .menu .menu__link.active .sticky-category {
  opacity: 1;
  color: var(--dark-gray);
  left: 25px;
}
.sticky-image-distortion-wrapper .menu:hover .menu__link .sticky-image-title a {
  opacity: 0.3;
}
.sticky-image-title {
  position: relative;
  display: flex;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 0;
  width: 100%;
}
.sticky-image-title a {
  padding: 25px 50px 25px;
  width: 100%;
}
.sticky-image-title .title {
  position: absolute;
  left: 100px;
  width: 100%;
  top: 45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  word-break: normal;
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.sticky-category {
  display: flex;
  align-items: center;
  position: absolute;
  left: 32px;
  height: 100%;
  width: 50px;
  justify-content: center;
  top: 0;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.sticky-category .category {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  writing-mode: vertical-lr;
}
/* Horizontal portfolio */
.threeD-letter-menu {
  position: relative;
  min-height: 100vh;
}
.threeD-letter-menu .menu-item .hover-reveal {
  position: fixed;
  z-index: 1;
  width: 420px;
  height: 420px;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transform-origin: 100% 0%;
  will-change: transform, filter;
  border-radius: 100%;
}
.threeD-letter-menu .menu-item .hover-reveal .hover-reveal__inner {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
}
.threeD-letter-menu .menu-item .hover-reveal .hover-reveal__img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  will-change: transform;
  border-radius: 100%;
}
.threeD-letter-menu .menu-item .text-white-linear:hover span {
  color: var(--white);
  -webkit-text-fill-color: #fff;
}
.threeD-letter-menu .menu-item .menu-item-text {
  position: relative;
  display: inline-block;
}
.threeD-letter-menu .menu-item .menu-item-text .word {
  overflow: hidden;
  line-height: 120%;
}
.threeD-letter-menu .menu-item .menu-item-text .words .char {
  transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.threeD-letter-menu .menu-item .menu-item-text .clone {
  position: absolute;
  top: 0;
  left: 0;
}
.threeD-letter-menu .menu-item .menu-item-text .clone .char {
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  opacity: 0;
}
.threeD-letter-menu .menu-item .menu-item-text:hover .clone .char {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.threeD-letter-menu .menu-item .menu-item-text:hover .words:not(.clone) .char {
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
}
/* ===================================
   16. Shop
====================================== */
/* Categories style 01 */
.categories-style-01 .categories-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 1;
  -webkit-transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.categories-style-01 a {
  position: relative;
}
.categories-style-01 a:last-child:after {
  content: "";
  position: absolute;
  border-bottom: 2px solid;
  border-color: var(--dark-gray);
  left: 0;
  bottom: 0;
  width: 0%;
  -webkit-transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.categories-style-01 .count-circle {
  position: absolute;
  top: -15px;
  right: -10px;
  opacity: 0;
  -webkit-transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.categories-style-01:hover .categories-box {
  -webkit-transform: translate3d(0, -7px, 0);
  transform: translate3d(0, -7px, 0);
}
.categories-style-01:hover a:last-child:after {
  border-color: var(--dark-gray);
  color: var(--dark-gray);
  width: 100%;
}
.categories-style-01:hover .count-circle {
  opacity: 1;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.categories-style-01:hover img {
  opacity: 0.6;
}
/* Categories style 02 */
.categories-style-02 {
  -webkit-transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.categories-style-02 .categories-box {
  position: relative;
}
.categories-style-02:hover {
  transform: translate3d(0, -8px, 0);
}
/* Categories style 03 */
.categories-style-03 .categories-box {
  position: relative;
  -webkit-transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.categories-style-03 .categories-box .category-title {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 30px;
  width: 100%;
}
.categories-style-03 .categories-box .category-image {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.categories-style-03 .categories-box:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.categories-style-03 .categories-box:hover .category-image {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* Shop boxed */
.shop-boxed {
  margin-left: -10px;
  margin-right: -10px;
}
.shop-boxed .shop-box {
  border: 6px solid #fff;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.shop-boxed .shop-box .shop-image {
  overflow: hidden;
}
.shop-boxed .shop-box .shop-image a {
  display: block;
  overflow: hidden;
}
.shop-boxed .shop-box .shop-image .product-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.shop-boxed .shop-box .shop-image .shop-hover {
  position: absolute;
  bottom: 0px;
  width: 100%;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.shop-boxed .shop-box .shop-image .shop-hover a {
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
  opacity: 0;
}
.shop-boxed .shop-box .shop-footer del {
  margin-right: 8px;
}
.shop-boxed .shop-box:hover {
  -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.08);
  border: 6px solid #fff;
}
.shop-boxed .shop-box:hover .shop-image .shop-hover {
  bottom: 25px;
}
.shop-boxed .shop-box:hover .shop-image .shop-hover a {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  opacity: 1;
}
.shop-boxed .shop-box:hover .shop-image .shop-hover a:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.shop-boxed .shop-box:hover .shop-image .shop-hover a:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.shop-boxed .shop-box:hover .shop-image .shop-hover a:nth-child(4) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
/* Shop modern */
.shop-modern {
  margin-left: -15px;
  margin-right: -15px;
}
.shop-modern .grid-item .shop-box .shop-image {
  overflow: hidden;
}
.shop-modern .grid-item .shop-box .shop-image img {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.shop-modern .grid-item .shop-box .shop-image .shop-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.shop-modern .grid-item .shop-box .shop-image .shop-hover {
  position: absolute;
  top: 20px;
  width: auto;
  right: 12px;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.shop-modern .grid-item .shop-box .shop-image .shop-hover ul {
  padding: 0;
}
.shop-modern .grid-item .shop-box .shop-image .shop-hover ul li {
  display: block;
  width: auto;
  margin: 0 0 8px;
  padding: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.shop-modern .grid-item .shop-box .shop-image .shop-hover ul li:nth-child(1) {
  -webkit-transition-delay: 25ms;
  transition-delay: 25ms;
}
.shop-modern .grid-item .shop-box .shop-image .shop-hover ul li:nth-child(2) {
  -webkit-transition-delay: 50ms;
  transition-delay: 50ms;
}
.shop-modern .grid-item .shop-box .shop-image .shop-hover ul li:nth-child(3) {
  -webkit-transition-delay: 75ms;
  transition-delay: 75ms;
}
.shop-modern .grid-item .shop-box .shop-image .shop-hover ul li :nth-child(4) {
  -webkit-transition-delay: 1ms;
  transition-delay: 1ms;
}
.shop-modern .grid-item .shop-box .shop-image .shop-hover ul li a:hover {
  background-color: var(--base-color);
}
.shop-modern .grid-item .shop-box .shop-image .shop-buttons-wrap {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: calc(100% - 40px);
  text-align: center;
}
.shop-modern .grid-item .shop-box .shop-image .shop-buttons-wrap .btn {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.shop-modern .grid-item .shop-box .shop-image .shop-buttons-wrap .btn.btn-large {
  padding: 8px 20px;
}
.shop-modern .grid-item .shop-box .shop-footer del {
  margin-right: 8px;
}
.shop-modern .grid-item .shop-box:hover .shop-image img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.shop-modern .grid-item .shop-box:hover .shop-image .shop-buttons-wrap .btn {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}
.shop-modern .grid-item .shop-box:hover .shop-image .shop-overlay {
  opacity: 1;
}
.shop-modern .grid-item .shop-box:hover .shop-image .shop-hover {
  opacity: 1;
  top: 20px;
}
.shop-modern .grid-item .shop-box:hover .shop-image .shop-hover ul li {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
/* Shop sidebar */
.shop-sidebar .shop-filter {
  padding: 0;
  margin: 0;
  list-style: none;
}
.shop-sidebar .shop-filter li {
  position: relative;
  margin-bottom: 2px;
  padding: 0 25px;
}
.shop-sidebar .shop-filter li a {
  display: block;
}
.shop-sidebar .shop-filter li a:hover .product-cb {
  border-color: var(--dark-gray);
}
.shop-sidebar .shop-filter li a:hover .product-cb:after {
  opacity: 1;
}
.shop-sidebar .shop-filter li .product-cb {
  width: 16px;
  height: 16px;
  border: 1px solid var(--medium-gray);
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 8px;
}
.shop-sidebar .shop-filter li .product-cb:after {
  width: 8px;
  height: 8px;
  background-color: var(--dark-gray);
  border-radius: 100%;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.shop-sidebar .shop-filter li .item-qty {
  position: absolute;
  right: 0;
  top: 0;
  background: var(--very-light-gray);
  border-radius: 100%;
  height: 30px;
  width: 30px;
  font-size: 11px;
  text-align: center;
  font-weight: 500;
  color: var(--dark-gray);
  line-height: 30px;
}
.shop-sidebar .shop-filter li:last-child {
  margin-bottom: 0;
}
.shop-sidebar .shop-filter.color-filter li .product-cb {
  border: 0;
}
.shop-sidebar .shop-filter.color-filter li .product-cb:after {
  background-color: var(--white);
}
.shop-sidebar .shop-filter.fabric-filter li .product-cb {
  line-height: 0;
  overflow: hidden;
  border: 0;
}
.shop-sidebar .shop-filter.fabric-filter li .product-cb:after {
  background-color: var(--white);
}
.shop-sidebar .shop-filter.rating-filter li {
  padding-left: 0;
}
.shop-sidebar .shop-filter.tag-cloud a {
  padding: 5px 10px;
}
/* Toolbar wrapper */
.toolbar-wrapper a img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.toolbar-wrapper a:hover img {
  opacity: 1;
}
/* Shop lable */
.shop-image {
  position: relative;
}
.shop-image .lable {
  padding: 0 8px;
  position: absolute;
  left: 17px;
  top: 17px;
  color: var(--white);
  font-size: 9px;
  line-height: 21px;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 700;
}
.shop-image .lable.new {
  background-color: var(--green);
}
.shop-image .lable.hot {
  background-color: var(--red);
}
/* Product detail */
.product-image-thumb .swiper-slide {
  cursor: pointer;
  border: 1px solid transparent;
}
.product-image-thumb .swiper-slide.swiper-slide-thumb-active {
  border-color: var(--light-gray);
}
/* Single product thumb vertical */
.single-product-thumb .slider-vertical {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 15px;
}
.single-product-thumb .slider-vertical .swiper-slide {
  height: auto !important;
  cursor: pointer;
}
.product-image-slider img {
  cursor: crosshair;
}
/* Shop color */
.shop-color {
  list-style: none;
  padding: 0;
  display: flex;
}
.shop-color li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.shop-color li > input[type=radio] + label {
  display: flex;
}
.shop-color li > input[type=radio] + label span {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  margin-right: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}
.shop-color li input[type=radio]:checked + label span:after {
  position: absolute;
  top: 2px;
  left: 2px;
  color: var(--white);
  height: 28px;
  width: 28px;
  border: 2px solid var(--white);
  border-radius: 100%;
  text-align: center;
  content: "";
  font-weight: 900;
}
/* Shop size */
.shop-size {
  list-style: none;
  padding: 0;
  display: flex;
}
.shop-size li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}
.shop-size li > input[type=radio] + label {
  display: flex;
}
.shop-size li > input[type=radio] + label span {
  height: 37px;
  width: 37px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border: 1px solid var(--extra-medium-gray);
  text-align: center;
  font-size: 12px;
  line-height: normal;
  color: var(--dark-gray);
  font-weight: 500;
}
.shop-size li input[type=radio]:checked + label span:after {
  position: absolute;
  top: -1px;
  left: -1px;
  height: 37px;
  width: 37px;
  border: 1px solid var(--dark-gray);
  border-radius: 100%;
  text-align: center;
  content: "";
}
/* Shop quantity  */
.quantity {
  display: inline-block;
  position: relative;
}
.quantity button {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: -9px 0 0 0;
  font-size: 22px;
  line-height: 18px;
  position: absolute;
  left: 15px;
  top: 50%;
  color: var(--dark-gray);
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
.quantity button.qty-plus {
  right: 15px;
  left: auto;
}
.quantity .qty-text {
  width: 115px;
  height: 54px;
  border: 1px solid var(--extra-medium-gray);
  border-radius: 5px;
  text-align: center;
  color: var(--dark-gray);
}
.btn-cart.btn.btn-switch-text.btn-extra-large > span {
  padding: 13px 48px;
}
.wishlist {
  height: 54px;
  width: 54px;
}
/* Cart */
.cart-products th {
  border-top: 0;
  border-bottom: 1px solid var(--extra-medium-gray) !important;
  padding: 15px 10px;
  font-weight: normal;
  text-transform: capitalize;
  color: var(--dark-gray);
}
.cart-products td {
  vertical-align: middle;
  border-bottom: 1px solid var(--extra-medium-gray);
  border-top: 0;
  padding: 25px 10px;
  color: var(--medium-gray);
}
.cart-products .product-remove {
  width: 25px;
}
.cart-products .product-thumbnail {
  width: 100px;
}
.cart-products .quantity button {
  color: var(--medium-gray);
}
.cart-products .quantity .qty-text {
  color: var(--medium-gray);
  height: 42px;
  width: 100px;
}
.coupon-code-panel {
  position: relative;
}
.coupon-code-panel:before {
  content: "\e8bf";
  font-family: feather;
  position: absolute;
  left: 20px;
  z-index: 2;
  line-height: 50px;
}
.coupon-code-panel input {
  margin: 0;
  border: none;
  border: 1px dashed var(--extra-medium-gray);
  padding: 8px 70px 9px 45px;
  width: 100%;
  position: relative;
  font-size: 14px;
}
.coupon-code-panel .apply-coupon-btn {
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--dark-gray);
  line-height: 30px;
  letter-spacing: 0;
}
.coupon-code-panel .apply-coupon-btn:active {
  border-color: transparent;
}
.total-price-table th, .total-price-table td {
  border-bottom: 1px solid #e4e4e4;
  padding-top: 20px;
  padding-bottom: 20px;
  vertical-align: top;
}
.total-price-table .total-amount th, .total-price-table .total-amount td {
  border-bottom: 0;
}
.calculate-shipping .form-select:focus {
  border-color: #dfdfdf;
}
/* Checkout */
.your-order-table .product td {
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
}
.your-order-box .terms-condition .box:before {
  background-color: var(--white);
}
/* Minicart */
.header-cart-icon .header-cart .cart-item-list {
  border-radius: 6px;
  padding: 10px 30px 25px;
  min-width: 260px;
}
.header-cart-icon .header-cart .cart-item-list .cart-item {
  padding: 15px 0;
}
.header-cart-icon .header-cart .cart-item-list .cart-item .close {
  right: 0;
  top: 50%;
  font-size: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.header-cart-icon .header-cart .cart-item-list .cart-item .product-detail a {
  font-size: 15px;
  line-height: 22px;
}
.header-cart-icon .header-cart .cart-item-list .cart-item .product-detail .item-ammount {
  font-size: 14px;
}
.header-cart-icon .header-cart .cart-item-list .cart-item:last-of-type {
  border-bottom: 0;
}
.header-cart-icon .header-cart .cart-item-list .cart-total {
  background-color: transparent;
  border: 0;
  padding-bottom: 0;
  padding-top: 10px;
}
.header-cart-icon .header-cart .cart-item-list .cart-total .btn.btn-large {
  font-size: 14px;
  padding: 11px 20px;
  border-width: 1px;
}
/* Shop category style 02 */
.shop-category-02 .category-box {
  overflow: hidden;
  position: relative;
}
.shop-category-02 .category-box .category-title {
  padding: 0 15px;
  width: 100%;
}
.shop-category-02 .category-box img {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.shop-category-02 .category-box .category-hover-content {
  position: absolute;
  left: 20px;
  top: 20px;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
.shop-category-02 .category-box .category-hover-content a {
  opacity: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
}
.shop-category-02 .category-box .category-hover-content a:hover {
  color: var(--dark-gray);
}
.shop-category-02 .category-box .category-hover-content .title {
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.shop-category-02 .category-box:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.6;
}
.shop-category-02 .category-box:hover .category-hover-content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.shop-category-02 .category-box:hover .category-hover-content .title {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.shop-category-02 .category-box:hover .category-hover-content a {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
/* ===================================
   17. Footer
====================================== */
footer {
  padding-top: 110px;
  padding-bottom: 110px;
}
footer.half-footer {
  padding-top: 70px;
  padding-bottom: 70px;
}
footer.big-footer {
  padding-top: 140px;
  padding-bottom: 140px;
}
footer .footer-logo img {
  max-height: 30px;
}
footer ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
footer ul li {
  margin-bottom: 5px;
}
footer .elements-social.social-icon-style-01 li, footer .elements-social.social-icon-style-02 li, footer .elements-social.social-icon-style-05 li, footer .elements-social.social-icon-style-08 li {
  margin: 0 15px 0 0;
}
footer .elements-social.social-icon-style-01 li a, footer .elements-social.social-icon-style-02 li a, footer .elements-social.social-icon-style-05 li a, footer .elements-social.social-icon-style-08 li a {
  width: auto;
  height: auto;
}
footer .newsletter-style-05 .input-small {
  font-size: 14px;
}
footer .nav-link {
  color: var(--medium-gray);
  padding: 0.5rem 1rem;
}
footer .footer-navbar .nav-item:last-child a {
  padding-right: 0;
}
/* Footer dark  */
.footer-dark {
  color: var(--aluminium-grey);
}
.footer-dark p, .footer-dark a {
  color: var(--aluminium-grey);
}
.footer-dark a:hover {
  color: var(--base-color);
}
/* Footer light */
.footer-light a:hover {
  color: var(--base-color);
}
/* Footer navbar */
.footer-navbar li {
  display: inline-block;
  margin-bottom: 0;
}
.footer-navbar li a:hover {
  color: var(--base-color);
}
.footer-navbar li:last-child a {
  padding-right: 0;
}
.footer-navbar li:first-child a {
  padding-left: 0;
}
.footer-navbar .nav-link:focus {
  color: inherit;
}
/* Footer sticky */
.main-content {
  position: relative;
  z-index: 2;
  background: var(--white);
}
.footer-sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  overflow: hidden;
}
/* Landing page footer */
.footer-demo ul li {
  margin-bottom: 0;
  line-height: 30px;
}
.footer-demo a:hover {
  color: #fff;
}
.footer-demo img {
  max-height: 55px !important;
}